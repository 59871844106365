import { ClientType } from '../apollo'
import { types, MutationResult } from '../types'

import { updateOneJobOutput } from '@dinosband/bv-main-schema/dist/client-gql'

export async function updateJobOutput (client: ClientType, id: number, data: types.JobOutputUpdateInput): Promise<types.JobOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneJobOutput,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneJobOutputArgs,
    }) as MutationResult
    return result.data.updateOneJobOutput
  } catch (e) {
  }
}