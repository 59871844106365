import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyDontShowAgain } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readDontShowAgains (client: ClientType, userId: number): Promise<types.DontShowAgain[] | undefined> {
  try {
    const result = await client.query({
      query: findManyDontShowAgain,
      variables: {
        where: {
          userId: { equals: userId },
        },
      } as types.QueryFindManyDontShowAgainArgs,
    })
    return result.data.findManyDontShowAgain
  } catch (e) {
  }
}
