import { ClientType } from '../apollo'
import { types } from '../types'

import { q_readCrmLogs, m_createCrmLogs } from './crm-gql'

export async function readCrmLogs (client: ClientType, crmId: number): Promise<types.CustomerCommunicationLog[] | undefined> {
  try {
    const result = await client.query({
      query: q_readCrmLogs,
      variables: {
        where: {
          relationshipId: { equals: crmId },
        },
        orderBy: [
          { id: 'desc' },
        ],
      } as types.QueryFindManyCustomerCommunicationLogArgs,
    })
    return result.data.findManyCustomerCommunicationLog
  } catch (e) {
  }
}

export async function createCrmLog (client: ClientType, data: types.CustomerCommunicationLogCreateInput): Promise<types.CustomerCommunicationLog | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_createCrmLogs,
      variables: {
        data,
      } as types.MutationCreateOneCustomerCommunicationLogArgs,
    })
    return result.data.createOneCustomerCommunicationLog
  } catch (e) {
  }
}
