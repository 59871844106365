import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueFollow, findManyFollow, findManyFollowCount } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readFollow (client: ClientType, channelId: number, userId: number, followeeId: number): Promise<types.Follow | undefined> {
  try {
    const result = await client.query({
      query: findUniqueFollow,
      variables: {
        where: {
          channelId_userId_followerId: {
            channelId: channelId,
            userId: followeeId,
            followerId: userId,
          },
        },
      } as types.QueryFindUniqueFollowArgs,
    })
    return result.data.findUniqueFollow ?? undefined
  } catch (e) {
  }
}

export async function readFollowersOfUser (client: ClientType, channelId: number, userId: number, cursor?: number, take = 100): Promise<types.Follow[] | undefined> {
  const where: types.FollowWhereInput = {
    channelId: { equals: channelId },
    userId: { equals: userId },
  }
  if (cursor) {
    where.id = { lt: cursor }
  }
  const opt = {
    query: findManyFollow,
    variables: {
      where: where,
      orderBy: [
        { createdAt: 'desc' },
      ],
      take,
    } as types.QueryFindManyFollowArgs,
  }
  try {
    const result = await client.query(opt)
    return result.data.findManyFollow
  } catch (e) {
  }
}

export async function readFolloweesByUser (client: ClientType, channelId: number, userId: number, cursor?: number, take = 100): Promise<types.Follow[] | undefined> {
  const where: types.FollowWhereInput = {
    channelId: { equals: channelId },
    followerId: { equals: userId },
  }
  if (cursor) {
    where.id = { lt: cursor }
  }
  const opt = {
    query: findManyFollow,
    variables: {
      where: where,
      orderBy: [
        { createdAt: 'desc' },
      ],
      take,
    } as types.QueryFindManyFollowArgs,
  }
  try {
    const result = await client.query(opt)
    return result.data.findManyFollow
  } catch (e) {
  }
}

export async function readFollowerCountOfUser (client: ClientType, channelId: number, userId: number): Promise<number | undefined> {
  const opt = {
    query: findManyFollowCount,
    variables: {
      where: {
        channelId: { equals: channelId },
        userId: { equals: userId },
      },
    } as types.QueryFindManyFollowCountArgs,
  }
  try {
    const result = await client.query(opt)
    return result.data.findManyFollow
  } catch (e) {
  }
}