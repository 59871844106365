import loadImage from 'blueimp-load-image'

export async function getImagesize (file: File | Blob | string): Promise<{width: number, height: number}> {
  return new Promise((resolve, reject) => {
    if ((file instanceof File) || (file instanceof Blob)) {
      const fr = new FileReader
      fr.onload = function () {
        const img = new Image
        img.onload = function () {
          resolve({ width: img.width, height: img.height })
        }
        if (!fr.result) {
          resolve({ width: 256, height: 256 })
        }
        img.src = fr.result as string
      }
      fr.readAsDataURL(file)
    } else {
      const img = new Image()
      img.onload = function () {
        resolve({ width: img.width, height: img.height })
      }
      img.src = file
    }
  })
}

export async function makeImageThumbnail (file: Blob | string, maxWidth = 192, maxHeight = 192, cropAspectRatio?: number, quality?: number): Promise<string> {
  let image = file
  if (cropAspectRatio) {
    image = await crop(file, cropAspectRatio)
  }
  const result = await loadImage(image, {
    maxWidth,
    maxHeight,
    canvas: true,
  })

  const maxSize = 50_000
  if (!quality) {
    quality = 0.8
  }

  let thumbnail = ''
  while (quality > 0.2) {
    thumbnail = toDataURL(result, quality)
    if (thumbnail.length <= maxSize) {
      return thumbnail
    }
    quality -= 0.1
  }
  return thumbnail
}

export async function resizeFile (file: File | Blob | string, name: string, maxWidth: number, maxHeight: number, cropAspectRatio?: number, quality?: number): Promise<File> {
  if (file instanceof File && file.type === 'image/svg+xml') {
    return file
  }

  let image = file

  if (cropAspectRatio) {
    image = await crop(file, cropAspectRatio)
  }
  const result = await loadImage(image, {
    maxWidth,
    maxHeight,
    canvas: true,
  })

  if (!quality) {
    quality = 0.7
  }
  const newFile = toFile(await toBlob(result, quality), name)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return newFile as any as File
}

async function crop (file: Blob | string, aspectRatio: number) {
  const result = await loadImage(file, {
    aspectRatio,
  })
  return await toBlob(result, 1.0)
}

function toDataURL (result: loadImage.LoadImageResult, quality: number) {
  const canvas = result.image as unknown as HTMLCanvasElement
  return canvas.toDataURL('image/jpeg', quality)
}

async function toBlob (result: loadImage.LoadImageResult, quality: number) {
  const canvas = result.image as unknown as HTMLCanvasElement
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      blob ? resolve(blob) : reject(undefined)
    }, 'image/jpeg', quality)
  })
}

export function toFile (blob: Blob, filename: string) {
  const blobFile = {
    name: filename,
    size: blob.size,
    type: blob.type,
    blob: blob,
  }
  return blobFile
  // return new File([blob], filename)
}

export function isImageType (type: string) {
  const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml', 'image/*']
  return imageTypes.includes(type)
}
