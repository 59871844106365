
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: i18n.tc('strings.components.SiLoading'),
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
})
