import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { Components, getMode } from '@ionic/core'

export interface ActionMenuItem {
  text: string
  icon?: string
  role?: string
  handler?: () => void
}

export interface ActionMenu {
  title?: string
  items: ActionMenuItem[]
}

let _actionController: Components.IonActionSheet | Components.IonPopover | undefined = undefined

export async function showActionMenu (vue: Vue, ev: Event | undefined, menu: ActionMenu, mode?: string): Promise<string | undefined> {
  // let controller: Components.IonActionSheet | Components.IonPopover

  menu.items.push({
    text: i18n.tc('common.cancel'),
    role: 'cancel',
  })

  if (!ev) {
    mode = 'ios'
  }
  if (!mode) {
    mode = getMode()
  }

  if (mode === 'md') {
    _actionController = await vue.$ionic.popoverController.create({
      component: 'DbPopOver',
      componentProps: { menu },
      event: ev,
      cssClass: 'db-popover',
      // reference: 'event',
    })
  } else {
    _actionController = await vue.$ionic.actionSheetController.create({
      header: menu.title,
      buttons: menu.items
        .filter((item) => item.text)
        .map((item) => ({
          ...item,
          handler: async () => {
            await _actionController?.dismiss('true', item.role)
            if (item.handler) {
              item.handler()
            }
          },
        })),
    })
  }
  await _actionController.present()
  const { role } = await _actionController.onDidDismiss()
  _actionController = undefined
  return role
}

export function isActionMenuOn () {
  const isOn = !!document.querySelector('ion-popover') || !!document.querySelector('ion-action-sheet')
  if (!isOn && _actionController) {
    _actionController = undefined
  }
  return isOn
}

export async function dismissActionMenu () {
  if (_actionController) {
    await _actionController.dismiss()
  }
}
