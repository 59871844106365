import 'ionicons/dist/collection/components/icon/icon.css'

// Webpack import for ionicons
import { addIcons } from 'ionicons'
// import {
//   arrowBackSharp,
//   chevronBack,
//   chevronForward,
//   closeCircle,
//   closeSharp,
//   menu,
//   menuOutline,
//   menuSharp,
//   reorderThreeOutline,
//   reorderTwoSharp,
//   searchOutline,
//   searchSharp,
// } from 'ionicons/icons'

import arrowBackSharp from 'ionicons/dist/svg/arrow-back-sharp.svg'
import chevronBack from 'ionicons/dist/svg/chevron-back.svg'
import chevronForward from 'ionicons/dist/svg/chevron-forward.svg'
import closeCircle from 'ionicons/dist/svg/close-circle.svg'
import closeSharp from 'ionicons/dist/svg/close-sharp.svg'
import menu from 'ionicons/dist/svg/menu.svg'
import menuOutline from 'ionicons/dist/svg/menu-outline.svg'
import menuSharp from 'ionicons/dist/svg/menu-sharp.svg'
import reorderThreeOutline from 'ionicons/dist/svg/reorder-three-outline.svg'
import reorderTwoSharp from 'ionicons/dist/svg/reorder-two-sharp.svg'
import searchOutline from 'ionicons/dist/svg/search-outline.svg'
import searchSharp from 'ionicons/dist/svg/search-sharp.svg'

export function registerInternalIcons (): void {
  // Icons that are used by internal components
  addIcons({
    'arrow-back-sharp': arrowBackSharp,
    'chevron-back': chevronBack,
    'chevron-forward': chevronForward,
    'close-circle': closeCircle,
    'close-sharp': closeSharp,
    'menu': menu,
    'menu-outline': menuOutline,
    'menu-sharp': menuSharp,
    'reorder-two-sharp': reorderTwoSharp,
    'reorder-three-outline': reorderThreeOutline,
    'search-outline': searchOutline,
    'search-sharp': searchSharp,
  })
}
