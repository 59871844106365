import { ClientType } from '../apollo'
import { types } from '../types'

import { findManySysInfo, findManySecret } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readSysInfo (client: ClientType): Promise<types.SysInfo | undefined> {
  try {
    const result = await client.query({
      query: findManySysInfo,
      variables: {
        where: {},
      } as types.QueryFindManySysInfoArgs,
    })
    const infos = result.data.findManySysInfo
    if (infos?.length) {
      return infos[0]
    } else {
      return undefined
    }
  } catch (e) {
  }
}

export async function readSecrets (client: ClientType): Promise<types.Secret[] | undefined> {
  try {
    const result = await client.query({
      query: findManySecret,
      variables: {
        where: {},
      } as types.QueryFindManySecretArgs,
    })
    const secrets = result.data.findManySecret
    if (secrets) {
      return secrets
    } else {
      return []
    }
  } catch (e) {
  }
}
