import { Route, DbParams } from './interface'
import { NativeComponent, RouteTransitionDirection } from './router'

export enum PathModifierCmd {
  push = 'push',
  pop = 'pop',
  popToRoot = 'popToRoot',
  switch = 'switch',
}

interface PathModifierParamsOfPush {
  pushPath: string
}

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
interface PathModifierParamsOfPop {
  // empty
}

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
interface PathModifierParamsOfPopToRoot {
  // empty
}

interface PathModifierParamsOfSwitch {
  /* eslint-disable-next-line no-use-before-define */
  switchNode: RouteNode
}

interface PathModifier {
  cmd: PathModifierCmd
  /* eslint-disable-next-line no-use-before-define */
  target: RouteNode
  params: PathModifierParamsOfPop | PathModifierParamsOfPush | PathModifierParamsOfSwitch | PathModifierParamsOfPopToRoot
}

export enum RouteTransition {
  none = '',
  appear = 'appear',
  push = 'push',
  pop = 'pop',
  switch = 'switch',
}

const DEFAULT_CHILD = 'default'

export type OnCloseCallback = (isOK: boolean) => void

let routeNodeId = 0

interface NodeSummary {
  key: string
  children: NodeSummary[]
  over?: NodeSummary
}

export class RouteNode {
  id: number
  // route info
  //readonly path: string
  readonly route: Route | null
  protected _routeParams: DbParams | undefined
  protected _key = ''
  protected _params: DbParams | undefined
  // links
  protected _under: RouteNode | null = null // A node under this node. The node is in the previous node tree.
  protected _over: RouteNode | null = null // A node over this node. The node is in the same node tree.
  protected _level = 1
  protected _pushPath = ''
  protected _children = new Map<string, RouteNode>()
  protected _currentChildKey = ''
  protected _parent: RouteNode | null = null
  protected _path = ''
  // state
  protected _isModal = false
  protected _isDetail = false
  protected _isMounted = false
  // transitions
  transitionForward: RouteTransition = RouteTransition.none
  transitionBackward: RouteTransition = RouteTransition.none
  // tabBar
  showTabBar = true
  // title
  title = ''
  protected _nativeComponent: NativeComponent | undefined = undefined
  protected _onClose: OnCloseCallback | undefined = undefined

  constructor (route: Route | null) {
    this.id = ++routeNodeId
    this.route = route
    if (route && route._pathCmd) {
      this._key = route._pathCmd
    }
  }

  static makeComponentNode (component: string, params?: DbParams, onClose?: OnCloseCallback) {
    const node = new RouteNode({
      path: '+' + component,
      _pathCmd: component,
      component,
    })
    node.setParams(params)
    node._onClose = onClose
    return node
  }

  clone (): RouteNode {
    const node = Object.assign(new RouteNode(this.route), this)
    this.children.forEach((child, key) => {
      const childClone = child.clone()
      node.setChild(key, childClone)
    })
    if (this.over) {
      node.setOver(this.over.clone())
    }
    return node
  }

  assign (newNode: RouteNode): RouteNode {
    if (!this.isSame(newNode)) {
      if (this.component === newNode.component) {
        // component는 같고 parameter만 다른 경우
        // component는 그대로 사용되므로 일단은 같은 것으로 간주하고 필요한 내용만 복사
        this._key = newNode._key
        this._routeParams = newNode._routeParams
      } else {
        // src가 다른 node이면 src로 replace
        return newNode
      }
    }

    const children = new Map<string, RouteNode>()
    newNode.children.forEach((child, key) => {
      const myChild = this.children.get(key)
      if (myChild && child.isSameComponent(myChild)) {
        // src의 child와 같은 child가 있으면 현재의 child를 src로 대체
        const ch = myChild.assign(child)
        children.set(key, ch)
        ch._parent = this
      } else {
        // src의 child와 같은 child가 없으면 src의 child로 바로 대체
        children.set(key, child)
        child._parent = this
      }
    })
    this._children = children
    this._currentChildKey = newNode._currentChildKey

    if (newNode.over) {
      if (this.over && this.over.isSameComponent(newNode.over)) {
        // src의 over와 현재의 over가 같으면 현재의 over를 src의 over로 대체
        this.setOver(this.over.assign(newNode.over))
      } else {
        // src의 over로 바로 대체
        this.setOver(newNode.over)
      }
    } else {
      // src의 over가 없으면 over를 없는 것으로...
      this.setOver(null)
    }

    return this
  }

  get children () {
    return this._children
  }

  get currentChild () {
    return this._children.get(this._currentChildKey)
  }

  setCurrentChild (key: string) {
    this._currentChildKey = key.split('/')[0]
  }

  get onClose () {
    return this._onClose
  }

  setIsModal () {
    this._isModal = true
  }

  get isModal () {
    return this.getBottomNode()._isModal
  }

  setIsDetail () {
    this._isDetail = true
  }

  get isDetail () {
    return this.getBottomNode()._isDetail
  }

  get isMounted () {
    return this._isMounted
  }

  setMounted () {
    this._isMounted = true
  }

  setNativeComponent (comp: NativeComponent) {
    this._nativeComponent = comp
  }

  get nativeComponent () {
    return this._nativeComponent
  }

  setParams (params: DbParams | undefined) {
    this._params = params
  }

  setRouteParam (key: string, value: string, applyToKey = true): void {
    if (!this._routeParams) {
      this._routeParams = {}
    }
    this._routeParams[key] = value
    if (applyToKey) {
      this._key += '/' + value
    }
  }

  setChild (key: string, node: RouteNode): void {
    this.children.set(key, node)
    node._parent = this
    //this._key += '/' + key
    //this.children.push(node)
  }

  get component (): string {
    return this.route?.component ?? ''
  }

  get componentParams (): DbParams {
    return this._params ?? {}
  }

  get routeParams (): DbParams | undefined {
    return this._routeParams
  }

  get parent (): RouteNode | null {
    return this._parent
  }

  get over (): RouteNode | null {
    return this._over
  }

  protected setOver (node: RouteNode | null) {
    this._over = node
    if (node) {
      node._under = this
    }
  }

  get under (): RouteNode | null {
    return this._under
  }

  get level (): number {
    return this._level
  }

  get key (): string {
    return this._key
  }

  get pathKey (): string {
    return this._key.split('/')[0]
  }

  findChild (key: string): RouteNode | undefined {
    return this.children.get(key)
  }

  isContainer (): boolean {
    if (this.route && this.route._isView) {
      return true
    }
    return false
  }

  getTransition (direction: RouteTransitionDirection): RouteTransition {
    switch (direction) {
      case RouteTransitionDirection.forward:
        return this.transitionForward
      case RouteTransitionDirection.backward:
        return this.transitionBackward
      default:
        return RouteTransition.none
    }
  }

  getVisibleNode (): RouteNode {
    if (this.over) {
      return this.over.getVisibleNode()
    } else if (this.route && this.route.component) {
      return this
    } else if (this.children.has(DEFAULT_CHILD)) {
      const child = this.children.get(DEFAULT_CHILD)
      if (child) {
        return child.getVisibleNode()
      }
    }

    throw 'getVisibleNode failed: ' + this._key
  }

  getTopMostNode (): RouteNode {
    if (this.over) {
      return this.over.getVisibleNode()
    } else if (this.children.has(DEFAULT_CHILD)) {
      const child = this.children.get(DEFAULT_CHILD)
      if (child) {
        return child.getVisibleNode()
      }
    } else if (this.currentChild) {
      return this.currentChild.getVisibleNode()
    } else if (this.route?.component) {
      return this
    }

    throw 'getVisibleNode failed: ' + this._key
  }

  getBottomNode (): RouteNode {
    if (this._under) {
      return this._under.getBottomNode()
    } else {
      return this
    }
  }

  static buildNodeTreeFromPath (path: string, routes: Route[]): RouteNode {

    // remove leading '/'
    if (path.length > 0 && path[0] === '/') {
      path = path.slice(1)
    }

    // split path to path elements
    const peList = path.split('/')

    // create RouteNodes from path elements
    const node = RouteNode.buildNodeTreeFromPathElements(peList, routes, routes)
    // buildNodeTree...()에서 recursive하게 build하므로 level이 제대로 설정되지 않는다.
    // 따라서 level을 다시 만들어준다.
    node.setLevels()
    return node
  }

  setLevels () {
    this.children.forEach((child, key) => {
      child.setLevels()
    })
    if (this.over) {
      this.over._level = this._level + 1
      this.over.setLevels()
    }
  }

  //-----------------------------------------------------------------------
  // buildNodeTreeFromPathElements
  //-----------------------------------------------------------------------
  private static buildNodeTreeFromPathElements (peList: string[], routes: Route[], rootRoutes: Route[]): RouteNode {
    const _path = peList.join('/')

    // get next path element
    let pe = peList.shift()
    if (!pe) {
      throw 'Error: incomplete path'
    }

    // check for hidden node which has some node above
    // - path element starts with '_'
    let hasOver = false
    if (pe[0] == '_') {
      hasOver = true
      pe = pe.slice(1) // remove leading '_'
    }

    let showTabBar = true
    if (pe[0] === '*') {
      showTabBar = false
      pe = pe.slice(1) // remove leading '*'
    }

    const inlineParams: { key: string, value: string }[] = []
    if (pe.includes('__')) {
      const args = pe.split('__')
      pe = args[0]
      const params = args.slice(1)
      params.forEach((param) => {
        const keyValPair = param.split('=')
        if (keyValPair.length == 2) {
          inlineParams.push({
            key: keyValPair[0],
            value: keyValPair[1],
          })
        } else {
          throw 'parse error: ' + pe
        }
      })
    }

    let routeNode: RouteNode | undefined = undefined
    // component name is used as path element
    const pathCanBeComponent = routes === rootRoutes
    if (pathCanBeComponent && pe[0] == '+') {
      routeNode = new RouteNode({
        path: pe,
        component: pe.slice(1),
        _pathCmd: pe,
      })
      routeNode._path = _path
      routeNode.showTabBar = showTabBar
      inlineParams.forEach((param) => {
        routeNode?.setRouteParam(param.key, param.value)
      })
    } else {
      // find route
      const route = routes.find((r) => r._pathCmd === pe)

      // if found, set params and add to RouteNodes
      if (route) {
        routeNode = new RouteNode(route)
        routeNode._path = _path
        inlineParams.forEach((param) => {
          // routeNode?.setRouteParam(param.key, param.value, false)
          routeNode?.setRouteParam(param.key, param.value)
        })
        routeNode.showTabBar = showTabBar
        if (route._isWildcard) {
          // wildcard인 경우에는 여기에서 멈춘다.
          return routeNode
        }
        let hasChildrenParam = false
        route._pathParams?.forEach((param) => {
          if (param[0] === '$') {
            // params for child nodes
            hasChildrenParam = true
            if (param === '$') {
              // if param is just '$', param is name of view among multiple views like tabs
              if (route._childRoutes) {
                // add empty node to children
                // We add the empty nodes here even it is empty,
                // in order to preserve the order of children
                route._childRoutes.forEach((child) => {
                  if (child._pathCmd) {
                    const key = child._pathCmd
                    routeNode?.setChild(key, new RouteNode(child))
                  }
                })
                let currentChildKey = ''
                while (peList.length) {
                  let isSelected = false
                  let cmd = peList[0]
                  if (cmd[0] === '&') {
                    cmd = cmd.slice(1)
                    isSelected = true
                  }
                  peList[0] = cmd
                  const node = RouteNode.buildNodeTreeFromPathElements(peList, route._childRoutes, rootRoutes)
                  if (!node) {
                    break
                  }
                  if (node.route && routeNode) {
                    routeNode?.setChild(node.route._pathCmd || '', node)
                    if (isSelected || !currentChildKey) {
                      currentChildKey = node.key
                    }
                  }
                }
                if (routeNode) {
                  routeNode.setCurrentChild(currentChildKey)
                }
              } else {
                throw 'param is $, but route has no children'
              }
            } else {
              // otherwise, param is path of child
              let subRoutes = rootRoutes
              if (route._namedChildRoutes && route._namedChildRoutes[param]) {
                subRoutes = route._namedChildRoutes[param]
              }
              const node = RouteNode.buildNodeTreeFromPathElements(peList, subRoutes, rootRoutes)
              routeNode?.setChild(param, node)
            }
          } else {
            // normal parameters
            const pe = peList.shift()
            if (pe) {
              routeNode?.setRouteParam(param, pe)
            } else {
              throw 'Error: incomplete path (no param)'
            }
          }
        })
        // If there is no children params but the route has childRoutes, it means it has default view for children
        if (!hasChildrenParam && route._childRoutes) {
          const node = RouteNode.buildNodeTreeFromPathElements(peList, route._childRoutes, rootRoutes)
          routeNode.setChild(DEFAULT_CHILD, node)
        }
      }
    }

    if (!routeNode) {
      throw 'Something is wrong: routeNode is undefined'
    }

    // if the route is hasOver, add a node over it
    if (hasOver) {
      routeNode.pushNode(RouteNode.buildNodeTreeFromPathElements(peList, rootRoutes, rootRoutes))
      // routeNode._over = RouteNode.buildNodeTreeFromPathElements(peList, rootRoutes, rootRoutes)
      // routeNode._over._under = routeNode
    }
    return routeNode
  }

  //------------------------------------------------------
  // cloneWithPM
  //------------------------------------------------------
  protected cloneWithPM (pm: PathModifier): RouteNode {
    const isTarget = this.isSame(pm.target)
    const node = Object.assign(new RouteNode(this.route), this)

    // clone children
    node._children = new Map<string, RouteNode>()
    this.children.forEach((child, key) => {
      const childClone = child.cloneWithPM(pm)
      node.setChild(key, childClone)
      // if (isTarget && pm.cmd === PathModifierCmd.switch) {
      //   const params = pm.params as PathModifierParamsOfSwitch
      //   if (child.isSame(params.switchNode)) {
      //     node.currentChild = childClone
      //   }
      // } else if (this.currentChild === child) {
      //   node.currentChild = childClone
      // }
    })
    if (isTarget && pm.cmd === PathModifierCmd.switch) {
      const params = pm.params as PathModifierParamsOfSwitch
      node.setCurrentChild(params.switchNode.key)
    }

    // clone paths over this
    if (this.over) {
      if (pm.cmd === PathModifierCmd.popToRoot) {
        let top: RouteNode | null = this.over
        while (top) {
          if (top.isSame(pm.target)) {
            node.setOver(null)
            return node
          }
          top = top.over
        }
      }
      if (pm.cmd === PathModifierCmd.pop && this.over.isSame(pm.target)) {
        node.setOver(null)
      } else {
        node.pushNode(this.over.cloneWithPM(pm))
        // node._over = this._over.cloneWithPM(pm)
        // node._over._under = node
      }
    }
    if (pm.cmd === PathModifierCmd.push && isTarget) {
      // node._over = new RouteNode(null)
      // node._over._under = node
      const params = pm.params as PathModifierParamsOfPush
      node._pushPath = params.pushPath
    }

    return node
  }

  //------------------------------------------------------
  // getPath
  //------------------------------------------------------
  getPath (): string {
    let path = ''
    if (this.over || this._pushPath) {
      path = '_'
    }

    const appliedParams: string[] = []
    if (this.route) {
      const peList = this.route.path.split('/')
      let pe: string | undefined
      let hasChildrenParam = false
      while (peList.length > 0) {
        pe = peList.shift()
        if (!pe) {
          break
        }
        if (pe[0] === ':') {
          const key = pe.slice(1)
          if (this.routeParams && Object.prototype.hasOwnProperty.call(this.routeParams, key)) {
            path += '/' + this.routeParams[key]
            appliedParams.push(key)
          } else {
            path += '/??_' + pe
          }
        } else if (pe[0] === '$') {
          hasChildrenParam = true
          let child: RouteNode | undefined = undefined
          if (pe === '$') {
            let hasNonSelectedChild = false
            this.children.forEach((ch) => {
              if (this.currentChild !== ch && ch.over) {
                hasNonSelectedChild = true
                path += '/' + ch.getPath()
              }
            })
            const selected = hasNonSelectedChild ? '&' : ''
            path += '/' + selected + this.currentChild?.getPath()
          } else {
            const key = pe
            child = this.children.get(key)
            if (child) {
              path += '/' + child.getPath()
            } else {
              path += '/??_' + pe
            }
          }
        } else {
          path += pe
        }
      }
      if (!hasChildrenParam && this.route.children) {
        const child = this.children.get(DEFAULT_CHILD)
        if (child) {
          path += '/' + child.getPath()
        } else {
          path += '/??_' + pe
        }
      }
    }
    // add routeParams not defined in the route
    if (this._routeParams) {
      const routeParams = this._routeParams
      Object.keys(routeParams).forEach((key) => {
        if (!appliedParams.includes(key)) {
          const value = routeParams[key]
          if (typeof value === 'string') {
            path += `__${key}=${routeParams[key]}`
          }
        }
      })
    }

    if (this.over) {
      path += '/' + this.over.getPath()
    }
    if (this._pushPath) {
      path += '/' + this._pushPath
    }
    return path
  }

  toPath (pm: PathModifier): string {
    const clone = this.cloneWithPM(pm)
    return clone.getPath()
  }

  protected copyStates (preNode: RouteNode): void {
    this.showTabBar = preNode.showTabBar
  }

  isSame (node: RouteNode | null | undefined): boolean {
    return this._key === node?._key
  }

  isSameComponent (node: RouteNode | null | undefined): boolean {
    return this.component === node?.component
  }

  print (): void {
  }

  toString (indent = ''): string {
    if (this.route) {
      let str = indent + this.route.path
      if (this._routeParams) {
        for (const [key, param] of Object.entries(this._routeParams)) {
          str += `\n${indent}-- ${key}: ${param}`
        }
      }
      for (const [key, node] of Object.entries(this.children)) {
        str += `\n${indent}** ${key}: ${node.toString(indent + '  ')}`
      }
      return str
    } else {
      return 'null'
    }
  }

  getSummary (): NodeSummary {
    const summary: NodeSummary = {
      key: this.key,
      over: this.over?.getSummary(),
      children: [],
    }
    this.children.forEach((ch) => {
      summary.children.push(ch.getSummary())
    })
    return summary
  }

  pushNode (node: RouteNode) {
    node._under = this
    node._level = this._level + 1
    this.setOver(node)
  }

  popNode () {
    if (this.under) {
      this.under.setOver(null)
    } else {
      console.error('popNode: no under', this)
    }
  }

  popToRoot () {
    let node = this.getBottomNode()
    while (node.over) {
      const over = node.over
      node.setOver(null)
      node = over
    }
  }
}
