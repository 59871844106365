import { ClientType } from '../apollo'
import { ApolloQueryResult, types } from '../types'

import { createOneThumbnail } from '@dinosband/bv-main-schema/dist/client-gql'

interface Mutations {
  createOneThumbnail: types.Thumbnail
}

type Result = ApolloQueryResult<Mutations>

export async function createThumbnail (client: ClientType, thumbnail: string): Promise<types.Thumbnail | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneThumbnail,
      variables: {
        data: {
          data: thumbnail,
        },
      },
    }) as Result
    return result.data.createOneThumbnail
  } catch (e) {
  }
}

