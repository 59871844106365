import { DbFormInput, DbFormMatchType, DbFormValues } from '@dinosband/dbi-utils'
import { ClientType } from '../apollo'
import { types } from '../types'
import * as _ from 'lodash-es'

import { q_readProfile_ChannelsOfChannel, q_readProfile_ChannelsOfProfile } from './profile_channel-gql'
import { findManyProfile_ChannelCount } from '@dinosband/bv-main-schema/dist/client-gql'
import { DbFormSelectArgs } from '@dinosband/dbi-utils/src/form'

export interface ReadProfile_ChannelsOfChannelArgs {
  channelId: number
  pcId?: number
  isAdmin?: boolean
  expelled?: boolean
  excludes?: number[]
  search?: string
  userIds?: number[]
  realUser?: boolean
  expertIds?: number[]
  filter?: DbFormValues
  filterForm?: DbFormInput[]
  userInfoForm?: DbFormInput[]
  cursor?: number
  take?: number
  orderBy?: types.Profile_ChannelOrderByWithRelationInput[]
}

export async function readProfile_ChannelsOfChannel (client: ClientType, args: ReadProfile_ChannelsOfChannelArgs): Promise<types.Profile_Channel[] | undefined> {
  const variables: types.QueryFindManyProfile_ChannelArgs = {}
  const where: types.Profile_ChannelWhereInput = {
    channelId: {
      equals: args.channelId,
    },
  }
  variables.where = where
  const AND: types.Profile_ChannelWhereInput[] = []

  if (args.cursor !== undefined) {
    variables.cursor = {
      id: args.cursor,
    }
    variables.skip = 1
  }
  if (args.take) {
    variables.take = args.take
  }
  variables.orderBy = args.orderBy

  if (args.isAdmin !== undefined) {
    if (args.isAdmin) {
      where.isAdmin = { equals: true }
    } else {
      where.OR = [
        {
          isAdmin: {
            equals: false,
          },
        },
        {
          isAdmin: {
            equals: null,
          },
        },
      ]
    }
  }

  if (args.expelled !== undefined) {
    where.expelledAt = args.expelled ? {
      gt: new Date('1970-01-01'),
    } : {
      equals: null,
    }
  }

  if (args.excludes) {
    where.profileId = {
      notIn: args.excludes,
    }
  }

  if (args.search) {
    const searchWords = args.search!.trim().split(/\s+/)
    searchWords.forEach((search) => {
      type UserField = {
        field: string
        keyword: string
        maxCount?: number
      }
      const userInfoSelects = args.userInfoForm?.filter((i) => i.type === 'select') ?? []
      const userInfoFields: UserField[] = _.uniq(
        userInfoSelects
          .map((i) => ({
            field: i.id,
            maxCount: (i.args as DbFormSelectArgs).maxCount,
            keyword: ((i.args as DbFormSelectArgs).selections as string[]).find((s) => s.includes(search)) ?? '',
          }))
          .filter((f) => !!f.keyword),
      )


      AND.push({
        OR: [
          {
            profile: {
              name: {
                contains: search,
              },
            },
          },
          {
            userInfo: {
              path: ['userDescription'],
              string_contains: search,
            },
          },
          ...userInfoFields.map((f) => ({
            userInfo: {
              path: [f.field],
              [f.maxCount === 1 ? 'equals' : 'array_contains']: f.keyword,
            },
          })),
        ],
      })
    })
  }

  if (args.realUser) {
    if (args.userIds) {
      where.userId = {
        in: args.userIds,
      }
    } else {
      where.userId = {
        not: null,
      }
    }
  } else if (args.realUser === false) {
    where.userId = {
      equals: null,
    }
  }

  if (args.expertIds?.length) {
    if (args.expertIds.includes(0)) {
      AND.push({
        OR: [
          {
            expertInfos: {
              none: {},
            },
          },
          {
            expertInfos: {
              some: {
                approvedAt: { not: null },
                channelExpertId: { in: args.expertIds },
              },
            },
          },
        ],
      })
    } else {
      where.expertInfos = {
        some: {
          approvedAt: { not: null },
          channelExpertId: { in: args.expertIds },
        },
      }
    }
  }

  const filter = args.filter
  if (filter) {
    const searchAll = '@'
    const eot = ';' // end of token
    const _AND_MESG: types.Profile_ChannelWhereInput[] = [] // 각 filter들의 AND 조합

    // 각 filter value에 조건식 생성
    Object.entries(filter).forEach(([key, value]) => {
      if (key === '_sortBy') {
        if (!variables.orderBy) {
          variables.orderBy = []
        }
        const sortInfo = value as types.SortInfo
        switch (sortInfo.field) {
          case 'createdAt':
            variables.orderBy.unshift({
              createdAt: sortInfo.order,
            })
            break
          case 'reviewAverage':
            variables.orderBy.unshift({
              reviewAverage: { sort: sortInfo.order },
            })
            break
        }
      }
      if (!value && value !== false) {
        return
      }
      const splits = key.split('.')
      const field = splits[1]
      const OR: types.Profile_ChannelWhereInput[] = []
      switch (splits[0]) {
        //-----------------------------------------------------------------------
        // message filters
        //-----------------------------------------------------------------------
        case 'ik': { // infoKeywords
          OR.push({
            mesgFilterTokens: { contains: `i:${searchAll}${eot}` },
          })
          const infoKeywords = value as string[]
          infoKeywords.forEach((k) => {
            OR.push({
              mesgFilterTokens: { contains: `i:${k.toLowerCase()}` },
            })
          })
          _AND_MESG.push({ OR })
          break
        }
        case 'mk': { // matchKeywords
          OR.push({
            mesgFilterTokens: { contains: `m${field}:${searchAll}${eot}` },
          })
          const matchKeywords = value as string[]
          matchKeywords.forEach((k) => {
            OR.push({
              mesgFilterTokens: { contains: `m${field}:${k.toLowerCase()}` },
            })
          })
          _AND_MESG.push({ OR })
          break
        }
        case 'lk': { // location keywords
          OR.push({
            mesgFilterTokens: { contains: `l:${searchAll}${eot}` },
          })
          const locations = value as string[]
          locations.forEach((k) => {
            const regions = k.split(' ')
            for (let i = 0; i < regions.length; i++) {
              const region = regions.slice(0, i + 1).join(' ')
              OR.push({
                mesgFilterTokens: { contains: `lo:${region.toLowerCase()}${eot}` },
              })
            }
          })
          _AND_MESG.push({ OR })
          break
        }
        //-----------------------------------------------------------------------
        // userInfoFilter
        //-----------------------------------------------------------------------
        case 'pc': {
          const form = args.filterForm?.find((f) => f.id === key)
          const match: DbFormMatchType = form?.match ?? (form?.type === 'select' ? 'list-contain' : 'contain')
          switch (match) {
            case 'equal':
              if (Array.isArray(value)) {
                value.forEach((v) => {
                  OR.push({
                    userInfo: {
                      path: [field],
                      equals: v,
                    },
                  })
                })
                if (OR.length) {
                  AND.push({ OR })
                }
              } else {
                AND.push({
                  userInfo: {
                    path: [field],
                    equals: value,
                  },
                })
              }
              break
            case 'contain':
              AND.push({
                userInfo: {
                  path: [field],
                  string_contains: value as string,
                },
              })
              break
            case 'start':
              AND.push({
                userInfo: {
                  path: [field],
                  string_starts_with: value as string,
                },
              })
              break
            case 'list-contain':
              if (Array.isArray(value)) {
                value.forEach((v) => {
                  OR.push({
                    userInfo: {
                      path: [field],
                      array_contains: v,
                    },
                  })
                })
                if (OR.length) {
                  AND.push({ OR })
                }
              } else {
                AND.push({
                  userInfo: {
                    path: [field],
                    array_contains: [value],
                  },
                })
              }
              break
          }
          break
        }
        //-----------------------------------------------------------------------
        // profile
        //-----------------------------------------------------------------------
        case 'pf': {
          const form = args.filterForm?.find((f) => f.id === key)
          switch (form?.match) {
            case 'equal':
              AND.push({
                profile: {
                  [field]: { equals: value, mode: 'insensitive' },
                },
              })
              break
            case 'contain':
              AND.push({
                profile: {
                  [field]: { contains: value, mode: 'insensitive' },
                },
              })
              break
            case 'start':
              AND.push({
                profile: {
                  [field]: { startsWith: value, mode: 'insensitive' },
                },
              })
              break
          }
          break
        }
        //-----------------------------------------------------------------------
        // profile
        //-----------------------------------------------------------------------
        case 'cr': {
          AND.push({
            crmAsCustomers: {
              some: {
                userPcId: { equals: args.pcId },
                [field]: { equals: value },
              },
            },
          })
          break
        }
        //-----------------------------------------------------------------------
        // ETC
        //-----------------------------------------------------------------------
        case '$': {
          switch (field) {
            case 'isJoined':
              if (value) {
                AND.push({
                  profile: {
                    userId: { not: null },
                  },
                })
              }
              break
            case 'crmInterest':
              if (value) {
                AND.push({
                  crmAsCustomers: {
                    some: {
                      userPcId: { equals: args.pcId },
                      stage: { gt: 0 },
                    },
                  },
                })
              }
              break
          }
          break
        }
      }
    })
    if (_AND_MESG.length) {
      // mesgFilterTokens가 없는 경우 => 처음 만들어진 경우 => 필터 없음 => 모두 허용
      const OR: types.Profile_ChannelWhereInput[] = [
        {
          mesgFilterTokens: null,
        },
        {
          AND: _AND_MESG,
        },
      ]
      // top-level AND에 추가
      AND.push({ OR })
    }
  }

  if (AND.length) {
    where.AND = AND
  }


  try {
    const result = await client.query({
      query: q_readProfile_ChannelsOfChannel,
      variables,
    })
    return result.data.findManyProfile_Channel
  } catch (e) {
  }
}

export async function readProfile_ChannelsOfProfile (client: ClientType, profileId: number): Promise<types.Profile_Channel[] | undefined> {
  const variables = {
    where: {
      profileId: {
        equals: profileId,
      },
    },
  } as types.QueryFindManyProfile_ChannelArgs
  try {
    const result = await client.query({
      query: q_readProfile_ChannelsOfProfile,
      variables,
    })
    return result.data.findManyProfile_Channel
  } catch (e) {
  }
}

export async function readProfile_ChannelsOfUser (client: ClientType, userId: number, channelId?: number): Promise<types.Profile_Channel[] | undefined> {
  try {
    const result = await client.query({
      query: q_readProfile_ChannelsOfProfile,
      variables: {
        where: {
          userId: { equals: userId },
          channelId: channelId ? { equals: channelId } : undefined,
        },
      } as types.QueryFindManyProfile_ChannelArgs,
    })
    return result.data.findManyProfile_Channel
  } catch (e) {
  }
}

export async function readProfile_ChannelsByTelOrEmail (client: ClientType, channelId: number, tel: string, email: string): Promise<types.Profile_Channel[] | undefined> {
  try {
    const channelTel = `${tel}:${channelId}`
    const result = await client.query({
      query: q_readProfile_ChannelsOfProfile,
      variables: {
        where: {
          channelId: { equals: channelId },
          OR: [
            {
              user: {
                tel: tel ? { in: [tel, channelTel] } : undefined,
                email: email ? { equals: email } : undefined,
              },
            },
            {
              profile: {
                tel: tel ? { equals: tel } : undefined,
                email: email ? { equals: email } : undefined,
              },
            },
          ],
        },
      } as types.QueryFindManyProfile_ChannelArgs,
    })
    return result.data.findManyProfile_Channel
  } catch (e) {
  }
}

export async function readProfile_ChannelsByIds (client: ClientType, ids: number[]): Promise<types.Profile_Channel[] | undefined> {
  try {
    const result = await client.query({
      query: q_readProfile_ChannelsOfChannel,
      variables: {
        where: {
          id: { in: ids },
        },
      } as types.QueryFindManyProfile_ChannelArgs,
    })
    return result.data.findManyProfile_Channel
  } catch (e) {
  }
}

export async function checkProfile_ChannelExist (client: ClientType, where: types.Profile_ChannelWhereInput): Promise<boolean | undefined> {
  try {
    const result = await client.query({
      query: findManyProfile_ChannelCount,
      variables: {
        where,
      } as types.QueryFindManyProfile_ChannelCountArgs,
    })
    return result.data.findManyProfile_ChannelCount > 0
  } catch (e) {
  }
}