export async function delay (milliseconds: number) {
  return new Promise<void>((resolve) => {
    const timer = setTimeout(() => {
      clearTimeout(timer)
      resolve()
    }, milliseconds)
  })
}

export async function waitFor (condition: () => boolean, timeout = 3000, interval = 100): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    if (condition()) {
      resolve(true)
      return
    }

    const deadline = Date.now() + timeout
    const timer = setInterval(() => {
      if (condition()) {
        clearInterval(timer)
        resolve(true)
      }
      if (deadline < Date.now()) {
        clearInterval(timer)
        resolve(false)
      }
    }, interval)
  })
}

export async function waitForAsync (condition: () => Promise<boolean>, timeout = 5_000, interval = 500): Promise<boolean> {
  const start = Date.now()
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (await condition()) {
      return true
    }
    if (timeout <= Date.now() - start) {
      return false
    }
    await delay(interval)
  }
}

