
import Vue from 'vue'
export default Vue.extend({
  props: {
    title: {
      type: String,
      default: '',
    },
    ok: {
      type: String,
      default: 'OK',
    },
    disableOk: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: String,
      default: 'Cancel',
    },
    data: {
      type: Object,
      default: undefined,
    },
    footer: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },

  mounted () {
    if (this.fullscreen) {
      const wrapper = document.getElementsByClassName('modal-wrapper')[0] as HTMLElement
      if (wrapper) {
        wrapper.style.width = '100%'
        wrapper.style.height = '100%'
      }
    }
  },

  methods: {
    async onCancel () {
      await this.$ionic.modalController.dismiss(undefined, 'cancel')
    },

    async onOK () {
      await this.$ionic.modalController.dismiss(this.data, 'ok')
    },
  },
})
