// import { client, cache } from '../apollo'
import { ClientType } from '../apollo'
import { types, SmartSubscribe } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { onProfile_ChannelUpdated } from './profile_channel-gql'

type Callback = (newData: types.Profile_Channel) => void

export function subscribeOnProfile_ChannelUpdated (client: ClientType, userId: number, profileIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onProfile_ChannelUpdated,
    variables: {
      profileIds,
    },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      const newData = data.onProfile_ChannelUpdated as types.Profile_Channel
      debug.log('=> onProfile_ChannelUpdated: profileId, channelId = ', newData.profileId, newData.channelId)
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}

