import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueThumbnail } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readThumbnail (client: ClientType, id: number): Promise<types.Thumbnail | undefined> {
  try {
    const opt = {
      query: findUniqueThumbnail,
      variables: {
        where: { id },
      } as types.QueryFindUniqueThumbnailArgs,
    }
    const result = await client.query(opt)
    return result.data.findUniqueThumbnail
  } catch (e) {
  }
}

