import { ClientType } from '../apollo'
import { ApolloQueryResult, types } from '../types'

import { deleteOneSysPicture } from '@dinosband/bv-main-schema/dist/client-gql'

interface Mutations {
  deleteOneSysPicture: types.SysPicture
}

type Result = ApolloQueryResult<Mutations>

export async function deleteSysPicture (client: ClientType, id: number): Promise<types.SysPicture | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteOneSysPicture,
      variables: {
        where: { id },
      } as types.MutationDeleteOneSysPictureArgs,
    }) as Result
    return result.data.deleteOneSysPicture
  } catch (e) {
  }
}
