import { HttpServer } from '../http-server'

export interface PageCallRoom {
  id: string
  name: string
  type: string
  is_terminated: boolean
  meeting_time?: number
}

interface PageCallRoomResponse {
  ok: boolean
  room: PageCallRoom
}

interface PageCallUserResponse {
  ok: boolean
  user: {
    access_token: string
  }
}

type GetKeyCallback = () => Promise<string>

class PageCall {
  protected server = new HttpServer()
  protected apiKey = ''
  protected room_template_id = ''
  protected getKeyCb: undefined | GetKeyCallback = undefined

  constructor () {
    this.server.setUrl('https://api.pagecall.net/v1')
  }

  setApiKeyCb (cb: GetKeyCallback) {
    this.getKeyCb = cb
  }

  protected getUserId (userId: number) {
    return `4324slds${userId}sdfdsxi883`
  }

  protected async getApiKey () {
    if (this.apiKey) {
      return this.apiKey
    }
    if (this.getKeyCb) {
      this.apiKey = await this.getKeyCb()
      return this.apiKey
    }
    return ''
  }

  async createUser (userId: number, name: string) {
    try {
      const user_id = this.getUserId(userId)
      const apiKey = await this.getApiKey()
      const res = await this.server.post('users', {
        user_id,
        name,
      }, apiKey) as unknown as PageCallUserResponse
      if (res?.ok) {
        return res.user.access_token
      } else {
        return ''
      }
    } catch (e) {
      console.error('Error in createUser: ', e)
      return ''
    }
  }

  async updateUser (userId: number, name: string) {
    try {
      const user_id = this.getUserId(userId)
      const apiKey = await this.getApiKey()
      const res = await this.server.put(`users/${user_id}`, {
        name,
      }, apiKey) as unknown as PageCallUserResponse
      return res.ok
    } catch (e) {
      console.error('Error in updateUser: ', e)
      return false
    }
  }

  async createRoom (userIds: number[], name: string) {
    try {
      const user_ids = userIds.map((id) => this.getUserId(id))
      const apiKey = await this.getApiKey()
      const res = await this.server.post('rooms', {
        type: 'private',
        is_distinct: false,
        name,
        record: true,
        // room_template_id: '',
        user_ids,
      }, apiKey) as unknown as PageCallRoomResponse
      if (res.ok) {
        return res.room
      } else {
        return null
      }
    } catch (e) {
      console.error('Error in createRoom: ', e)
      return null
    }
  }

  async uploadImages (roomId: string, urls: string[]) {
    try {
      const apiKey = await this.getApiKey()
      const body = urls.map((url) => ({
        images: [{ src: url }],
      }))
      const res = await this.server.post(`rooms/${roomId}/pages/create_many`,
        body, apiKey) as unknown as PageCallRoomResponse
      return res.ok
    } catch (e) {
      console.error('Error in joinRoom: ', e)
      return false
    }
  }

  async readRoom (roomId: string) {
    try {
      const apiKey = await this.getApiKey()
      const res = await this.server.get(`rooms/${roomId}`, apiKey) as unknown as PageCallRoomResponse
      if (res.ok) {
        return res.room
      } else {
        return null
      }
    } catch (e) {
      console.error('Error in readRoom: ', e)
      return null
    }
  }

  async joinRoom (roomId: string, userId: number) {
    try {
      const apiKey = await this.getApiKey()
      const user_id = this.getUserId(userId)
      const res = await this.server.post(`rooms/${roomId}/members`, {
        user_id,
        options: {
          muteVideo: true,
        },
      }, apiKey) as unknown as PageCallRoomResponse
      return res.ok
    } catch (e) {
      console.error('Error in joinRoom: ', e)
      return false
    }
  }

  async closeRoom (roomId: string) {
    try {
      const apiKey = await this.getApiKey()
      const res = await this.server.put(`rooms/${roomId}`, {
        is_terminated: true,
      }, apiKey) as unknown as PageCallRoomResponse
      return res.ok
    } catch (e) {
      console.error('Error in closeRoom: ', e)
      return false
    }
  }
}

export const pageCall = new PageCall()