import gql from 'graphql-tag'

import { Message, QueryFilter } from '@dinosband/bv-messenger-schema'
import * as types from '@dinosband/bv-messenger-schema'
import { ClientType } from '@dinosband/bv-apollo-client'

import { _MESSAGE_DATA_PROPERTIES } from './const'

import { _first, _sortBy } from '@dinosband/dbi-utils'
import { messageQueryFilter } from './message-filter'

const _DEBUG_LOG = process.env.NODE_ENV !== 'production' &&
                   !!process.env.VUE_APP_BV_ADDRESS_DEBUG_LOG

// export interface MessageQueryFilterType {
//   mesgFilter?: MesgFilter
//   option?: ChatRoomQueryOption
// }

export async function queryMessageDetail (apollo: ClientType, chatRoomId: number, id: number) {
  try {
    const result = await apollo.query<{message: Message}>({
      query: gql`query message($chatRoomId: Int, $id: Int) {
      message(chatRoomId: $chatRoomId, id: $id) {
        ${_MESSAGE_DATA_PROPERTIES}
      }
    }`,
      variables: { id, chatRoomId },
    })
    return result.data.message
  } catch (e) {
  }
}

function _nextId (id?: number) {
  return id == undefined ? undefined : id + 1
}

function _cached (range?: { min: number, max: number}) {
  return range ? { gte: range.min, lte: range.max } : undefined
}

export async function queryMessages (apollo: ClientType, chatRoomIds: number[] | undefined, size: number,
                                     direction: 'before' | 'after' | 'around' | 'recent', messageId?: number,
                                     cachedRange?: { min: number, max: number },
                                     filter?: QueryFilter) {

  const cached = _cached(cachedRange)

  const args: types.QueryMessagesArgs = {}
  if (direction === 'recent' || !messageId) {
    args.where = { id: { gte: _nextId(messageId) } }
    args.order = { id: types.OrderValue.Desc }
  } else if (direction === 'after') {
    args.where = { id: { gte: messageId + 1 } }
    args.order = { id: types.OrderValue.Asc }
  } else if (direction === 'before') {
    args.where = { id: { lte: messageId - 1 } }
    args.order = { id: types.OrderValue.Desc }
  } else { // 'around'
    return await queryMessagesAround(apollo, chatRoomIds, size, messageId, cached, filter)
  }
  args.where = { ...args.where, chatRoomId: chatRoomIds }
  args.limit = size
  if (cached) {
    // args.where.id = { ...args.where.id, cached }
    args.where.seq = { cached }
  }
  const filterWhere = messageQueryFilter(filter)
  if (filterWhere) {
    args.where = { ...args.where, ...filterWhere }
  }

  return await _queryMessages(apollo, args)
}

export async function queryFirstMessageId (apollo: ClientType, chatRoomId: number, filter?: QueryFilter) {
  return await _queryEdgeMessageId(apollo, chatRoomId, true, filter)
}

export async function queryLastMessageId (apollo: ClientType, chatRoomId: number, filter?: QueryFilter) {
  return await _queryEdgeMessageId(apollo, chatRoomId, false, filter)
}

async function _queryEdgeMessageId (apollo: ClientType, chatRoomId: number, isFirst: boolean, filter?: QueryFilter) {
  const args: types.QueryMessagesArgs = {
    where: {
      chatRoomId: [chatRoomId],
    },
    order: { id: isFirst ? types.OrderValue.Asc : types.OrderValue.Desc },
    limit: 1,
  }
  const filterWhere = messageQueryFilter(filter)
  if (filterWhere) {
    args.where = { ...args.where, ... filterWhere }
  }
  try {
    const result = await apollo.query<{messages: Message[]}>({
      query: gql`query messages($where: MessagesWhereInput, $order: MessagesOrderInput, $limit: Int) {
      messages(where: $where, order: $order, limit: $limit) {
        id
        seq
        chatRoomId
        profileId
        createdAt
        updatedAt
        deletedAt
        deleteReason
      }
    }`,
      variables: {
        ...args,
      },
      fetchPolicy: 'no-cache',
    })
    return _first(result.data.messages)
  } catch (e) {
  }
}

async function queryMessagesAround (apollo: ClientType, chatRoomId: number[] | undefined, size: number,
                                    messageId: number, cached?: types.MessageCachedIdInput, filter?: QueryFilter) {
  const limit = Math.floor(size / 2)
  let where: types.MessagesWhereInput = {
    chatRoomId,
    seq: { cached },
  }
  const filterWhere = messageQueryFilter(filter)
  if (filterWhere) {
    where = { ...where, ...filterWhere }
  }
  const args1: types.QueryMessagesArgs = {
    where: {
      ...where,
      id: { lte: messageId },
    },
    order: { id: types.OrderValue.Desc },
  }
  const args2: types.QueryMessagesArgs = {
    where: {
      ...where,
      id: { gte: messageId + 1 },
    },
    order: { id: types.OrderValue.Asc },
  }
  try {
    const result = await apollo.query({
      query: gql`query messages($where1: MessagesWhereInput, $order1: MessagesOrderInput, $where2: MessagesWhereInput, $order2: MessagesOrderInput, $limit1: Int, $limit2: Int) {
      messages_1: messages(where: $where1, order: $order1, limit: $limit1) {
        ${_MESSAGE_DATA_PROPERTIES}
      }
      messages_2: messages(where: $where2, order: $order2, limit: $limit2) {
        ${_MESSAGE_DATA_PROPERTIES}
      }
    }`,
      variables: {
        where1: args1.where,
        order1: args1.order,
        limit1: limit + 1,
        where2: args2.where,
        order2: args2.order,
        limit2: limit,
      },
      fetchPolicy: 'no-cache',
    })
    const messages_1 = _sortBy(result.data.messages_1, (i) => i.id) as Message[]
    const messages_2 = _sortBy(result.data.messages_2, (i) => i.id) as Message[]
    const messages = [...messages_1, ...messages_2]
    return messages
  } catch (e) {
  }
}

export async function queryMessagesByIds (apollo: ClientType, ids: number[]) {
  const where: types.MessagesWhereInput = {
    id: { in: ids },
  }
  return await _queryMessages(apollo, { where })
}

// export async function queryMessagesBySeqs (apollo: ClientType, chatRoomId: number[], seqs: number[]) {
//   const where: types.MessagesWhereInput = {
//     chatRoomId,
//     seq: { in: seqs },
//   }
//   return await _queryMessages(apollo, { where })
// }

// export async function queryMessagesBySeqRange (apollo: ClientType, chatRoomId: number[], range: { gte: number, lte: number }) {
//   const where: types.MessagesWhereInput = {
//     chatRoomId,
//     seq: range,
//   }
//   return await _queryMessages(apollo, { where })
// }

export async function _queryMessages (apollo: ClientType, args: types.QueryMessagesArgs) {
  if (!apollo) {
    return []
  }

  try {
    const result = await apollo.query<{messages: Message[]}>({
      query: gql`query messages($where: MessagesWhereInput, $order: MessagesOrderInput, $limit: Int) {
      messages(where: $where, order: $order, limit: $limit) {
        ${_MESSAGE_DATA_PROPERTIES}
      }
    }`,
      variables: {
        ...args,
      },
      fetchPolicy: 'no-cache',
    })
    const messages = _sortBy(result.data.messages, (obj) => obj.id)
    return messages
  } catch (e) {
  }
}

