import { MesgFilter, MesgFilterInfo } from '@dinosband/bv-main-schema/dist/types'
import { UnreadCount, UnreadNotiCount, UnreadNotiCount_Empty } from '@dinosband/bv-messenger-schema'

import { settings_property, secure_property } from './property-decorator'
import { DbFormValues } from '@dinosband/dbi-utils'

class BvSettings {

  private initializing: Promise<void>[] = []

  @settings_property('number')
  userId = 0

  @settings_property('number')
  badge = 0

  /*
    { [chatRoomId]: UnreadCount }
    chatRoomId = 0 is for the notificaitons tab.
    To update,
    ```
      settings.unreadCounts = {
        ...settings.unreadCounts,
        [this.chatRoomId]: {
          chatRoomId,
          unreadCount,
          lastMessageId,
        }
      }
    ```
   */
  @settings_property('object')
  unreadCounts: Record<number, UnreadCount> = {}

  @settings_property('object')
  unreadNotiCount: UnreadNotiCount = UnreadNotiCount_Empty

  @settings_property('number')
  lastMessageId = 0

  @settings_property('number')
  lastSysNotiId = 0

  // @deprecated: use mesgFilterInfo
  @settings_property('object')
  messageFilters: Record<number, MesgFilter> = {} // { [chatRoomId]: MesgFilter }

  // @deprecated: use mesgFilterInfo
  @settings_property('object')
  chatRoomIsFiltered: Record<number, boolean> = {} // { [chatRoomId]: isFiltered }}

  @settings_property('object')
  /* { [key]: MesgFilterInfo }
   * key is chatRoomId or unique string
   * ex) 10, channel_23:1
  */
  mesgFilterInfo: Record<string, MesgFilterInfo> = {}

  @settings_property('object')
  expertListFilter: Record<string, DbFormValues> = {}

  @secure_property('string')
  authToken = ''

  @secure_property('boolean')
  development = false

  @settings_property('boolean')
  skipIntro = false

  @settings_property('string')
  ackVersion = ''

  @settings_property('string')
  preVersion = ''

  @settings_property('string')
  nextVersion = ''

  @settings_property('number')
  signOnCount = 0

  @settings_property('number')
  signOnBlock = 0

  @settings_property('string')
  sysInfo = ''

  @settings_property('string')
  userGraph = ''

  @settings_property('number')
  userGraphVersion = 0

  @settings_property('string')
  categories = ''

  @settings_property('string')
  userUsage = ''

  @settings_property('number')
  tooManyNotisAck = 0

  @settings_property('number')
  referrerId = 0

  @settings_property('string')
  uuid = ''

  @settings_property('boolean')
  logFirstRun = false

  @settings_property('boolean')
  joinChannelAck = false

  @settings_property('string')
  thumbnails = ''

  @settings_property('string')
  pluginDataEnc = ''

  @settings_property('object')
  chatRoomSearchHistory: string[] = []

  @settings_property('boolean')
  fullViewMode = true

  @settings_property('string')
  blockedChannelIds = ''

  @settings_property('number')
  lastChannelId = 0

  @settings_property('string')
  appReviewAt = ''

  @settings_property('string')
  appReviewVersion = ''

  @settings_property('number')
  appReviewCount = 0

  @settings_property('object')
  hideSlotDashboardPerChannel: Record<number, boolean> = {}

  @settings_property('object')
  hideSlotContentsPerChannel: Record<number, boolean> = {}

  @settings_property('object')
  latestChatRoomIds: Record<number, number | string> = {} // channelId:chatRoomId

  @settings_property('object')
  welcomeInfo: Record<number, Record<string, unknown> | undefined> = {} // channelId:chatRoomId

  @settings_property('string')
  previewChatRoom = ''

  @settings_property('string')
  previewExpertList = ''

  @settings_property('string')
  dictionary = '[]'

  async initialize () {
    for (const i of this.initializing) {
      await i
    }
  }

  /*
  set (name: string, key: string | number, value: unknown) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const this_ = this as any
    const obj = this_[name]
    if (!obj) {
      return
    }
    obj[key] = value
    this_[name] = obj
  }

  remove (name: string, key: string | number) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const this_ = this as any
    const obj = this_[name]
    if (!obj) {
      return
    }
    delete obj[key]
    this_[name] = obj
  } */

}

const settings = new BvSettings()
export default settings

