import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { debug } from '@dinosband/dbi-utils'

import {
  onUser_ChatRoomViewUpdated,
  onUser_ChatRoomViewAdded,
} from './user_chatroom_view-gql'

export type ChatRoomViewCallback = (newData: types.User_ChatRoomView) => void

export function subscribeOnUser_ChatRoomViewUpdated (client: ClientType, chatRoomId: number, userId: number, cb: ChatRoomViewCallback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onUser_ChatRoomViewUpdated,
    variables: { chatRoomId, userId },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      debug.log('=> onUser_ChatRoomViewUpdated')
      const newData = data.onUser_ChatRoomViewUpdated as types.User_ChatRoomView
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}

export function subscribeOnUser_ChatRoomViewAdded (client: ClientType, chatRoomId: number, userId: number, cb: ChatRoomViewCallback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onUser_ChatRoomViewAdded,
    variables: { chatRoomId, userId },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      // debug.log('=> onUser_ChatRoomViewAdded')
      const newData = data.onUser_ChatRoomViewAdded as types.User_ChatRoomView
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
