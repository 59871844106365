import { ClientType } from '../apollo'
import { types } from '../types'

import { createOneAdvertisement } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createAdvertisement (client: ClientType, data: types.AdvertisementCreateInput): Promise<types.Advertisement | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneAdvertisement,
      variables: {
        data,
      } as types.MutationCreateOneAdvertisementArgs,
    })
    return result.data.createOneAdvertisement as types.Advertisement
  } catch (e) {
  }
}
