import { ClientType } from '../apollo'
import { types, MutationResult } from '../types'

import { createOneSysNotification } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createSysNotification (client: ClientType, data: types.SysNotificationCreateInput): Promise<types.SysNotification | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneSysNotification,
      variables: { data },
    }) as MutationResult
    return result.data.createOneSysNotification
  } catch (e) {
  }
}
