import { ClientType } from '../apollo'
import { types } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { findUniqueChatRoomBrief, findUniqueChatRoomDetail } from './chatroom-gql'
import { findUniqueChatRoom } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readChatRoom (client: ClientType, id: number): Promise<types.ChatRoom | undefined> {
  try {
    const result = await client.query({
      query: findUniqueChatRoom,
      variables: {
        where: {
          id,
        },
      } as types.QueryFindUniqueChatRoomArgs,
    })
    return result.data.findUniqueChatRoom
  } catch (e) {
    debug.error('readChatRoom: error = ', e)
  }
}

export async function readChatRoomBrief (client: ClientType, id: number): Promise<types.ChatRoom | undefined> {
  try {
    const result = await client.query({
      query: findUniqueChatRoomBrief,
      variables: {
        where: {
          id,
        },
      } as types.QueryFindUniqueChatRoomArgs,
    })
    return result.data.findUniqueChatRoom
  } catch (e) {
    debug.error('readChatRoomBrief: error = ', e)
  }
}

export async function readChatRoomDetail (client: ClientType, id: number): Promise<types.ChatRoom | undefined> {
  debug.log('readChatRoomDetail: ', id)
  try {
    const result = await client.query({
      query: findUniqueChatRoomDetail,
      variables: {
        where: {
          id,
        },
      } as types.QueryFindUniqueChatRoomArgs,
    })
    return result.data.findUniqueChatRoom
  } catch (e) {
    debug.error('readChatRoomDetail: error = ', e)
  }
}
