import { ClientType } from '../apollo'
import { types } from '../types'

import { q_findManyJobOfferResponse, q_findManyJobOfferResponseWithPayments } from './job-offer-gql'

export async function readJobOfferResponses (client: ClientType, variables: types.QueryFindManyJobOfferResponseArgs) {
  try {
    const result = await client.query<{findManyJobOfferResponse: types.JobOfferResponse[]}>({
      query: q_findManyJobOfferResponse,
      variables,
    })
    return result.data.findManyJobOfferResponse
  } catch (e) {
  }
}

export async function readMyJobOfferResponses (client: ClientType, profileIds: number[]) {
  try {
    const result = await client.query<{findManyJobOfferResponse: types.JobOfferResponse[]}>({
      query: q_findManyJobOfferResponse,
      variables: {
        where: {
          providerId: { in: profileIds },
        },
        orderBy: [
          { id: 'desc' },
        ],
      } as types.QueryFindManyJobOfferResponseArgs,
    })
    return result.data.findManyJobOfferResponse
  } catch (e) {
  }
}

export async function readJobOfferResponsesWithPayments (client: ClientType, where: types.JobOfferResponseWhereInput, cursor?: number, take?: number) {
  try {
    const result = await client.query<{findManyJobOfferResponse: types.JobOfferResponse[]}>({
      query: q_findManyJobOfferResponseWithPayments,
      variables: {
        where,
        orderBy: [
          { id: 'desc' },
        ],
        take,
        cursor,
        skip: cursor ? 1 : 0,
      } as types.QueryFindManyJobOfferResponseArgs,
    })
    return result.data.findManyJobOfferResponse
  } catch (e) {
  }
}
