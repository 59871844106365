import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
// import * as frag from '../fragments-gql'

export const q_readCustomerRelationships = gql`
  query q_readCustomerRelationships($where: CustomerRelationshipWhereInput!) {
    findManyCustomerRelationship(where: $where) {
      ...CustomerRelationshipFields
    }
  }
  ${client.CustomerRelationshipFields}
`

export const q_readCrmLogs = gql`
  query q_readCrmLogs($where: CustomerCommunicationLogWhereInput!, $orderBy: [CustomerCommunicationLogOrderByWithRelationInput!]) {
    findManyCustomerCommunicationLog(where: $where, orderBy: $orderBy) {
      ...CustomerCommunicationLogFields
    }
  }
  ${client.CustomerCommunicationLogFields}
`

export const m_createCrmLogs = gql`
  mutation m_createCrmLogs($data: CustomerCommunicationLogCreateInput!) {
    createOneCustomerCommunicationLog(data: $data) {
      ...CustomerCommunicationLogFields
      relationship {
        ...CustomerRelationshipFields
      }
    }
  }
  ${client.CustomerCommunicationLogFields}
  ${client.CustomerRelationshipFields}
`

export const onCustomerRelationshipUpdated = gql`
  subscription onCustomerRelationshipUpdated($userId: Int!) {
    onCustomerRelationshipUpdated (userId: $userId) {
      ...CustomerRelationshipFields
    }
  }
  ${client.CustomerRelationshipFields}
`