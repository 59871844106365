import _Vue from 'vue'
import { applyPolyfills, defineCustomElements } from '@ionic/core/loader'
import { setupConfig, IonicConfig } from '@ionic/core'
import '@ionic/core/css/ionic.bundle.css'
import { defineCustomElements as defineIconElements } from 'ionicons/dist/loader'

import { createComponents } from './components'
import { createControllers, IonicControllers } from './controllers'
import { vueDelegate, registerInternalIcons } from './utils'

// interface VueIonicPluginOptions extends IonicConfig {
// }
type VueIonicPluginOptions = IonicConfig

function install (Vue: typeof _Vue, config: VueIonicPluginOptions): void {
  Vue.config.ignoredElements.push(/^ion-/)

  setupConfig(config)

  applyPolyfills().then(() => {
    defineIconElements(window)
    defineCustomElements(window)
  })
  /*
  applyPolyfills().then(() => defineCustomElements(window, {
    exclude: ['ion-tabs', 'ion-page'],
  }))
  */

  createComponents(vueDelegate)
  const controllers = createControllers(vueDelegate)
  registerInternalIcons()

  Object.defineProperty(Vue.prototype, '$ionic', {
    get () {
      return {
        ...controllers,
      }
    },
  })
}

export const VueIonicPlugin = {
  install,
  version: '__VERSION__',
}

declare module 'vue/types/vue' {
  interface Vue {
    $ionic: IonicControllers
  }
}

export { vueDelegate } from './utils'