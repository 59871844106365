import Vue from 'vue'
import { doModal } from './modal'
import { isPageModal } from './page'

function showOnDetail (vue: Vue | undefined) {
  const router = Vue.prototype.$dbiRouter
  return router.hasDetailPane
  // if (vue) {
  //   return router.hasDetailPane && !router.inDetailPane(vue)
  // } else {
  //   return router.hasDetailPane
  // }
}

export async function doModalOrDetail (
  vue: Vue,
  component: string,
  data: Record<string, unknown> | undefined = undefined,
  mode?: string,
  swipeToClose = true,
  backdropDismiss = true,
  cssClass = 'dbi-modal',
): Promise<unknown|null> {
  const router = Vue.prototype.$dbiRouter
  if (!isPageModal(vue) && showOnDetail(vue) && !router.inDetailPane(vue)) {
    router.showOnDetail(vue, component, data)
    return null
  } else {
    return await doModal(vue, component, data, mode, swipeToClose, backdropDismiss, cssClass)
  }
}