import { ClientType } from '../apollo'
import { types, MutationResult } from '../types'

import { createOneJobOutput } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createJobOutput (client: ClientType, data: types.JobOutputCreateInput): Promise<types.JobOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneJobOutput,
      variables: {
        data,
      } as types.MutationCreateOneJobOutputArgs,
    }) as MutationResult
    return result.data.createOneJobOutput
  } catch (e) {
  }
}