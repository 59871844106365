
import Vue from 'vue'
import { Components } from '@ionic/core'
import { checkNavStack } from './nav-stack'
import { RouterEvent } from './router-event'

export default Vue.extend({
  data () {
    return {
      loaded: false,
    }
  },

  methods: {
    onNavWillLoad () {
      if (!this.loaded) {
        this.loaded = true
        const ref = this.$refs.nav as Vue
        const nav = ref?.$el as unknown as Components.IonNav
        if (nav) {
          nav.setRoot('DbiRouterView')
        } else {
          throw 'Failed to find nav'
        }
      }
    },

    async onNavDidChange () {
      // await this.$dbiRouter.checkStack()
      RouterEvent.$emit('nav-did-change')
      // RouterEvent.$emit('detail-pane')
      const ref = this.$refs.nav as Vue
      const nav = ref?.$el as unknown as Components.IonNav
      if (nav) {
        // const active = await nav.getActive()
      }
      if (!this.$dbiRouter.useIonicRouter) {
        const ref = this.$refs.nav as Vue
        const nav = ref?.$el as unknown as Components.IonNav
        if (nav) {
          await checkNavStack(this, nav)
        }
      }
    },
  },
})
