import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
import * as frag from '../fragments-gql'

export const findChannelByName = gql`
  query findChannelByName($where: ChannelWhereUniqueInput!) {
    findUniqueChannel(where: $where) {
      id
    }
  }
`

export const findUniqueChannelGraph = gql`
  query findUniqueChannelGraph($where: ChannelWhereUniqueInput!) {
    findUniqueChannel(where: $where) {
      ...ChannelGraph
    }
  }
  ${frag.ChannelGraph}
`

export const findMyChannelGraphs = gql`
  query findMyChannelGraphs($where: ChannelWhereInput!) {
    findMyChannels(where: $where) {
      ...ChannelGraph
    }
  }
  ${frag.ChannelGraph}
`

export const findManyChannelCounts = gql`
  query findManyChannelCounts($where: ChannelWhereInput!) {
    findManyChannel(where: $where) {
      id
      profileCount
    }
  }
`

export const findSuggestedChannels = gql`
  query findSuggestedChannels($joinedChannelIds: [Int!]!) {
    findSuggestedChannels(joinedChannelIds: $joinedChannelIds) {
      ...ChannelFields
    }
  }
  ${client.ChannelFields}
`

export const createChannel = gql`
  mutation createChannel($profileId: Int!, $data: ChannelCreateInput!) {
    createChannel(profileId: $profileId, data: $data) {
      ...ChannelGraph
      chatRooms {
        ...ChatRoomFields
      }
      profiles {
        ...Profile_ChannelFields
        channel {
          ...ChannelFields
        }
      }
    }
  }
  ${frag.ChannelGraph}
  ${client.ChatRoomFields}
  ${client.Profile_ChannelFields}
  ${client.ChannelFields}
`

export const m_updateChannel = gql`
  mutation m_updateChannel($where: ChannelWhereUniqueInput!, $data: ChannelUpdateInput!) {
    updateOneChannel(where: $where, data: $data) {
      ...ChannelGraph
    }
  }
  ${frag.ChannelGraph}
`

export const onChannelUpdated = gql`
  subscription onChannelUpdated($channelIds: [Int]!) {
    onChannelUpdated(channelIds: $channelIds) {
      ...ChannelGraph
    }
  }
  ${frag.ChannelGraph}
`
// export const m_updateChannel = gql`
//   mutation m_updateChannel($where: ChannelWhereUniqueInput!, $data: ChannelUpdateInput!) {
//     updateOneChannel(where: $where, data: $data) {
//       # ...ChannelFields
//       # owner {
//       #   ...ProfileFields
//       # }
//       # experts {
//       #   ...ChannelExpertFields
//       # }
//       # chatRoomMenus {
//       #   ...ChannelChatRoomMenuFields
//       #   chatRoom {
//       #     ...ChatRoomFields
//       #   }
//       # }
//     }
//   }
//   # ${client.ChannelFields}
//   # ${client.ProfileFields}
//   # ${client.ChannelExpertFields}
// `

// export const onChannelUpdated = gql`
//   subscription onChannelUpdated($channelIds: [Int]!) {
//     onChannelUpdated(channelIds: $channelIds) {
//       # ...ChannelFields
//       # owner {
//       #   ...ProfileFields
//       # }
//       # experts {
//       #   ...ChannelExpertFields
//       # }
//       # chatRoomMenus {
//       #   ...ChannelChatRoomMenuFields
//       #   chatRoom {
//       #     ...ChatRoomFields
//       #   }
//       # }
//     }
//   }
//   # ${client.ChannelFields}
//   # ${client.ProfileFields}
//   # ${client.ChannelExpertFields}
//   # ${client.ChannelChatRoomMenuFields}
//   # ${client.ChatRoomFields}
// `
