import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyUserLog } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readUserLogs (client: ClientType, from: Date | undefined, to: Date | undefined): Promise<types.UserLog[] | undefined> {
  const where: types.UserLogWhereInput = {}
  if (from && to) {
    where.AND = [
      { createdAt: { gte: from } },
      { createdAt: { lte: to } },
    ]
  } else if (from) {
    where.createdAt = { gte: from }
  } else if (to) {
    where.createdAt = { lte: to }
  }

  try {
    const result = await client.query({
      query: findManyUserLog,
      variables: {
        where,
        orderBy: [{ id: 'asc' }],
      } as types.QueryFindManyUserLogArgs,
    })
    return result.data.findManyUserLog
  } catch (e) {
  }

}
