import { ClientType } from '../apollo'
import { types } from '../types'

import { findManySysPicture } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readSysPictures (client: ClientType, type: types.SysPictureType): Promise<types.SysPicture[] | undefined> {
  try {
    const result = await client.query({
      query: findManySysPicture,
      variables: {
        where: { type: { equals: type } },
      } as types.QueryFindManySysPictureArgs,
    })
    return result.data.findManySysPicture
  } catch (e) {
  }
}

