import { ClientType } from '../apollo'
import { types } from '../types'

import { createOneChannelChatRoomMenu } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createChatRoomMenu (client: ClientType, channelId: number, chatRoomId: number, input: types.ChannelChatRoomMenuCreateWithoutChannelInput): Promise<types.ChannelChatRoomMenu | undefined> {
  try {
    const data: types.ChannelChatRoomMenuCreateInput = {
      ...input,
      channel: { connect: { id: channelId } },
    }
    if (chatRoomId) {
      data.chatRoom = { connect: { id: chatRoomId } }
    }
    const result = await client.mutate({
      mutation: createOneChannelChatRoomMenu,
      variables: {
        data,
      } as types.MutationCreateOneChannelChatRoomMenuArgs,
    })
    return result.data.createOneChannelChatRoomMenu
  } catch (e) {
  }
}
