import { Animation, createAnimation } from '@ionic/core'

const MIN_PRESENTING_SCALE = 0.93 // from SwipeToCloseDefaults

/* eslint-disable @typescript-eslint/no-non-null-assertion */

const getScale = (aniStep: number) => {
  const delta = 1.0 - MIN_PRESENTING_SCALE
  const deltaStep = delta * aniStep
  return 1.0 - deltaStep
}

/**
 * iOS Modal Leave Animation
 */
export const iosBottomSheetLeaveAnimation = (
  baseEl: HTMLElement,
  presentingEl?: HTMLElement,
  duration = 500,
  full = true,
): Animation => {
  const wrapperEl = baseEl.querySelector('.modal-wrapper') as HTMLElement
  const screenH = baseEl.offsetHeight
  const wrapperH = wrapperEl?.offsetHeight || 0


  const nameFull = full ? '-full' : ''
  const top = screenH - wrapperH

  const backdropAnimation = createAnimation('ion-leave-backdrop' + nameFull)
    .addElement(baseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', 'var(--backdrop-opacity)', 0.0)

  let aniStep = 1.0
  // if (!full) {
  if (wrapperEl) {
    const style = window.getComputedStyle(wrapperEl)
    const matrix = new DOMMatrixReadOnly(style.transform)
    const curTop = matrix.m42
    // aniStep = (screenH - curTop) / screenH
    aniStep = (screenH - curTop) / wrapperH
  }
  // }

  const wrapperAnimation = createAnimation('ion-leave-wrapper' + nameFull)
    .addElement(baseEl.querySelectorAll('.modal-wrapper, .modal-shadow')!)
    .beforeStyles({ 'opacity': 1 })
    // .fromTo('transform', `translateY(${curTop}px)`, 'translateY(100vh)')

  if (full) {
    wrapperAnimation.fromTo('transform', `translateY(${top}px)`, 'translateY(100vh)')
  } else {
    wrapperAnimation.to('transform', 'translateY(100vh)')
  }

  const baseAnimation = createAnimation('ion-leave-base' + nameFull)
    .addElement(baseEl)
    .easing('cubic-bezier(0.32,0.72,0,1)')
    .duration(duration)
    .addAnimation(wrapperAnimation)

  let numModals = 0
  if (presentingEl) {
    const isMobile = window.innerWidth < 768
    const hasCardModal = baseEl.tagName === 'ION-MODAL' && (baseEl as HTMLIonModalElement).presentingElement !== undefined

    const bodyEl = document.body
    const presentingAnimation = createAnimation('ion-leave-presenting' + nameFull)
      .beforeClearStyles(['transform'])
      .afterClearStyles(['transform'])
      .onFinish((currentStep) => {
        // only reset background color if this is the last card-style modal
        if (currentStep !== 1) { return }

        presentingEl.style.setProperty('overflow', '')

        numModals = Array.from(bodyEl.querySelectorAll('ion-modal')).filter((m) => m.presentingElement !== undefined).length
        if (numModals <= 1) {
          bodyEl.style.setProperty('background-color', '')
        }
      })

    if (isMobile) {
      /*
      const transformOffset = !CSS.supports('width', 'max(0px, 1px)') ? '30px' : 'max(30px, var(--ion-safe-area-top))'
      const modalTransform = hasCardModal ? '-10px' : transformOffset
      // const toPresentingScale = SwipeToCloseDefaults.MIN_PRESENTING_SCALE
      const toPresentingScale = MIN_PRESENTING_SCALE
      */
      const transformOffset = !CSS.supports('width', 'max(0px, 1px)') ? `${30 * aniStep}px` : `max(${30 * aniStep}px, calc(var(--ion-safe-area-top)*${aniStep}))`
      const modalTransform = hasCardModal ? `${-10 * aniStep}px` : transformOffset
      const toPresentingScale = getScale(aniStep)
      const finalTransform = `translateY(${modalTransform}) scale(${toPresentingScale})`

      presentingAnimation
        .addElement(presentingEl)
        .keyframes([
          { offset: 0, filter: `contrast(${0.85 * aniStep})`, transform: finalTransform, borderRadius: '10px 10px 0 0' },
          { offset: 1, filter: 'contrast(1)', transform: 'translateY(0px) scale(1)', borderRadius: '0px' },
        ])

      baseAnimation.addAnimation(presentingAnimation)
    } else {
      baseAnimation.addAnimation(backdropAnimation)

      if (!hasCardModal) {
        wrapperAnimation.to('opacity', '0')
      } else {
        // const toPresentingScale = hasCardModal ? SwipeToCloseDefaults.MIN_PRESENTING_SCALE : 1
        /*
        const toPresentingScale = hasCardModal ? MIN_PRESENTING_SCALE : 1
        const finalTransform = `translateY(-10px) scale(${toPresentingScale})`
        */
        const toPresentingScale = hasCardModal ? getScale(aniStep) : 1
        const finalTransform = `translateY(${-10 * aniStep}px) scale(${toPresentingScale})`

        presentingAnimation
          .addElement(presentingEl.querySelector('.modal-wrapper')!)
          .afterStyles({
            'transform': 'translate3d(0, 0, 0)',
          })
          .keyframes([
            { offset: 0, filter: `contrast(${0.85 * aniStep})`, transform: finalTransform },
            { offset: 1, filter: 'contrast(1)', transform: 'translateY(0) scale(1)' },
          ])

        const shadowAnimation = createAnimation('ion-leave-shadow' + nameFull)
          .addElement(presentingEl.querySelector('.modal-shadow')!)
          .afterStyles({
            'transform': 'translateY(0) scale(1)',
          })
          .keyframes([
            { offset: 0, opacity: `${1 - aniStep}`, transform: finalTransform },
            { offset: 1, opacity: '1', transform: 'translateY(0) scale(1)' },
          ])

        baseAnimation.addAnimation([presentingAnimation, shadowAnimation])
      }
    }
  } else {
    baseAnimation.addAnimation(backdropAnimation)
  }

  return baseAnimation
}