import { ClientType } from '../apollo'
import { types, SmartSubscribe } from '../types'

import { updateOneCustomerRelationship, createOneCustomerRelationship } from '@dinosband/bv-main-schema/dist/client-gql'
import { q_readCustomerRelationships, onCustomerRelationshipUpdated } from './crm-gql'

type Callback = (newData: types.CustomerRelationship) => void

export async function createCustomerRelationship (client: ClientType, data: types.CustomerRelationshipCreateInput): Promise<types.CustomerRelationship | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneCustomerRelationship,
      variables: {
        data,
      } as types.MutationCreateOneCustomerRelationshipArgs,
    })
    return result.data.createOneCustomerRelationship
  } catch (e) {
  }
}

export async function readCustomerRelationships (client: ClientType, userPcId: number, customerPcIds?: number[], where?: types.CustomerRelationshipWhereInput): Promise<types.CustomerRelationship[] | undefined> {
  try {
    const result = await client.query({
      query: q_readCustomerRelationships,
      variables: {
        where: {
          ... where ?? {},
          userPcId: { equals: userPcId },
          customerPcId: customerPcIds ? { in: customerPcIds } : undefined,
        },
      } as types.QueryFindManyCustomerRelationshipArgs,
    })
    return result.data.findManyCustomerRelationship
  } catch (e) {
  }
}

export async function updateCustomerRelationship (client: ClientType, id: number, data: types.CustomerRelationshipUpdateInput): Promise<types.CustomerRelationship | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneCustomerRelationship,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneCustomerRelationshipArgs,
    })
    return result.data.updateOneCustomerRelationship
  } catch (e) {
  }
}

export function subscribeOnCrmUpdated (client: ClientType, userId: number, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onCustomerRelationshipUpdated,
    variables: {
      userId,
    },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      const newData = data.onCustomerRelationshipUpdated as types.CustomerRelationship
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}