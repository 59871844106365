import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { onAdvertisementAdded } from './advertise-gql'

type Callback = (newData: types.Advertisement) => void

export function subscribeOnAdvertisementAdded (client: ClientType, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onAdvertisementAdded,
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      debug.log('=> onAdvertisementAdded')
      const newData = data.onAdvertisementAdded as types.Advertisement
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
