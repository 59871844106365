import { ClientType } from '../apollo'
import { types, MutationResult } from '../types'

import { deleteOneFollow } from '@dinosband/bv-main-schema/dist/client-gql'

export async function deleteFollow (client: ClientType, channelId: number, userId: number, followeeUserId: number): Promise<types.Follow | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteOneFollow,
      variables: {
        where: {
          channelId_userId_followerId: {
            channelId: channelId,
            userId: followeeUserId,
            followerId: userId,
          },
        },
      } as types.MutationDeleteOneFollowArgs,
    }) as MutationResult
    return result.data.deleteOneFollow ?? undefined
  } catch (e) {
  }
}
