import { apc } from '../apollo'

const AUTH_KEY = 'bv-admin'

export function getToken (): string | null {
  return localStorage.getItem(AUTH_KEY)
}

export function setToken (token: string): void {
  localStorage.setItem(AUTH_KEY, token)
  apc.setAuthToken(token)
}

export function removeToken () {
  localStorage.removeItem(AUTH_KEY)
  apc.setAuthToken(undefined)
}
