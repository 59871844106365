import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { deleteOneExpertInfo } from '@dinosband/bv-main-schema/dist/client-gql'

export async function deleteExpertInfo (client: ClientType, id: number): Promise<types.ExpertInfo | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteOneExpertInfo,
      variables: {
        where: { id },
      } as types.MutationDeleteOneExpertInfoArgs,
    }) as MutationResult
    return result.data.deleteOneExpertInfo ?? undefined
  } catch (e) {
  }
}
