
import { Capacitor } from '@capacitor/core'
import { Directory } from '@capacitor/filesystem'
import { Share } from '@capacitor/share'
import write_blob from 'capacitor-blob-writer'
import filenamify from 'filenamify'
import parsePath from 'parse-filepath'
import { DBIFiles } from '@dinosband/dbi-capacitor-plugin-files'
import { _trim } from '@dinosband/dbi-utils'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { isMobile } from './util'

export function compatibleFilename (filename: string): string {
  // eslint-disable-next-line prefer-const
  let { name, ext } = parsePath(filename.normalize())
  ext = _trim(ext, '.') ?? ''
  return filenamify(filenamify(name, { maxLength: 120 }) + '.' + filenamify(ext), { maxLength: 128 })
}

export function cacheBlob (data: Blob, filename: string, type: string): Promise<string> {
  if (!Capacitor.isNativePlatform()) {
    throw Error('cacheBlob: not native')
  }
  // const directory = isMobile.android.device ? FilesystemDirectory.Documents : FilesystemDirectory.Cache
  const directory = Directory.Cache
  return write_blob({
    path: filename,
    blob: data,
    directory,
    on_fallback: (error: Error) => {
      console.error('cacheBlob', error)
    },
  })
}
export function viewAction (path: string, type: string): void {
  DBIFiles.view({ path, type })
}

export function shareAction (path: string, type: string): void {
  if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
    if (path.startsWith('/')) {
      path = 'file://' + path
    }
    Share.share({ url: path })
  } else {
    DBIFiles.share({ path, type })
  }
}

export function formatBytes (bytes: number, decimals = 2) {
  if (bytes === 0) { return '0 Bytes' }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
