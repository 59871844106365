import filenamify from 'filenamify'
import parsePath from 'parse-filepath'
import { _trim } from '@dinosband/dbi-utils'

export function compatibleFilename (filename: string): string {
  // eslint-disable-next-line prefer-const
  let { name, ext } = parsePath(filename.normalize())
  ext = _trim(ext, '.') ?? ''
  return filenamify(filenamify(name, { maxLength: 120 }) + '.' + filenamify(ext), { maxLength: 128 })
}
