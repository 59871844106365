
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { types } from '@dinosband/bv-main-client/src/types'
import { readPictureFromUrl } from '@dinosband/bv-attachment'
// import { getSignedURL } from '@dinosband/bv-attachment'
import BvaImageLoader from '../BvaImageLoader.vue'
import { savePicture } from '../../attachment'
import { apollo, makeUpdate } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'

interface Params {
  ad?: types.Advertisement
}

interface ChannelId {
  id: number
  name: string
}

export default Vue.extend({
  props: {
    params: {
      type: Object as () => Params,
      default: undefined,
    },
  },

  data () {
    const validFrom = new Date()
    const validTo = new Date()
    validTo.setDate(validFrom.getDate() + 7)
    return {
      ad: {
        title: '',
        contents: '',
        channelId: types.AD_CHANNEL_ALL,
        isAppChannel: false,
        validFrom,
        validTo,
        byChannel: false,
      } as types.AdvertisementCreateInput,
      contents: {
        text: '',
        onClick: 'none',
        onClickArgs: '',
        pictureURLs: {},
      } as types.AdContents,
      imageTarget: '',
      pictures: {} as Record<string, string>,
      onClickActions: [
        'none', 'open-url',
        'share-app', 'share-channel',
        'goto-channel',
      ] as types.AdOnClick[],
      onClickArgs: {} as types.AdOnClickArgs,
      showSetValidFrom: false,
      showSetValidTo: false,
      channels: [] as ChannelId[],
      channelId: 0,
      argChannelId: 0,
    }
  },

  computed: {
    validFromStr (): string {
      const date = new Date(this.ad.validFrom)
      const year = date.getFullYear()
      const month = date.getMonth()
      const day = date.getDay()
      return `${year}-${month}-${day}`
    },

    banner (): string {
      return this.pictures.banner || ''
    },

    card (): string {
      return this.pictures.card || ''
    },

    full (): string {
      return this.pictures.full || ''
    },

    needArgChannel (): boolean {
      const onClick = this.contents.onClick
      return onClick === 'share-channel' || onClick === 'goto-channel'
    },

    needArgUrl (): boolean {
      const onClick = this.contents.onClick
      return onClick === 'open-url'
    },
  },

  async created () {
    if (this.params?.ad) {
      this.ad = Object.assign({}, this.params.ad) as types.AdvertisementCreateInput
      this.contents = JSON.parse(this.ad.contents) as types.AdContents
      this.onClickArgs = JSON.parse(this.contents.onClickArgs ?? '{}') as types.AdOnClickArgs
      for (const [display, url] of Object.entries(this.contents.pictureURLs)) {
        const res = await readPictureFromUrl(url)
        Vue.set(this.pictures, display, res)
        // Vue.set(this.pictures, display, res.signedURL)
        // this.pictures[display] = res.signedURL
      }
    }
    const channels: ChannelId[] = await apollo.readChannels(await apc.getClient()) ?? []
    this.channels = [
      {
        id: types.AD_CHANNEL_ALL,
        name: `${i18n.tc('common.every')} ${i18n.tc('report.platform')}`,
      },
      {
        id: types.AD_CHANNEL_HOME,
        name: i18n.tc('views.pageHome'),
      },
      ...channels,
    ]
  },

  methods: {
    onClickActionChanged (event: CustomEvent<{value: types.AdOnClick}>) {
      this.contents.onClick = event.detail.value
      this.onClickArgs = {}
    },

    onChannelChanged (event: CustomEvent<{value: types.AdOnClick}>) {
      this.ad.channelId = parseInt(event.detail.value, 10)
    },

    onToggleAppChannel (e: CustomEvent<{checked: boolean}>) {
      const checked = e.detail.checked
      this.ad.isAppChannel = checked
    },

    onArgChannelChanged (event: CustomEvent<{value: types.AdOnClick}>) {
      this.argChannelId = parseInt(event.detail.value, 10)
      this.onClickArgs = { channelId: this.argChannelId }
    },

    onArgUrlChanged (event: CustomEvent<{value: string}>) {
      const url = event.detail.value
      this.onClickArgs = { url }
    },

    onValidFromChanged (ev: CustomEvent<{value: string}>) {
      this.ad.validFrom = ev.detail.value
    },

    onValidToChanged (ev: CustomEvent<{value: string}>) {
      this.ad.validTo = ev.detail.value
    },

    onUploadBanner (ev: Event) {
      this.imageTarget = 'banner'
      ;(this.$refs.imageLoader as BvaImageLoader).click(ev)
    },

    onUploadCard (ev: Event) {
      this.imageTarget = 'card'
      ;(this.$refs.imageLoader as BvaImageLoader).click(ev)
    },

    onUploadFull (ev: Event) {
      this.imageTarget = 'full'
      ;(this.$refs.imageLoader as BvaImageLoader).click(ev)
    },

    onImageUploaded (e: { target: HTMLInputElement}) {
      if (this.imageTarget) {
        savePicture(this, e, async (info) => {
          this.contents.pictureURLs[this.imageTarget] = info.url
          const res = await readPictureFromUrl(info.url)
          if (res) {
            Vue.set(this.pictures, this.imageTarget, res)
          }
          // const res = await getSignedURL(info.url, undefined, true)
          // if (res.signedURL) {
          //   Vue.set(this.pictures, this.imageTarget, res.signedURL)
          // }
        })
      }
    },

    async onSave () {
      this.contents.onClickArgs = JSON.stringify(this.onClickArgs)
      this.ad.contents = JSON.stringify(this.contents)
      if (this.params?.ad) {
        const update = makeUpdate(this.params.ad, this.ad) as types.AdvertisementUpdateInput
        const ad = await apollo.updateAdvertisement(await apc.getClient(), this.params.ad.id, update)
      } else {
        const ad = await apollo.createAdvertisement(await apc.getClient(), this.ad)
      }
      this.$dbIon.closePage(this, true, this.ad)
    },
  },
})
