import { types, apollo } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'

interface Output {
  // eslint-disable-next-line
  log: (...args: any[]) => void
}

let output = console as Output

export function setOutput (out: Output) {
  output = out
}

type UserStats = {
  userId: number
  logs: types.UserLog[]
  signUpAt?: Date
  fromChannel?: number
  fromUrl?: string
}

type VisitorStats = {
  uuid: string
  logs: types.UserLog[]
  fromChannel?: number
  fromUrl?: string
}

type Stats = {
  countUsers: number
  countDeletedUsers: number
  users: UserStats[]
  newUsers: UserStats[]
  visitors: VisitorStats[]
  noTries: VisitorStats[]
  noActions: VisitorStats[]
}

interface IStats {
  logs: types.UserLog[]
}

export type DateRange = {
  from: Date
  to: Date
}

type ProgressFn = (progress: number) => void

async function getStats (range: DateRange, progressFn: ProgressFn): Promise<Stats> {
  const userStats = new Map<number, UserStats>()
  const visitorStats = new Map<string, VisitorStats>()
  const uuidToUserId = new Map<string, number>()

  const fromTick = range.from.getTime()
  const toTick = range.to.getTime()
  const oneDay = 24 * 3600 * 1000
  const totalTick = toTick - fromTick

  for (let curTick = fromTick; curTick < toTick; curTick += oneDay) {
    const rangeFrom = new Date(curTick)
    const rangeTo = new Date(curTick + oneDay - 1)

    output.log(`processing data of ${rangeFrom}`)
    const logs = await apollo.readUserLogs(await apc.getClient(), rangeFrom, rangeTo) ?? []

    logs.forEach((log) => {
      if (log.userId) {
        const stat = userStats.get(log.userId)
        if (stat) {
          stat.logs.push(log)
        } else {
          userStats.set(log.userId, {
            userId: log.userId,
            logs: [log],
          })
        }
      } else if (log.uuid) {
        let stat = visitorStats.get(log.uuid)
        if (stat) {
          stat.logs.push(log)
        } else {
          stat = {
            uuid: log.uuid,
            logs: [log],
          }
          visitorStats.set(log.uuid, stat)
        }
        if (log.type === 'd-link' && log.body.includes('url=/substitute-teacher')) {
          const url = new URL(log.body)
          const params = url.searchParams
          stat.fromChannel = parseInt(params.get('id') || '', 10)
          stat.fromUrl = decodeURIComponent(params.get('url') || '')
        }
        if (log.type === 'complete-sign-up' || log.type === 'complete-sign-in') {
          const { userId } = JSON.parse(log.body) as { userId: number }
          uuidToUserId.set(log.uuid, userId)
          stat.logs.forEach((log) => {
            log.userId = userId
          })
          let userStat = userStats.get(userId)
          if (userStat) {
            // If the user already exists, merge logs
            const visitorStat = visitorStats.get(log.uuid)
            if (visitorStat) {
              userStat.logs.push(...stat.logs)
            }
          } else {
            userStat = {
              userId,
              logs: stat.logs,
            }
            userStats.set(userId, userStat)
          }
          if (log.type === 'complete-sign-up') {
            userStat.signUpAt = log.createdAt
          }
          userStat.fromChannel = stat.fromChannel
          userStat.fromUrl = stat.fromUrl
          // remove from visitorStats
          visitorStats.delete(log.uuid)
        }
      }
    })
    progressFn((rangeTo.getTime() - fromTick) / totalTick)
  }

  output.log('------------ Number of Users -----------------')
  const countUsers = await apollo.readUserCount(await apc.getClient()) ?? 0
  const countDeletedUsers = await apollo.readDeletedUserCount(await apc.getClient()) ?? 0
  const countValidUsers = countUsers - countDeletedUsers
  output.log(`Number of Users: ${countValidUsers}/${countUsers}`)

  // output.log(`\n----- Active Users: ${userStats.size} ------`)
  // userStats.forEach((user) => {
  //   output.log(`${user.userId}: ${user.signUpAt || 'signIn'} ---`)
  //   user.logs.forEach((log) => {
  //     output.log(`  ${log.createdAt} ${log.type} ${log.body} ${log.uuid}`)
  //   })
  // })

  const newUserStats = Array.from(userStats.values()).filter((stat) => stat.signUpAt)
  // output.log('\nNumber of New Users: ', newUserStats.length)

  // output.log(`\n----- Visitors: ${visitorStats.size} ------`)
  // visitorStats.forEach((stat) => {
  //   output.log(`${stat.uuid} ---`)
  //   stat.logs.forEach((log) => {
  //     output.log(`  ${log.createdAt} ${log.type} ${log.body} ${log.uuid}`)
  //   })
  // })

  // output.log('--------------------------------------------------------')

  const visitors = Array.from(visitorStats.values())
  const noTries = visitors.filter((stat) => !hasType(stat.logs, 'enter-channel') && !hasType(stat.logs, 'start-sign-up'))
  const noActions = visitors.filter((stat) => stat.logs.length <= 2)

  return {
    countUsers,
    countDeletedUsers,
    users: Array.from(userStats.values()),
    newUsers: newUserStats,
    visitors,
    noTries,
    noActions,
  }
}

function hasType (logs: types.UserLog[], type: string, beforeType = '') {
  for (const log of logs) {
    if (log.type === type) {
      return true
    } else if (beforeType && log.type === beforeType) {
      return false
    }
  }
  return false
}

function countOfType (stats: IStats[], type: string, beforeType = '') {
  return stats.filter((stat) => hasType(stat.logs, type)).length
}

function ratio (a: number, b: number) {
  if (b) {
    const ratio = Math.floor(a / b * 100)
    return `${ratio}%`
  } else {
    return 'None'
  }
}

interface DeviceInfo {
  platform: string
  osVersion: string
  manufacturer: string
  model: string
  appVersion: string
  appBuild: string
}

function incCount (counts: Map<string, number[]>, key: string | undefined, inx: number) {
  if (key) {
    const count = counts.get(key) || [0, 0, 0, 0]
    count[inx]++
    counts.set(key, count)
  }
}

function printCounts (counts: Map<string, number[]>, countName: string) {
  output.log(`${countName}: [users, visitors, noTries, noActions]`)
  const keys = Array.from(counts.keys()).sort()
  keys.forEach((key) => {
    const count = counts.get(key)
    output.log(`  - ${key}: ${count}`)
  })
}

function analyzeDevice (statsList: IStats[][]) {
  const countPlatform = new Map<string, number[]>()
  const countManufacturer = new Map<string, number[]>()
  const countModel = new Map<string, number[]>()
  const countOsVersion = new Map<string, number[]>()

  for (let i = 0; i < statsList.length; i++) {
    const stats = statsList[i]
    stats.forEach((stat) => {
      const log = stat.logs.find((log) => log.type === 'first-run')
      if (log) {
        const info = JSON.parse(log.body) as DeviceInfo
        incCount(countPlatform, info.platform, i)
        incCount(countManufacturer, info.manufacturer, i)
        incCount(countModel, info.model, i)
        incCount(countOsVersion, info.platform + '-' + info.osVersion, i)
      }
    })
  }
  printCounts(countPlatform, 'platform')
  printCounts(countManufacturer, 'manufacturer')
  printCounts(countModel, 'model')
  printCounts(countOsVersion, 'osVersion')
}

function analyzeHomepageUsers (stats: Stats) {
  const users = stats.newUsers.filter((stat) => stat.fromUrl)
  const visitors = stats.visitors.filter((stat) => stat.fromUrl)
  const countUsersFromHomepage = users.length
  const countVisitorsFromHomepage = visitors.length
  const countFromHomepage = countUsersFromHomepage + countVisitorsFromHomepage

  output.log('-------- From Homepage ---------')
  output.log('visitors from homepage: ', countVisitorsFromHomepage)
  output.log('users from homepage: ', countUsersFromHomepage, ratio(countUsersFromHomepage, countFromHomepage))
  output.log('-- users --')
  users.forEach((stat) => {
    output.log(`- ${stat.userId}`)
    stat.logs.forEach((log) => {
      output.log(`    ${log.type}: ${log.body}`)
    })
  })
  output.log('-- visitors --')
  visitors.forEach((stat) => {
    output.log(`- ${stat.uuid}`)
    stat.logs.forEach((log) => {
      output.log(`    ${log.type}: ${log.body}`)
    })
  })
}

export async function analyze (range: DateRange, progress: (n: number) => void) {
  const stats = await getStats(range, progress)

  const countNewUser = stats.newUsers.length
  const countNoTries = stats.noTries.length
  const countNoActions = stats.noActions.length
  const countFirstRun = countNewUser + stats.visitors.length
  const countStartSignUp = countNewUser + countOfType(stats.visitors, 'start-sign-up')
  const countAgreed = countNewUser + countOfType(stats.visitors, 'agreed')
  const countEnterChannelOfVisitors = countOfType(stats.visitors, 'enter-channel')
  const countEnterChannelOfNewUsers = countOfType(stats.newUsers, 'enter-channel', 'complete-sign-up')
  const countEnterChannel = countEnterChannelOfVisitors + countEnterChannelOfNewUsers

  output.log('------- Statistics -----------')
  output.log('first-run: ', countFirstRun, '100%')
  output.log('start-sign-up: ', countStartSignUp, ratio(countStartSignUp, countFirstRun))
  output.log('agreed: ', countAgreed, ratio(countAgreed, countFirstRun))
  output.log('complete-sign-up: ', countNewUser, ratio(countNewUser, countFirstRun))
  output.log('no-tries: ', countNoTries, ratio(countNoTries, countFirstRun))
  output.log('no-actions: ', countNoActions, ratio(countNoActions, countFirstRun))
  output.log('complete-sign-up / start-sign-up: ', ratio(countNewUser, countStartSignUp))
  output.log('enter-channel: ', countEnterChannel, ratio(countEnterChannel, countFirstRun))
  output.log('complete-sign-up / enter-channel: ', ratio(countNewUser, countEnterChannel))
  output.log('enter-channel / complete-sign-up: ', ratio(countEnterChannelOfNewUsers, countNewUser))
  analyzeDevice([stats.newUsers, stats.visitors, stats.noTries, stats.noActions])
  analyzeHomepageUsers(stats)
  output.log('------------------------------')
}
