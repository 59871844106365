import Vue from 'vue'
import { isPlatform } from '@ionic/core'
import {
  iosBottomSheetEnterAnimation,
  // iosBottomSheetLeaveAnimation,
  mdBottomSheetEnterAnimation,
  // mdBottomSheetLeaveAnimation,
} from './animations'

export async function showBottomSheet (vue: Vue, component: string, params: unknown | undefined = undefined): Promise<unknown | null> {
  const modalController = vue.$ionic.modalController
  const presentingElement = vue.$el.closest('ion-page') as HTMLElement

  const ios = isPlatform('ios')
  const enterAnimation = ios ? iosBottomSheetEnterAnimation : mdBottomSheetEnterAnimation
  // const leaveAnimation = ios ? iosBottomSheetLeaveAnimation : mdBottomSheetLeaveAnimation

  const modal = await modalController.create({
    component,
    componentProps: {
      params,
      presentingElement,
    },
    presentingElement,
    enterAnimation,
    // leaveAnimation,
  })
  modal.style.alignItems = 'flex-start'

  await modal.present()

  const res = await modal.onDidDismiss()
  if (res.role == 'ok') {
    return res.data
  } else {
    return null
  }
}
