import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueAccountInfo } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readAccountInfo (client: ClientType, where?: types.AccountInfoWhereUniqueInput): Promise<types.AccountInfo | undefined> {
  try {
    const result = await client.query({
      query: findUniqueAccountInfo,
      variables: {
        where,
      } as types.QueryFindUniqueAccountInfoArgs,
    })
    return result.data.findUniqueAccountInfo
  } catch (e) {
  }
}
