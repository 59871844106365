
import Vue from 'vue'
import _ from 'lodash'
import { generateHTML } from '@tiptap/vue-2'
import { isImageType, getSignedURL } from '@dinosband/bv-attachment'
import { AttachmentType, requestAttachmentFile, uploadAttachmentFile } from '@dinosband/bv-attachment'
import { AddImageCallback } from './DbiTipTap.vue'
import { EXTENSIONS, convertToHtml } from './tiptap'

export default Vue.extend({
  props: {
    html: {
      type: String,
      default: '',
    },
    json: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      htmlConverted: '',
      urlMap: new Map<string, string>(),
      addImageCb: undefined as AddImageCallback | undefined,
    }
  },

  // watch: {
  //   json: {
  //     immediate: true,
  //     async handler () {
  //       if (this.json) {
  //         this.htmlConverted = await convertToHtml({ json: this.json })
  //       }
  //     },
  //   },

  //   html: {
  //     immediate: true,
  //     async handler () {
  //       if (this.html) {
  //         this.htmlConverted = await convertToHtml({ html: this.html })
  //       }
  //     },
  //   },
  // },

  async created () {
    this.htmlConverted = await convertToHtml({
      json: this.json,
      html: this.html,
    })
  },

  methods: {
    onAddImage (cb: (url: string) => void) {
      this.addImageCb = cb
      const inputElem = this.$refs.photo as HTMLElement
      inputElem.click()
    },

    async onUploadImage (e: { target: HTMLInputElement}) {
      _.forEach(e.target.files, async (file, i) => {
        if (!file) {
          return
        }
        if (isImageType(file.type)) {
          const attachment = await requestAttachmentFile(file, AttachmentType.PROFILE_IMAGE, '')
          if (attachment && attachment.url) {
            await uploadAttachmentFile(attachment, AttachmentType.PROFILE_IMAGE).catch(() => {
              throw 'upload failed'
            })
            const ret = await getSignedURL(attachment.url as string)
            if (ret?.signedURL) {
              const signedUrl = ret.signedURL
              this.urlMap.set(signedUrl, attachment.url)
              if (this.addImageCb) {
                this.addImageCb(signedUrl)
              }
            } else {
              console.error('failed to get signedUrl: ', ret)
            }
          } else {
            console.error('failed to requestAttachmentFile')
          }
        }
      })
    },

    // eslint-disable-next-line
    onChanged (doc: Record<string, any>) {
      // eslint-disable-next-line
      doc.content.forEach((c: any) => {
        if (c.type === 'image') {
          const url = this.urlMap.get(c.attrs?.src)
          if (url) {
            c.attrs.src = url
          }
        }
      })
      const json = JSON.stringify(doc)
      const html = generateHTML(doc, EXTENSIONS)
      this.$emit('change', { html, json })
    },
  },
})
