import { registerPlugin } from '@capacitor/core'
import { DBIFilesPlugin } from './definitions'
import { DBIFilesWeb } from './web'

const DBIFiles = registerPlugin<DBIFilesPlugin>('DBIFiles', {
  web: () => new DBIFilesWeb(),
})

export * from './definitions'
export { DBIFiles }
