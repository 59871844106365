import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyUserCount } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readUserCount (client: ClientType): Promise<number | undefined> {
  try {
    const result = await client.query({
      query: findManyUserCount,
      variables: {
        where: {},
      } as types.QueryAggregateUserArgs,
    })
    return result.data.findManyUserCount
  } catch (e) {
  }
}

export async function readDeletedUserCount (client: ClientType): Promise<number | undefined> {
  try {
    const result = await client.query({
      query: findManyUserCount,
      variables: {
        where: {
          deleted: { equals: true },
        },
      } as types.QueryAggregateUserArgs,
    })
    return result.data.findManyUserCount
  } catch (e) {
  }
}
