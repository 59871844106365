import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueUserIdentity } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readUserIdentity (client: ClientType, where?: types.UserIdentityWhereUniqueInput): Promise<types.UserIdentity | undefined> {
  try {
    const result = await client.query({
      query: findUniqueUserIdentity,
      variables: {
        where,
      } as types.QueryFindUniqueUserIdentityArgs,
    })
    return result.data.findUniqueUserIdentity
  } catch (e) {
  }
}

