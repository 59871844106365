import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'

export const onAdvertisementAdded = gql`
  subscription onAdvertisementAdded {
    onAdvertisementAdded {
      ...AdvertisementFields
    }
  }
  ${client.AdvertisementFields}
`
