
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { apollo, types } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'

interface Params {
  notice: types.SysNotice
}

interface ChannelId {
  id: number
  name: string
}

export default Vue.extend({
  props: {
    params: {
      type: Object as () => Params,
      default: undefined,
    },
  },

  data () {
    return {
      subject: '',
      saved: undefined as types.SysNotice | undefined,
      json: '',
      html: '',
      channels: [] as ChannelId[],
      channelId: 0 as number | undefined,
    }
  },

  async created () {
    if (this.params?.notice) {
      this.saved = this.params.notice
      this.subject = this.saved.subject
      this.json = this.saved.content
      this.html = this.saved.html || ''
      this.channelId = this.saved.channelId ?? 0
    }

    const channels: ChannelId[] = await apollo.readChannels(await apc.getClient()) ?? []
    this.channels = [
      {
        id: 0,
        name: i18n.tc('strings.components.channelsChannelDetailPane01'),
      },
      ...channels,
    ]
  },

  methods: {
    onChanged (args: { json: string, html: string }) {
      this.json = args.json
      this.html = args.html
    },

    onChannelChanged (event: CustomEvent<{value: types.AdOnClick}>) {
      this.channelId = parseInt(event.detail.value, 10)
    },

    async onSave () {
      if (this.saved) {
        const saved = this.saved
        if (saved.subject === this.subject && saved.content === this.json && saved.channelId === this.channelId) {
          await this.$dbIon.alert(i18n.tc('strings.components.sys-noticeaNoticeCreate02'))
        } else {
          const updated = await apollo.updateSysNotice(await apc.getClient(), saved.id, {
            subject: { set: this.subject },
            content: { set: this.json },
            html: { set: this.html },
            channelId: { set: this.channelId || null },
          })
          if (updated) {
            this.saved = updated
            this.$dbIon.closePage(this, true, updated)
          }
        }
      } else {
        const created = await apollo.createSysNotice(await apc.getClient(), {
          subject: this.subject,
          content: this.json,
          html: this.html,
          channelId: this.channelId || null,
        })
        if (created) {
          this.saved = created
          this.$dbIon.closePage(this, true, created)
        }
      }
    },
  },
})
