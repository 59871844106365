import { ClientType } from '../apollo'
import { types } from '../types'
import {
  deleteManyMessageTranslation,
} from '@dinosband/bv-main-schema/dist/client-gql'

//import { findManyMessageInfo } from '@dinosband/bv-main-schema/dist/client-gql'
import { findManyMessageTranslations, findMessageTranslation } from './translation-gql'

export async function readMessageTranslations (client: ClientType, mesgId: number, version?: string) {
  try {
    const result = await client.query<{getMessageTranslations: types.MessageTranslation[]}>({
      query: findManyMessageTranslations,
      variables: {
        mesgId,
        version,
      } as types.QueryGetMessageTranslationsArgs,
    })
    return result.data?.getMessageTranslations ?? []
  } catch (e) {
  }
}

export async function readMessageTranslation (client: ClientType, mesgId: number, target: string, version?: string) {
  try {
    const result = await client.query<{getMessageTranslation: types.MessageTranslation}>({
      query: findMessageTranslation,
      variables: {
        mesgId,
        target,
        version,
      } as types.QueryGetMessageTranslationArgs,
    })
    return result.data?.getMessageTranslation
  } catch (e) {
  }
}


export async function deleteMessageTranslation (client: ClientType, mesgId: number): Promise<types.SearchWord | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteManyMessageTranslation,
      variables: {
        where: {
          mesgId: { equals: mesgId },
        },
      } as types.MutationDeleteManyMessageTranslationArgs,
    })

    return result.data.deleteManyMessageTranslation
  } catch (e) {
  }
}
