import { ClientType } from '../apollo'
import { types } from '../types'

import {
  findProfile,
  findProfileBrief,
  findProfileWithProfile_Channels,
  findProfileWithUser_Profiles,
} from './profile-gql'

import { findManyProfileCount, findManyProfile } from '@dinosband/bv-main-schema/dist/client-gql'

export function option_readProfile (id: number) {
  return {
    query: findProfileWithProfile_Channels,
    variables: {
      where: {
        id,
      },
    } as types.QueryFindUniqueProfileArgs,
  }
}

export async function readProfileDetail (client: ClientType, id: number): Promise<types.Profile | undefined> {
  const opt = option_readProfile(id)
  try {
    const result = await client.query(opt)
    return result.data.findUniqueProfile
  } catch (e) {
  }
}

export async function readProfileUsers (client: ClientType, id: number): Promise<types.Profile | undefined> {
  const opt = {
    query: findProfileWithUser_Profiles,
    variables: {
      where: {
        id,
      },
    } as types.QueryFindUniqueProfileArgs,
  }
  try {
    const result = await client.query(opt)
    return result.data.findUniqueProfile
  } catch (e) {
  }
}

export async function readProfile (client: ClientType, id: number): Promise<types.Profile | undefined> {
  const opt = {
    query: findProfile,
    variables: {
      where: {
        id,
      },
    } as types.QueryFindUniqueProfileArgs,
  }
  try {
    const result = await client.query(opt)
    return result.data.findUniqueProfile
  } catch (e) {
  }
}


export async function readProfileBrief (client: ClientType, id: number): Promise<Partial<types.Profile> | undefined> {
  const opt = {
    query: findProfileBrief,
    variables: {
      where: {
        id,
      },
    } as types.QueryFindUniqueProfileArgs,
  }
  try {
    const result = await client.query(opt)
    return result.data.findUniqueProfile
  } catch (e) {
  }
}

export async function readProfiles (client: ClientType, ids: number[]): Promise<types.Profile[] | undefined> {
  try {
    const result = await client.query({
      query: findManyProfile,
      variables: {
        where: {
          id: { in: ids },
        },
      } as types.QueryFindManyProfileArgs,
    })
    return result.data.findManyProfile
  } catch (e) {
  }
}

export async function checkProfileNameExist (client: ClientType, name: string): Promise<boolean | undefined> {
  try {
    const result = await client.query({
      query: findManyProfileCount,
      variables: {
        where: { name: { equals: name } },
      } as types.QueryFindManyProfileCountArgs,
    })
    return result.data.findManyProfileCount > 0
  } catch (e) {
  }
}

export async function checkProfileExist (client: ClientType, where: types.ProfileWhereInput): Promise<boolean | undefined> {
  try {
    const result = await client.query({
      query: findManyProfileCount,
      variables: {
        where,
      } as types.QueryFindManyProfileCountArgs,
    })
    return result.data.findManyProfileCount > 0
  } catch (e) {
  }
}
