import Vue from 'vue'
import { Store } from 'vuex'

import { Route, Router } from '@dinosband/dbi-router'

import { initAttachmentStoreModule } from './store'
import apolloClient from './apollo-client'
// import { registerComponents } from '@dinosband/db-ionic-vue'
import { BvApolloClient } from '@dinosband/bv-apollo-client'

export * from './api'
// export * from './apollo-client'
export * from './errors'
export * from './core'
export * from './types'
export { updateUploadingState } from './store'
export * from './picture'

interface BvAttachmentPluginOptions {
  dbiRouter: Router
  store: Store<unknown>
}

function install (vue: typeof Vue, options: BvAttachmentPluginOptions): void {
  // registerComponents(
  //   require.context('./components', true, /[A-Z]\w+\.(vue|js)$/, 'lazy'),
  // )

  // register store modules
  initAttachmentStoreModule(options.store)

  const routes: Route[] = [{
    path: 'attachment/:objectId',
    component: 'AttachmentView',
    icon: '',
    label: '',
  }]
  options.dbiRouter.addRoutes(routes)

  const $ionic = vue.prototype.$ionic
  if (!$ionic) {
    console.error('Install vue-ionic plugin before installing this plugin.')
    return
  }

  const $dbIon = vue.prototype.$dbIon
  if (!$dbIon) {
    console.error('Install dbi-router plugin before installing this plugin.')
    return
  }
}

export function initApolloClient (client: { s3: BvApolloClient, broadcast: BvApolloClient }) {
  apolloClient.s3 = client.s3
  apolloClient.broadcast = client.broadcast
}

export default {
  install,
  version: '__VERSION__',
}
