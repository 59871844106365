import { ClientType } from '../apollo'
import { ApolloQueryResult, types } from '../types'

import { q_findManyCommerceProductReview } from './commerce-gql'
import { createOneCommerceProductReview, findUniqueCommerceProductReview } from '@dinosband/bv-main-schema/dist/client-gql'

interface Mutations {
  createOneCommerceProductReview: types.CommerceProductReview
  updateOneCommerceProductReview: types.CommerceProductReview
}

type MutationResult = ApolloQueryResult<Mutations>

export async function createCommerceProductReview (client: ClientType, data: types.CommerceProductReviewCreateInput): Promise<types.CommerceProductReview> {
  const result = await client.mutate({
    mutation: createOneCommerceProductReview,
    variables: {
      data,
    } as types.MutationCreateOneCommerceProductReviewArgs,
  }) as MutationResult
  return result.data.createOneCommerceProductReview
}

export async function readCommerceProductReviews (client: ClientType, where: types.CommerceProductReviewWhereInput, orderBy?: types.CommerceProductReviewOrderByWithRelationInput[], cursor?: number, take?: number): Promise<types.CommerceProductReview[] | undefined> {
  const result = await client.query({
    query: q_findManyCommerceProductReview,
    variables: {
      where,
      orderBy,
      cursor: cursor ? { id: cursor } : undefined,
      skip: cursor ? 1 : undefined,
      take,
    } as types.QueryFindManyCommerceProductReviewArgs,
  })
  return result.data.findManyCommerceProductReview
}

export async function readCommerceProductReview (client: ClientType, where: types.CommerceProductReviewWhereUniqueInput): Promise<types.CommerceProductReview | undefined> {
  const result = await client.query({
    query: findUniqueCommerceProductReview,
    variables: {
      where,
    } as types.QueryFindManyCommerceProductReviewArgs,
  })
  return result.data.findUniqueCommerceProductReview
}
