import Vue from 'vue'

interface InputComponent {
  attrs: Record<string, unknown>
  handleChange (e: Event): void
  handleInput (e: Event): void
  handleBlur (e: Event): void
  handleFocus (e: Event): void
  handleKeyup (e: Event): void
}

/**
 * Create a wrapped input component that captures typical ionic input events
 * and emits core ones so v-model works.
 * @param {} name the vue name of the component
 * @param {*} coreTag the actual tag to render (such as ion-datetime)
 */
function createInputComponent (
  name: string,
  coreTag: string,
  modelEvent = 'ionChange',
  valueProperty = 'value',
) {
  return Vue.component(name, {
    model: {
      event: modelEvent,
      prop: valueProperty,
    },
    methods: {
      handleChange ($event: Event) {
        if (modelEvent === 'ionChange') {
          // Vue expects the value to be sent as the argument for v-model, not the
          // actual event object
          if ($event.target) {
            const t = $event.target as unknown as Record<string, unknown>
            this.$emit('ionChange', t[valueProperty])
          }
        } else {
          this.$emit('ionChange', $event)
        }
      },
      handleInput ($event: Event) {
        if (modelEvent === 'ionInput') {
          // Vue expects the value to be sent as the argument for v-model, not the
          // actual event object
          if ($event.target) {
            const t = $event.target as unknown as Record<string, unknown>
            this.$emit('ionInput', t[valueProperty])
          }
        } else {
          this.$emit('ionInput', $event)
        }
      },
      handleBlur ($event: Event) {
        this.$emit('ionBlur', $event)
      },
      handleFocus ($event: Event) {
        this.$emit('ionFocus', $event)
      },
      handleKeyup ($event: Event) {
        this.$emit('keyup', $event)
      },
    },
    render (createElement) {
      // Vue types have a bug accessing member properties:
      // https://github.com/vuejs/vue/issues/8721
      /* eslint-disable @typescript-eslint/no-this-alias */
      const cmp = this as unknown as InputComponent

      return createElement(
        coreTag,
        {
          attrs: cmp.attrs,
          on: {
            ionChange: cmp.handleChange,
            ionInput: cmp.handleInput,
            ionBlur: cmp.handleBlur,
            ionFocus: cmp.handleFocus,
            keyup: cmp.handleKeyup,
          },
        },
        this.$slots.default,
      )
    },
  })
}

export function createInputComponents (): void {
  createInputComponent('IonCheckboxVue', 'ion-checkbox', 'ionChange', 'checked')
  createInputComponent('IonDatetimeVue', 'ion-datetime')
  createInputComponent('IonInputVue', 'ion-input', 'ionInput')
  createInputComponent('IonRadioVue', 'ion-radio')
  createInputComponent('IonRangeVue', 'ion-range')
  createInputComponent('IonSearchbarVue', 'ion-searchbar', 'ionInput')
  createInputComponent('IonSelectVue', 'ion-select')
  createInputComponent('IonTextareaVue', 'ion-textarea')
  createInputComponent('IonToggleVue', 'ion-toggle', 'ionChange', 'checked')
}
