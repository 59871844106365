import { types } from '../types'
import { ClientType } from '../apollo'
import { q_getLatestVersion } from './version-gql'

export async function getLatestVersion (client: ClientType, version: string, platform: string): Promise<types.VersionInfo[] | undefined> {
  try {
    const result = await client.query<{getLatestVersion: types.VersionInfo[]}>({
      query: q_getLatestVersion,
      variables: {
        version,
        platform,
      },
    })
    return result.data.getLatestVersion
  } catch (e) {
  }
}