
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { types, apollo } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'
import { format } from 'date-fns'

export default Vue.extend({
  data () {
    return {
      reports: [] as types.AbusingReport[],
      lastId: undefined as number | undefined,
      count: 10,
      hasMore: true,
    }
  },

  async created () {
    await this.loadMore()
  },

  methods: {
    formatDate (dateStr: string | undefined) {
      if (dateStr) {
        return format(new Date(dateStr), 'yyyy-MM-dd')
      }
      return undefined
    },

    label (report: types.AbusingReport): string {
      const createdAt = this.formatDate(report.createdAt)
      return `[${report.profile?.name}] ${report.description} ---- ${createdAt} ---- ${report.lastActionAt || i18n.tc('strings.components.aAbusingReports04')}`
    },

    async loadMore () {
      const reports = await apollo.readAbusingReports(await apc.getClient(), this.lastId, this.count)
      if (reports?.length) {
        this.reports.push(...reports)
        this.lastId = reports[reports.length - 1].id
      } else {
        this.hasMore = false
      }
    },

    async onClick (report: types.AbusingReport) {
      await this.$dbIon.doFullScreenModal(this, 'BvaAbusingReportAction', { id: report.id })
      const updated = await apollo.readAbusingReport(await apc.getClient(), report.id)
      if (updated) {
        const inx = this.reports.findIndex((i) => i.id === updated.id)
        if (inx >= 0) {
          Vue.set(this.reports, inx, updated)
        }
      }
    },
  },
})
