import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'

export const q_findManyCommerceProduct = gql`
  query q_findManyCommerceProduct($where: CommerceProductWhereInput!, $cursor: CommerceProductWhereUniqueInput, $take: Int, $orderBy: [CommerceProductOrderByWithRelationInput!]) {
    findManyCommerceProduct(where: $where, cursor: $cursor, take: $take, orderBy: $orderBy) {
      ...CommerceProductFields
      seller {
        ...ProfileFields
      }
    }
  }
  ${client.CommerceProductFields}
  ${client.ProfileFields}
`

export const q_findManyCommerceOrder = gql`
  query q_findManyCommerceOrder($where: CommerceOrderWhereInput!, $cursor: CommerceOrderWhereUniqueInput, $take: Int, $orderBy: [CommerceOrderOrderByWithRelationInput!]) {
    findManyCommerceOrder(where: $where, cursor: $cursor, take: $take, orderBy: $orderBy) {
      ...CommerceOrderFields
      profile {
        ...ProfileFields
      }
      product {
        ...CommerceProductFields
      }
      payments {
        ...PaymentFields
      }
    }
  }
  ${client.CommerceOrderFields}
  ${client.CommerceProductFields}
  ${client.ProfileFields}
  ${client.PaymentFields}
`

export const q_findManyCommerceProductReview = gql`
  query q_findManyCommerceProductReview($where: CommerceProductReviewWhereInput!, $cursor: CommerceProductReviewWhereUniqueInput, $take: Int, $orderBy: [CommerceProductReviewOrderByWithRelationInput!]) {
    findManyCommerceProductReview(where: $where, cursor: $cursor, take: $take, orderBy: $orderBy) {
      ...CommerceProductReviewFields
      reviewer {
        ...ProfileFields
      }
    }
  }
  ${client.CommerceProductReviewFields}
  ${client.ProfileFields}
`
