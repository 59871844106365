import Vue from 'vue'

export class Store {
  showTabLabel = true
  icons: Record<string, string> = {}
  labels: Record<string, string> = {}
  badges: Record<string, string | number> = {}

  getIcon (key: string): string | undefined {
    return this.icons[key]
  }

  getLabel (key: string): string | undefined {
    return this.labels[key]
  }

  getBadge (key: string): string | number | undefined {
    return this.badges[key]
  }

  setIcon (key: string, value: string): void {
    Vue.set(this.icons, key, value)
  }

  setLabel (key: string, value: string): void {
    Vue.set(this.labels, key, value)
  }

  setBadge (key: string, value: string | number): void {
    Vue.set(this.badges, key, value)
  }

  setShowTabLabel (showLabel: boolean): void {
    this.showTabLabel = showLabel
  }
}

export const store = Vue.observable(new Store())
