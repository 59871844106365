import { ProfileReview } from '@dinosband/bv-main-schema/dist/types'
import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { onProfileReviewUpdated } from './user-gql'

type Callback = (newData: types.ProfileReview) => void

export function subscribeOnProfileReviewUpdated (client: ClientType, userId: number, profileIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onProfileReviewUpdated,
    variables: {
      profileIds,
    },
  })

  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      const newData = data.onProfileReviewUpdated as ProfileReview
      cb(newData)
      /*
      const profileReview = data.onProfileReviewUpdated as ProfileReview
      const opt = option_readUserGraph(userId)
      const cached = JSON.parse(JSON.stringify(client.readQuery(opt)))

      const user = cached.findUniqueUser as types.User

      debug.log('onProfileReviewUpdated')

      if (user.profiles) {
        const u_p = user.profiles.find((p) => p.profileId === profileReview.revieweeId)
        if (u_p) {
          u_p.profile.profileReviewsOnMe.push(profileReview)
          client.writeQuery({
            query: opt.query,
            data: cached,
          })
        }
      }
      */
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
