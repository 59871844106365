import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyDocument } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readDocument (client: ClientType, type: string, channelId?: number): Promise<types.Document | undefined> {
  try {
    const result = await client.query({
      query: findManyDocument,
      variables: {
        where: {
          channelId: channelId ? {
            equals: channelId,
          } : undefined,
          type: { equals: type },
        },
        orderBy: [
          {
            id: 'desc',
          },
        ],
        take: 1,
      } as types.QueryFindManyDocumentArgs,
    })
    const documents = result.data.findManyDocument as types.Document[]
    if (documents?.length) {
      return documents[0]
    } else {
      return undefined
    }
  } catch (e) {
  }
}

export async function readDocuments (client: ClientType, type: string, channelId?: number): Promise<types.Document[] | undefined> {
  try {
    const result = await client.query({
      query: findManyDocument,
      variables: {
        where: {
          channelId: channelId ? {
            equals: channelId,
          } : undefined,
          type: { equals: type },
        },
        orderBy: [
          {
            id: 'desc',
          },
        ],
      } as types.QueryFindManyDocumentArgs,
    })
    return result.data.findManyDocument
  } catch (e) {
  }
}
