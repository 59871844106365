import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyCategoryKeyword } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readCategoryKeywords (client: ClientType): Promise<types.CategoryKeyword[] | undefined> {
  try {
    const result = await client.query({
      query: findManyCategoryKeyword,
    // variables: {
    //   where: {
    //     id,
    //   },
    // } as types.QueryFindManyCategoryKeywordArgs,
    })
    return result.data.findManyCategoryKeyword
  } catch (e) {
  }
}

