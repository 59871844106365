
import Vue from 'vue'

export default Vue.extend({
  props: {
    icon: {
      type: Array as () => string[],
      default: () => [],
    },
  },

  computed: {
    classes (): string {
      return this.icon.join(' fa-')
    },
  },
})
