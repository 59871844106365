import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { m_updateOneProfile } from './profile-gql'

export async function updateProfile (client: ClientType, id: number, data: types.ProfileUpdateInput): Promise<types.Profile | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_updateOneProfile,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneProfileArgs,
    }) as MutationResult
    return result.data.updateOneProfile
  } catch (e) {
  }
}
