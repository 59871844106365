
import Vue from 'vue'
import { apollo, types } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'

type ItemType = types.JobOffer | types.JobOfferResponse

export default Vue.extend({
  data () {
    return {
      items: [] as ItemType[],
      timePassedMap: new Map<number, number>(),
      loading: false,
    }
  },

  methods: {
    rowStyle (item: ItemType) {
      if (item.__typename === 'JobOffer') {
        return {
          background: 'lightgray',
        }
      } else {
        return {
          // background: 'gray',
        }
      }
    },

    profile (item: ItemType) {
      if (item.__typename === 'JobOffer') {
        const offer = item as types.JobOffer
        return offer.client.name
      } else {
        const jrsp = item as types.JobOfferResponse
        return jrsp.provider.name
      }
    },

    createdAt (item: ItemType) {
      const d = new Date(item.createdAt)
      const month = d.getMonth() + 1
      const date = d.getDate()
      const hour = d.getHours()
      const mins = d.getMinutes()
      return `${month}-${date} ${hour}:${mins}`
    },

    passedToString (passed: number) {
      let mins = Math.floor(passed / 60_000)
      const hours = Math.floor(mins / 60)
      mins = mins % 60
      return `${hours}:${mins}`
    },

    timePassed (item: ItemType) {
      if (item.__typename === 'JobOffer') {
        const passed = this.timePassedMap.get(-item.id)
        if (passed) {
          return this.passedToString(passed)
        } else {
          return ''
        }
      } else {
        const passed = this.timePassedMap.get(item.id)
        if (passed) {
          return this.passedToString(passed)
        } else {
          return ''
        }
      }
    },

    async onClickRun () {
      this.loading = true
      const chatRoomId = process.env.NODE_ENV === 'development' ? 1930 : 674
      const offers = await apollo.readJobOfferGraphs(await apc.getClient(), {
        where: {
          chatRoomId: { equals: chatRoomId },
        },
        orderBy: [
          {
            id: 'desc',
          },
        ],
      }) ?? []
      const items: ItemType[] = []
      for (const offer of offers) {
        items.push(offer)
        const from = new Date(offer.createdAt).getTime()
        if (!offer.responses.length) {
          const to = Date.now()
          this.timePassedMap.set(-offer.id, to - from)
        }
        for (const jrsp of offer.responses) {
          items.push(jrsp)
          const to = new Date(jrsp.createdAt).getTime()
          this.timePassedMap.set(jrsp.id, to - from)
        }
      }
      this.items = items
      this.loading = false
    },
  },
})
