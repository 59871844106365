import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { m_updateChannel } from './channel-gql'

export async function updateChannel (client: ClientType, id: number, data: types.ChannelUpdateInput): Promise<types.Channel | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_updateChannel,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneChannelArgs,
    }) as MutationResult
    return result.data.updateOneChannel
  } catch (e) {
  }

}

export async function addWaitingToChannel (client: ClientType, channelId: number, userId: number): Promise<types.Channel | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_updateChannel,
      variables: {
        where: { id: channelId },
        data: {
          waitings: {
            connect: [
              {
                id: userId,
              },
            ],
          },
        },
      } as types.MutationUpdateOneChannelArgs,
    }) as MutationResult
    return result.data.updateOneChannel
  } catch (e) {
  }
}
