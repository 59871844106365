import { ClientType } from '../apollo'
import { types } from '../types'
import { findManySysNotification, findManyMesgNotification } from '@dinosband/bv-main-schema/dist/client-gql'
import { profiler } from '@dinosband/dbi-utils'

export async function readSysNotifications (client: ClientType, profileIds: number[], cursor?: number, before?: boolean): Promise<types.SysNotification[] | undefined> {
  try {
    const result = await client.query<{findManySysNotification: types.SysNotification[]}>({
      query: findManySysNotification,
      variables: {
        where: {
          receiverId: { in: profileIds },
          id: cursor ? before ? { lt: cursor } : { gt: cursor } : undefined,
        },
        orderBy: [
          { id: 'desc' },
        ],
        take: 100,
      } as types.QueryFindManySysNotificationArgs,
    })
    return result.data.findManySysNotification
  } catch (e) {
  }
}

export async function readMesgNotifications (client: ClientType, userId: number, cursor?: number, before?: boolean): Promise<types.MesgNotification[] | undefined> {
  profiler.of('read-mesg-noti').begin()
  try {
    const result = await client.query<{findManyMesgNotification: types.MesgNotification[]}>({
      query: findManyMesgNotification,
      variables: {
        where: {
          userId: { equals: userId },
          id: cursor ? before ? { lt: cursor } : { gt: cursor } : undefined,
        },
        orderBy: [
          { id: 'desc' },
        ],
        take: 20,
      } as types.QueryFindManyMesgNotificationArgs,
    })
    profiler.of('read-mesg-noti').end()
    return result.data.findManyMesgNotification
  } catch (e) {
  }
}