import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyChannel } from '@dinosband/bv-main-schema/dist/client-gql'

export async function searchChannels (client: ClientType, keywords: string[]): Promise<types.Channel[] | undefined> {
  const where: types.ChannelWhereInput = {}

  if (keywords.length === 0) {
    return []
  } else {
    where.OR = []
    keywords.forEach((word) => {
      where.OR?.push({
        keywords: { contains: word, mode: 'insensitive' },
      })
      where.OR?.push({
        name: { contains: word, mode: 'insensitive' },
      })
    })
  }
  try {
    const result = await client.query({
      query: findManyChannel,
      variables: { where } as types.QueryFindManyChannelArgs,
    })
    return result.data.findManyChannel
  } catch (e) {
  }
}
