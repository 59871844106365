
import Vue from 'vue'
import { RouteConfig } from 'vue-router'

interface MenuNavItem {
  label: string
  path: string
  icon?: string
  key?: string
}

interface Params {
  menuList: (MenuNavItem | string)[]
  redirects: Record<string, string>
}

export default Vue.extend({
  props: {
    params: {
      type: Object as () => Params,
      default: undefined,
    },
    keepChildView: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isDarkMode: false,
      currentPath: '',
      offDarkMode: undefined as (() => void) | undefined,
    }
  },

  watch: {
    '$route': {
      immediate: true,
      handler (to: RouteConfig) {
        let path = to.path
        path = this.params.redirects[path] ?? path
        this.currentPath = path.replace('_', '')
      },
    },
  },

  created () {
    this.isDarkMode = document.body.classList.contains('dark')
    this.offDarkMode = this.$dbiRouter.event.$on('dark', (isDarkMode: boolean) => {
      this.isDarkMode = isDarkMode
    })
  },

  beforeDestroy () {
    if (this.offDarkMode) {
      this.offDarkMode()
    }
  },
})
