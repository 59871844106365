import { JobOffer, JobOfferResponseCreateNestedOneWithoutChatRoomInput, JobOfferResponseProps, JobOfferResponseState, Profile_ChatRoomCreateNestedManyWithoutChatRoomInput } from '@dinosband/bv-main-schema/dist/types'
import { ClientType } from '../apollo'
import { types } from '../types'

import { createOneChatRoomWithParticipants,
  createGroupChatRoom as createGroupChatRoomMutation,
  createCommentsChatRoom as createCommentsChatRoomMutation,
  createHelpDeskChatRoom as createHelpDeskChatRoomMutation,
} from './chatroom-gql'

export async function createChatRoom (client: ClientType, channelId: number | undefined, data: types.ChatRoomCreateInput): Promise<types.ChatRoom | undefined> {
  if (channelId) {
    data = {
      ...data,
      channel: { connect: { id: channelId } },
    }
  }
  try {
    const result = await client.mutate({
      mutation: createOneChatRoomWithParticipants,
      variables: {
        data,
      } as types.MutationCreateOneChatRoomArgs,
    })
    return result.data.createOneChatRoom as types.ChatRoom
  } catch (e) {
  }
}

export interface CreatePrivateChatRoomArgs {
  // profileIds: number[]
  targetId: number // root message owner
  targetProfileId: number
  requesterId: number // requester
  requesterProfileId: number
  providerPost?: boolean // rootMessage를 만든 사람이 provider인가?
  channelId?: number
  rootMessageId?: number
  rootChatRoomId?: number // for comments chat room
  firstMessageBody?: string
  jrspProps?: JobOfferResponseProps
  forceCreate?: boolean
  jobOffer?: JobOffer
  jrsp?: {
    jobOfferId: number
    state?: JobOfferResponseState
    startAt?: Date
    duration?: number
    priceExt?: number
    toBePaidExt?: number
    paymentTid?: string
  }
}

export async function createPrivateChatRoom (client: ClientType, args: CreatePrivateChatRoomArgs) {
  const profileIds = [args.targetProfileId, args.requesterProfileId]
  const participants: Profile_ChatRoomCreateNestedManyWithoutChatRoomInput = {
    create: profileIds.map((id) => ({
      profile: {
        connect: {
          id,
        },
      },
      role: '',
    })),
  }
  const providerId = args.providerPost ? args.targetProfileId : args.requesterProfileId
  const providerUserId = args.providerPost ? args.targetId : args.requesterId
  const clientId = args.providerPost ? args.requesterProfileId : args.targetProfileId
  const clientUserId = args.providerPost ? args.requesterId : args.targetId

  let jobOfferResponse: JobOfferResponseCreateNestedOneWithoutChatRoomInput | undefined
  if (args.rootMessageId) {
    let props: string | undefined
    if (args.jrspProps) {
      props = JSON.stringify(args.jrspProps)
    }
    jobOfferResponse = {
      create: {
        jobOfferId: args.rootMessageId,
        providerId,
        providerUserId,
        clientId,
        clientUserId,
        state: args.jrsp?.state ?? 'Start',
        props,
        startAt: args.jrsp?.startAt,
        duration: args.jrsp?.duration,
        priceExt: args.jrsp?.priceExt,
        toBePaidExt: args.jrsp?.toBePaidExt,
        payments: args.jrsp?.paymentTid ? { connect: [{ tid: args.jrsp.paymentTid }] } : undefined,
      },
    }
  }
  const data: types.ChatRoomCreateInput = {
    type: types.ChatRoomType.ONE_TO_ONE,
    status: types.ChatRoomStatus.OPENED,
    name: 'one-to-one',
    rootMessageId: args.rootMessageId,
    participants,
    jobOfferResponse,
  }
  return await createChatRoom(client, args.channelId, data)
}

export async function createGroupChatRoom (client: ClientType, rootMessageId: number, channelId: number) {
  const data: types.GroupChatRoomCreateInput = {
    type: types.ChatRoomType.JOB_GROUP,
    rootMessageId,
    channelId,
  }
  try {
    const result = await client.mutate({
      mutation: createGroupChatRoomMutation,
      variables: { data },
    })
    return result.data.createGroupChatRoom as types.ChatRoom
  } catch (e) {
  }
}

export async function createCommentsChatRoom (client: ClientType, commentsRootMessageId: number, commentsRootChatRoomId: number, channelId: number) {
  const data: types.CommentsChatRoomCreateInput = {
    channelId,
    commentsRootMessageId,
    commentsRootChatRoomId,
  }
  try {
    const result = await client.mutate({
      mutation: createCommentsChatRoomMutation,
      variables: { data },
    })
    return result.data.createCommentsChatRoom as types.ChatRoom
  } catch (e) {
  }
}


export async function createHelpDeskChatRoom (client: ClientType, channelId: number, profileId: number) {
  try {
    const data: types.HelpDeskChatRoomCreateInput = {
      channelId,
      profileId,
    }
    const result = await client.mutate({
      mutation: createHelpDeskChatRoomMutation,
      variables: { data },
    })
    return result.data.createHelpDeskChatRoom as types.ChatRoom
  } catch (e) {
  }
}
