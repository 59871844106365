import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
// import { debug } from '@dinosband/dbi-utils'

import { onUser_ProfileAdded } from './profile-gql'
// import { option_readUserGraph } from '../user'

type Callback = (newData: types.User_Profile) => void

export function subscribeOnUser_ProfileAdded (client: ClientType, userId: number, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onUser_ProfileAdded,
    variables: {
      userId,
    },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      const newData = data.onUser_ProfileAdded as types.User_Profile
      cb(newData)
      /*
      const userId = newData.userId

      debug.log('=> onUser_ProfileAdded: profileId = ', newData.profileId)

      const opt = option_readUserGraph(userId)
      const cached = JSON.parse(JSON.stringify(client.readQuery(opt)))

      const user = cached.findUniqueUser as types.User
      if (user.profiles) {
        user.profiles.push(newData)
      } else {
        user.profiles = [newData]
      }
      client.writeQuery({
        query: opt.query,
        data: cached,
      })
      */
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
