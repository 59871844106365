import { ClientType } from '../apollo'
import { ApolloQueryResult, types } from '../types'

import { createOneCommerceOrder, updateOneCommerceOrder, findManyCommerceOrder, findUniqueCommerceOrder } from '@dinosband/bv-main-schema/dist/client-gql'

interface Mutations {
  createOneCommerceOrder: types.CommerceOrder
  updateOneCommerceOrder: types.CommerceOrder
}

type MutationResult = ApolloQueryResult<Mutations>

export async function createCommerceOrder (client: ClientType, data: types.CommerceOrderCreateInput): Promise<types.CommerceOrder> {
  const result = await client.mutate({
    mutation: createOneCommerceOrder,
    variables: {
      data,
    } as types.MutationCreateOneCommerceOrderArgs,
  }) as MutationResult
  return result.data.createOneCommerceOrder
}

export async function updateCommerceOrder (client: ClientType, id: number, data: types.CommerceOrderUpdateInput): Promise<types.CommerceOrder> {
  const result = await client.mutate({
    mutation: updateOneCommerceOrder,
    variables: {
      where: { id },
      data,
    } as types.MutationUpdateOneCommerceOrderArgs,
  }) as MutationResult
  return result.data.updateOneCommerceOrder
}

export async function readCommerceOrders (client: ClientType, where: types.CommerceOrderWhereInput, cursor?: number, take?: number): Promise<types.CommerceOrder[] | undefined> {
  const result = await client.query({
    query: findManyCommerceOrder,
    variables: {
      where,
      orderBy: [
        { id: 'desc' },
      ],
      cursor: cursor ? { id: cursor } : undefined,
      skip: cursor ? 1 : undefined,
      take,
    } as types.QueryFindManyCommerceOrderArgs,
  })
  return result.data.findManyCommerceOrder
}

export async function readCommerceOrder (client: ClientType, id: number): Promise<types.CommerceOrder | undefined> {
  const result = await client.query({
    query: findUniqueCommerceOrder,
    variables: {
      where: { id },
    } as types.QueryFindManyCommerceOrderArgs,
  })
  return result.data.findUniqueCommerceOrder
}
