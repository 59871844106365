import { ClientType } from '../apollo'
import { types, MutationResult } from '../types'

import { createOneCategory, updateOneCategory } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createCategory (client: ClientType, data: types.CategoryCreateInput): Promise<types.Category | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneCategory,
      variables: {
        data,
      } as types.MutationCreateOneCategoryArgs,
    }) as MutationResult
    return result.data.createOneCategory
  } catch (e) {
  }
}

export async function updateCategory (client: ClientType, id: number, data: types.CategoryUpdateInput): Promise<types.Category | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneCategory,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneCategoryArgs,
    }) as MutationResult
    return result.data.updateOneCategory
  } catch (e) {
  }
}