export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
};

export type Broadcast = {
  __typename?: 'Broadcast';
  /** Chatting Room ID */
  chatRoomId?: Maybe<Scalars['Int']>;
  /** created date of broadcast */
  createdAt?: Maybe<Scalars['Date']>;
  /** Sender ID */
  profileId?: Maybe<Scalars['Int']>;
  /** properties of broadcast */
  props?: Maybe<Scalars['JSON']>;
  /** device tokens of broadcast */
  tokens?: Maybe<Scalars['JSON']>;
};

export type ChannelIdInput = {
  equal?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ChannelOperation = {
  __typename?: 'ChannelOperation';
  channelId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  /** joined, invited, kickedOut, destroyed, left */
  action?: Maybe<Scalars['String']>;
  /** Unique identifier for the chatting room */
  chatRoomId?: Maybe<Scalars['Int']>;
  /** Participant Profile IDs  */
  profileIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** updated date of chatting room */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Profile IDs which left or invited or joined chatting room */
  updatedProfileIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** an User ID which did try update(left, join, invite, destroy) the chatting room */
  userId?: Maybe<Scalars['Int']>;
};

export type CreatedAtInput = {
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
};

export type DeleteReasonInput = {
  equal?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type IntTypeInput = {
  equal?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum JobOfferStateEnum {
  Hired = 'Hired',
  Open = 'Open'
}

export type JobOfferStateInput = {
  equal?: InputMaybe<JobOfferStateEnum>;
  in?: InputMaybe<Array<JobOfferStateEnum>>;
  notIn?: InputMaybe<Array<JobOfferStateEnum>>;
};

export type Keyword = {
  __typename?: 'Keyword';
  /** keyword */
  keyword?: Maybe<Scalars['String']>;
  /** updated date of keyword */
  updatedAt?: Maybe<Scalars['Date']>;
};

export type KeywordsOrderInput = {
  createdAt?: InputMaybe<KeywordsOrderValue>;
  keyword?: InputMaybe<KeywordsOrderValue>;
  updatedAt?: InputMaybe<KeywordsOrderValue>;
};

/** Order by asc or desc */
export enum KeywordsOrderValue {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Message = {
  __typename?: 'Message';
  /** Chatting Room ID */
  chatRoomId?: Maybe<Scalars['Int']>;
  /** created date of message */
  createdAt?: Maybe<Scalars['Date']>;
  /** reason for deletion */
  deleteReason?: Maybe<Scalars['Int']>;
  /** deleted date of message */
  deletedAt?: Maybe<Scalars['Date']>;
  /** Unique identifier for the message */
  id?: Maybe<Scalars['Int']>;
  /** Sender ID */
  profileId?: Maybe<Scalars['Int']>;
  /** properties of message */
  props?: Maybe<Scalars['JSON']>;
  /** Sequential no of Chat room message */
  seq?: Maybe<Scalars['Int']>;
  /** updated date of message */
  updatedAt?: Maybe<Scalars['Date']>;
};

export type MessageCachedIdInput = {
  gte?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
};

export type MessageIdInput = {
  cached?: InputMaybe<MessageCachedIdInput>;
  equal?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lte?: InputMaybe<Scalars['Int']>;
};

export type MessagesOptionInput = {
  excludeThumbnail?: InputMaybe<Scalars['Boolean']>;
};

export type MessagesOrderInput = {
  _score?: InputMaybe<OrderValue>;
  chatRoomId?: InputMaybe<OrderValue>;
  id?: InputMaybe<OrderValue>;
  seq?: InputMaybe<OrderValue>;
  userId?: InputMaybe<OrderValue>;
};

export type MessagesScoreInput = {
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notiUserCount?: InputMaybe<Scalars['Boolean']>;
};

export type MessagesWhereAdvancedLocationsInput = {
  keyword?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MessagesWhereConditionInput = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MessagesWhereConditionNumberInput = {
  all?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type MessagesWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<MessagesWhereRecursiveInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MessagesWhereRecursiveInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MessagesWhereRecursiveInput>>>;
  advancedLocations?: InputMaybe<Array<InputMaybe<MessagesWhereAdvancedLocationsInput>>>;
  body?: InputMaybe<Scalars['String']>;
  /** Also include messages by admin where userId is set */
  byAdmin?: InputMaybe<Scalars['Boolean']>;
  channelId?: InputMaybe<ChannelIdInput>;
  chatRoomId?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  chatRoomIds?: InputMaybe<IntTypeInput>;
  chatRoomType?: InputMaybe<IntTypeInput>;
  createdAt?: InputMaybe<CreatedAtInput>;
  deleteReason?: InputMaybe<DeleteReasonInput>;
  excludeThumbnail?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<MessageIdInput>;
  isCensored?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  jobOfferState?: InputMaybe<JobOfferStateInput>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lessonId?: InputMaybe<StringTypeInput>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locationsOrKeywords?: InputMaybe<MessagesWhereLocationKeywordInput>;
  profileId?: InputMaybe<ProfileIdInput>;
  seq?: InputMaybe<MessageIdInput>;
  texts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updatedAt?: InputMaybe<UpdatedAtInput>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type MessagesWhereLocationKeywordInput = {
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MessagesWhereRecursiveInput = {
  AND?: InputMaybe<Array<InputMaybe<MessagesWhereRecursiveInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MessagesWhereRecursiveInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MessagesWhereRecursiveInput>>>;
  analyzedKeywords?: InputMaybe<MessagesWhereConditionInput>;
  locations?: InputMaybe<MessagesWhereConditionInput>;
  profileIds?: InputMaybe<MessagesWhereConditionNumberInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  broadcast: Broadcast;
  deleteMessage: Message;
  destroy?: Maybe<ChatRoom>;
  invite?: Maybe<ChatRoom>;
  join?: Maybe<ChatRoom>;
  kickOut?: Maybe<ChatRoom>;
  leave?: Maybe<ChatRoom>;
  message: Message;
  moveMessageChatRoom: Message;
  restoreMessage: Message;
  restoreMessageChatRoom: Message;
  updateMessage: Message;
};


export type MutationBroadcastArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
  props?: InputMaybe<Scalars['JSON']>;
};


export type MutationDeleteMessageArgs = {
  chatRoomId: Scalars['Int'];
  deleteReason?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationDestroyArgs = {
  chatRoomId: Scalars['Int'];
};


export type MutationInviteArgs = {
  chatRoomId: Scalars['Int'];
  profileIds: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationJoinArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type MutationKickOutArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type MutationLeaveArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type MutationMessageArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
  props?: InputMaybe<Scalars['JSON']>;
};


export type MutationMoveMessageChatRoomArgs = {
  chatRoomId: Scalars['Int'];
  chatRoomType: Scalars['Int'];
  commentsRootChatRoomId?: InputMaybe<Scalars['Int']>;
  commentsRootMessageId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationRestoreMessageArgs = {
  chatRoomId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationRestoreMessageChatRoomArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateMessageArgs = {
  chatRoomId: Scalars['Int'];
  id: Scalars['Int'];
  props: Scalars['JSON'];
};

/** Order by asc or desc */
export enum OrderValue {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ProfileIdInput = {
  equal?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type Query = {
  __typename?: 'Query';
  analyze?: Maybe<Array<Maybe<Tokens>>>;
  keywords?: Maybe<Array<Maybe<Keyword>>>;
  lastChannelOperation?: Maybe<ChannelOperation>;
  lastMessageInfos?: Maybe<Array<Maybe<Message>>>;
  lastUpdatedMessageInfos?: Maybe<Array<Maybe<Message>>>;
  memberCount?: Maybe<Scalars['Int']>;
  members?: Maybe<Array<Maybe<Scalars['Int']>>>;
  message?: Maybe<Message>;
  messageCount?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<Maybe<Message>>>;
};


export type QueryAnalyzeArgs = {
  text: Scalars['String'];
};


export type QueryKeywordsArgs = {
  keyword: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<KeywordsOrderInput>;
};


export type QueryLastChannelOperationArgs = {
  channelId?: InputMaybe<Scalars['Int']>;
};


export type QueryLastMessageInfosArgs = {
  chatRoomId?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryLastUpdatedMessageInfosArgs = {
  chatRoomId?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryMemberCountArgs = {
  chatRoomId?: InputMaybe<Scalars['Int']>;
};


export type QueryMembersArgs = {
  chatRoomId?: InputMaybe<Scalars['Int']>;
};


export type QueryMessageArgs = {
  chatRoomId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryMessageCountArgs = {
  where?: InputMaybe<MessagesWhereInput>;
};


export type QueryMessagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  option?: InputMaybe<MessagesOptionInput>;
  order?: InputMaybe<MessagesOrderInput>;
  score?: InputMaybe<MessagesScoreInput>;
  where?: InputMaybe<MessagesWhereInput>;
};

export type StringTypeInput = {
  equal?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  broadcasted?: Maybe<Broadcast>;
  deletedMessage?: Maybe<Message>;
  destroy?: Maybe<ChatRoom>;
  invited?: Maybe<ChatRoom>;
  joined?: Maybe<ChatRoom>;
  lastMessageInfos?: Maybe<Message>;
  left?: Maybe<ChatRoom>;
  message?: Maybe<Message>;
  updatedChatRoom?: Maybe<ChatRoom>;
  updatedMessage?: Maybe<Message>;
};


export type SubscriptionBroadcastedArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type SubscriptionDeletedMessageArgs = {
  chatRoomId?: InputMaybe<Array<Scalars['Int']>>;
};


export type SubscriptionDestroyArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type SubscriptionInvitedArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type SubscriptionJoinedArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type SubscriptionLastMessageInfosArgs = {
  chatRoomId?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type SubscriptionLeftArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type SubscriptionMessageArgs = {
  where?: InputMaybe<MessagesWhereInput>;
};


export type SubscriptionUpdatedChatRoomArgs = {
  chatRoomId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type SubscriptionUpdatedMessageArgs = {
  chatRoomId?: InputMaybe<Array<Scalars['Int']>>;
};

export type Tokens = {
  __typename?: 'Tokens';
  /** token location end offset in input text */
  end_offset?: Maybe<Scalars['Int']>;
  /** position */
  position?: Maybe<Scalars['Int']>;
  /** token location start offset in input text */
  start_offset?: Maybe<Scalars['Int']>;
  /** a separated token of input text */
  token?: Maybe<Scalars['String']>;
  /** type */
  type?: Maybe<Scalars['String']>;
};

export type UpdatedAtInput = {
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
};
