
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { MenuItem } from '../utils/menu-item'

@Component
export default class DbTree extends Vue {
  @Prop() root!: MenuItem
  @Prop() highlight!: MenuItem
  @Prop({ default: false }) section!: boolean
  @Prop({ default: true }) useDetail!: boolean
  @Prop({ default: false }) useToggle!: boolean
  @Prop({ default: '0' }) indent!: string // if it is defined as number, it works like string

  items: MenuItem[] = []
  checkedItem: MenuItem | null = null
  topSelectionLevel = 1 // first level user can interact

  created (): void {
    this.updateItems()
  }

  @Watch('root')
  onPropertyChanged (value: string, oldValue: string): void {
    this.updateItems()
  }

  itemLabel (item: MenuItem): string {
    if (item === this.highlight) {
      return item.name + '***'
    } else {
      return item.name
    }
  }

  updateItems (): void {
    const items: MenuItem[] = []
    this.addItems(items, this.root.children)
    this.items = items
    if (this.section) {
      this.topSelectionLevel = this.root.level + 2
    } else {
      this.topSelectionLevel = this.root.level + 1
    }
  }

  addItems (items: MenuItem[], nodes: MenuItem[]): void {
    nodes.forEach((node) => {
      items.push(node)
      this.addItems(items, node.children)
    })
  }

  isLastLevel (item: MenuItem): boolean {
    if (item.level !== this.topSelectionLevel) {
      return false
    }
    if (!item.parent) {
      return false
    }

    let last = true
    for (const sibling of item.parent.children) {
      if (sibling.hasChildren()) {
        last = false
        break
      }
    }
    return last
  }

  itemStyle (item: MenuItem): Record<string, unknown> {
    let indent = parseInt(this.indent)
    if (this.isLastLevel(item)) {
      if (!this.useDetail) {
        indent += 1
      }
    } else {
      const ii = item.level - this.root.level - 1
      indent += ii
      if (this.section && !this.isSectionTop(item)) {
        indent -= 1
      }
      if (item.component) {
        indent -= 1
      }
    }

    const style: Record<string, unknown> = {
      'padding-left': indent + 'em',
    }
    return style
  }

  getExpIcon (item: MenuItem): string {
    if (item.hasChildren()) {
      if (item.isExpanded) {
        return 'remove'
      } else {
        return 'add'
      }
    } else {
      return 'xxx'
    }
  }

  isSectionTop (item: MenuItem): boolean {
    return this.section && (item.level === this.root.level + 1)
  }

  isVisible (item: MenuItem): boolean {
    if (this.section && (item.level <= this.root.level + 2)) {
      return true
    }
    return item.isVisible
  }

  isItemButton (item: MenuItem): boolean {
    return item.isEnabled && !item.component
  }

  showDetail (item: MenuItem): boolean {
    return this.useDetail && item.hasChildren()
  }

  showExpandButton (item: MenuItem): boolean {
    return !this.useDetail && !this.isSectionTop(item) && !this.isLastLevel(item)
  }

  showCheckbox (item: MenuItem): boolean {
    return item.isCheck && !this.isSectionTop(item) && !this.useToggle
  }

  showToggle (item: MenuItem): boolean {
    return item.isCheck && !this.isSectionTop(item) && this.useToggle
  }

  onClickExp (item: MenuItem): void {
    item.toggleExpanded()

    // In section mode, this item is visible even though its parent is not expanded.
    // If user expand this item intentionally, it is safe to assume user want to expand its parent.
    // Actually, unless we expand this item's parent, this item's children will not be shown even if we expand this item.
    if (this.section && item.parent && !item.parent.isExpanded) {
      item.parent.toggleExpanded()
    }
  }

  // In order to differentiate checked change by setting value and by clicking,
  // process onCheck in onItemClicked() instead of onCheck().
  onCheck (item: MenuItem): void {
    this.checkedItem = item
  }

  onItemClicked (item: MenuItem): void {
    if (!item.isEnabled || item.component) {
      return
    }

    if (this.checkedItem === item) {
      item.updateSelectedOfRelatedItems()
      this.$emit('check', item)
    } else if (this.useDetail && item.hasChildren()) {
      this.onClickExp(item)
    } else if (!item.hasChildren()) {
      this.$emit('click', item)
    }
    this.checkedItem = null
  }
}
