import { Capacitor, registerPlugin } from '@capacitor/core'
import { Storage } from '@capacitor/storage'

const SharedPreferencesPlugin = registerPlugin('SharedPreferences')

const Preferences = Capacitor.isNativePlatform() && Capacitor.getPlatform() == 'ios' ?
  SharedPreferencesPlugin as unknown as typeof Storage :
  Storage

export async function get (key: string, type: string) {
  const value = await _get(key)
  if (value == null) {
    return undefined
  }
  if (type == 'number') {
    return Number(value)
  } else if (type == 'boolean') {
    return value === 'true'
  } else if (type == 'object') {
    return JSON.parse(value)
  }
  return value
}

export async function set (key: string, value: unknown) {
  if (value == null) {
    remove(key)
    return
  }
  let value_: string
  if (typeof value == 'string') {
    value_ = value
  } else if (typeof value == 'object') {
    value_ = JSON.stringify(value)
  } else {
    value_ = String(value)
  }
  const key_ = `Beezvalley_${key}`
  if (Capacitor.isNativePlatform()) {
    await Preferences.set({ key: key_, value: value_ })
  } else {
    localStorage.setItem(key_, value_)
  }
}

export async function remove (key: string) {
  const key_ = `Beezvalley_${key}`
  if (Capacitor.isNativePlatform()) {
    await Preferences.remove({ key: key_ })
  } else {
    localStorage.removeItem(key_)
  }
}

export async function clear (key: string) {
  if (Capacitor.isNativePlatform()) {
    await Preferences.clear()
  } else {
    localStorage.clear()
  }
}

async function _get (key: string) {
  const key_ = `Beezvalley_${key}`
  if (Capacitor.isNativePlatform()) {
    const result = await Preferences.get({ key: key_ })
    return result.value
  } else {
    return localStorage.getItem(key_)
  }
}

