import { isPlatform } from '@ionic/core'
import { addIcons } from 'ionicons'

import shareOutline from 'ionicons/dist/svg/share-outline.svg'
import shareSocialOutline from 'ionicons/dist/svg/share-social-outline.svg'
import ellipsisVertical from 'ionicons/dist/svg/ellipsis-vertical.svg'

export const icons: Record<string, string> = {
  share: isPlatform('ios') ? 'share-outline' : 'share-social-outline',
  menu: 'ellipsis-vertical',
}

export function registerDefaultIcons () {
  addIcons({
    'share-outline': shareOutline,
    'share-social-outline': shareSocialOutline,
    'ellipsis-vertical': ellipsisVertical,
  })
}
