import { ClientType } from '../apollo'
import { types } from '../types'
import { findManyUser_ChatRoomViewFields } from './user_chatroom_view-gql'

function option_readUser_ChatRoomViews (chatRoomIds?: number[], userId?: number) {
  const variables: types.QueryFindManyUser_ChatRoomViewArgs = {}
  if (chatRoomIds) {
    variables.where = { chatRoomId: { in: chatRoomIds } }
  }
  if (userId) {
    variables.where = { ...variables.where, userId: { equals: userId } }
  }
  return {
    query: findManyUser_ChatRoomViewFields,
    variables,
  }
}

export async function readUser_ChatRoomViews (client: ClientType, chatRoomIds?: number[], userId?: number) {
  const opt = option_readUser_ChatRoomViews(chatRoomIds, userId)
  try {
    const result = await client.query<{findManyUser_ChatRoomView: types.User_ChatRoomView[]}>(opt)
    return result.data.findManyUser_ChatRoomView
  } catch (e) {
  }
}