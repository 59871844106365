
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { types, apollo } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'
import { AttachmentType } from '@dinosband/bv-attachment'
import BvaImageLoader from './BvaImageLoader.vue'
import { saveAttachment } from '../attachment'

type SysPictureWithThumbnail = types.SysPicture & {
  thumbnail?: string
}

export default Vue.extend({
  data () {
    return {
      avatars: [] as SysPictureWithThumbnail[],
    }
  },

  async created () {
    this.avatars = await apollo.readSysPictures(await apc.getClient(), 'profile') ?? []
    for (const avatar of this.avatars) {
      const thumbnail = await apollo.readThumbnail(await apc.getClient(), avatar.thumbnailId)
      if (thumbnail) {
        // avatar.thumbnail = thumbnail.data
        Vue.set(avatar, 'thumbnail', thumbnail.data)
      }
    }
  },

  methods: {
    onAddPicture (ev: Event) {
      (this.$refs.imageLoader as BvaImageLoader).click(ev)
    },

    onImageUploaded (e: { target: HTMLInputElement}) {
      saveAttachment(this, e, AttachmentType.PROFILE_IMAGE, async (info) => {
        if (info.url) {
          const picture = await apollo.createSysPicture(await apc.getClient(), {
            type: 'profile',
            url: info.url,
            thumbnailId: info.thumbnailId || 0,
          }) as SysPictureWithThumbnail
          if (picture) {
            this.avatars.push(picture)
          }
        }
      })
    },

    async onDeletePicture (pic: types.SysPicture) {
      const inx = this.avatars.findIndex((p) => p.id === pic.id)
      if (inx >= 0) {
        this.avatars.splice(inx, 1)
      }
      await apollo.deleteSysPicture(await apc.getClient(), pic.id)
    },
  },
})
