import { Animation, createAnimation } from '@ionic/core'

/* eslint-disable @typescript-eslint/no-non-null-assertion */

/**
 * Md Modal Enter Animation
 */
export const mdBottomSheetEnterAnimation = (
  baseEl: HTMLElement,
  presentingEl: HTMLElement,
  full = false,
): Animation => {
  const headerEl = baseEl.querySelector('.bottom-sheet-header') as HTMLElement
  const contentEl = baseEl.querySelector('.bottom-sheet-content') as HTMLElement
  // const wrapperEl = baseEl.querySelector('.modal-wrapper') as HTMLElement

  const headerH = headerEl?.offsetHeight || 0
  const contentH = headerH + contentEl?.offsetHeight || 0
  const screenH = baseEl.offsetHeight

  const aniStep = full ? 1.0 : contentH / screenH
  const contentTop = full ? 0 : screenH - contentH

  // wrapperEl.style.position = 'absolute'


  const baseAnimation = createAnimation()
  const backdropAnimation = createAnimation()
  const wrapperAnimation = createAnimation()

  backdropAnimation
    .addElement(baseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', 0.01, `calc(var(--backdrop-opacity) * ${aniStep})`)
    .beforeStyles({
      'pointer-events': 'none',
    })
    .afterClearStyles(['pointer-events'])

  wrapperAnimation
    .addElement(baseEl.querySelector('.modal-wrapper')!)
    .keyframes([
      // { offset: 0, opacity: 0.01, transform: 'translateY(100vh)' },
      { offset: 0, opacity: 0.01, transform: `translateY(${screenH}px)` },
      { offset: 1, opacity: 1, transform: `translateY(${contentTop}px)` },
    ])

  return baseAnimation
    .addElement(baseEl)
    .easing('cubic-bezier(0.36,0.66,0.04,1)')
    .duration(280)
    .addAnimation([backdropAnimation, wrapperAnimation])
}