import { ClientType } from '../apollo'
import { types } from '../types'

import {
  findUniquePayment,
} from '@dinosband/bv-main-schema/dist/client-gql'
import { q_readPayments } from './payment-gql'

export async function readPayments (client: ClientType, where: types.PaymentWhereInput, take?: number, cursor?: number): Promise<types.Payment[] | undefined> {
  try {
    const result = await client.query({
      query: q_readPayments,
      variables: {
        where,
        orderBy: [
          { id: 'desc' },
        ],
        take,
        cursor: cursor ? { id: cursor } : undefined,
        skip: cursor ? 1 : undefined,
      } as types.QueryFindManyPaymentArgs,
    })
    return result.data.findManyPayment
  } catch (e) {
  }
}

export async function readPayment (client: ClientType, id: number): Promise<types.Payment | undefined> {
  try {
    const result = await client.query({
      query: findUniquePayment,
      variables: {
        where: { id },
      } as types.QueryFindUniquePaymentArgs,
    })
    return result.data.findUniquePayment
  } catch (e) {
  }
}
