import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
import * as frag from '../fragments-gql'

export const q_readProfile_ChannelsOfChannel = gql`
  query q_readProfile_ChannelsOfChannel($where: Profile_ChannelWhereInput!, $cursor: Profile_ChannelWhereUniqueInput, $skip: Int, $take: Int) {
    findManyProfile_Channel(where: $where, cursor: $cursor, skip: $skip, take: $take) {
      ...Profile_ChannelFields
      profile {
        ...ProfileBase
      }
      expertInfos {
        ...ExpertInfoFields
      }
    }
  }
  ${client.Profile_ChannelFields}
  ${frag.ProfileBase}
  ${client.ExpertInfoFields}
`

export const q_readProfile_ChannelsOfProfile = gql`
  query q_readProfile_ChannelsOfProfile($where: Profile_ChannelWhereInput!, $cursor: Profile_ChannelWhereUniqueInput, $skip: Int, $take: Int) {
    findManyProfile_Channel(where: $where, cursor: $cursor, skip: $skip, take: $take) {
      ...Profile_ChannelFields
      channel {
        ...ChannelFields
        experts {
          ...ChannelExpertFields
        }
      }
      profile {
        ...ProfileFields
      }
      expertInfos {
        ...ExpertInfoFields
      }
    }
  }
  ${client.Profile_ChannelFields}
  ${client.ProfileFields}
  ${client.ChannelFields}
  ${client.ChannelExpertFields}
  ${client.ExpertInfoFields}
`

export const q_findManyProfile_ChannelActivity = gql`
  query q_findManyProfile_ChannelActivity($where: Profile_ChannelActivityWhereInput!, $cursor: Profile_ChannelActivityWhereUniqueInput, $skip: Int, $take: Int, $orderBy: [Profile_ChannelActivityOrderByWithRelationInput!]) {
    findManyProfile_ChannelActivity(where: $where, cursor: $cursor, skip: $skip, take: $take, orderBy: $orderBy) {
      ...Profile_ChannelActivityFields
      profileChannel {
        ...Profile_ChannelFields
        profile {
          ...ProfileFields
        }
      }
    }
  }
  ${client.Profile_ChannelActivityFields}
  ${client.Profile_ChannelFields}
  ${client.ProfileFields}
`

export const q_checkChannelAccessCode = gql`
  query q_checkChannelAccessCode($channelId: Int!, $code: String!) {
    checkChannelAccessCode(channelId: $channelId, code: $code)
  }
`

export const m_joinChannel = gql`
  mutation m_joinChannel($profileId: Int!, $channelId: Int!, $code: String, $referrerId: Int, $referrerTel: String) {
    joinChannel(profileId: $profileId, channelId: $channelId, code: $code, referrerId: $referrerId, referrerTel: $referrerTel) {
      error
      profile_Channel {
        ...Profile_ChannelGraph
      }
    }
  }
  ${frag.Profile_ChannelGraph}
`

export const m_joinChannelAndSetAll = gql`
  mutation m_joinChannelAndSetAll($input: JoinChannelAndSetAllInput!) {
    joinChannelAndSetAll(input: $input) {
      error
      profile_Channel {
        ...Profile_ChannelGraph
      }
    }
  }
  ${frag.Profile_ChannelGraph}
`

export const onProfile_ChannelAdded = gql`
  subscription onProfile_ChannelAdded($profileIds: [Int]!) {
    onProfile_ChannelAdded(profileIds: $profileIds) {
      ...Profile_ChannelGraph
    }
  }
  ${frag.Profile_ChannelGraph}
`

export const onProfile_ChannelUpdated = gql`
  subscription onProfile_ChannelUpdated($profileIds: [Int]!) {
    onProfile_ChannelUpdated(profileIds: $profileIds) {
      ...Profile_ChannelFields
    }
  }
  ${client.Profile_ChannelFields}
`

export const onProfile_ChannelDeleted = gql`
  subscription onProfile_ChannelDeleted($profileIds: [Int]!) {
    onProfile_ChannelDeleted(profileIds: $profileIds) {
      id
    }
  }
`
