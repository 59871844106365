import Vue from 'vue'

type ToastArgs = {
  color?: string
  cssClass?: string
}

export async function toast (message: string, args: ToastArgs = { }, duration = 1000) {
  const toast = await Vue.prototype.$ionic.toastController.create(Object.assign({}, {
    // color: 'primary',
    duration,
    message,
    position: 'bottom',
  }, args))
  await toast.present()
}
