import { BvApolloClient } from '@dinosband/bv-apollo-client'
import { initApolloClient as initAttachment } from '@dinosband/bv-attachment'
import { mainStore } from '@dinosband/bv-utils'

export class MessengerClient {
  readonly apolloClient = new BvApolloClient()
  protected userId = -1
  protected server = undefined as unknown as { httpURI: string, wsURI: string}

  async getClient () {
    return await this.apolloClient.getClient()
  }

  initialize (server: { httpURI: string, wsURI: string}) {
    this.server = server
    initAttachment({ s3: mainStore.apollo, broadcast: this.apolloClient })
  }

  async reconnect () {
    if (this.userId == mainStore.userId) {
      return
    }
    this.userId = mainStore.userId
    this.apolloClient.setAuthToken(0 < this.userId ? mainStore.authToken : '')
    if (!this.apolloClient.initialized) {
      await this.apolloClient.createApolloClient(this.server.httpURI, this.server.wsURI)
    } else {
      await this.apolloClient.reconnect()
    }
  }
}

export const messengerClient = new MessengerClient()