import { ClientType } from '../apollo'
import { ApolloQueryResult, types } from '../types'

import { createOneSysPicture } from '@dinosband/bv-main-schema/dist/client-gql'

interface Mutations {
  createOneSysPicture: types.SysPicture
}

type Result = ApolloQueryResult<Mutations>

export async function createSysPicture (client: ClientType, data: types.SysPictureCreateInput): Promise<types.SysPicture | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneSysPicture,
      variables: {
        data,
      },
    }) as Result
    return result.data.createOneSysPicture
  } catch (e) {
  }
}

