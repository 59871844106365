import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { debug } from '@dinosband/dbi-utils'
import {
  onJobOfferUpdated,
  onJobOfferOfResponseUpdated,
} from './job-offer-gql'

type Callback = (newData: types.JobOffer) => void

export function subscribeOnJobOfferUpdated (client: ClientType, profileIds: number[], userId: number | undefined, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onJobOfferUpdated,
    variables: { profileIds, userId },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      const newData = data.onJobOfferUpdated as types.JobOffer
      debug.log(`=> onJobOfferUpdated: id=${newData.id}`)
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}

export function subscribeOnJobOfferOfResponseUpdated (client: ClientType, offerIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onJobOfferOfResponseUpdated,
    variables: { offerIds },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      debug.log('=> onJobOfferOfResponseUpdated')
      const newData = data.onJobOfferOfResponseUpdated as types.JobOffer
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
