import { ClientType } from '../apollo'
import { types } from '../types'

import { createOneSearchWord } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createUserSearchWord (client: ClientType, userId: number, words: string): Promise<types.SearchWord | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneSearchWord,
      variables: {
        data: {
          words,
          user: { connect: { id: userId } },
        } as types.SearchWordCreateInput,
      },
    })
    return result.data.createOneSearchWord
  } catch (e) {
  }
}
