import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { _createOneExpertInfo } from './expert-gql'

export async function createExpertInfo (client: ClientType, data: types.ExpertInfoCreateInput): Promise<types.ExpertInfo | undefined> {
  try {
    const result = await client.mutate({
      mutation: _createOneExpertInfo,
      variables: {
        data,
      } as types.MutationCreateOneExpertInfoArgs,
    }) as MutationResult
    return result.data.createOneExpertInfo
  } catch (e) {
  }
}
