import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { updateManyProfile_Channel } from '@dinosband/bv-main-schema/dist/client-gql'

export async function expelProfiles (client: ClientType, channelId: number, profileIds: number[]): Promise<void> {
  const now = new Date()
  try {
    await client.mutate({
      mutation: updateManyProfile_Channel,
      variables: {
        where: {
          AND: [
            {
              channelId: {
                equals: channelId,
              },
            },
            {
              profileId: {
                in: profileIds,
              },
            },
          ],
        },
        data: {
          expelledAt: {
            set: now,
          },
        },
      } as types.MutationUpdateManyProfile_ChannelArgs,
    }) as MutationResult
  } catch (e) {
  }
}

export async function unexpelProfiles (client: ClientType, channelId: number, profileIds: number[]): Promise<void> {
  try {
    await client.mutate({
      mutation: updateManyProfile_Channel,
      variables: {
        where: {
          AND: [
            {
              channelId: {
                equals: channelId,
              },
            },
            {
              profileId: {
                in: profileIds,
              },
            },
          ],
        },
        data: {
          expelledAt: {
            set: null,
          },
        },
      } as types.MutationUpdateManyProfile_ChannelArgs,
    }) as MutationResult
  } catch (e) {
  }
}
