import { ClientType } from '../apollo'
import { types } from '../types'

import { updateOneJobOffer } from '@dinosband/bv-main-schema/dist/client-gql'

export async function updateJobOfferState (client: ClientType, id: number, newState: types.JobOfferState): Promise<types.JobOffer | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneJobOffer,
      variables: {
        where: { id },
        data: {
          state: {
            set: newState,
          },
        },
      } as types.MutationUpdateOneJobOfferArgs,
    })
    return result.data.updateOneJobOffer
  } catch (e) {
  }
}

export async function updateJobOfferProps (client: ClientType, id: number, props: types.JobOfferProps): Promise<types.JobOffer | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneJobOffer,
      variables: {
        where: { id },
        data: {
          props: {
            set: JSON.stringify(props),
          },
        },
      } as types.MutationUpdateOneJobOfferArgs,
    })
    return result.data.updateOneJobOffer
  } catch (e) {
  }
}

export async function updateJobOfferOfMessage (client: ClientType, mesgId: number, data: types.JobOfferUpdateInput): Promise<types.JobOffer | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneJobOffer,
      variables: {
        where: { mesgId },
        data,
      } as types.MutationUpdateOneJobOfferArgs,
    })
    return result.data.updateOneJobOffer
  } catch (e) {
  }
}

export async function updateJobOffer (client: ClientType, id: number, data: types.JobOfferUpdateInput): Promise<types.JobOffer | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneJobOffer,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneJobOfferArgs,
    })
    return result.data.updateOneJobOffer
  } catch (e) {
  }
}
