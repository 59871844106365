
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { types, apollo } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'

export default Vue.extend({
  data () {
    return {
      list: [] as types.SysNotice[],
    }
  },

  async mounted () {
    this.list = await apollo.readAllSysNotices(await apc.getClient()) ?? []
  },

  methods: {
    getLabel (item: types.SysNotice) {
      if (item.channelId) {
        return `[${item.channelId}] ${item.subject}`
      } else {
        return item.subject
      }
    },

    async onCreate () {
      const created = await this.$dbIon.doModal(this, 'BvaNoticeCreate') as types.SysNotice
      if (created) {
        this.list.push(created)
      }
    },

    async onShowDetail (notice: types.SysNotice) {
      this.$dbiRouter.push(this, 'BvaNoticeDetail', { id: notice.id })
    },

    async onDelete (item: types.SysNotice) {
      if (await this.$dbIon.yesNoAlert(`'${item.subject}'를 삭제할까요?`, i18n.tc('messenger.delete'), i18n.tc('components.notification-tabNotificationsHeader05'))) {
        await apollo.deleteSysNotice(await apc.getClient(), item.id)
        this.list = this.list.filter((i) => i.id !== item.id)
      }
    },
  },
})
