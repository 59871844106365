import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import pqueue from 'p-queue'
import { format } from 'date-fns'

import { readFileEntry } from './cordova-plugin-file-util'

import * as s3 from '../apollo-client'

import { Error_No_Response } from '../errors'

const queue = new pqueue({ concurrency: 1 })

export type ProgressCallback = (value: number) => void

export async function requestFileUploadURL (file: File, domain: string, inputUrl?: string, isPublic = false) {
  const uploadUrl = await s3.requestFileUploadURL(file.type ?? '', domain, inputUrl, isPublic)
  return uploadUrl.url
}

export async function requestFileUploadSignedURL (file: File, domain: string, inputUrl?: string, isPublic = false) {
  const uploadUrl = await s3.requestFileUploadURL(file.type ?? '', domain, inputUrl, isPublic)
  return uploadUrl
}

export async function uploadFile (file: File, domain: string, url: string, progress?: ProgressCallback, isUrlSigned = false) {
  await queue.add(async () => {
    await trasnferFile(file, domain, url, progress, isUrlSigned)
  })
}

export async function uploadFilePublic (channelId: number, filename: string, lang: string, ext: string, file: File, progress?: ProgressCallback): Promise<string> {
  const date = format(new Date(), 'yyyy-MM-dd-HH-mm-ss')
  const filePath = `/channels/${channelId}/${filename}-${lang}-${date}.${ext}`

  try {
    const url = await requestFileUploadSignedURL(file, 'files', filePath, true)
    await uploadFile(file, 'files', url.signedURL, progress, true)
    return url.url
  } catch (e) {
    console.error('Failed to uploadFilePublic:', e)
    return ''
  }
}

export function checkFileType (file: File, type: string) {
  const inputExt = file.name.split('.').pop()
  switch (type) {
    case 'html':
      if (inputExt !== 'html' && inputExt !== 'htm') {
        return false
      }
      if (!file.type.includes('html')) {
        return false
      }
      break
  }
  return true
}

async function trasnferFile (file: File, domain: string, url: string, progress?: ProgressCallback, isUrlSigned = false) {
  const { signedURL } = isUrlSigned ? { signedURL: url } : await s3.requestFileUploadURL(file.type ?? '', domain, url)
  const size = file.size
  let half = false

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': file.type ?? '',
    },
    onUploadProgress: (event: { loaded: number }) => {
      if (progress) {
        if (half) {
          progress((size + event.loaded) * 100 / (size * 2))
        } else {
          progress(event.loaded * 100 / size)
        }
      }
    },
  }
  try {
    let data: unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const f_ = file as any
    if (f_.fileURL) {
      data = f_.fileURL
    } else if (f_.entry) {
      half = true
      data = await readFileEntry(f_.entry, (event: { loaded: number }) => {
        if (progress) {
          progress(event.loaded * 100 / (size * 2))
        }
      })
    } else if (f_.blob) {
      data = f_.blob
    } else {
      data = file
    }
    await axios.put(signedURL, data, config)
  } catch (e) {
    onAxiosError(e as AxiosError)
  }
}

function onAxiosError (error: AxiosError) {
  if (error.response) {
    // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
    console.error(error.response)
    console.error(error.response.data)
    console.error(error.response.status)
    throw Error(error.message + '(' + error.response.statusText + ')')
  } else if (error.request) {
    // 요청이 이루어 졌으나 응답을 받지 못했습니다.
    // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
    // Node.js의 http.ClientRequest 인스턴스입니다.
    console.error(error.request)
    throw Error_No_Response
  }
  // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
  console.error('Error', error.message)
  throw Error(error.message)
}
