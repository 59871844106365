import { ClientType } from '../apollo'
import { ApolloQueryResult, types } from '../types'

import { createChannel as mutation } from './channel-gql'

interface Mutations {
  createChannel: types.Channel
}

type Result = ApolloQueryResult<Mutations>

export async function createChannel (client: ClientType, profileId: number, data: types.ChannelCreateInput): Promise<types.Channel | undefined> {
  try {
    const result = await client.mutate({
      mutation,
      variables: {
        profileId,
        data,
      },
    }) as Result
    return result.data.createChannel
  } catch (e) {
  }
}
