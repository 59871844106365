import Vue from 'vue'
import type { Store } from '@dinosband/bv-main-client'

type MainStoreRef = {
  store: Store | undefined
}

export const mainStoreRef: MainStoreRef = Vue.observable({
  store: undefined,
})

export let mainStore!: Store

export function setMainStore (store: Store) {
  mainStore = store
  mainStoreRef.store = store
}
