import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyChatRoomMenusWithChatRooms } from './chatroom-menu-gql'

export async function readChatRoomMenusWithChatRooms (client: ClientType, channelIds: number[]): Promise<types.ChannelChatRoomMenu[] | undefined> {
  try {
    const result = await client.query<{findManyChannelChatRoomMenu: types.ChannelChatRoomMenu[]}>({
      query: findManyChatRoomMenusWithChatRooms,
      variables: {
        where: { channelId: { in: channelIds } },
      } as types.QueryFindManyChannelChatRoomMenuArgs,
    })
    return result.data.findManyChannelChatRoomMenu
  } catch (e) {
  }
}
