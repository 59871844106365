import gql from 'graphql-tag'

import { Message, MessageProps } from '@dinosband/bv-messenger-schema'
import * as types from '@dinosband/bv-messenger-schema'
import { ClientType } from '@dinosband/bv-apollo-client'

import { _MESSAGE_DATA_PROPERTIES } from './const'

export async function createMessage (apollo: ClientType, args: types.MutationMessageArgs) {
  try {
    const result = await apollo.mutate < { message: Message }>({
      mutation: gql`mutation message($chatRoomId: Int!, $profileId: Int!, $props: JSON) {
      message(chatRoomId: $chatRoomId, profileId: $profileId, props: $props) {
        ${_MESSAGE_DATA_PROPERTIES}
      }
    }`,
      variables: {
        ...args,
      },
    })
    return result.data?.message
  } catch (e) {
  }
}

export function deleteMessage (apollo: ClientType, messageId: number, chatRoomId: number, deleteReason?: types.MessageDeleteReason) {
  try {
    return apollo.mutate({
      mutation: gql`mutation deleteMessage($messageId: Int!, $chatRoomId: Int!, $deleteReason: Int) {
      deleteMessage( id: $messageId, chatRoomId: $chatRoomId, deleteReason: $deleteReason ) {
        id
      }
    }`,
      variables: {
        messageId,
        chatRoomId,
        deleteReason,
      },
    })
  } catch (e) {
  }
}

export async function editMessage (apollo: ClientType, messageId: number, chatRoomId: number, props: MessageProps) {
  try {
    const result = await apollo.mutate<{ updateMessage: Message }>({
      mutation: gql`mutation updateMessage($messageId: Int!, $chatRoomId: Int!, $props: JSON!) {
      updateMessage( id: $messageId, chatRoomId: $chatRoomId, props: $props ) {
        ${_MESSAGE_DATA_PROPERTIES}
      }
    }`,
      variables: {
        messageId,
        chatRoomId,
        props,
      },
    })
    return result.data?.updateMessage
  } catch (e) {
  }
}