import { ClientType } from '../apollo'
import { types } from '../types'

import { createOneJobOffer } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createJobOffer (client: ClientType, chatRoomId: number, profileId: number, mesgId?: number): Promise<types.JobOffer | undefined> {
  try {
    const mesg = mesgId ? {
      create: {
        id: mesgId,
        chatRoom: { connect: { id: chatRoomId } },
      },
    } : undefined
    const result = await client.mutate({
      mutation: createOneJobOffer,
      variables: {
        data: {
          id: mesgId || 0,
          mesg,
          chatRoom: {
            connect: { id: chatRoomId },
          },
          client: {
            connect: { id: profileId },
          },
          state: 'Open',
        } as types.JobOfferCreateInput,
      },
    })
    return result.data.createOneJobOffer
  } catch (e) {
  }
}
