import { ClientType } from '../apollo'
import { types, ApolloQueryResult } from '../types'

import { getPermissionOfChatRoom as mutation } from './chatroom-gql'

interface Mutations {
  getPermissionOfChatRoom: types.Profile_Channel
}

type Result = ApolloQueryResult<Mutations>

export async function getPermissionOfChatRoom (client: ClientType, chatRoomId: number, profile_ChannelId: number, code: string): Promise<types.Profile_Channel | undefined> {
  try {
    const result = await client.mutate({
      mutation,
      variables: {
        chatRoomId,
        profile_ChannelId,
        code,
      },
    }) as Result
    return result.data.getPermissionOfChatRoom
  } catch (e) {
  }
}

