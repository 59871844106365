import _Vue from 'vue'
import { BackButtonEvent, Components } from '@ionic/core'
import { App } from '@capacitor/app'
import '@dinosband/vue-ionic'
import '@dinosband/dbi-router'
import { debug } from '@dinosband/dbi-utils'

import {
  assetManager,
  registerComponents, registerComponentsSync,
  doModal, doFullScreenModal, showBottomSheet,
  alert, yesNoAlert, isAlertOn, dismissAlert,
  toast, tooltip,
  loading, dismissLoading,
  showActionMenu, isActionMenuOn, dismissActionMenu,
  goBack, closePage, goBackOrClose,
  isPageModal,
  doModalOrDetail,
  getSimpleInput, getSimpleKeyword,
} from './utils'

export { FabController } from './utils/fab-controller'
export type { DbFab } from './utils/fab-controller'

export type RequireContext = __WebpackModuleApi.RequireContext

export interface DbIonicVuePluginOptions {
  componentsContext: RequireContext | RequireContext[]
  staticComponentsContext?: RequireContext | RequireContext[]
  assetsContext: RequireContext | RequireContext[]
  globalAssets?: string
  icons: string[]
  siteConfig?: Record<string, unknown>
  vueOptions: Record<string, unknown>
}

function install (Vue: typeof _Vue, options: DbIonicVuePluginOptions): void {
  registerComponentsSync(
    // 타이밍 문제가 있어서 일단 Db... components는 static하게 링크되도록 한다.
    // require.context('./components', false, /[A-Z]\w+\.(vue|js)$/, 'lazy'),
    require.context('./components', false, /[A-Z]\w+\.(vue|js)$/),
  )

  const start = Date.now()

  if (options.componentsContext) {
    if (Array.isArray(options.componentsContext)) {
      options.componentsContext.forEach((context) => {
        registerComponents(context)
      })
    } else {
      registerComponents(options.componentsContext)
    }
  }

  if (options.staticComponentsContext) {
    if (Array.isArray(options.staticComponentsContext)) {
      options.staticComponentsContext.forEach((context) => {
        registerComponentsSync(context)
      })
    } else {
      registerComponentsSync(options.staticComponentsContext)
    }
  }
  debug.log('DbIonicVue: register options.Components', (Date.now() - start) / 1000)

  if (options.assetsContext) {
    if (Array.isArray(options.assetsContext)) {
      options.assetsContext.forEach((context) => assetManager.registerAssets(context))
    } else {
      assetManager.registerAssets(options.assetsContext)
    }
  }

  if (options.globalAssets) {
    assetManager.setGlobalAssets(options.globalAssets)
  }

  const $ionic = Vue.prototype.$ionic
  if (!$ionic) {
    console.error('Install vue-ionic plugin before installing this plugin.')
    return
  }

  // ionic에서 default로 설정한 handler의 priority가 99와 100이므로
  // 그 이상으로 priority를 설정해 주어야 한다.
  // App.addListener는 priority를 줄 수 없으므로 default action을 막을 수 없다.
  // 따라서 App.addListener 대신 ionBackButton을 사용해야 한다.
  document.addEventListener('ionBackButton', (e) => {
    const ev = e as BackButtonEvent
    const priority = 200
    ev.detail.register(priority, () => {
      handleBackButton()
    })
  })

  // App.addListener('backButton', ({ canGoBack }) => {
  //   handleBackButton()
  //   // if (!canGoBack) {
  //   //   App.exitApp()
  //   // } else {
  //   //   window.history.back()
  //   // }
  // })

  // for testing
  document.addEventListener('keyup', (ev) => {
    const keyCode = ev.key
    if (keyCode == 'Backspace' && ev.altKey && ev.ctrlKey) {
      handleBackButton()
    }
  }, false)

  Object.defineProperty(Vue.prototype, '$dbIon', {
    get () {
      return {
        siteConfig: options.siteConfig,
        assetManager,
        doModal,
        doModalOrDetail,
        doFullScreenModal,
        showBottomSheet,
        alert,
        yesNoAlert,
        toast,
        tooltip,
        loading,
        dismissLoading,
        showActionMenu,
        goBack,
        closePage,
        goBackOrClose,
        isPageModal,
        getSimpleInput,
        getSimpleKeyword,
      }
    },
  })

  // teardown mixin
  Vue.mixin({
    destroyed () {
      // eslint-disable-next-line
      const vm = this as any
      vm._props = {}
      if (vm.$options) {
        vm.$options.propsData = {}
      }
      if (vm.$vnode?.componentOptions) {
        vm.$vnode.componentOptions.propsData = {}
      }

      vm._watchers = []
      vm._watcher = undefined
      // if (vm._watchers && Array.isArray(vm._watchers)) {
      //   // eslint-disable-next-line
      //   vm._watchers.forEach((watcher: any) => {
      //     watcher.teardown()
      //   })
      // }
      // this._computedWatchers = {}
      delete vm._computedWatchers
      vm._data = {}
    },
  })
}

export * from './utils'
export * from './components'

export const DbIonicVuePlugin = {
  install,
  version: '__VERSION__',
}

declare module 'vue/types/vue' {
  interface Vue {
    $dbIon: {
      siteConfig: Record<string, unknown> | undefined
      assetManager: typeof assetManager
      doModal: typeof doModal
      doModalOrDetail: typeof doModalOrDetail
      doFullScreenModal: typeof doFullScreenModal
      showBottomSheet: typeof showBottomSheet
      alert: typeof alert
      toast: typeof toast
      tooltip: typeof tooltip
      yesNoAlert: typeof yesNoAlert
      loading: typeof loading
      dismissLoading: typeof dismissLoading
      showActionMenu: typeof showActionMenu
      goBack: typeof goBack
      closePage: typeof closePage
      goBackOrClose: typeof goBackOrClose
      isPageModal: typeof isPageModal
      getSimpleInput: typeof getSimpleInput
      getSimpleKeyword: typeof getSimpleKeyword
    }
  }
}

let _backButtonIsClosing = 0

async function handleBackButton () {
  // const vue = getActivePage()
  // const canGoBack = vue.$el.classList.contains('can-go-back')
  // const isModal = isPageModal(vue)

  const router = _Vue.prototype.$dbiRouter
  const node = router.activeNode()
  const canGoBack = !!node.under
  const isModal = node.isModal
  const vue = node.nativeComponent

  const now = Date.now()


  if (_backButtonIsClosing && now - _backButtonIsClosing < 1000) {
    _backButtonIsClosing = 0
    // alert('exitApp')
    App.exitApp()
    return
  } else {
    _backButtonIsClosing = 0
  }

  if (isAlertOn()) {
    dismissAlert()
  } else if (isActionMenuOn()) {
    dismissActionMenu()
  } else if (await isMenuOpen()) {
    closeMenu()
  } else if (canGoBack) {
    const page = vue as unknown as { goBack: (isOK: boolean) => void }
    page?.goBack(false)
  } else if (isModal) {
    const page = vue as unknown as { onCancel: () => void }
    page?.onCancel()
  } else {
    _backButtonIsClosing = now
  }
}

function _findMenu () {
  return document.getElementById('bv-main-menu') as Components.IonMenu | null
}

async function isMenuOpen () {
  const menu = _findMenu()
  if (menu) {
    return await menu.isOpen()
  }
  return false
}

function closeMenu () {
  const menu = _findMenu()
  if (menu) {
    menu.close()
  }
}