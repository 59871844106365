import { ClientType } from '../apollo'
import { types } from '../types'

import { m_requestVerificationCode as m_request, m_verifyCode as m_verify } from './code-verification-gql'

export async function requestVerificationCode (client: ClientType, input: types.RequestVerificationCodeInput): Promise<types.RequestVerificationCodeOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_request,
      variables: { input },
    })
    return result.data.requestVerificationCode
  } catch (e) {
  }
}

export async function verifyCode (client: ClientType, input: types.VerifyCodeInput): Promise<types.VerifyCodeOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_verify,
      variables: { input },
    })
    return result.data.verifyCode
  } catch (e) {
  }
}
