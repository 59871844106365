import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { onUserBlockingAdded, onUserBlockingDeleted } from './blocking-gql'
// import { option_readUserGraph } from '../user/read-user-graph'

type Callback = (newData: types.UserBlocking) => void

export function subscribeOnUserBlockingAdded (client: ClientType, userId: number, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onUserBlockingAdded,
    variables: {
      userId,
    },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      const newData = data.onUserBlockingAdded as types.UserBlocking
      debug.log('=> onUserBlockingAdded')
      cb(newData)
      /*

      const opt = option_readUserGraph(userId)
      const pre = client.readQuery(opt)
      const cached = JSON.parse(JSON.stringify(pre))
      const user = cached.findUniqueUser as types.User
      if (newData.blockerId === userId) {
        if (user.blockedUsers) {
          user.blockedUsers.push(newData)
        } else {
          user.blockedUsers = [newData]
        }
      } else if (newData.blockeeId === userId) {
        if (user.blockingUsers) {
          user.blockingUsers.push(newData)
        } else {
          user.blockingUsers = [newData]
        }
      } else {
        debug.error('onUserBlockingAdded: no matching userId')
      }
      client.writeQuery({
        query: opt.query,
        data: cached,
      })
      */
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}

export function subscribeOnUserBlockingDeleted (client: ClientType, userId: number, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onUserBlockingDeleted,
    variables: {
      userId,
    },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      const newData = data.onUserBlockingDeleted as types.UserBlocking
      debug.log('=> onUserBlockingDeleted')
      cb(newData)
      /*

      const opt = option_readUserGraph(userId)
      const pre = client.readQuery(opt)
      const cached = JSON.parse(JSON.stringify(pre))
      const user = cached.findUniqueUser as types.User
      if (newData.blockerId === userId) {
        if (user.blockedUsers) {
          const inx = user.blockedUsers.findIndex((blk) => blk.id === newData.id)
          if (inx >= 0) {
            user.blockedUsers.splice(inx, 1)
          }
        }
      } else if (newData.blockeeId === userId) {
        if (user.blockingUsers) {
          const inx = user.blockingUsers.findIndex((blk) => blk.id === newData.id)
          if (inx >= 0) {
            user.blockingUsers.splice(inx, 1)
          }
        }
      } else {
        debug.error('onUserBlockingAdded: no matching userId')
      }
      client.writeQuery({
        query: opt.query,
        data: cached,
      })
      */
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}

