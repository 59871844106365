import { ClientType } from '../apollo'
import { types } from '../types'

import { m_updateOneJobOfferResponse } from './job-offer-gql'

export async function updateJobOfferResponse (client: ClientType, id: number, data: types.JobOfferResponseUpdateInput): Promise<types.JobOfferResponse | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_updateOneJobOfferResponse,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneJobOfferResponseArgs,
    })
    return result.data.updateOneJobOfferResponse
  } catch (e) {
  }
}

export async function updateJobOfferResponseProps (client: ClientType, id: number, props: types.JobOfferResponseProps): Promise<types.JobOfferResponse | undefined> {
  return updateJobOfferResponse(client, id, {
    props: {
      set: JSON.stringify(props),
    },
  })
}

export async function updateJobOfferResponseState (client: ClientType, id: number, mesgId: number, state?: types.JobOfferResponseState, toBeDoneByProvider?: string | null, toBeDoneByClient?: string | null, userId?: number, partsHired?: string[]) {
  const data: types.JobOfferResponseUpdateInput = {}
  if (state) {
    data.state = { set: state }
    let trType: types.JobTransactionType | undefined
    let trBody = ''
    switch (state) {
      case 'Hired':
        data.hiredAt = { set: new Date() }
        trType = 'Hire'
        trBody = ''
        break
      case 'Canceled':
        data.hiredAt = { set: null }
        data.canceledAt = { set: new Date() }
        data.canceledBy = { set: userId }
        trType = 'Cancel'
        trBody = ''
        break
      case 'WorkFinished':
        data.finishedAt = { set: new Date() }
        trType = 'Finish'
        trBody = ''
        break
      case 'Closed':
        trType = 'Close'
        trBody = ''
        break
    }
    if (trType) {
      data.transactions = {
        create: [{
          type: trType,
          body: trBody,
          mesgId,
        }],
      }
    }
  }
  if (toBeDoneByProvider !== undefined && toBeDoneByProvider !== null) {
    data.toBeDoneByProvider = { set: toBeDoneByProvider }
  }
  if (toBeDoneByClient !== undefined && toBeDoneByClient !== null) {
    data.toBeDoneByClient = { set: toBeDoneByClient }
  }
  if (partsHired) {
    data.partsHired = partsHired
  }
  return await updateJobOfferResponse(client, id, data)
}

export async function setJobOfferResponseCanceled (client: ClientType, id: number, mesgId: number, userId: number) {
  return await updateJobOfferResponseState(client, id, mesgId, 'Canceled', undefined, undefined, userId)
}

export async function setJobOfferResponseWorkFinished (client: ClientType, id: number, mesgId: number) {
  return await updateJobOfferResponseState(client, id, mesgId, 'WorkFinished')
}

export async function setJobOfferResponseClose (client: ClientType, id: number, mesgId: number) {
  return await updateJobOfferResponseState(client, id, mesgId, 'Closed', '', '')
}
