
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { MenuItem } from '../utils/menu-item'

@Component
export default class DbTree extends Vue {
  @Prop() root!: MenuItem
  @Prop() timestamp!: number

  items: MenuItem[] = []
  checkedItem: MenuItem | null = null

  @Watch('timestamp', { immediate: true })
  onTimestampChanged (value: string, oldValue: string): void {
    this.updateItems()
  }

  itemLabel (item: MenuItem): string {
    if (item === this.root) {
      return 'Root'
    } else {
      return item.name
    }
  }

  updateItems (): void {
    const items: MenuItem[] = []
    this.addItem(items, this.root)
    this.items = items
  }

  addItem (items: MenuItem[], node: MenuItem): void {
    items.push(node)
    node.children.forEach((child) => {
      this.addItem(items, child)
    })
  }

  isLastLevel (item: MenuItem): boolean {
    return !item.hasChildren()
    /*
    if (item.level !== this.topSelectionLevel) {
      return false
    }
    if (!item.parent) {
      return false
    }

    let last = true
    for (const sibling of item.parent.children) {
      if (sibling.hasChildren()) {
        last = false
        break
      }
    }
    return last
    */
  }

  itemStyle (item: MenuItem): Record<string, unknown> {
    const indent = item.level - this.root.level
    /*
    let indent = 0
    if (this.isLastLevel(item)) {
      if (!this.useDetail) {
        indent += 1
      }
    } else {
      const ii = item.level - this.root.level - 1
      indent += ii
    }
    */

    const style: Record<string, unknown> = {
      'padding-left': indent + 'em',
    }
    return style
  }

  getExpIcon (item: MenuItem): string {
    if (item.hasChildren()) {
      if (item.isExpanded) {
        return 'remove'
      } else {
        return 'add'
      }
    } else {
      return 'xxx'
    }
  }

  isVisible (item: MenuItem): boolean {
    return item.isVisible
  }

  onClickExp (item: MenuItem): void {
    item.toggleExpanded()
  }

  onClickMenu (ev: Event, item: MenuItem): void {
    this.$emit('click', { event: ev, item })
  }
}
