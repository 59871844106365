import { ClientType } from '../apollo'
import { types } from '../types'

import { findManySysNotice, findManySysNoticeCount, findUniqueSysNotice } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readUnreadSysNoticeCount (client: ClientType, lastId: number): Promise<number | undefined> {
  try {
    const result = await client.query({
      query: findManySysNoticeCount,
      variables: {
        where: {
          id: { gt: lastId },
        },
      } as types.QueryFindManySysNoticeCountArgs,
    })
    return result.data.findManySysNoticeCount
  } catch (e) {
  }
}

export async function readAllSysNotices (client: ClientType): Promise<types.SysNotice[] | undefined> {
  try {
    const result = await client.query({
      query: findManySysNotice,
      variables: {
        where: {
          channelId: { equals: 0 },
        },
        orderBy: [
          { id: 'desc' },
        ],
        take: 10,
      } as types.QueryFindManySysNoticeArgs,
    })
    return result.data.findManySysNotice
  } catch (e) {
  }
}

export async function readSysNotices (client: ClientType): Promise<types.SysNotice[] | undefined> {
  try {
    const result = await client.query({
      query: findManySysNotice,
      variables: {
        orderBy: [
          { id: 'desc' },
        ],
        take: 10,
      } as types.QueryFindManySysNoticeArgs,
    })
    return result.data.findManySysNotice
  } catch (e) {
  }
}

export async function readSysNotice (client: ClientType, id: number): Promise<types.SysNotice | undefined> {
  try {
    const result = await client.query({
      query: findUniqueSysNotice,
      variables: {
        where: { id },
      } as types.QueryFindUniqueSysNoticeArgs,
    })
    return result.data.findUniqueSysNotice
  } catch (e) {
  }
}
