import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { onChatRoomUpdated, onChatRoomAdded, onGroupChatRoomUpdated } from './chatroom-gql'

type Callback = (newData: types.ChatRoom) => void

export function subscribeOnChatRoomUpdated (client: ClientType, userId: number, chatRoomIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onChatRoomUpdated,
    variables: { userId, chatRoomIds },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      const newData = data.onChatRoomUpdated as types.ChatRoom
      debug.log('=> onChatRoomUpdated: id = ', newData.id)
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}

export function subscribeOnChatRoomAdded (client: ClientType, userId: number, type: types.ChatRoomType[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onChatRoomAdded,
    variables: { userId, profileIds: [], type },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      const newData = data.onChatRoomAdded as types.ChatRoom
      debug.log('=> onChatRoomAdded: id = ', newData.id)
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}


export function subscribeOnGroupChatRoomUpdated (client: ClientType, userId: number, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onGroupChatRoomUpdated,
    variables: { userId },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      const newData = data.onGroupChatRoomUpdated as types.ChatRoom
      debug.log('=> onGroupChatRoomUpdated: id = ', newData.id)
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
