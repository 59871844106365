import { ClientType } from '../apollo'
import { types, MutationResult } from '../types'

import { createOneFollow } from '@dinosband/bv-main-schema/dist/client-gql'
import { MutationCreateOneFollowArgs } from '@dinosband/bv-main-schema/dist/types'

export async function createFollow (client: ClientType, channelId: number, userId: number, followeeUserId: number): Promise<types.Follow | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneFollow,
      variables: {
        data: {
          channelId: channelId,
          user: { connect: { id: followeeUserId } },
          follower: { connect: { id: userId } },
        },
      } as MutationCreateOneFollowArgs,
    }) as MutationResult
    return result.data.createOneFollow
  } catch (e) {
  }
}
