import Vue from 'vue'
import { Components } from '@ionic/core'
import { RouteNode } from '../core/route-node'

async function waitForNavReady (vue: Vue, retry: number): Promise<Components.IonNav | undefined> {
  let nav: Components.IonNav | undefined = undefined
  for (let i = 0; i < retry; i++) {
    nav = vue.$el?.closest('ion-nav') as Components.IonNav
    if (nav) {
      return nav
    }
    await vue.$nextTick()
  }
  return undefined
}

const navStack: RouteNode[] = []
let isHandling = false

export async function updateNavStack (vue: Vue, node: RouteNode) {
  // if (!node.over) {
  //   return
  // }

  if (isHandling) {
    return
  }
  isHandling = true

  const nav = await waitForNavReady(vue, 5) as Components.IonNav
  if (!nav) {
    throw '!!! updateNavStack: nav is not ready, yet !!!'
  }

  const topNode = node.getTopMostNode()
  node = topNode.getBottomNode()

  while (node.over) {
    node = node.over
    if (node.isMounted) {
      continue
    }
    if (vue.$dbiRouter.waitForNodeReady) {
      await vue.$dbiRouter.waitForNodeReady(node)
    }
    // vue.$dbiRouter.pushToStack(vue)
    await nav.push(node.component, {
      params: node.componentParams,
      routeNode: node,
      routeParams: node.routeParams,
    })
  }

  let lenViews = 0
  for (let i = 0; ; i++) {
    const view = await nav.getByIndex(i)
    if (!view) {
      lenViews = i
      break
    }
  }
  while (node.level < lenViews) {
    await nav.pop()
    lenViews--
  }

  isHandling = false
}

export async function updateNavStackOld (vue: Vue, node: RouteNode) {

  if (isHandling) {
    return
  }
  isHandling = true

  const nav = await waitForNavReady(vue, 5) as Components.IonNav
  if (!nav) {
    throw '!!! updateNavStack: nav is not ready, yet !!!'
  }

  // const active = await nav.getActive()

  const topNode = node.getTopMostNode()
  node = topNode.getBottomNode()

  let i = 0
  for (; i < navStack.length; i++) {
    if (!node.over || !navStack[i].isSame(node.over)) {
      break
    }
    node = node.over
  }
  for (let j = navStack.length - 1; i <= j; j--) {
    navStack.pop()
    // vue.$dbiRouter.popFromStack()
    // await nav.pop()
    await nav.pop()
  }

  while (node.over) {
    node = node.over
    navStack.push(node)
    if (vue.$dbiRouter.waitForNodeReady) {
      await vue.$dbiRouter.waitForNodeReady(node)
    }
    // vue.$dbiRouter.pushToStack(vue)
    await nav.push(node.component, {
      params: node.componentParams,
      routeParams: node.routeParams,
    })
  }
  isHandling = false
}

// interface RouteParams {
//   routeNode: RouteNode
// }

export async function checkNavStack (vue: Vue, nav: Components.IonNav) {
  // if (isHandling) {
  //   return
  // }
  // const active = await nav.getActive()
  // if (active?.nav) {
  //   // const routeParams = active.params?.routeParams as RouteParams
  //   const views = active.nav.views
  //   const numPushedViews = views.length - 1
  //   if (numPushedViews !== navStack.length) {
  //     if (numPushedViews === navStack.length - 1) {
  //       const node = navStack.pop()
  //       if (node) {
  //         node.popNode()
  //         // vue.$dbiRouter.popNode(node)
  //       }
  //     } else {
  //       throw `Ooops: unexpected view counts: ${views.length} -- ${navStack.length}`
  //     }
  //   }
  // }
}