import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
// import * as frag from '../fragments-gql'

export const MessageSummaryFields = gql`
  fragment MessageSummaryFields on MessageSummary {
    id
    chatRoomId
    jobOfferState
    jobOfferPartsAndCounts
    jobOfferCountsHired
    fanCount
    notiUserCount
    liked
    bookmarked
    bookmarkedCount
    updatedBy
    commentsChatRoomId
  }
`

export const findManyMessageSummary = gql`
  query findManyMessageSummary($mesgIds: [Int]!) {
    findManyMessageSummary(mesgIds: $mesgIds) {
      ...MessageSummaryFields
    }
  }
  ${MessageSummaryFields}
`

export const m_upsertOneMessageInfo = gql`
  mutation m_upsertOneMessageInfo($where: MessageInfoWhereUniqueInput!, $create: MessageInfoCreateInput!, $update: MessageInfoUpdateInput!) {
    upsertOneMessageInfo(where: $where, create: $create, update: $update) {
      ...MessageInfo
    }
  }
  ${client.MessageInfo}
`

export const onMessageSummaryUpdated = gql`
  subscription onMessageSummaryUpdated($chatRoomId: Int!, $messageId: Int, $userId: Int) {
    onMessageSummaryUpdated(chatRoomId: $chatRoomId, messageId: $messageId, userId: $userId) {
      ...MessageSummaryFields
    }
  }
  ${MessageSummaryFields}
`
