import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueUserUsage } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readUserUsage (client: ClientType, id: number): Promise<types.UserUsage | undefined> {
  try {
    const result = await client.query({
      query: findUniqueUserUsage,
      variables: {
        where: {
          id,
        },
      } as types.QueryFindUniqueUserUsageArgs,
    })
    return result.data.findUniqueUserUsage
  } catch (e) {
  }
}
