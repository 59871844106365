import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
import * as frag from '../fragments-gql'

export const q_findManyUserBlocking = gql`
  query q_findManyUserBlocking($where: UserBlockingWhereInput!, $orderBy: [UserBlockingOrderByWithRelationInput!]) {
    findManyUserBlocking(where: $where, orderBy: $orderBy) {
      ...UserBlockingGraph
    }
  }
  ${frag.UserBlockingGraph}
`

export const m_blockProfile = gql`
  mutation m_blockProfile($profileId: Int!, $userId: Int) {
    blockProfile(profileId: $profileId, userId: $userId) {
      ...UserBlockingGraph
    }
  }
  ${frag.UserBlockingGraph}
`

export const m_unblockProfile = gql`
  mutation m_unblockProfile($profileId: Int!, $userId: Int) {
    unblockProfile(profileId: $profileId, userId: $userId) {
      ok
    }
  }
`

export const onUserBlockingAdded = gql`
  subscription onUserBlockingAdded($userId: Int!) {
    onUserBlockingAdded(userId: $userId) {
      ...UserBlockingGraph
    }
  }
  ${frag.UserBlockingGraph}
`

export const onUserBlockingDeleted = gql`
  subscription onUserBlockingDeleted($userId: Int!) {
    onUserBlockingDeleted(userId: $userId) {
      ...UserBlockingFields
    }
  }
  ${client.UserBlockingFields}
`
