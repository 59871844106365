import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { deleteOneProfile_Channel } from '@dinosband/bv-main-schema/dist/client-gql'
import { m_joinChannel, m_joinChannelAndSetAll, q_checkChannelAccessCode } from './profile_channel-gql'

export async function joinChannel (client: ClientType, input: types.JoinChannelInput): Promise<types.JoinChannelOutput | undefined> {
  try {
    const { profileId, channelId, code, referrerId, referrerTel } = input
    const result = await client.mutate({
      mutation: m_joinChannel,
      variables: {
        profileId,
        channelId,
        code,
        referrerId,
        referrerTel,
      } as types.MutationJoinChannelArgs,
    }) as MutationResult
    return result.data.joinChannel
  } catch (e) {
  }
}

export async function joinChannelAndSetAll (client: ClientType, input: types.JoinChannelAndSetAllInput): Promise<types.JoinChannelOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_joinChannelAndSetAll,
      variables: { input },
    }) as MutationResult
    return result.data.joinChannelAndSetAll
  } catch (e) {
  }
}

export async function checkChannelAccessCode (client: ClientType, channelId: number, code: string): Promise<boolean | undefined> {
  try {
    const result = await client.query({
      query: q_checkChannelAccessCode,
      variables: {
        channelId, code,
      },
    })
    return result.data.checkChannelAccessCode
  } catch (e) {
  }
}

export async function leaveChannel (client: ClientType, profileId: number, channelId: number): Promise<types.Profile_Channel | null | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteOneProfile_Channel,
      variables: {
        where: {
          profileId_channelId: { profileId, channelId },
        },
      } as types.MutationDeleteOneProfile_ChannelArgs,
    }) as MutationResult
    return result.data.deleteOneProfile_Channel || null
  } catch (e) {
  }
}
