import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueProfile_ChannelActivity, findManyProfile_ChannelActivityCount } from '@dinosband/bv-main-schema/dist/client-gql'
import { q_findManyProfile_ChannelActivity } from './profile_channel-gql'

export async function readProfileChannelActivity (client: ClientType, profileChannelId: number): Promise<types.Profile_ChannelActivity | undefined> {
  try {
    const result = await client.query({
      query: findUniqueProfile_ChannelActivity,
      variables: {
        where: {
          profileChannelId: profileChannelId,
        },
      } as types.QueryFindUniqueProfile_ChannelActivityArgs,
    })
    return result.data.findUniqueProfile_ChannelActivity ?? undefined
  } catch (e) {
  }
}

export async function findProfileChannelActivities (client: ClientType, args: types.QueryFindManyProfile_ChannelActivityArgs): Promise<types.Profile_ChannelActivity[] | undefined> {
  try {
    const result = await client.query({
      query: q_findManyProfile_ChannelActivity,
      variables: args,
    })
    return result.data.findManyProfile_ChannelActivity ?? []
  } catch (e) {
  }
}

export async function findProfileChannelActivityCount (client: ClientType, args: types.QueryFindManyProfile_ChannelActivityCountArgs): Promise<number | undefined> {
  try {
    const result = await client.query({
      query: findManyProfile_ChannelActivityCount,
      variables: args,
    })
    if (result) {
      return result.data.findManyProfile_ChannelActivityCount
    } else {
      return -1
    }
  } catch (e) {
  }
}
