
import Vue from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-2'
import { EXTENSIONS } from './tiptap'

export type AddImageCallback = (url: string) => void

type Level = 1 | 2 | 3 | 4

export default Vue.extend({
  components: {
    EditorContent,
  },

  props: {
    html: {
      type: String,
      default: '',
    },

    menubar: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      // linkUrl: null,
      linkMenuIsActive: false,
      editor: null as Editor | null,
    }
  },

  watch: {
    html: {
      immediate: true,
      handler () {
        this.editor?.commands.setContent(this.html)
      },
    },
  },

  async mounted () {
    const content = this.html
    const extensions = EXTENSIONS
    // if (this.html) {
    //   const doc = generateJSON(this.html, extensions)
    //   if (doc?.content) {
    //     for (const c of doc.content) {
    //       if (c.type === 'image') {
    //         const res = await getSignedURL(c.attrs.src as string)
    //         c.attrs.src = res.signedURL
    //       }
    //     }
    //     content = generateHTML(doc, extensions)
    //   }
    // }
    this.editor = new Editor({
      editable: !this.readOnly,
      extensions,
      // extensions: [
      //   new Blockquote(),
      //   new BulletList(),
      //   new CodeBlock(),
      //   new HardBreak(),
      //   new Heading({ levels: [1, 2, 3] }),
      //   new Link({ rel: '', target: '_blank' }),
      //   new HorizontalRule(),
      //   new ListItem(),
      //   new OrderedList(),
      //   new TodoItem(),
      //   new TodoList(),
      //   new Bold(),
      //   new Code(),
      //   new Italic(),
      //   new Strike(),
      //   new Underline(),
      //   new History(),
      // ],
      content,
      onUpdate: ({ editor }) => {
        this.$emit('change', editor.getJSON())
      },
    })
  },

  beforeDestroy () {
    this.editor?.destroy()
  },

  methods: {
    // showLinkMenu (attrs) {
    //   this.linkUrl = attrs.href
    //   this.linkMenuIsActive = true
    //   this.$nextTick(() => {
    //     this.$refs.linkInput.focus()
    //   })
    // },

    // hideLinkMenu () {
    //   this.linkUrl = null
    //   this.linkMenuIsActive = false
    // },

    // setLinkUrl (command, url) {
    //   command({ href: url, target: '_blank' })
    //   this.hideLinkMenu()
    // },

    isActive (cmd: string, args: Record<string, unknown> = {}): boolean {
      return this.editor?.isActive(cmd, args) || false
    },

    onBold () {
      this.editor?.chain().focus().toggleBold().run()
    },

    onItalic () {
      this.editor?.chain().focus().toggleItalic().run()
    },

    onStrike () {
      this.editor?.chain().focus().toggleStrike().run()
    },

    onUnderline () {
      this.editor?.chain().focus().toggleUnderline().run()
    },

    onLink () {
      const previousUrl = this.editor?.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          ?.chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        ?.chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },

    onCode () {
      this.editor?.chain().focus().toggleCode().run()
    },

    onCodeBlock () {
      this.editor?.chain().focus().toggleCodeBlock().run()
    },

    onParagraph () {
      this.editor?.chain().focus().setParagraph().run()
    },

    onHeading (args: { level: Level }) {
      this.editor?.chain().focus().setHeading(args).run()
    },

    onBulletList () {
      this.editor?.chain().focus().toggleBulletList().run()
    },

    onOrderedList () {
      this.editor?.chain().focus().toggleOrderedList().run()
    },

    onBlockquote () {
      this.editor?.chain().focus().toggleBlockquote().run()
    },

    onHR () {
      this.editor?.chain().focus().setHorizontalRule().run()
    },

    onUndo () {
      this.editor?.commands.undo()
    },

    onRedo () {
      this.editor?.commands.redo()
    },

    onAddImage () {
      this.$emit('add-image', (url: string) => {
        this.editor?.chain().focus().setImage({ src: url }).run()
      })
    },
  },
})
