
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class DbHtml extends Vue {
  @Prop() html!: string

  purified = ''

  @Watch('html', { immediate: true })
  async onHtmlDidChange () {
    this.purified = await this.domPurify(this.html)
  }

  async domPurify (src: string) {
    const DOMPurify = (await import('dompurify')).default
    return DOMPurify.sanitize(src)
  }
}
