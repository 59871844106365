// eslint-disable-next-line
type PubSubCallback = (data: any) => void
export type PubSubUnsubscriber = () => void

export class PubSub {
  subscribers = new Map<string, PubSubCallback[]>()

  // eslint-disable-next-line
  publish (eventName: string, data: any = undefined) {
    const callbacks = this.subscribers.get(eventName)
    if (callbacks) {
      callbacks.forEach((callback) => {
        callback(data)
      })
    }
  }
  subscribe (eventName: string, callback: PubSubCallback) {
    const callbacks = this.subscribers.get(eventName) || []
    callbacks.push(callback)
    this.subscribers.set(eventName, callbacks)
    return () => {
      this.unsubscribe(eventName, callback)
    }
  }

  unsubscribe (eventName: string, callback: PubSubCallback) {
    const callbacks = this.subscribers.get(eventName) || []
    const inx = callbacks.findIndex((v) => v === callback)
    if (inx >= 0) {
      callbacks.splice(inx, 1)
      this.subscribers.set(eventName, callbacks)
    }
  }

  // eslint-disable-next-line
  $emit (eventName: string, data: any = undefined) {
    return this.publish(eventName, data)
  }

  $on (eventName: string, callback: PubSubCallback) {
    return this.subscribe(eventName, callback)
  }

  $off (eventName: string, callback: PubSubCallback) {
    this.unsubscribe(eventName, callback)
  }

  cleanup () {
    this.subscribers.clear()
  }
}