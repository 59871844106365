
import Vue from 'vue'
import { isPlatform } from '@ionic/core'
export default Vue.extend({
  props: {
    params: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      showHeader: isPlatform('desktop'),
      showFooter: isPlatform('desktop'),
      site: this.$dbIon.siteConfig,
    }
  },

  methods: {
    onSplitPaneVisibilityChanged (e: CustomEvent) {
    },
  },
})
