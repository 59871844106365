import { BvApolloClient } from '@dinosband/bv-apollo-client'

export * from './request-file-state'
export * from './request-file-upload-url'
export * from './set-attachment-state'


export default {
  s3: undefined as unknown as BvApolloClient,
  broadcast: undefined as unknown as BvApolloClient,
}
