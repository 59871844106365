import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { isSameDay, previousDay } from '@dinosband/dbi-utils'
import { toLocaleDateString, toLocaleTimeString, DateFormat } from '@dinosband/bv-utils'
import { detectLanguage, i18nDefaultLocale, i18nFallbackLocale } from './detect'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: i18nDefaultLocale,
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency', currency: 'USD',
      },
    },
    'ko-KR': {
      currency: {
        style: 'currency', currency: 'KRW',
      },
    },
  },
  fallbackLocale: i18nFallbackLocale,
  messages: loadLocaleMessages(),
})

export async function initializeI18n () {
  i18n.locale = await detectLanguage()
}

export function getUserLanguage (): string | undefined {
  if (i18n.locale === i18nDefaultLocale) {
    return undefined
  }
  return i18n.locale
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: VueI18n.LocaleMessages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export function localizedDateString (date: string | Date, allowTodayTime = false, showYear = true) {
  const created = new Date(date)
  const now = new Date()

  if (isSameDay(created, now)) {
    if (allowTodayTime) {
      return toLocaleTimeString(created)
    } else {
      return i18n.t('date.today') as string
    }
  } else if (isSameDay(created, previousDay(created))) {
    return i18n.t('date.yesterday') as string
  } else {
    const format = showYear ? DateFormat.YEAR_MONTH_DATE : DateFormat.MONTH_DATE
    return toLocaleDateString(created, format)
  }
}

interface Datetime {
  year?: number
  month: number
  date?: number
}

export function localizedTimeString (time: Date, datetime: Datetime) {
  const format: DateFormat = datetime.year ? datetime.date ? DateFormat.YEAR_MONTH_DATE : DateFormat.YEAR_MONTH : DateFormat.MONTH_DATE
  return toLocaleDateString(time, format)
}

export default i18n
