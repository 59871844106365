
import Vue from 'vue'
import { MenuItem } from '../utils'

export default Vue.extend({
  props: {
    node: {
      type: Object,
      default: undefined,
    },
    onSelected: {
      type: Function,
      default: undefined,
    },
    level: {
      type: Number,
      default: 0,
    },
    maxLevel: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    children () {
      const node = this.node as MenuItem
      if (node) {
        return node.children.filter((n) => n.isEnabled)
      } else {
        return []
      }
    },
  },

  methods: {
    menuLabel (node: MenuItem): string {
      const suffix = this.level < this.maxLevel && node.hasChildren() ? '...' : ''
      return node.name + suffix
    },

    async dismiss (): Promise<void> {
      const popover = this.$el.closest('ion-popover')
      if (popover) {
        await popover.dismiss()
      }
    },

    async onClick (ev: CustomEvent, node: MenuItem): Promise<void> {
      if (this.level < this.maxLevel && node.hasChildren()) {
        const popover = await this.$ionic.popoverController.create({
          component: 'DbDropDownPopover',
          componentProps: {
            node,
            onSelected: this.onSubmenuSelected,
            level: this.level + 1,
            maxLevel: this.maxLevel,
          },
          event: ev,
          translucent: true,
        })
        await popover.present()
      } else {
        this.dismiss()
        if (this.onSelected) {
          this.onSelected(node)
        }
      }
    },

    onSubmenuSelected (node: MenuItem): void {
      this.dismiss()
      if (this.onSelected) {
        this.onSelected(node)
      }
    },
  },
})
