import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
import * as frag from '../fragments-gql'

export const createOneChatRoomWithParticipants = gql`
  mutation createOneChatRoomWithParticipants($data: ChatRoomCreateInput!) {
    createOneChatRoom(data: $data) {
      ...ChatRoomWithParticipants
    }
  }
  ${frag.ChatRoomWithParticipants}
`

export const createGroupChatRoom = gql`
  mutation createGroupChatRoom($data: GroupChatRoomCreateInput!) {
    createGroupChatRoom(data: $data) {
      ...ChatRoomWithParticipants
    }
  }
    ${frag.ChatRoomWithParticipants}
`

export const createCommentsChatRoom = gql`
  mutation createCommentsChatRoom($data: CommentsChatRoomCreateInput!) {
    createCommentsChatRoom(data: $data) {
      ...ChatRoomFields
    }
  }
  ${client.ChatRoomFields}
`

export const createHelpDeskChatRoom = gql`
  mutation createHelpDeskChatRoom($data: HelpDeskChatRoomCreateInput!) {
    createHelpDeskChatRoom(data: $data) {
      ...ChatRoomWithParticipants
    }
  }
  ${frag.ChatRoomWithParticipants}
`

export const getPermissionOfChatRoom = gql`
  mutation getPermissionOfChatRoom($chatRoomId: Int!, $profile_ChannelId: Int!, $code: String!) {
    getPermissionOfChatRoom(chatRoomId: $chatRoomId, profile_ChannelId: $profile_ChannelId, code: $code) {
      ...Profile_ChannelFields
    }
  }
  ${client.Profile_ChannelFields}
`

export const findUniqueChatRoomBrief = gql`
  query findUniqueChatRoomBrief($where: ChatRoomWhereUniqueInput!) {
    findUniqueChatRoom(where: $where) {
      ...ChatRoomFields
    }
  }
  ${client.ChatRoomFields}
`

export const findUniqueChatRoomDetail = gql`
  query findUniqueChatRoomDetail($where: ChatRoomWhereUniqueInput!) {
    findUniqueChatRoom(where: $where) {
      ...ChatRoomFields
      channel {
        ...ChannelFields
      }
    }
  }
  ${client.ChatRoomFields}
  ${client.ChannelFields}
`

export const findUniqueChatRoomWithChannel = gql`
  query findUniqueChatRoomWithChannel($where: ChatRoomWhereUniqueInput!) {
    findUniqueChatRoom(where: $where) {
      channel {
        ...ChannelFields
      }
    }
  }
  ${client.ChannelFields}
`

export const findManyChatRoomWithParticipants = gql`
  query findManyChatRoomWithParticipants($where: ChatRoomWhereInput) {
    findManyChatRoom(where: $where) {
      ...ChatRoomFields
      participants {
        ...Profile_ChatRoomFields
        profile {
          ...ProfileBase
        }
      }
    }
  }
  ${client.ChatRoomFields}
  ${client.Profile_ChatRoomFields}
  ${frag.ProfileBase}
`

export const findCommentsChatRoom = gql`
  query findCommentsChatRoom($where: ChatRoomWhereInput) {
    findManyChatRoom(where: $where) {
      ...ChatRoomFields
    }
  }
  ${client.ChatRoomFields}
`

export const onChatRoomUpdated = gql`
  subscription onChatRoomUpdated($chatRoomIds: [Int!]!) {
    onChatRoomUpdated(chatRoomIds: $chatRoomIds) {
      ...ChatRoomFields
    }
  }
  ${client.ChatRoomFields}
`

export const onGroupChatRoomUpdated = gql`
  subscription onGroupChatRoomUpdated($userId: Int!) {
    onGroupChatRoomUpdated(userId: $userId) {
      ...ChatRoomFields
      participants {
        ...Profile_ChatRoomFields
        profile {
          ...ProfileFields
        }
      }
    }
  }
  ${client.ChatRoomFields}
  ${client.Profile_ChatRoomFields}
  ${client.ProfileFields}
`

export const onChatRoomAdded = gql`
  subscription onChatRoomAdded($profileIds: [Int!]!, $userId: Int, $type: [Int!]) {
    onChatRoomAdded(profileIds: $profileIds, userId: $userId, type: $type) {
      ...ChatRoomWithParticipants
    }
  }
  ${frag.ChatRoomWithParticipants}
`
