"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNotiChatRoomEnabledByDefault = exports.getDefaultNotiTypesOfChatRoomType = exports.getDefaultNotification = exports.isNotificationAllowed = void 0;
const types = __importStar(require("../types"));
const allowNotification = [
    // NEW, KEYWORD, REPLY, MENTION
    [true, false, true, true],
    [true, false, false, false],
    [true, false, false, false],
    [true, false, false, false],
    [false, true, true, true],
    [true, false, true, true],
    [true, false, true, true],
    [],
    [true, false, true, true], // COMMENTS
];
const defaultNotification = [
    // NEW, KEYWORD, REPLY, MENTION
    [false, false, true, true],
    [true, false, true, true],
    [true, false, true, true],
    [true, false, true, true],
    [false, true, true, true],
    [true, false, true, true],
    [true, false, true, true],
    [],
    [false, false, false, false], // COMMENTS
];
function isNotificationAllowed(chatRoomType, notiType) {
    var _a, _b;
    return (_b = (_a = allowNotification[chatRoomType]) === null || _a === void 0 ? void 0 : _a[notiType]) !== null && _b !== void 0 ? _b : false;
}
exports.isNotificationAllowed = isNotificationAllowed;
function getDefaultNotification(chatRoomType, notiType) {
    var _a, _b;
    return (_b = (_a = defaultNotification[chatRoomType]) === null || _a === void 0 ? void 0 : _a[notiType]) !== null && _b !== void 0 ? _b : false;
}
exports.getDefaultNotification = getDefaultNotification;
function getDefaultNotiTypesOfChatRoomType(chatRoomType) {
    const def = defaultNotification[chatRoomType];
    const notiTypes = [];
    for (let i = 0; i < def.length; i++) {
        if (def[i]) {
            notiTypes.push(i);
        }
    }
    return notiTypes;
}
exports.getDefaultNotiTypesOfChatRoomType = getDefaultNotiTypesOfChatRoomType;
function isNotiChatRoomEnabledByDefault(chatRoomType) {
    if (defaultNotification.length <= chatRoomType) {
        return false;
    }
    const def = defaultNotification[chatRoomType];
    return def[types.MesgNotificationType.New] || def[types.MesgNotificationType.Keyword];
}
exports.isNotiChatRoomEnabledByDefault = isNotiChatRoomEnabledByDefault;
