import i18n from '@dinosband/bv-i18n'
import { types } from '../types'

const stageLabels: Record<number, string> = {
  0: '잠재 고객', // prospecting
  1: '관심 고객', // lead qulification
  2: '연락중', // demo/meeting
  3: '제안중', // proposal
  4: '협상중', // negotiation and commitment
  5: '수락', // opportunity won
  6: '거래중', // post-purchase
  [types.CRM_STAGE_REJECTED]: i18n.tc('views.my-pagesub37'), // rejected
}

export const CRM_STAGE_CONNECTED = 3
export const CRM_STAGE_WON = 5

export function getCrmStageLabel (props: types.ChannelProps, crm?: types.CustomerRelationship): string {
  const stage = crm?.rejected ? types.CRM_STAGE_REJECTED : crm?.stage ?? 0
  return props.crmStages?.[stage] ?? stageLabels[stage] ?? ''
}

export function getCrmStageOfLabel (props: types.ChannelProps, label: string): number {
  const labels = props.crmStages ?? stageLabels
  for (const [key, value] of Object.entries(labels)) {
    if (value === label) {
      return parseInt(key)
    }
  }
  return 0
}

export type CrmStageAndLabel = {
  id: number
  label: string
}

export function getCrmStages (props: types.ChannelProps): string[] {
  const labels = props.crmStages ?? stageLabels
  const keys = Object.keys(labels).map((k) => parseInt(k))
  keys.sort((a, b) => a - b)
  return keys.map((id) => labels[id])
}

export function getCrmStagesAndLabels (props: types.ChannelProps): CrmStageAndLabel[] {
  const labels = props.crmStages ?? stageLabels
  const keys = Object.keys(labels).map((k) => parseInt(k))
  keys.sort((a, b) => a - b)
  return keys.map((id) => ({
    id,
    label: labels[id] ?? '',
  }))
}
