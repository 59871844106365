import Vue from 'vue'
// import { debug } from '@dinosband/dbi-utils'

// type VueWithId = Vue & { _uid: number }
// const pageStack: VueWithId[] = []

// export function addPageToStack (_vue: Vue) {
//   const vue = _vue as VueWithId
//   // debug.log('addPageToStack: ', vue._uid)
//   pageStack.push(vue)
// }

// export function removePageFromStack (_vue: Vue) {
//   const vue = _vue as VueWithId
//   // debug.log('removePageFromStack: ', vue._uid)
//   const inx = pageStack.findIndex((page) => page._uid === vue._uid)
//   if (inx >= 0) {
//     pageStack.splice(inx, 1)
//   } else {
//     // debug.log('removePageFromStack not found: ', pageStack)
//   }
// }

export function getActivePage () {
  // return pageStack[pageStack.length - 1]
  const node = Vue.prototype.$dbiRouter.activeNode()
  return node.nativeComponent
}

export function goBack (vue: Vue, isOK = true): boolean {
  for (const child of vue.$children) {
    if (Object.prototype.hasOwnProperty.call(child, 'goBack')) {
      const page = child as unknown as { goBack: (isOK: boolean) => void }
      page.goBack(isOK)
      return true
    }
    if (goBack(child, isOK)) {
      return true
    }
  }
  return false
}

export function closePage (vue: Vue, isOK = true, params?: unknown): boolean {
  for (const child of vue.$children) {
    if (isOK) {
      if (Object.prototype.hasOwnProperty.call(child, 'onOK')) {
        const page = child as unknown as { onOK: (params: unknown) => void }

        page.onOK(params)
        return true
      }
    } else {
      if (Object.prototype.hasOwnProperty.call(child, 'onCancel')) {
        const page = child as unknown as { onCancel: () => void }
        page.onCancel()
        return true
      }
    }
    if (closePage(child, isOK, params)) {
      return true
    }
  }
  return false
}

export async function goBackOrClose (vue: Vue, isOK = true): Promise<void> {
  let isPushModal = false
  for (const child of vue.$children) {
    if (Object.prototype.hasOwnProperty.call(child, 'isPushModal')) {
      const page = child as unknown as { isPushModal: boolean }
      isPushModal = page.isPushModal
      break
    }
  }
  if (isPushModal) {
    vue.$dbIon.goBack(vue, isOK)
  } else {
    vue.$dbIon.closePage(vue, isOK)
  }
}

export function isPageModal (vue: Vue) {
  const modal = vue.$el.closest('ion-modal')
  if (modal) {
    return true
  } else {
    const parent = vue.$parent as unknown as { $$props: { isModal: boolean } }
    if (parent?.$$props?.isModal) {
      return true
    }
  }
  return false
}

export async function isPagePushModal (vue: Vue) {
  if (isPageModal(vue)) {
    return await vue.$dbiRouter.canGoBack(vue)
  }
  return false
}