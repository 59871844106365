import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { Components } from '@ionic/core'

// import { alertController } from '@ionic/core'

// let _alertController: typeof alertController

// export function setAlertController (controller: typeof alertController): void {
//   _alertController = controller
// }
let _alertController: Components.IonAlert | undefined = undefined

export async function alert (message: string, title = i18n.tc('common.alert'), buttonLabels = [i18n.tc('common.ok')], cssClass?: string): Promise<string> {
  let selectedLabel = ''
  const buttons = buttonLabels.map((btn) => ({
    text: btn,
    handler: () => {
      selectedLabel = btn
    },
  }))
  _alertController = await Vue.prototype.$ionic.alertController.create({
    header: title,
    message: message,
    buttons: buttons,
    cssClass,
  })
  await _alertController?.present()
  await _alertController?.onDidDismiss()
  _alertController = undefined
  return selectedLabel
}

export async function yesNoAlert (message: string, title: string, ok: string, cancel = i18n.tc('common.cancel')): Promise<boolean> {
  if (!title) {
    title = i18n.tc('common.confirm')
  }
  const rst = await alert(message, title, [cancel, ok])
  return rst === ok
}

export function isAlertOn (): boolean {
  const isOn = !!document.querySelector('ion-alert')
  if (!isOn && _alertController) {
    _alertController = undefined
  }
  return isOn
}

export async function dismissAlert () {
  if (_alertController) {
    await _alertController.dismiss()
  }
}
