import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { updateManyProfile_Channel } from '@dinosband/bv-main-schema/dist/client-gql'

export async function setAdmin (client: ClientType, profile_ChannelId: number, isAdmin: boolean): Promise<void> {
  try {
    await client.mutate({
      mutation: updateManyProfile_Channel,
      variables: {
        where: {
          id: { equals: profile_ChannelId },
        },
        data: {
          isAdmin: {
            set: isAdmin,
          },
        },
      } as types.MutationUpdateManyProfile_ChannelArgs,
    }) as MutationResult
  } catch (e) {
  }
}
