import { ClientType } from '../apollo'
import { types } from '../types'
import {
  deleteOneSysNotification,
  deleteManySysNotification,
  deleteOneMesgNotification,
  deleteManyMesgNotification,
} from '@dinosband/bv-main-schema/dist/client-gql'

export function option_deleteSysNotification (sysNotiId: number) {
  return {
    mutation: deleteOneSysNotification,
    variables: {
      where: {
        id: sysNotiId,
      },
    } as types.MutationDeleteOneSysNotificationArgs,
  }
}

export async function deleteSysNotification (client: ClientType, sysNotiId: number): Promise<types.SysNotification | undefined> {
  const opt = option_deleteSysNotification(sysNotiId)
  try {
    const result = await client.mutate(opt)
    return result.data.deleteOneSysNotification
  } catch (e) {
  }
}

export async function deleteAllSysNotifications (client: ClientType, sysNotiId: number): Promise<number | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteManySysNotification,
      variables: {
        where: {
          id: { lte: sysNotiId },
        },
      } as types.MutationDeleteManySysNotificationArgs,
    })
    return result.data.deleteManySysNotification
  } catch (e) {
  }
}

export async function deleteMesgNotification (client: ClientType, userId: number, messageId: number): Promise<types.MesgNotification | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteOneMesgNotification,
      variables: {
        where: {
          userId_messageId: { userId, messageId },
        },
      } as types.MutationDeleteOneMesgNotificationArgs,
    })
    return result.data.deleteOneMesgNotification
  } catch (e) {
  }
}

export async function deleteAllMesgNotifications (client: ClientType, userId: number, messageId: number): Promise<number | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteManyMesgNotification,
      variables: {
        where: {
          userId: { equals: userId },
          messageId: { lte: messageId },
        },
      } as types.MutationDeleteManyMesgNotificationArgs,
    })
    return result.data.deleteManyMesgNotification
  } catch (e) {
  }
}
