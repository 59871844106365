
import Vue from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: '',
    },
    footer: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      canGoBack: false,
    }
  },

  async created () {
    // dismiss loading controller if it is still running
    await this.$dbIon.dismissLoading()

    this.canGoBack = await this.$dbiRouter.canGoBack(this)
  },

  methods: {
    onBack () {
      this.$emit('back')
      this.$dbiRouter.pop(this, false)
    },

    async dismiss () {
      this.$emit('close')
      await this.$ionic.modalController.dismiss(undefined, 'cancel')
    },
  },
})
