import Vue from 'vue'
import { VueDelegate } from '../utils'
import { Components, isPlatform } from '@ionic/core'
import { iosTransitionAnimation } from '../animations/transition'

interface IonNavVue {
  key: string
  root: string
  onNavWillLoad (): void
  onNavWillChange (): void
  onNavDidChange (): void
}

export function createIonNavVue (delegate: VueDelegate): void {
  Vue.component('IonNavVue', {
    props: {
      // eslint-disable-next-line
      key: {
        type: String,
        default: '',
      },
      root: {
        type: String,
        default: '',
      },
      rootParams: {
        type: Object,
        default: undefined,
      },
    },

    methods: {
      onNavWillLoad () {
        const nav = this.$el as unknown as Components.IonNav
        nav.delegate = delegate
        if (isPlatform('ios')) {
          nav.animation = iosTransitionAnimation
        }
        if (this.root) {
          nav.setRoot(this.root, this.rootParams)
        }
        this.$emit('ionNavWillLoad', this)
      },
      onNavWillChange () {
        this.$emit('ionNavWillChange')
      },
      onNavDidChange () {
        this.$emit('ionNavDidChange')
      },
    },

    render (createElement) {
      // Vue types have a bug accessing member properties:
      // https://github.com/vuejs/vue/issues/8721
      /* eslint-disable @typescript-eslint/no-this-alias */
      const cmp = this as IonNavVue

      return createElement('ion-nav', {
        key: cmp.key || cmp.root,
        keepAlive: true,
        on: {
          ionNavWillLoad: cmp.onNavWillLoad,
          ionNavWillChange: cmp.onNavWillChange,
          ionNavDidChange: cmp.onNavDidChange,
        },
      })
    },
  })
}
