import Vue from 'vue'

function upperFirst (str: string): string {
  const first = str.slice(0, 1)
  return first.toUpperCase() + str.slice(1)
}

function camelCase (str: string): string {
  // remove dash and make the following characters uppercase
  const list = str.split('-')
  if (list.length > 1) {
    const back = list.slice(1).map((s)=> upperFirst(s))
    str = list[0].concat(...back)
  }

  // make following uppercase characters lowercase when uppercase characters continues
  let ret = ''
  let preUpper = true // initialize to true in order to make the first character lowercase
  for (let i = 0; i < str.length; i++) {
    let ch = str[i]
    if ('A' <= ch && ch <= 'Z') {
      if (preUpper) {
        ch = ch.toLowerCase()
      }
      preUpper = true
    } else {
      preUpper = false
    }
    ret += ch
  }
  return ret
}

export function registerComponents (requireComponent: __WebpackModuleApi.RequireContext): void {
  // register components globally
  requireComponent.keys().forEach((path: string) => {
    // Get PascalCase name of component
    const fileName = path.split('/').pop()
    if (fileName) {
      const componentName = upperFirst(
        camelCase(
        // Gets the file name regardless of folder depth
          fileName.replace(/\.\w+$/, ''),
        ),
      )

      // Register component globally
      Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        // componentConfig.default || componentConfig,
        // () => requireComponent(path),
        () => {
          const comp = requireComponent(path)
          return comp
        },
      )
    }
  })
}

export function registerComponentsSync (requireComponent: __WebpackModuleApi.RequireContext): void {
  // register components globally
  requireComponent.keys().forEach((path: string) => {
    // Get component config
    const componentConfig = requireComponent(path)

    // Get PascalCase name of component
    const fileName = path.split('/').pop()
    if (fileName) {
      const componentName = upperFirst(
        camelCase(
        // Gets the file name regardless of folder depth
          fileName.replace(/\.\w+$/, ''),
        ),
      )

      // Register component globally
      Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig,
      )
    }
  })
}
