import { ClientType } from '../apollo'
import { SmartSubscribe } from '../types'
import { debug } from '@dinosband/dbi-utils'
import { onSysNotificationUpdated, onMesgNotificationUpdated } from './notification-gql'
import { SysNotification, MesgNotification } from '@dinosband/bv-main-schema/dist/types'

export function subscribeOnSysNotificationUpdated (client: ClientType, profileIds: number[], callback: (noti: SysNotification) => void): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onSysNotificationUpdated,
    variables: {
      profileIds,
    },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      debug.log('=> onSysNotificationUpdated')
      const sysNotification = data.onSysNotificationUpdated as SysNotification
      callback(sysNotification)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}

export function subscribeOnMesgNotificationUpdated (client: ClientType, userId: number, callback: (noti: MesgNotification) => void): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onMesgNotificationUpdated,
    variables: { userId },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      debug.log('=> onMesgNotificationUpdated')
      const mesgNotification = data.onMesgNotificationUpdated as MesgNotification
      callback(mesgNotification)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
