import { Vue, Component } from 'vue-property-decorator'
// import { RouteConfig } from 'vue-router'
import { DbParams } from '../core/interface'
import { RouteNode } from '../core/route-node'
import { RouteTransitionDirection, DetailInfo } from '../core/router'
import { RouterEvent } from './router-event'
// import { store, Store } from './store'
import { debug } from '@dinosband/dbi-utils'
import DbiTabButton from './DbiTabButton.vue'

interface IonicTabs {
  select? (tabs: string): void
}

interface TabInfo {
  key: string
  node: RouteNode
  component: string
  params?: DbParams
  routeParams?: DbParams
  navLoaded: boolean
  showDetail: boolean
  detailInfo?: DetailInfo
  refMain: string
  refDetail: string
}

@Component({
  components: {
    DbiTabButton,
  },
})
export default class DbiTabsView extends Vue {
  // @Prop() params!: Record<string, unknown>

  tabInfos: TabInfo[] = []
  curTabInfo: TabInfo | undefined
  selectedTab = ''
  // routerStore: Store = store // make store reactive
  navLoaded = new Map<string, boolean>()
  isDarkMode = document.body.classList.contains('dark')
  dbiRouter = this.$dbiRouter
  // routerEvent handlers
  offUpdateRoute: (() => void) | undefined = undefined
  offSelectTab: (() => void) | undefined = undefined
  offDetailPane: (() => void) | undefined = undefined
  offDark: (() => void) | undefined = undefined

  // @Watch('$route', { immediate: true })
  // async routeUpdated (to: RouteConfig, from: RouteConfig): Promise<void> {
  //   await this.updateView(this.$dbiRouter.transitionDirection)
  // }

  get showTabBar () {
    return this.dbiRouter.isTabBarVisible
  }

  get useDetailPane () {
    // showTabBar가 true인 경우(메뉴가 없는 경우)에는 App 레벨의 detailPane을 사용하므로 여기에서는 사용하지 않는다.
    const use = this.dbiRouter.keepTabRoutes && this.dbiRouter.hasDetailPane && !this.showTabBar
    return use
  }

  get useTabNav () {
    return this.useDetailPane
  }

  created (): void {
    this.setupTabs()

    this.offUpdateRoute = RouterEvent.$on('update-route', async (direction: RouteTransitionDirection) => {
      await this.updateView()
    })

    this.offSelectTab = RouterEvent.$on('select-tab', async (node: RouteNode) => {
      debug.log('TabsView.on-select-tab: ', node.key)
      if (this.$dbiRouter.useIonicRouter) {
        this.selectTabNode(node)
      }
    })

    this.offDetailPane = RouterEvent.$on('detail-pane-with-key', (detail: DetailInfo) => {
      if (!this.useDetailPane) {
        return
      }

      const info = this.curTabInfo
      if (info) {
        info.detailInfo = detail
        // RouterEvent.$emit('detail-pane-with-key', {
        //   key: info.key,
        //   detailInfo: info.detailInfo,
        // })
        const showDetail = !!detail.node
        if (showDetail !== info.showDetail) {
          info.showDetail = showDetail
          this.showHideDetail(info, showDetail)
        }
      } else {
        console.error('TabsView.detail-pane: no curTabInfo')
      }
    })

    this.offDark = RouterEvent.$on('dark', (isDarkMode: boolean) => {
      this.isDarkMode = isDarkMode
    })

    // RouterEvent.$on('update-tab-bar', () => {
    //   const routeNode = this.$dbiRouter.getRouteNode(this)
    //   const child = routeNode?.currentChild
    //   if (child) {
    //     const node = child.getVisibleNode()
    //     this.showTabBar = node.showTabBar
    //   }
    // })
  }

  beforeDestroy () {
    if (this.offUpdateRoute) {
      this.offUpdateRoute()
    }
    if (this.offSelectTab) {
      this.offSelectTab()
    }
    if (this.offDetailPane) {
      this.offDetailPane()
    }
    if (this.offDark) {
      this.offDark()
    }
    this.$dbiRouter.event.$emit('params-changed')
  }

  setupTabs (): void {
    const routeNode = this.$dbiRouter.getRouteNode(this)

    if (routeNode) {
      // const nodes: RouteNode[] = []
      const infos: TabInfo[] = []

      for (const [key, node] of routeNode.children) {
        infos.push({
          key: key,
          node: node,
          component: node.component,
          params: node.componentParams,
          routeParams: node.routeParams,
          navLoaded: false,
          showDetail: false,
          refMain: `main-${key}`,
          refDetail: `detail-${key}`,
        })
      }

      this.tabInfos = infos
      this.selectTabNode(routeNode.currentChild)
    }
  }

  async updateView (): Promise<void> {
    // this.showTabBar = true
    const routeNode = this.$dbiRouter.getRouteNode(this)

    if (routeNode) {
      const currentChild = routeNode.currentChild
      if (currentChild) {
        this.selectTabNode(currentChild)
        // tab이 있는 경우에는 여기에서 updateView를 불러주고
        // tab이 없는 경우에는 RouterView에서 updateView를 불러준다.
        this.$dbiRouter.updateView(this, currentChild)
      }
    }
  }

  useIonNav (node: RouteNode): boolean {
    return true
    // return this.$dbiRouter.useIonicRouter && !node.isContainer()
    // return this.$dbiRouter.useIonicRouter
  }

  // getNavKey (node: RouteNode): string {
  //   return node.key
  // }

  getTabInfo (key: string) {
    return this.tabInfos.find((i) => i.key === key)
  }

  async onNavWillLoad (info: TabInfo) {
    if (!info.navLoaded) {
      info.navLoaded = true
    }
    // if (!this.navLoaded.get(node.key)) {
    //   this.navLoaded.set(node.key, true)
    // }
  }

  async onNavDidChange (info: TabInfo) {
    RouterEvent.$emit('nav-did-change', info.key)
  }

  tabSelectedFn (key: string) {
    return () => this.selectedTab === key
  }

  rootParams (info: TabInfo) {
    const rootParams = {
      params: info.params,
      routeNode: info.node,
      routeParams: info.routeParams,
      // since IonNavVue does not support reactive rootParams, use function for tabSelectedFn prop
      tabSelectedFn: this.tabSelectedFn(info.key),
    }
    return rootParams
  }

  onTabsWillChange (e: CustomEvent): void {
    if (e.detail.tab !== this.selectedTab) {
      const tabs = (this.$refs.tabs as unknown) as IonicTabs
      if (tabs && tabs.select) {
        tabs.select(this.selectedTab)
      }
    }
  }

  onTabClicked (node: RouteNode): void {
    this.$dbiRouter.switchView(this, node)
  }

  selectTabNode (node?: RouteNode): void {
    const nodeKey = node?.pathKey ?? ''
    if (this.selectedTab === nodeKey) {
      return
    }
    if (node) {
      debug.log('selectTabNode: ', nodeKey)
      const tabInfo = this.getTabInfo(nodeKey)
      if (tabInfo) {
        // this.params = node.componentParams
        // this.routeParams = node.routeParams
        tabInfo.node = node
        this.curTabInfo = tabInfo
      }
      this.selectedTab = nodeKey
      // this.childParams.set(node.key, node.componentParams)
      // this.childRouteParams.set(node.key, node.routeParams)
      const tabs = (this.$refs.tabs as unknown) as IonicTabs
      if (tabs && tabs.select) {
        tabs.select(this.selectedTab)
      }
      this.$dbiRouter.event.$emit('params-changed', node)
    }
  }

  async showHideDetail (info: TabInfo, show: boolean) {
    const main = (this.$refs[info.refMain] as HTMLElement[])?.[0]
    if (main) {
      main.style.transition = show ? 'width 0s .5s' : 'width 0s'
      main.classList.toggle('show-detail', show)
    }
    const refDetail = (this.$refs[info.refDetail] as Vue[])?.[0]
    const detail = refDetail?.$el as HTMLElement
    if (detail) {
      detail.classList.toggle('show-detail', show)
    }
  }
}