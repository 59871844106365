import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
import * as frag from '../fragments-gql'

export const findProfile = gql`
  query findProfile($where: ProfileWhereUniqueInput!) {
    findUniqueProfile(where: $where) {
      ...ProfileBase
    }
  }
  ${frag.ProfileBase}
`

export const findProfileBrief = gql`
  query findProfile($where: ProfileWhereUniqueInput!) {
    findUniqueProfile(where: $where) {
      id
      name
      thumbnailId
      userId
      deleted
      blockChatting
    }
  }
`

export const findProfileWithProfile_Channels = gql`
  query findProfileWithProfile_Channels($where: ProfileWhereUniqueInput!) {
    findUniqueProfile(where: $where) {
      ...ProfileBase
      channels {
        ...Profile_ChannelFields
        channel {
          ...ChannelFields
        }
        expertInfos {
          ...ExpertInfoFields
        }
      }
    }
  }
  ${frag.ProfileBase}
  ${client.Profile_ChannelFields}
  ${client.ChannelFields}
  ${client.ExpertInfoFields}
`

export const findProfileWithUser_Profiles = gql`
  query findProfileWithUser_Profiles($where: ProfileWhereUniqueInput!) {
    findUniqueProfile(where: $where) {
      ...ProfileBase
      users {
        id
        userId
      }
    }
  }
  ${frag.ProfileBase}
`

export const ProfileWithChannels = gql`
  fragment ProfileWithChannels on Profile {
    ...ProfileFields
    channel {
      ...Profile_ChannelFields
    }
  }
  ${client.ProfileFields}
  ${client.Profile_ChannelFields}
`

export const User_ProfileFieldsInput = gql`
  input User_ProfileFieldsInput {
    access: Int!
  }
`

export const m_createProfileOfUser = gql`
  mutation m_createProfileOfUser($userId: Int!, $profile: ProfileCreateInput!, $user_profile: User_ProfileFieldsInput!) {
    createProfileOfUser(userId: $userId, profile: $profile, user_profile: $user_profile) {
      ...User_ProfileGraph
    }
  }
  ${frag.User_ProfileGraph}
`

export const m_createProfileWithoutUser = gql`
  mutation m_createProfileWithoutUser($data: ProfileCreateInput!) {
    createOneProfile(data: $data) {
      ...ProfileBase
      channels {
        ...Profile_ChannelFields
      }
    }
  }
  ${frag.ProfileBase}
  ${client.Profile_ChannelFields}
`

export const m_createManyProfiles = gql`
  mutation m_createManyProfiles($input: [ProfileCreateInput!]!) {
    createManyProfiles(input: $input) {
      count
    }
  }
`

export const m_updateOneProfile = gql`
  mutation m_updateOneProfile($where: ProfileWhereUniqueInput!, $data: ProfileUpdateInput!) {
    updateOneProfile(where: $where, data: $data) {
      ...ProfileFields
      infos {
        ...ProfileInfoFields
      }
    }
  }
  ${client.ProfileFields}
  ${client.ProfileInfoFields}
`

export const onProfileUpdated = gql`
  subscription onProfileUpdated($profileIds: [Int]!) {
    onProfileUpdated(profileIds: $profileIds) {
      ...ProfileFields
      infos {
        ...ProfileInfoFields
      }
    }
  }
  ${client.ProfileFields}
  ${client.ProfileInfoFields}
`

export const onUser_ProfileAdded = gql`
  subscription onUser_ProfileAdded($userId: Int!) {
    onUser_ProfileAdded(userId: $userId) {
      ...User_ProfileGraph
    }
  }
  ${frag.User_ProfileGraph}
`
