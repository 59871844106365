import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { createOneUserPenalty } from '@dinosband/bv-main-schema/dist/client-gql'

async function createUserPenalty (client: ClientType, data: types.UserPenaltyCreateInput): Promise<types.UserPenalty | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneUserPenalty,
      variables: {
        data,
      } as types.MutationCreateOneUserPenaltyArgs,
    }) as MutationResult
    return result.data.createOneUserPenalty
  } catch (e) {
  }
}

export async function suspendUser (client: ClientType, userId: number, hours: number): Promise<types.UserPenalty | undefined> {
  return await createUserPenalty(client, {
    user: { connect: { id: userId } },
    type: 'suspend',
    hours,
  })
}

export async function expelUser (client: ClientType, userId: number): Promise<types.UserPenalty | undefined> {
  return await createUserPenalty(client, {
    user: { connect: { id: userId } },
    type: 'expel',
  })
}

export async function releaseUser (client: ClientType, userId: number): Promise<types.UserPenalty | undefined> {
  return await createUserPenalty(client, {
    user: { connect: { id: userId } },
    type: 'release',
  })
}

export async function warnUser (client: ClientType, userId: number, props: string): Promise<types.UserPenalty | undefined> {
  return await createUserPenalty(client, {
    user: { connect: { id: userId } },
    type: 'warning',
    props,
  })
}
