import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

export interface RootState {
  version: string
}

const options: StoreOptions<RootState> = {
  modules: {
  },
  // ,
  // plugins: [createPersistedState()]
}

export const store = new Vuex.Store(options)
