export class Profile {
  protected name
  protected _refCount = 0
  protected _count = 0
  protected _totalTick = 0
  protected _ticks: number[] = []
  protected beginTick = 0
  protected windowSize

  constructor (name: string, windowSize: number) {
    this.name = name
    this.windowSize = windowSize
  }

  get stat (): Record<string, number | string> {
    return {
      name: this.name,
      refCount: this._refCount,
      count: this._count,
      average: this.average,
      latestAverage: this.latestAverage,
    }
  }

  get refCount () {
    return this._refCount
  }

  incRef () {
    this._refCount++
  }

  decRef () {
    this._refCount--
  }

  begin () {
    this.beginTick = Date.now()
  }

  end () {
    const tick = Date.now() - this.beginTick
    this.addTick(tick)
  }

  addTick (tick: number) {
    this._ticks.push(tick)
    while (this._ticks.length > this.windowSize) {
      this._ticks.shift()
    }
    this._count++
    this._totalTick += tick
  }

  get count () {
    return this._count
  }

  get average () {
    if (this._count) {
      return Math.floor(this._totalTick / this._count)
    } else {
      return 0
    }
  }

  get latestAverage () {
    if (this._ticks.length) {
      const total = this._ticks.reduce((pre, cur) => pre + cur)
      return Math.floor(total / this._ticks.length)
    } else {
      return 0
    }
  }

  get latest () {
    if (this._ticks.length) {
      return this._ticks[this._ticks.length - 1]
    } else {
      return 0
    }
  }
}

export class Profiler {
  protected profiles = new Map<string, Profile>()

  of (name: string, windowSize = 30): Profile {
    let profile = this.profiles.get(name)
    if (!profile) {
      profile = new Profile(name, windowSize)
      this.profiles.set(name, profile)
    }
    return profile
  }

  forEach (cb: (profile: Profile, name: string) => void) {
    const names = Array.from(this.profiles.keys()).sort()
    names.forEach((name) => {
      const profile = this.profiles.get(name)
      if (profile) {
        cb(profile, name)
      }
    })
  }
}

export const profiler = new Profiler()
