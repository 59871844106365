
import Vue from 'vue'
import { Components } from '@ionic/core'
import { RouterEvent, DetailInfo } from '@dinosband/dbi-router'

export default Vue.extend({
  props: {
    paneKey: {
      type: String,
      required: true,
    },

    empty: {
      type: String,
      default: 'DbDetailEmpty',
    },
  },

  data () {
    return {
      loaded: false,
      isShowingEmpty: false,
      offRouterEvent: undefined as undefined | (() => void),
    }
  },

  created () {
    this.offRouterEvent = RouterEvent.$on('detail-pane-with-key', (detail: DetailInfo) => {
      if (detail.key === this.paneKey) {
        const ref = this.$refs.nav as Vue
        const nav = ref?.$el as unknown as Components.IonNav
        const node = detail.node
        const component = node?.component || this.empty
        // empty가 아닌 component는 파라미터가 바뀔 수 있으므로 검사하지 않고
        // empty가 중복되는 경우에만 isChanged 검사해서 empty가 반복적으로 setRoot가 불려지지 않도록 한다.
        const isChanged = !this.isShowingEmpty || component !== this.empty
        this.isShowingEmpty = component === this.empty
        if (nav && isChanged) {
          if (node) {
            nav.setRoot(component, {
              routeNode: node,
              routeParams: node.routeParams,
              params: node.componentParams,
            })
          } else {
            nav.setRoot(component)
          }
        }
      }
    })
  },

  beforeDestroy () {
    if (this.offRouterEvent) {
      this.offRouterEvent()
    }
  },

  methods: {
    onNavWillLoad () {
      if (!this.loaded) {
        this.loaded = true
        const ref = this.$refs.nav as Vue
        const nav = ref?.$el as unknown as Components.IonNav
        if (nav) {
          nav.setRoot(this.empty)
          this.isShowingEmpty = true
        } else {
          throw 'Failed to find nav'
        }
      }
    },

    async onNavDidChange () {
      // RouterEvent.$emit('detail-nav-did-change')
      // const ref = this.$refs.nav as Vue
      // const nav = ref?.$el as unknown as Components.IonNav
      // if (nav) {
      //   const active = await nav.getActive()
      // }
    },
  },
})
