
import { Module, Store } from 'vuex'
import { AttachmentState } from '../types'

let vueStore: Store<unknown> = undefined as unknown as Store<unknown>

export interface AttachmentModuleState {
  uploadings: string[] // fileURL[]
  finished: string[] // fileURL[]
  failed: Record<string, File> // { fileURL: File}
  progress: Record<string, number> // { fileURL: progress }
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type RootState = any

const _module: Module<AttachmentModuleState, RootState> = {
  namespaced: true,
  state: {
    uploadings: [],
    finished: [],
    failed: {},
    progress: {},
  },
  getters: {
    state: (state) => (fileURL: string) => {
      if (state.failed[fileURL]) {
        return AttachmentState.ERROR
      }
      if (state.uploadings.includes(fileURL)) {
        return AttachmentState.UPLOADING
      }
      return AttachmentState.UPLOADED
    },
    progress: (state) => (fileURL: string) => {
      const progress = state.progress[fileURL]
      return progress
    },
    failed: (state) => (fileURL: string) => state.failed[fileURL],
  },

  actions: {
    started (context, fileURL: string) {
      context.state.uploadings = [...context.state.uploadings, fileURL]
      delete context.state.failed[fileURL]
      context.state.failed = { ...context.state.failed }
    },
    finished (context, fileURL: string) {
      context.state.finished = [...context.state.finished, fileURL]
      context.state.uploadings = context.state.uploadings.filter((i) => i != fileURL)
    },
    failed (context, args: { fileURL: string, file: File}) {
      context.state.failed = { ...context.state.failed, [args.fileURL]: args.file }
      context.state.uploadings = context.state.uploadings.filter((i) => i != args.fileURL)
    },
    update (context, args: { fileURL: string, progress: number}) {
      context.state.progress = { ...context.state.progress, [args.fileURL]: args.progress }
    },
  },
}

export function initAttachmentStoreModule (store: Store<unknown>) {
  vueStore = store
  vueStore.registerModule('attachment', _module)
}

export function updateUploadingState (fileURL: string, state: AttachmentState, file?: File) {
  if (state === AttachmentState.ERROR) {
    vueStore.dispatch('attachment/failed', { fileURL, file })
  } else {
    const path = state === AttachmentState.UPLOADED ? 'finished' : 'started'
    vueStore.dispatch(`attachment/${path}`, fileURL)
  }
}

export function updateUploadingProgress (fileURL: string, progress: number) {
  if (progress < 100) {
    vueStore.dispatch('attachment/update', { fileURL, progress })
  } else {
    updateUploadingState(fileURL, AttachmentState.UPLOADED)    
  }
}

export function getFailedFile (fileURL?: string) {
  return vueStore.getters['attachment/failed'](fileURL)
}
