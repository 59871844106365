
import gql from 'graphql-tag'
import apolloClient from './'

export async function requestFileUploadURL (type: string, domain?: string, url?: string, isPublic = false) {
  const result = await (await apolloClient.s3.getClient()).mutate({
    mutation: gql`mutation requestUploadUrl($domain: String, $type: String!, $url: String, $public: Boolean) {
      requestUploadUrl(input: {domain: $domain, type: $type, url: $url, public: $public}) {
        signedURL
        url
      }
    }`,
    variables: {
      domain,
      type,
      url,
      public: isPublic,
    },
    fetchPolicy: 'no-cache',
  })
  return result.data.requestUploadUrl as { url: string, signedURL: string }
}

