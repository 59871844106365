import { generateHTML, generateJSON } from '@tiptap/html'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import { getSignedURL } from '@dinosband/bv-attachment'
// import { readPictureFromUrl } from '@dinosband/bv-attachment'

export const EXTENSIONS = [StarterKit, Underline, Image, Link]

export type JsonHtml = {
  json?: string
  html?: string
}

export async function convertToHtml (arg: JsonHtml) {
  // eslint-disable-next-line
  let doc: Record<string, any> | undefined = undefined
  if (arg.html) {
    doc = generateJSON(arg.html, EXTENSIONS)
  } else if (arg.json) {
    doc = JSON.parse(arg.json)
  }

  if (doc?.content) {
    for (const c of doc.content) {
      if (c.type === 'image') {
        const res = await getSignedURL(c.attrs.src as string)
        if (res?.signedURL) {
          c.attrs.src = res.signedURL
        }
      }
    }
    return generateHTML(doc, EXTENSIONS)
  }
  return ''
}
