import { isScalar } from '../utils/scalar'

export function makeUpdate (from: Record<string, unknown>, to: Record<string, unknown>) {
  const update: Record<string, unknown> = {}

  Object.keys(to).forEach((key) => {
    if (isScalar(to[key]) && from[key] != to[key]) {
      update[key] = {
        set: to[key],
      }
    }
  })
  Object.keys(from).forEach((key) => {
    if (isScalar(from[key]) && from[key] && !Object.prototype.hasOwnProperty.call(to, key)) {
      update[key] = {
        set: null,
      }
    }
  })
  return update
}
