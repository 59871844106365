
import Vue from 'vue'
import { store } from './store'
import { Route } from '../core'

export default Vue.extend({
  props: {
    nodeKey: {
      type: String,
      default: '',
    },
    route: {
      type: Object as () => Route,
      default: undefined,
    },
    selectedTab: {
      type: String,
      default: '',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tab (): string {
      return this.nodeKey
    },

    tabLayout (): string {
      if (store.showTabLabel) {
        return 'icon-top'
      } else {
        return 'label-hide'
      }
    },

    tabIcon (): string {
      let icon = store.getIcon(this.nodeKey)
      if (icon) {
        return icon
      }

      const route = this.route
      if (this.selectedTab === this.nodeKey && route?.iconSelected) {
        icon = route.iconSelected
      } else if (route?.icon) {
        icon = route.icon
      } else {
        return ''
      }
      if (icon) {
        if (this.darkMode) {
          icon = 'dark-' + icon
        }
      }
      return icon ?? ''
    },

    tabLabel (): string {
      const label = store.getLabel(this.nodeKey)
      if (label) {
        return label
      }

      const route = this.route
      if (route?.label) {
        return route.label
      } else {
        return ''
      }
    },

    tabBadge (): string | number | undefined {
      return store.getBadge(this.nodeKey)
    },
  },

  methods: {
    onClick () {
      this.$emit('click')
    },
  },
})
