
import Vue from 'vue'
export default Vue.extend({
  props: {
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isIonIcon (): boolean {
      if (this.name.startsWith('http')) {
        return false
      }
      if (this.name.startsWith('<svg')) {
        return false
      }
      return this.icon && !this.name.endsWith('.svg')
    },

    iconName (): string {
      return 'menu'
    },

    url (): string {
      if (this.name.startsWith('http')) {
        return this.name
      }
      if (this.name.startsWith('<svg')) {
        return 'data:image/svg+xml;utf8,' + this.name
      }
      const _url = this.$dbIon.assetManager.getUrl(this.name)
      if (_url) {
        return _url
      } else {
        return 'not-found'
      }
    },
  },
})
