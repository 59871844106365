import { ClientType } from '../apollo'
import { types } from '../types'

import { q_findManyAbusingReport, q_findUniqueAbusingReport } from './report-gql'

export async function readAbusingReports (client: ClientType, cursor?: number, take = 100): Promise<types.AbusingReport[] | undefined> {
  try {
    const result = await client.query({
      query: q_findManyAbusingReport,
      variables: {
        where: {
          closedAt: { equals: null },
          id: cursor ? { lt: cursor } : undefined,
        },
        orderBy: [
        // { lastActionAt: 'desc' },
          { createdAt: 'desc' },
        ],
        take,
      } as types.QueryFindManyAbusingReportArgs,
    })
    return result.data.findManyAbusingReport
  } catch (e) {
  }
}

export async function readAbusingReport (client: ClientType, id: number): Promise<types.AbusingReport | undefined> {
  try {
    const result = await client.query({
      query: q_findUniqueAbusingReport,
      variables: {
        where: { id },
      } as types.QueryFindUniqueAbusingReportArgs,
    })
    return result.data.findUniqueAbusingReport
  } catch (e) {
  }
}
