
import Vue from 'vue'
import { ResizeObserver } from '@juggle/resize-observer'
import { waitFor, _first, _last, _reversed } from '@dinosband/dbi-utils'

interface ScrollItem {
  id: number | string
  tid: number | string
  version: number | string
  data: unknown
  height: number
  top: number
  color?: string
  el?: HTMLElement
  ref: string
  visible: boolean
  visibleNewItem: boolean
}

const LOADING_TOP = 'load-more-top'
const LOADING_BOTTOM = 'load-more-bottom'

enum HV_STATE {
  UNDEFINED = 'UNDEFINED',
  EMPTY = 'EMPTY',
  BUSY = 'BUSY',
  READY = 'READY',
  CLEANING = 'CLEANING',
}

// enum DATA_MODE {
//   NONE = 'NONE',
//   MORE = 'BY_MORE',
//   BOTTOM
// }

function toInt (input: number | string): number {
  if (typeof input === 'string') {
    return parseInt(input)
  } else {
    return input
  }
}

type DebugItem = {
  id: number
  mesg: string
}

export default Vue.extend({
  props: {
    component: {
      type: String,
      default: '',
    },

    compProps: {
      type: Object,
      default: undefined,
    },

    idField: {
      type: String,
      default: 'id',
    },

    tidField: {
      type: String,
      default: 'tempId',
    },

    versionField: {
      type: String,
      default: 'version',
    },

    moreTop: {
      type: Boolean,
      default: false,
    },

    moreBottom: {
      type: Boolean,
      default: false,
    },

    threshold: {
      type: Number,
      default: 0,
    },

    buffer: {
      type: Number,
      default: 1,
    },

    data: {
      type: Array,
      default: () => [],
    },

    stickyMargin: {
      type: Number,
      default: 100,
    },

    isOrdered: {
      type: Boolean,
      default: true,
    },

    itemOrder: {
      /* 'ASC': latest at the bottom
       * 'DESC': latest at the top
       */
      type: String,
      default: 'ASC',
    },

    enableStickToLatest: {
      type: Boolean,
      default: true,
    },

    scrollOnNewData: {
      type: Boolean,
      default: true,
    },

    startAtTop: {
      type: Boolean,
      default: false,
    },

    debug: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      containerEl: null as HTMLElement | null,
      groundEl: null as HTMLElement | null,

      ascending: true,
      items: new Array<ScrollItem>(),
      renderItems: new Array<ScrollItem>(),
      // hiddenItems: new Array<ScrollItem>(),
      renderFrom: 0,
      renderTo: 0,

      resizeObserver: null as ResizeObserver | null,

      groundHeight: 0,
      viewportHeight: 0,
      viewportWidth: 0,

      loadingHeight: 45, // height of loading area

      // anchor
      anchorItem: undefined as ScrollItem | undefined,
      anchorOffset: 0,
      topItem: undefined as ScrollItem | undefined,
      topItemMargin: 50,
      isAutoScrolling: false,

      // handling new data
      hiddenViewState: HV_STATE.UNDEFINED,
      isDataChanged: false,
      isWholeNewData: true,

      // handling hasMore...
      hasMoreChanged: false,
      hasMoreTop: false, //true,
      hasMoreBottom: false, //true,
      isLoading: '', // loading more data
      lazyCheckLoadMore: false,
      hasMoreChangeTick: 0,
      lastScrollTick: 0,
      preScrollTop: undefined as number | undefined, // in order to check scroll direction

      // handling move to
      moveToLatest: false,
      moveToItem: undefined as number | string | undefined,

      // handling resize
      isViewportResized: false,
      isRestoringLayout: false,

      // isScrollAtLatest: true,
      stickToLatest: undefined as boolean | undefined,
      fixStickToLatest: false,

      // checkScroll
      // runCheckScrollBy: 0,
      delayForUpdateMore: 1000,
      delayForCheckStable: 100,

      // check no displyed element
      zeroHeightItems: new Array<ScrollItem>(),
      timer: undefined as number | string | undefined,

      // refresh items
      holdRefresh: false,
      refreshTimer: undefined as number | string | undefined,

      // debugging
      debugCount: 0,
      debugMesgs: [] as DebugItem[],
      scrollTop: 0,
      debugMesg: '',
    }
  },

  watch: {
    data: {
      immediate: true,
      handler (data: unknown[]) {
        // const items = data as { id: number }[]
        // const ids = items.map((item) => item.id)
        this.addDebug(`data changed: ${data.length}`)
        if (this.hiddenViewState == HV_STATE.EMPTY) {
          this.applyDataToHiddenView()
        } else {
          this.isDataChanged = true
        }
      },
    },

    moreTop: {
      immediate: true,
      handler (newValue: boolean) {
        if (this.hasMoreTop !== newValue) {
          this.addDebug(`moreTop changed: ${this.moreTop}`)
          this.hasMoreChangeTick = Date.now()
          // this.runCheckScrollLoop(this.delayForUpdateMore)
          // this.hasMoreChanged = true
          // if (this.isLoading == LOADING_TOP) {
          //   this.isLoading = ''
          //   this.update(true)
          // }
        }
      },
    },

    moreBottom: {
      immediate: true,
      handler (newValue: boolean) {
        if (this.hasMoreBottom !== newValue) {
          this.addDebug(`moreBottom changed: ${this.moreBottom}`)
          this.hasMoreChangeTick = Date.now()
          // this.runCheckScrollLoop(this.delayForUpdateMore)
          // this.hasMoreChanged = true
          // if (this.isLoading == LOADING_BOTTOM) {
          //   this.isLoading = ''
          //   this.update(true)
          // }
        }
      },
    },
  },

  mounted () {
    this.ascending = this.itemOrder !== 'DESC'
    this.detect('views', () => {
      this.containerEl = this.$refs.container as HTMLElement
      this.groundEl = this.$refs.ground as HTMLElement

      return Boolean(this.containerEl && this.groundEl)
    }, () => {

      this.resizeObserver = new ResizeObserver((entries, observer) => {
        this.$nextTick(() => { // NOTE: resize looping 오류를 방지하기 위해 nextTick에서 처리해준다.
          entries.forEach((entry, index) => {
            let { inlineSize: width, blockSize: height } = entry.contentBoxSize[0]
            width = Math.floor(width)
            height = Math.floor(height)
            this.onResizeContainer(width, height)
          })
        })
      })

      if (this.containerEl) {
        this.containerEl.addEventListener('scroll', this.onScroll, { passive: true })
        this.resizeObserver.observe(this.containerEl)
      }

      if (this.isDataChanged) {
        this.applyDataToHiddenView()
      }

      this.refreshTimer = window.setInterval(() => {
        if (!this.holdRefresh) {
          this.refreshItems()
        }
        this.holdRefresh = false
      }, 1_000)
    })
  },

  beforeDestroy () {
    if (this.timer) {
      window.clearInterval(this.timer)
    }

    if (this.refreshTimer) {
      window.clearInterval(this.refreshTimer)
    }

    if (this.containerEl) {
      this.containerEl.removeEventListener('scroll', this.onScroll)
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
    // this.stopCheckScrollLoop()

    this.items = []
    this.containerEl = null
    this.groundEl = null
    this.debugMesgs = []
    this.zeroHeightItems = []
  },

  methods: {
    onMenu (data: unknown, e: Event) {
      this.$emit('menu', data, e)
    },

    detect (name: string, detector: () => boolean, onDetected: () => void, count = 100) {
      this.$nextTick(() => {
        const detected = detector()
        if (detected) {
          onDetected()
        } else {
          count--
          if (count == 0) {
            alert(`Failed to detect ${name}`)
          } else {
            this.detect(name, detector, onDetected, count)
          }
        }
      })
    },

    setItemTop (item: ScrollItem, top: number) {
      item.top = top
      const el = this.itemEl(item)
      if (el) {
        const loadingHeight = this.hasMoreTop ? this.loadingHeight : 0
        const top = item.top + loadingHeight
        el.style.top = top + 'px'
      }
    },

    itemRef (item: ScrollItem) {
      return `item-${item.tid}`
    },

    itemEl (item: ScrollItem): HTMLElement | undefined {
      if (!item.el) {
        const ref = this.itemRef(item)
        const refs = this.$refs[ref]
        if (refs) {
          if (Array.isArray(refs)) {
            if (refs.length > 0) {
              item.el = (refs[0].$el || refs[0]) as HTMLElement
            }
          } else {
            item.el = refs.$el as HTMLElement
          }
        }
      }
      return item.el
    },

    refreshItems () {
      let changeCount = 0
      this.items.forEach((item) => {
        const el = this.itemEl(item)
        if (item.height && el?.offsetHeight) {
          const newHeight = el.offsetHeight
          const diff = newHeight - item.height
          if (diff < -2 || 2 < diff) {
          // if (newHeight !== item.height) {
            item.height = newHeight
            changeCount++
          }
        }
      })
      if (changeCount > 0) {
        this.updateItemPositions(this.items)
        this.resizeGround(this.items)
        this.updateScrollTop(true)
      }
      return changeCount
    },

    refreshItem (id: number, el: HTMLElement) {
      this.$nextTick(()=> {
        const item = this.items.find((item) => item.id === id)
        if (item) {
          const newHeight = el.offsetHeight
          const diff = newHeight - item.height
          if (diff < -2 || 2 < diff) {
          // if (newHeight !== item.height) {
            item.height = el.clientHeight
            this.updateItemPositions(this.items)
            this.resizeGround(this.items)
            this.updateScrollTop(true)
            this.updateTopItem()
          }
        }
      })
    },

    async applyDataToHiddenView (refresh? = false) {
      let sameItemCount = 0
      this.hiddenViewState = HV_STATE.BUSY
      const hiddenItems = []
      if (this.data.length == 0) {
        this.items = []
      } else {
        const data_ = this.ascending ? this.data : _reversed(this.data)
        const newItems = []
        let i = 0, j = 0
        while (i < data_.length) {
          const d = data_[i] as Record<string, number | string>
          const newItem: ScrollItem = {
            id: d[this.idField],
            tid: d[this.tidField],
            version: d[this.versionField],
            data: d,
            height: 0,
            top: -1000 - (i * 300),
            ref: '',
            visible: true,
            visibleNewItem: true,
          }
          newItem.ref = this.itemRef(newItem)
          if (this.isOrdered) {
            if (i > 0 && data_[i].id < data_[i - 1].id === this.asceding) {
              this.addDebug(`역전: ${data_[i - 1].id} < ${data_[i].id}`)
              newItem.color = 'red'
            } else if (i > 0 && data_[i].id > data_[i - 1].id === !this.asceding) {
              this.addDebug(`역전: ${data_[i - 1].id} > ${data_[i].id}`)
              newItem.color = 'red'
            }
          }

          if (this.items.length <= j) {
            // no more data left in this.items => add new item
            newItems.push(newItem)
            hiddenItems.push(newItem)
            i++
          } else {
            const item = this.items[j]
            if (newItem.tid == item.tid) {
              // same item
              if (newItem.id === item.id && newItem.version === item.version && !refresh) {
                sameItemCount++
                // same version => reuse previous item
                newItems.push(item)
              } else {
                newItem.top = item.top
                newItem.ref = item.ref
                newItems.push(newItem)
                hiddenItems.push(newItem)
              }
              /*
              if (newItem.version === item.version) {
                // same version => reuse previous item
                newItems.push(item)
              } else {
                // different version => add new item
                // make a copy of data so it can be detected by renderer
                newItem.data = Object.assign({}, d)
                newItems.push(newItem)
                hiddenItems.push(newItem)
              }
              */
              i++, j++
            } else if (newItem.id < item.id === this.ascending) {
              // new item is not in this.items => add new item
              newItems.push(newItem)
              hiddenItems.push(newItem)
              i++
            } else { // item.id < newItem.id
              // previous item is not in new item list => remove the previous item
              j++
            }
          }
        }
        this.items = newItems
      }
      this.isDataChanged = false
      if (sameItemCount == 0) {
        this.isWholeNewData = true
      }
      if (hiddenItems.length) {
        await this.waitForHiddenItemsRendered(hiddenItems)
      }
      this.hiddenViewState = HV_STATE.READY
      this.update(true)
    },

    async waitForHiddenItemsRendered (hiddenItems: ScrollItem[]) {
      // await delay(50) // wait for nextTick, so the components are updated
      await this.$nextTick()
      await waitFor(() => {
        for (const item of hiddenItems) {
          if (!item.height) {
            const el = this.itemEl(item)
            if (el) {
              if (el.offsetHeight) {
                item.height = el.offsetHeight
                item.visibleNewItem = false
                continue
              }
            }
            return false
          }
        }
        return true
      })
      const dirtyItems = hiddenItems.filter((i) => !i.height)
      if (dirtyItems.length > 0) {
        this.zeroHeightItems = [...this.zeroHeightItems, ...dirtyItems]
        if (this.zeroHeightItems.length > 0) {
          this.checkZeroHeightItems()
        }
      }
    },

    checkZeroHeightItems () {
      if (this.timer) {
        return
      }
      this.timer = setInterval(() => {
        if (this.zeroHeightItems.length > 0) {
          const zeroItemCount = this.zeroHeightItems.length
          for (const item of this.zeroHeightItems) {
            if (!item.height) {
              const el = this.itemEl(item)
              if (el) {
                if (el.offsetHeight) {
                  item.height = el.offsetHeight
                  item.visibleNewItem = false
                } else {
                  break
                }
              } else {
                break
              }
            }
          }
          const dirtyItems = this.zeroHeightItems.filter((i) => !i.height)
          if (zeroItemCount != dirtyItems.length) {
            this.updateItemPositions(this.items)
            this.resizeGround(this.items)
            this.updateScrollTop(true)
          }
          this.zeroHeightItems = dirtyItems
        }
        if (this.zeroHeightItems.length == 0) {
          clearInterval(this.timer)
          this.timer = undefined
        }
      }, 300)
    },

    onResizeContainer (w: number, h: number) {
      // if (this.isRestoringLayout) {
      //   if (this.savedViewportHeight === h) {
      //     this.isRestoringLayout = false
      //   } else {
      //     return
      //   }
      // }
      if (h > 0 && this.viewportHeight !== h) {
        this.viewportHeight = h
        this.isViewportResized = true
        // set initial groundHeight
        if (this.groundHeight == 0) {
          this.resizeGround(this.items)
        } else {
          this.update(true)
        }
      }

      if (w > 0 && this.viewportWidth != w) {
        if (this.viewportWidth) {
          this.applyDataToHiddenView(true)
        }
        this.viewportWidth = w
      }
    },

    // checkScrollLoop (step: number) {
    //   const interval = setInterval(() => {
    //     this.update(true)
    //     if (this.runCheckScrollBy == undefined) {
    //       clearInterval(interval)
    //     } else if (this.runCheckScrollBy < Date.now()) {
    //       const changeCount = 0 // this.refreshItems()
    //       if (!changeCount) {
    //         clearInterval(interval)
    //         this.runCheckScrollBy = 0
    //       }
    //     }
    //   }, step)
    //   // do {
    //   //   await this.delay(step)
    //   //   this.update(true)
    //   // } while (Date.now() < this.runCheckScrollBy)
    // },

    // async runCheckScrollLoop (duration: number) {
    //   const step = 100 // ms
    //   const margin = step
    //   const isStopped = !this.runCheckScrollBy
    //   const timeEnd = Date.now() + duration + margin
    //   if (this.runCheckScrollBy < timeEnd) {
    //     this.runCheckScrollBy = timeEnd
    //   }
    //   if (isStopped) {
    //     this.checkScrollLoop(step)
    //   }
    // },

    // stopCheckScrollLoop () {
    //   this.runCheckScrollBy = 0
    // },

    onScroll () {
      // const scrollTop = Math.round(this.containerEl?.scrollTop || 0)
      this.holdRefresh = true
      // this.runCheckScrollLoop(this.delayForCheckStable)
      const isAutoScrolling = this.isAutoScrolling
      this.isAutoScrolling = false
      this.update(isAutoScrolling)
    },

    update (isAutoScrolling: boolean) {
      if (!this.containerEl) {
        return
      }
      if (this.containerEl) {
        const height = this.containerEl.clientHeight
        if (height !== this.viewportHeight) {
          this.viewportHeight = height
          this.isViewportResized = true
        }
      }

      let scrollChanged = false
      const scrollTop = Math.round(this.containerEl?.scrollTop || 0)
      const loadingHeight = this.hasMoreTop ? this.loadingHeight : 0
      let adjustedScrollTop = scrollTop - loadingHeight
      let applySticky = true

      const wasAtLatest = this.stickToLatest && this.scrollOnNewData

      if (this.scrollTop !== scrollTop) {
        if (!this.isAutoScrolling) {
          this.$emit('scroll', scrollTop)
        } else {
        }
        if (!this.isLoading) {
          this.checkAndTriggerLoadMore(scrollTop)
          this.lazyCheckLoadMore = false
        } else {
          this.lazyCheckLoadMore = true
        }
        scrollChanged = true
        this.scrollTop = scrollTop
        this.lastScrollTick = Date.now()
      }
      // this.checkScrollAtLatest()

      const isBouncing = scrollTop < 0 || (0 < this.groundHeight && this.groundHeight < scrollTop)
      if (isBouncing) {
        // this.setStickToLatest(false)
        // do not update while scroll is bouncing
        return
      }

      let needUpdateScrollTop = false
      let needUpdateAnchor = !isAutoScrolling && scrollChanged
      let needUpdateRenderItems = scrollChanged
      // let needUpdateStickToLatest = scrollChanged

      if (this.isViewportResized) {
        // if (this.isViewportResized > 0) {
        //   const loadingBottom = this.hasMoreBottom ? this.loadingHeight : 0
        //   adjustedScrollTop -= loadingBottom
        // } else {
        //   adjustedScrollTop -= this.isViewportResized
        // }
        // this.checkScrollAtBottom()
        this.isViewportResized = false
        needUpdateScrollTop = true
        needUpdateRenderItems = true
      }

      const isStable = Date.now() - this.lastScrollTick > this.delayForCheckStable
      if (isStable) {

        // const newLoadingHeight = this.hasMoreTop ? this.loadingHeight : 0
        // const loadingHeightDiff = newLoadingHeight - loadingHeight
        // loadingHeight = newLoadingHeight
        // adjustedScrollTop = scrollTop - loadingHeight

        if (this.hasMoreChangeTick) {
          const diff = Date.now() - this.hasMoreChangeTick
          if (diff > this.delayForUpdateMore) {
            this.hasMoreTop = this.moreTop
            this.hasMoreBottom = this.moreBottom
            this.resizeGround(this.items)
            this.hasMoreChangeTick = 0
            if (this.isLoading === LOADING_TOP && !this.hasMoreTop) {
              this.isLoading = ''
            } else if (this.isLoading === LOADING_BOTTOM && !this.hasMoreBottom) {
              this.isLoading = ''
            }
            needUpdateScrollTop = true
            needUpdateRenderItems = true
          }
        }
        // if (this.hasMoreChanged) {
        //   this.resizeGround(this.items)
        //   // if (loadingHeightDiff != 0) {
        //   //   adjustedScrollTop += loadingHeightDiff
        //   // }
        //   this.hasMoreChanged = false
        //   needUpdateScrollTop = true
        // }

        if (this.hiddenViewState == HV_STATE.READY) {
          // apply moreTop and moreBottom immediately with new data
          if (this.hasMoreChangeTick) {
            this.hasMoreTop = this.moreTop
            this.hasMoreBottom = this.moreBottom
            this.hasMoreChangeTick = 0
          }

          this.updateItemPositions(this.items)
          this.resizeGround(this.items)
          // adjustedScrollTop = this.updateView(adjustedScrollTop)
          // adjustedScrollTop += loadingHeightDiff
          // if (this.isLoading) {
          //   // do not apply sticky when new data was given due to loadMore action
          //   applySticky = false
          // }
          // if (this.stickToLatest && this.items.length) {
          //   const lastItem = this.items[this.items.length - 1]
          //   if (this.anchorItem?.id !== lastItem.id) {
          //     this.anchorItem = lastItem
          //   }
          // }
          this.updateAnchorForNewData(wasAtLatest, this.isLoading)
          this.isLoading = ''
          this.fixStickToLatest = false // release stickToLatest after new data are procesed
          this.preScrollTop = undefined // prevent triggering load-more caused by adding data
          this.hiddenViewState = HV_STATE.EMPTY
          if (this.isWholeNewData) {
            this.isWholeNewData = false
            // this.setStickToLatest(true)
          }
          needUpdateScrollTop = true
          needUpdateRenderItems = true
          needUpdateAnchor = false

          this.$emit('updated')

          if (this.lazyCheckLoadMore) {
            const scrollTop = this.scrollTop <= 0 ? -1 : this.scrollTop + 1
            this.preScrollTop = this.scrollTop
            this.checkAndTriggerLoadMore(scrollTop)
            this.lazyCheckLoadMore = false
            this.preScrollTop = undefined
          }
        }

        if (this.moveToLatest) {
          // adjustedScrollTop = this.getBottomScrollTop()
          // if (this.items.length > 0) {
          //   this.anchorItem = this.items[this.items.length - 1]
          //   this.anchorOffset = 0
          // }
          if (this.items.length) {
            this.anchorItem = this.ascending ? _last(this.items) : _first(this.items)
            this.anchorOffset = 0
            // this.setStickToLatest(true)
            needUpdateScrollTop = true
            needUpdateRenderItems = true
            needUpdateAnchor = false
            // needUpdateStickToLatest = false
          }
          this.moveToLatest = false
        } else if (this.moveToItem !== undefined) {
          const item = this.items.find((item) => item.id === this.moveToItem)
          if (item) {
            // adjustedScrollTop = item.top
            this.anchorItem = item
            // set anchorOffset so the item be placed at center
            this.anchorOffset = (this.viewportHeight - item.height) / 2
            if (this.anchorOffset < 0) {
              this.anchorOffset = 0
            }
            applySticky = false
            needUpdateScrollTop = true
            needUpdateRenderItems = true
            needUpdateAnchor = false
            // needUpdateStickToLatest = true
          }
          this.moveToItem = undefined
          // this.checkScrollAtBottom()
        }
      }

      // this.setScrollTop(adjustedScrollTop + loadingHeight)
      if (needUpdateAnchor) {
        this.updateAnchor()
      }
      if (needUpdateScrollTop) {
        adjustedScrollTop = this.updateScrollTop(applySticky)
      }
      if (needUpdateRenderItems) {
        this.updateRenderItems(adjustedScrollTop)
      }
      // if (needUpdateStickToLatest) {
      //   this.updateStickToLatest()
      // }
      // this.setStickToLatest(this.isAtLatest)
      this.updateStickToLatest()
      this.updateTopItem()

      if (this.hiddenViewState == HV_STATE.EMPTY) {
        if (this.isDataChanged) {
          this.$nextTick(() => {
            this.applyDataToHiddenView()
          })
        }
      }
    },

    /*
      +---------------------+ => 0
      |      loading        |  } loadingHeight
      |---------------------|
      |                     |
      |                     |
      |                     |
      |                     |
      |                     |
      |                     |
      |=====================| => scrollTop
      |      viewport       |
      |                     |
      |                     |  } anchorOffset (if descending)
      |                     |
      |                     |
      |---------------------| => anchorItem.top
      |     anchorItem      |
      |                     |
      |---------------------| => end of anchorItem: anchorItem.top + anchorItem.height
      |                     |  } anchorOffset (if ascending)
      |=====================| => end of viewport: scrollTop + viewport height
      |                     |
      |                     |
      |                     |
      |---------------------|
      |      loading        |  } loadingHeight
      +---------------------+

      if ascending,
        anchorItem.top + anchorItem.height + anchorOffset = scrollTop + viewportHeight
        scrollTop = anchorItem.top + anchorItem.height + anchorOffset - viewportHeight
    */

    updateScrollTop (applySticky = true): number {
      const origin = this.hasMoreTop ? this.loadingHeight : 0

      /*
      // if items are empty
      if (this.items.length == 0) {
        this.anchorItem = undefined
        this.anchorOffset = 0
        this.setScrollTop(origin)
        return 0
      }

      // if total item height is less than viewport height
      const lastItem = this.items[this.items.length - 1]
      if (lastItem.top + lastItem.height <= this.viewportHeight) {
        this.anchorItem = lastItem
        this.anchorOffset = this.viewportHeight - (lastItem.top + lastItem.height)
        this.setScrollTop(origin)
        return 0
      }

      // stickToLatest
      if (applySticky && this.stickToLatest) {
        this.anchorItem = lastItem
        this.anchorOffset = 0
      }

      // update anchorItem to one found in the new items list
      if (this.anchorItem) {
        this.anchorItem = this.items.find((item) => item.id === this.anchorItem?.id)
      }

      // if anchorItem is undefined or anchorOffset is undefined
      if (!this.anchorItem) {
        this.updateAnchor()
      }
      */
      if (this.anchorItem) {
        let scrollTop = 0
        if (this.ascending) {
          scrollTop = this.anchorItem.top + this.anchorItem.height + this.anchorOffset - this.viewportHeight
        } else {
          scrollTop = this.anchorItem.top - this.anchorOffset
        }
        this.setScrollTop(origin + scrollTop)
        return scrollTop
      } else {
        this.setScrollTop(origin)
        return 0
      }
    },

    //--------------------------------------------------------
    // updateAnchorForNewData
    //--------------------------------------------------------
    updateAnchorForNewData (wasAtLatest: boolean, isLoading: string) {
      // if (!this.isWholeNewData && !this.scrollOnNewData) {
      //   return
      // }

      if (!this.items.length) {
        this.anchorItem = undefined
        this.anchorOffset = 0
        // this.setStickToLatest(false)
        return
      }
      const lastItem = this.ascending ? _last(this.items) : _first(this.items)

      // if (this.isWholeNewData) {
      //   this.anchorItem = lastItem
      //   this.anchorOffset = 0
      //   this.setStickToLatest(true)
      //   return
      // }

      if (this.startAtTop && this.isWholeNewData) {
        this.updateAnchor()
        // const firstItem = this.ascending ? _first(this.items) : _last(this.items)
        // this.anchorItem = firstItem
        // this.anchorOffset = this.viewportHeight - firstItem.height
      } else if (this.stickToLatest && wasAtLatest && !isLoading) {
      // if (this.stickToLatest && item !== lastItem && !isLoading) {
        this.anchorItem = lastItem
        this.anchorOffset = 0
        // this.setStickToLatest(true)
      } else if (this.anchorItem) {
        const item = this.items.find((item) => item.id === this.anchorItem.id)
        if (item) {
          // if stickToLatest && new last item && not from loading, then scroll up
          if (this.isAtLatest && !isLoading) {
          // if (this.stickToLatest && item !== lastItem && !isLoading) {
            this.anchorItem = lastItem
            this.anchorOffset = 0
            // this.setStickToLatest(true)
          } else {
            this.anchorItem = item
          }
        } else {
          this.updateAnchor()
        }
      } else {
        this.anchorItem = lastItem
        this.anchorOffset = 0
        // this.setStickToLatest(true)
        // this.updateAnchor()
      }
    },

    //--------------------------------------------------------
    // updateAnchor
    //--------------------------------------------------------
    updateAnchor () {
      if (!this.containerEl) {
        return
      }

      let scrollTop = this.containerEl.scrollTop

      if (this.hasMoreTop) {
        scrollTop -= this.loadingHeight
        // if anchoItem is undefined, prevent scrollTop to be minus
        if (!this.anchorItem && scrollTop < 0) {
          scrollTop = 0
        }
      }
      const viewBottom = scrollTop + this.viewportHeight

      // set anchor to the bottom of viewport
      this.anchorItem = undefined
      let i = 0
      for (; i < this.items.length; i++) {
        const item = this.items[i]
        if (item.top <= -1000) {
          continue
        }
        if (this.ascending) {
          const itemBottom = item.top + item.height
          if (itemBottom <= viewBottom + 1) {
            this.anchorItem = item
          } else {
            break
          }
        } else {
          if (item.top >= scrollTop) {
            this.anchorItem = item
            break
          }
        }
      }
      // this.addDebug(`updateAnchor: i = ${i}`)
      if (this.anchorItem) {
        const item = this.anchorItem
        if (this.ascending) {
          const itemBottom = item.top + item.height
          this.anchorOffset = viewBottom - itemBottom
        } else {
          this.anchorOffset = item.top - scrollTop
        }
      } else {
        this.anchorOffset = 0
      }
      // this.addDebug(`updateAnchor: anchorItem, offset: ${this.anchorItem?.id}, ${this.anchorOffset}`)
    },

    updateStickToLatest () {
      // // if (this.fixStickToLatest) {
      // //   return
      // // }

      if (!this.containerEl || !this.enableStickToLatest) {
        return
      }

      let scrollTop = this.containerEl.scrollTop

      if (this.hasMoreTop) {
        scrollTop -= this.loadingHeight
        // if anchoItem is undefined, prevent scrollTop to be minus
        // if (!this.anchorItem && scrollTop < 0) {
        //   scrollTop = 0
        // }
      }

      // if scroll is off from bottom more than stickyMargin
      let groundHeight = this.groundHeight
      if (this.hasMoreTop) {
        groundHeight -= this.loadingHeight
      }
      if (this.hasMoreBottom) {
        groundHeight -= this.loadingHeight
      }
      if (scrollTop + this.viewportHeight + 1 >= groundHeight - this.stickyMargin) {
        this.setStickToLatest(true)
      } else {
        this.setStickToLatest(false)
      }
    },

    updateTopItem () {
      const topItem = this.getTopItem()
      if (topItem?.id !== this.topItem?.id) {
        this.$emit('top-item-changed', { id: topItem?.id, autoScroll: this.isAutoScrolling })
      }
      // even if id of topItem is same, set this.topItem to new one
      this.topItem = topItem
    },

    getTopItem () {
      if (!this.items.length || !this.anchorItem || !this.containerEl) {
        return
      }
      let topItem: ScrollItem | undefined
      let scrollTop = this.containerEl.scrollTop - (this.hasMoreTop ? this.loadingHeight : 0)
      if (this.ascending) {
        // newer item is at the bottom and anchoItem is the lowest visible item.
        let anchorIndex = this.items.findIndex((item) => item.id === this.anchorItem.id)
        if (anchorIndex < 0) {
          anchorIndex = this.items.length - 1
        }

        const topPos = scrollTop + this.topItemMargin
        topItem = this.items
          .slice(0, anchorIndex + 1) // start from anchorItem
          .reverse() // reverse() mutates the caller but it is OK because slice() returns a copied array
          .find((i) => i.top <= topPos)
      } else {
        scrollTop = Math.max(0, scrollTop)
        topItem = undefined
        // BEEZ-1293, 화면 기준 위에 걸친 item을 찾을때, item.top이 같은 것들 중 가장 마지막 item으로 결정해야 한다.
        // 화면이 가려져 있는 경우 추가된 item은 아직 그려지지 않아, 같은 top에 여러개가 중복되어 있어 화면 기준 마지막 item이 맞다
        this.items.some((item) => {
          if (topItem) {
            if (topItem.top == item.top) {
              topItem = item
            } else {
              return true
            }
          } else if (scrollTop <= item.top) {
            topItem = item
          }
          return false
        })
        // if (topItem?.id !== this.anchorItem?.id) {
        //   console.error('[DbVirtualScroll] getTopItem: topItem is not anchorItem', topItem?.id, this.anchorItem?.id)
        // }
      }
      return topItem ?? this.items[0]
    },

    setScrollTop (scrollTop: number) {
      if (this.containerEl) {
        this.isAutoScrolling = true
        this.containerEl.scrollTop = scrollTop
        // if (this.containerEl.scrollTop !== scrollTop) {
        //   this.containerEl.scrollTop = scrollTop
        //   this.runCheckScrollLoop(100)
        // }
      }
      // this.checkScrollAtBottom()
    },

    getBottomScrollTop (): number {
      if (this.items.length == 0) {
        return 0
      }
      const lastItem = this.items[this.items.length - 1]
      let newScrollTop = lastItem.top + lastItem.height - this.viewportHeight
      if (newScrollTop < 0) {
        newScrollTop = 0
      }
      return newScrollTop
    },

    setStickToLatest (stick: boolean) {
      if (this.stickToLatest != stick) {
        this.stickToLatest = stick
        this.$emit('scroll-at-latest', stick)
      }
    },

    checkAndTriggerLoadMore (scrollTop: number) {
      const direction = this.preScrollTop === undefined ? 0 : scrollTop - this.preScrollTop
      this.preScrollTop = scrollTop

      const tp = this.loadingHeight + toInt(this.threshold) // trigger point

      let loading = ''
      if (this.moreTop && direction < 0 && scrollTop < tp) {
        loading = LOADING_TOP
      } else if (this.moreBottom && direction > 0 && scrollTop > (this.groundHeight - tp - this.viewportHeight)) {
        loading = LOADING_BOTTOM
      }
      if (loading) {
        this.addDebug(`load more: ${loading}`)
        this.isLoading = loading
        this.$emit(loading)
      }
    },

    updateItemPositions (items: ScrollItem[]) {
      let itemTop = 0
      items.forEach((item) => {
        this.setItemTop(item, itemTop)
        itemTop += item.height
      })
    },

    resizeGround (items: ScrollItem[]) {
      // calculate groundHeight
      let groundHeight
      if (items.length === 0) {
        groundHeight = this.viewportHeight
      } else {
        const lastItem = items[items.length - 1]
        groundHeight = lastItem.top + lastItem.height
        // if (groundHeight < this.viewportHeight) {
        //   groundHeight = this.viewportHeight
        // }
      }
      // add spinning area
      if (this.hasMoreTop) {
        groundHeight += this.loadingHeight
      }
      if (this.hasMoreBottom) {
        groundHeight += this.loadingHeight
      }

      this.groundHeight = groundHeight
      if (this.groundEl) {
        this.groundEl.style.height = `${this.groundHeight}px`
      }
    },

    updateRenderItems (scrollTop: number) {
      if (this.items.length) {
        // find renderItems
        const margin = this.viewportHeight * toInt(this.buffer) // amount of margin outside of viewport
        const renderFrom = margin ? scrollTop - margin : 0
        const renderTo = margin ? scrollTop + this.viewportHeight + margin : this.groundHeight

        // update item visibility
        this.items.forEach((item) => {
          const inCurView = renderFrom <= item.top && item.top <= renderTo
          item.visible = item.top < 0 || inCurView
        })
      }
    },

    updateRenderItems2 (scrollTop: number) {
      // if (this.items.length > 0) {
      //   this.renderItems = this.items
      //   return
      // }


      if (this.items.length == 0) {
        this.renderItems = []
        return
      }

      // find renderItems
      const margin = this.viewportHeight * toInt(this.buffer) // amount of margin outside of viewport
      const renderTop = margin ? scrollTop - margin : 0
      const renderBottom = margin ? scrollTop + this.viewportHeight + margin : this.groundHeight
      let from, to

      // find from
      for (from = 0; from < this.items.length; from++) {
        const item = this.items[from]
        if (renderTop < (item.top + item.height)) {
          break
        }
      }

      if (from === this.items.length) {
        // console.error('updateRenderItems: Faild to find from.')
        // this.renderItems = []
        // return
        from = this.items.length - 1
      }

      // find to
      for (to = from; to < this.items.length; to++) {
        if (renderBottom <= this.items[to].top) {
          break
        }
      }

      // this.renderItems = this.items.slice(from, to)
      /* use below in case above slice makes flickering */
      let i = from, j = 0
      while (i < to && j < this.renderItems.length) {
        const item = this.items[i]
        const rItem = this.renderItems[j]

        if (rItem.id < item.id) {
          // rItem is between two items => remove rItem
          this.renderItems.splice(j, 1)
        } else if (rItem.id == item.id) {
          // same item => replace
          this.renderItems[j] = item
          // this.renderItems.splice(j, 1, item)
          // this.$set(this.renderItems, j, item)
          // if (rItem.version != item.verson) {
          // }
          i++, j++
        } else {
          this.renderItems.splice(j, 0, item)
          i++, j++
        }
      }
      // remove remaining renderItems
      if (j < this.renderItems.length) {
        this.renderItems.splice(j)
      }
      // add remaining items
      for (; i < to; i++) {
        this.renderItems.push(this.items[i])
      }

      // testing
      const len = this.renderItems.length
      let error = false
      if (len != to - from) {
        console.error('len != to - from', len, from, to)
        error = true
      }
      if (len > 0) {
        if (this.renderItems[0].id != this.items[from].id) {
          console.error('0 != from', this.renderItems[0], this.items[from])
          error = true
        }
        if (this.renderItems[len - 1].id != this.items[to - 1].id) {
          console.error('len-1 != to-1', this.renderItems[len - 1], this.items[to - 1])
          error = true
        }
      }
      // for (let i = 1; i < len; i++) {
      //   if (this.renderItems[i].top != this.renderItems[i - 1].top + this.renderItems[i - 1].height) {
      //     console.error('position mismatch: ', this.renderItems[i - 1], this.renderItems[i])
      //     error = true
      //   }
      // }
      if (error) {
      }

    },

    scrollToLatest () {
      this.moveToLatest = true
      this.moveToItem = undefined
      this.update(true)
    },

    scrollToItem (id: number | string) {
      this.moveToItem = id
      this.moveToLatest = false
      // this.setStickToLatest(false)

      // const autoScrolling = this.isAutoScrolling
      // this.isAutoScrolling = false
      this.update(true)
      // this.isAutoScrolling = autoScrolling
      // this.updateStickToLatest()
    },

    saveLayout () {
      this.fixStickToLatest = true
    },

    restoreLayout () {
      this.isRestoringLayout = true
    },

    addDebug (mesg: string) {
      if (this.debug) {
        const item: DebugItem = {
          id: ++this.debugCount,
          mesg,
        }
        // const maxLength = 10
        // if (this.debugMesgs.length === maxLength) {
        //   this.debugMesgs.shift()
        // }
        this.debugMesgs.unshift(item)
        // this.debugMesg += `${++this.debugCount}: ${mesg}\n`
      }
    },
  },
})
