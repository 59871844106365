import { ChatRoomWhereInput, ChatRoomWhereUniqueInput } from '@dinosband/bv-main-schema/dist/types'
import { ClientType } from '../apollo'
import { types } from '../types'
import { findManyChatRoomWithParticipants, findUniqueChatRoomBrief } from './chatroom-gql'
import { PRIVATE_CHAT_ROOM_TYPES } from '@dinosband/bv-main-schema/dist/chat-room'

function option_readChatRooms (chatRoomIds: number[]) {
  const variables: types.QueryFindManyChatRoomArgs = {
    where: {
      id: { in: chatRoomIds },
    },
  }
  return {
    query: findManyChatRoomWithParticipants,
    variables,
  }
}

export async function readChatRooms (client: ClientType, chatRoomIds: number[]) {
  try {
    const result = await client.query<{findManyChatRoom: types.ChatRoom[]}>(option_readChatRooms(chatRoomIds))
    return result.data.findManyChatRoom
  } catch (e) {
  }

}

export async function readPublicChatRoomsOfChannel (client: ClientType, channelId: number) {
  try {
    const result = await client.query<{findManyChatRoom: types.ChatRoom[]}>({
      query: findManyChatRoomWithParticipants,
      variables: {
        where: {
          channelId: {
            equals: channelId,
          },
          type: {
            notIn: PRIVATE_CHAT_ROOM_TYPES,
          },
        },
      } as types.QueryFindManyChatRoomArgs,
    })
    return result.data.findManyChatRoom
  } catch (e) {
  }
}

export async function readPrivateChatRooms (client: ClientType, userId: number, channelId?: number) {
  try {
    const result = await client.query<{findManyChatRoom: types.ChatRoom[]}>({
      query: findManyChatRoomWithParticipants,
      variables: {
        where: {
          channelId: channelId ? { equals: channelId } : undefined,
          participants: {
            some: {
              userId: { equals: userId },
            // profileId: { in: profileIds },
            },
          },
          type: {
            in: PRIVATE_CHAT_ROOM_TYPES,
          },
        },
      } as types.QueryFindManyChatRoomArgs,
    })
    return result.data.findManyChatRoom
  } catch (e) {
  }
}

export async function readHelpDeskChatRooms (client: ClientType, userId: number, joinedChannels: number[], adminChannels: number[]) {
  const where: ChatRoomWhereInput = {
    // channelId: { in: joinedChannels }, // 재가입하는 경우를 위해 탈퇴한 채널의 것도 읽어두자
    type: { equals: types.ChatRoomType.HELPDESK },
  }
  where.OR = [
    { participants: { some: { userId: { equals: userId } } } },
    { channelId: { in: adminChannels } },
  ]
  try {
    const result = await client.query<{findManyChatRoom: types.ChatRoom[]}>({
      query: findManyChatRoomWithParticipants,
      variables: {
        where,
      },
    })
    return result.data.findManyChatRoom
  } catch (e) {
  }
}

export async function readGroupChatRoom (client: ClientType, rootMessageId: number, userId: number) {
  const where: ChatRoomWhereInput = {
    type: { equals: types.ChatRoomType.JOB_GROUP },
    rootMessageId: { equals: rootMessageId },
    participants: { some: { userId: { equals: userId } } },
  }
  try {
    const result = await client.query<{findManyChatRoom: types.ChatRoom[]}>({
      query: findManyChatRoomWithParticipants,
      variables: {
        where,
      },
    })
    return result.data.findManyChatRoom[0]
  } catch (e) {
  }
}

export async function readCommentsChatRoom (client: ClientType, chatRoomId: number | undefined, commentsRootMessageId: number | undefined) {
  if (!chatRoomId && !commentsRootMessageId) {
    return undefined
  }
  const where: ChatRoomWhereUniqueInput = {
    id: chatRoomId,
    commentsRootMessageId,
  }
  try {
    const result = await client.query<{findUniqueChatRoom?: types.ChatRoom}>({
      query: findUniqueChatRoomBrief,
      variables: {
        where,
      },
    })
    return result.data.findUniqueChatRoom
  } catch (e) {
  }
}