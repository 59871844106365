import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
// import * as frag from '../fragments-gql'

export const onSysNotificationUpdated = gql`
  subscription onSysNotificationUpdated($profileIds: [Int]!) {
    onSysNotificationUpdated(profileIds: $profileIds) {
      ...SysNotification
    }
  }
  ${client.SysNotification}
`

export const onMesgNotificationUpdated = gql`
  subscription onMesgNotificationUpdated($userId: Int!) {
    onMesgNotificationUpdated(userId: $userId) {
      # ...MesgNotificationFields
      chatRoomId
      messageId
      userId
    }
  }
`
