import { ClientType } from '../apollo'
import { types } from '../types'

import { deleteOneChannelChatRoomMenu } from './chatroom-menu-gql'

export async function deleteChatRoomMenu (client: ClientType, id: number): Promise<types.ChannelChatRoomMenu | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteOneChannelChatRoomMenu,
      variables: {
        where: { id },
      },
    })
    return result.data.deleteOneChannelChatRoomMenu
  } catch (e) {
  }
}
