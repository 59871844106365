import gql from 'graphql-tag'
import { waitFor } from '@dinosband/dbi-utils'
import { compatibleFilename } from './file'
import { apollo } from './apollo-client'

export async function getSignedURL (url: string, title?: string, publicLink?: boolean): Promise<{ signedURL: string, filename: string | undefined}> {
  const filename = title ? compatibleFilename(title) : undefined
  const result = await requestFileDownloadURL(url, filename, publicLink)
  if (result) {
    const signedURL = result.data.requestDownloadUrl.signedURL
    return { signedURL, filename }
  }
  return { signedURL: '', filename: '' }
}

async function requestFileDownloadURL (url: string, filename?: string, publicLink?: boolean) {
  await waitFor(() => !!apollo?.isLive, 1_000 * 600)
  if (!apollo?.isLive) {
    return undefined
  }
  return await (await apollo?.getClient())?.mutate({
    mutation: gql`mutation requestDownloadUrl($url: String!, $filename: String, $publicLink: Boolean) {
      requestDownloadUrl(input: {url: $url, filename: $filename, publicLink: $publicLink}) {
        signedURL
      }
    }`,
    variables: {
      url,
      filename,
      publicLink,
    },
    fetchPolicy: 'no-cache',
  })
}
