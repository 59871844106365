import { ClientType } from '../apollo'
import { types } from '../types'

import { q_findUniqueJobOfferResponse } from './job-offer-gql'

export async function readJobOfferResponse (client: ClientType, variables: types.QueryFindUniqueJobOfferResponseArgs) {
  try {
    const result = await client.query<{findUniqueJobOfferResponse: types.JobOfferResponse}>({
      query: q_findUniqueJobOfferResponse,
      variables,
    })
    return result.data.findUniqueJobOfferResponse
  } catch (e) {
  }
}
