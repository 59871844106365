import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
// import * as frag from '../fragments-gql'

export const upsertOneChannelDictionary = gql`
  mutation upsertOneChannelDictionary($where: ChannelDictionaryWhereUniqueInput!, $create: ChannelDictionaryCreateInput!, $update: ChannelDictionaryUpdateInput!) {
    upsertOneChannelDictionary(where: $where, create: $create, update: $update) {
      ...ChannelDictionaryFields
    }
  }
  ${client.ChannelDictionaryFields}
`