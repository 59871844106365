import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
import * as frag from '../fragments-gql'

export const ExpertInfoDetail = gql`
  fragment ExpertInfoDetail on ExpertInfo {
    ...ExpertInfoGraph
    attachments {
      ...AttachmentFields
    }
    profile_Channel {
      id
      profileId
      profile {
        ...ProfileFields
      }
    }
  }
  ${frag.ExpertInfoGraph}
  ${client.AttachmentFields}
  ${client.ProfileFields}
`

export const _findUniqueExpertInfo = gql`
  query _findUniqueExpertInfo($where: ExpertInfoWhereUniqueInput!) {
    findUniqueExpertInfo(where: $where) {
      ...ExpertInfoDetail
    }
  }
  ${ExpertInfoDetail}
`

export const _findManyExpertInfo = gql`
  query _findManyExpertInfo($where: ExpertInfoWhereInput!) {
    findManyExpertInfo(where: $where) {
      ...ExpertInfoFields
      profile_Channel {
        id
        expelledAt
        profile {
          ...ProfileFields
        }
      }
    }
  }
  ${client.ExpertInfoFields}
  ${client.ProfileFields}
`

export const _findManyChannelExpert = gql`
  query _findManyChannelExpert($where: ChannelExpertWhereInput!) {
    findManyChannelExpert(where: $where) {
      ...ChannelExpertFields
      expertInfos {
        ...ExpertInfoFields
        profile_Channel {
          id
          profile {
            ...ProfileFields
          }
        }
      }
    }
  }
  ${client.ChannelExpertFields}
  ${client.ExpertInfoFields}
  ${client.ProfileFields}
`

export const _findFirstExpertInfo = gql`
  query _findFirstExpertInfo($where: ExpertInfoWhereInput!) {
    findFirstExpertInfo(where: $where) {
      ...ExpertInfoFields
    }
  }
  ${client.ExpertInfoFields}
`

export const _createOneExpertInfo = gql`
  mutation _createOneExpertInfo($data: ExpertInfoCreateInput!) {
    createOneExpertInfo(data: $data) {
      ...ExpertInfoGraph
    }
  }
  ${frag.ExpertInfoGraph}
`

export const _updateOneExpertInfo = gql`
  mutation _updateOneExpertInfo($where: ExpertInfoWhereUniqueInput!, $data: ExpertInfoUpdateInput!) {
    updateOneExpertInfo(where: $where, data: $data) {
      ...ExpertInfoFields
    }
  }
  ${client.ExpertInfoFields}
`

export const onExpertInfoUpdated = gql`
  subscription onExpertInfoUpdated($userId: Int!, $profile_ChannelIds: [Int!]!) {
    onExpertInfoUpdated(userId: $userId, profile_ChannelIds: $profile_ChannelIds) {
      ...ExpertInfoFields
    }
  }
  ${client.ExpertInfoFields}
`
