import { Capacitor, registerPlugin } from '@capacitor/core'

interface FacebookPlugin {
  logEvent(options: { event: string, params?: { [key: string]: unknown } }): Promise<void>
}

const facebookPlugin = registerPlugin<FacebookPlugin>('FacebookPlugin')

export class FacebookLogger {
  isEnabled = true

  async logEvent (name: string, params: Record<string, unknown>) {
    if (!Capacitor.isNativePlatform()) {
      return
    }
    if (this.isEnabled) {
      facebookPlugin.logEvent({ event: name, params })
    }
  }

  async enable () {
    this.isEnabled = true
  }

  async disable () {
    this.isEnabled = false
  }
}

