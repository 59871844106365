import { ClientType } from '../apollo'
import { types } from '../types'

import { q_findManyUserBlocking } from './blocking-gql'

export async function readUserBlockings (client: ClientType, userId: number): Promise<types.UserBlocking[] | undefined> {
  const opt = {
    query: q_findManyUserBlocking,
    variables: {
      where: {
        OR: [
          {
            blockerId: { equals: userId },
          },
          {
            blockeeId: { equals: userId },
          },
        ],
      },
      orderBy: [
        { id: 'desc' },
      ],
    } as types.QueryFindManyUserBlockingArgs,
  }
  try {
    const result = await client.query(opt)
    return result.data.findManyUserBlocking
  } catch (e) {
  }

}