import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyFaQ } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readFaQ (client: ClientType): Promise<types.FaQ[] | undefined> {
  try {
    const result = await client.query({
      query: findManyFaQ,
    })
    return result.data.findManyFaQ
  } catch (e) {
  }
}
