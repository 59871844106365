
export function _first<T> (array: ArrayLike<T> | null | undefined): T | undefined {
  return array ? array[0] : undefined
}

export function _last<T> (array: ArrayLike<T> | null | undefined): T | undefined {
  return array ? array[array.length - 1] : undefined
}

export function _min<T> (array: ArrayLike<T> | null | undefined): T | undefined {
  if (!array?.length) {
    return undefined
  }
  if (typeof array[0] === 'number') {
    const array_ = array as unknown as number[]
    return Math.min(...array_) as unknown as T
  }
  // eslint-disable-next-line no-prototype-builtins
  if (array.hasOwnProperty('reduce')) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (array as any).reduce((result: T | undefined, value: T) => result == undefined ? value : value < result ? value : result, undefined)
  }
  let min_ = array[0]
  for (let i = 1; i < array.length; i++) {
    const value = array[i]
    if (value < min_) {
      min_ = value
    }
  }
  return min_
}

export function _max<T> (array: ArrayLike<T> | null | undefined): T | undefined {
  if (!array?.length) {
    return undefined
  }
  if (typeof array[0] === 'number') {
    const array_ = array as unknown as number[]
    return Math.max(...array_) as unknown as T
  }
  // eslint-disable-next-line no-prototype-builtins
  if (array.hasOwnProperty('reduce')) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (array as any).reduce((result: T | undefined, value: T) => result == undefined ? value : value > result ? value : result, undefined)
  }
  let max_ = array[0]
  for (let i = 1; i < array.length; i++) {
    const value = array[i]
    if (max_ < value) {
      max_ = value
    }
  }
  return max_
}

export function _maxBy<T> (array: ArrayLike<T> | null | undefined, fn: (obj: T) => number): T | undefined {
  if (!array?.length) {
    return undefined
  }
  let selected_ = 0
  let max_ = fn(array[selected_])
  for (let i = 1; i < array.length; i++) {
    const value = fn(array[i])
    if (max_ < value) {
      selected_ = i
      max_ = value
    }
  }
  return array[selected_]
}

export function _findLast<T> (array: ArrayLike<T> | null | undefined, predicate: (value: T, index: number) => boolean): T | undefined {
  if (!array) {
    return undefined
  }
  let index = array.length - 1
  let value = array[index]
  while (index >= 0) {
    if (predicate(value, index)) {
      return value
    }
    index--
    value = array[index]
  }
  return undefined
}

export function _sortBy<T> (array: T[], fn: (obj: T) => number): T[] {
  const result = [...array]
  result.sort((a, b) => fn(a) - fn(b))
  return result
}

export function _sort<T> (array: T[], fn: (a: T, b: T) => number): T[] {
  const result = [...array]
  result.sort(fn)
  return result
}


export function _uniq<T> (array: T[]): T[] {
  return [... new Set(array)]
}

export function _removeAt<T> (array: T[], idx: number): T[] {
  const result = [...array]
  result.splice(idx, 1)
  return result
}

export function _isIntersected<T> (a?: T[], b?: T[]): boolean {
  if (!a || !b) {
    return false
  }
  return a.some((i) => b.includes(i))
}

export function _difference<T> (a: T[], b?: T[]): T[] {
  if (!b?.length) {
    return a
  }
  return a.filter((i) => !b.includes(i))
}

export function _partition<T> (array: T[], filter: (obj: T) => boolean) {
  const result = [[] as T[], [] as T[]]
  for (const item of array) {
    if (filter(item)) {
      result[0].push(item)
    } else {
      result[1].push(item)
    }
  }
  return result
}

export function _chunk<T> (array: T[], size: number): T[][] {
  const count = Math.ceil(array.length / size)
  return [...Array(count)].map((_, i) => array.slice(i * size, (i + 1) * size))
}

export function _range (min: number, max: number): number[] {
  if (min >= max) {
    return []
  }
  return Array(max - min).fill(0).map((_, i)=>i + min)
}

type _ComparableItemType = string | number | boolean | null | undefined | _ComparableItemType[]
export function _isEqualArray<T = _ComparableItemType> (a: T[], b: T[]): boolean {
  const length = a.length
  if (length !== b.length) {
    return false
  }
  if (length === 0 || length == undefined) {
    return true
  }
  // eslint-disable-next-line no-prototype-builtins
  if (a.hasOwnProperty('every')) {
    return a.every((i, index) => __is_equal(i, b[index]))
  } else {
    for (let i = 0; i < length; i++) {
      if (!__is_equal(a[i], b[i])) {
        return false
      }
    }
    return true
  }
}

export function _reversed<T> (array: T[] | null | undefined): T[] | undefined {
  return array?.slice().reverse()
}

function __is_equal (a: unknown, b: unknown): boolean {
  if (__is_array_like(a)) {
    return _isEqualArray(a as _ComparableItemType[], b as _ComparableItemType[])
  } else {
    return a == b
  }
}

function __is_object_like (value: unknown): boolean {
  return typeof value === 'object' && value !== null
}

function __is_array_like (value: unknown): boolean {
  return __is_object_like(value) && typeof (value as ArrayLike<unknown>).length === 'number'
}