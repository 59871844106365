import axios from 'axios'
import { debug } from '@dinosband/dbi-utils'
// import { DataCacheIndexedDB } from '@dinosband/bv-cache'
// import { DataCacheIndexedDB } from '@dinosband/bv-cache/types'
import { getSignedURL } from './get-signed-url'

type DataCacheIndexedDB<K, T> = {
  initialize: () => Promise<void>
  reset: () => Promise<void>
  get: (key: K) => Promise<T>
  set: (key: K, data: T) => Promise<void>
}

let pictureCache: undefined | DataCacheIndexedDB<string, string> = undefined

export async function initPictureCache () {
  debug.log('<< initPictureCache >>')
  const bvCache = await import('@dinosband/bv-cache')
  if (bvCache) {
    pictureCache = new bvCache.DataCacheIndexedDB<string, string>('bv-picture-cache', 300, 100) as unknown as DataCacheIndexedDB<string, string>
    await pictureCache.initialize()
  }
}

export async function resetPictureCache () {
  await pictureCache?.reset()
}

export async function readPictureFromUrl (url: string): Promise<string> {
  try {
    let data = await pictureCache?.get(url)
    if (data) {
      return data
    } else {
      const result = await getSignedURL(url, undefined, true)
      if (result?.signedURL) {
        const blob = await readBlobFromURL(result.signedURL)
        if (blob) {
          data = await blobToDataURL(blob)
          await pictureCache?.set(url, data)
          return data
        }
      }
    }
  } catch (e) {
    debug.error(e)
  }
  return ''
}

async function readBlobFromURL (url: string): Promise<Blob | null> {
  const res = await axios.get(url, { responseType: 'blob' })
  if (res?.data) {
    return new Blob([res.data], { type: res.headers['content-type'] })
  }
  return null
}

function blobToDataURL (blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (_e) => resolve(reader.result as string)
    reader.onerror = (_e) => reject(reader.error)
    reader.onabort = (_e) => reject(new Error('Read aborted'))
    reader.readAsDataURL(blob)
  })
}
