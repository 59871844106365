export type UpdateCallback = () => void | Promise<void>

export class DeferredUpdater {
  protected delay: number
  protected cb: UpdateCallback
  protected timeout = 0
  protected isHeld = false

  constructor (cb: UpdateCallback, delay = 10 * 1000, readonly autoHold = false) {
    this.cb = cb
    this.delay = delay
  }

  touch () {
    const isIdle = this.timeout === 0
    this.timeout = Date.now() + this.delay
    if (isIdle) {
      this.deferr(this.delay)
    }
  }

  hold () {
    this.isHeld = true
  }

  release (doTouch = true) {
    this.isHeld = false
    if (doTouch) {
      this.touch()
    }
  }

  protected deferr (delay: number) {
    const timer = setTimeout(async () => {
      clearTimeout(timer)
      const margin = 100
      const now = Date.now()
      if (this.timeout <= now + margin) {
        this.timeout = 0
        if (!this.isHeld) {
          if (this.autoHold) {
            this.hold()
          }
          await this.cb()
          if (this.autoHold) {
            this.release(false)
          }
        }
      } else {
        this.deferr(this.timeout - now)
      }
    }, delay)
  }
}
