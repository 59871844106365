function isObject (arg: unknown): boolean {
  return typeof arg === 'object' && arg !== null
}

function stringify (arg: unknown): string {
  if (isObject(arg)) {
    return JSON.stringify(arg)
  } else {
    return `${arg}`
  }
}

class MemConsole {
  protected _logs: string[] = []

  get logs (): string[] {
    return this._logs
  }

  clear () {
    this._logs = []
  }

  /* eslint-disable-next-line */
  log (...args: any[]): void {
    // const argStrs = args.map((arg) => stringify(arg).replace(/ /g, '&nbsp;'))
    const argStrs = args.map((arg) => stringify(arg))
    this._logs.push(argStrs.join(' '))
  }
}

export const memConsole = new MemConsole()