import { AttachmentState } from '@dinosband/bv-messenger-schema'

export interface AttachmentInput {
  title?: string
  type?: string
  size?: number
  info?: string
  thumbnail?: string | ArrayBuffer
  embed?: string
  url?: string
  width?: number
  height?: number
  // for application
  file?: File
}

export interface AttachmentBroadcast {
  url: string
  state: AttachmentState
}

export enum AttachmentType {
  PROFILE_IMAGE = 0,
  CHANNEL_IMAGE = 1,
  GENERAL_IMAGE = 2,
  GENERAL_FILE = 3,
  BANNER_IMAGE = 4,
}

export { AttachmentState } from '@dinosband/bv-messenger-schema'
export type { Attachment } from '@dinosband/bv-messenger-schema'
