import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyExpertInfo } from '@dinosband/bv-main-schema/dist/client-gql'
import { _findUniqueExpertInfo, _findManyExpertInfo, _findFirstExpertInfo } from './expert-gql'

export async function readExpertInfo (client: ClientType, id: number): Promise<types.ExpertInfo | undefined> {
  try {
    const result = await client.query({
      query: _findUniqueExpertInfo,
      variables: {
        where: { id },
      },
    })
    return result.data.findUniqueExpertInfo
  } catch (e) {
  }
}

export async function readExpertInfosOfChannelExpert (client: ClientType, channelExpertId: number): Promise<types.ExpertInfo[] | undefined> {
  try {
    const result = await client.query({
      query: _findManyExpertInfo,
      variables: {
        where: { channelExpertId },
      },
    })
    return result.data.findManyExpertInfo
  } catch (e) {
  }
}

export async function readExpertInfosOfChannel (client: ClientType, channelId: number): Promise<types.ExpertInfo[] | undefined> {
  try {
    const result = await client.query({
      query: _findManyExpertInfo,
      variables: {
        where: {
          channelExpert: {
            channelId: {
              equals: channelId,
            },
          },
          profile_Channel: {
            expelledAt: {
              equals: null,
            },
          },
        },
      } as types.QueryFindManyExpertInfoArgs,
    })
    return result.data.findManyExpertInfo
  } catch (e) {
  }
}

export async function readUserExpertInfoOfChannel (client: ClientType, channelId: number, userId: number): Promise<types.ExpertInfo | undefined> {
  try {
    const result = await client.query({
      query: _findFirstExpertInfo,
      variables: {
        where: {
          channelId: { equals: channelId },
          userId: { equals: userId },
        },
      } as types.QueryFindFirstExpertInfoArgs,
    })
    return result.data.findFirstExpertInfo
  } catch (e) {
  }
}

export async function searchExpertInfos (client: ClientType, channelId: number, channelExpertId: number, userIds: number[]): Promise<types.ExpertInfo[] | undefined> {
  try {
    const result = await client.query({
      query: findManyExpertInfo,
      variables: {
        where: {
          channelId: { equals: channelId },
          channelExpertId: { equals: channelExpertId },
          userId: { in: userIds },
        },
      } as types.QueryFindFirstExpertInfoArgs,
    })
    return result.data.findManyExpertInfo
  } catch (e) {
  }
}

