import { ClientType } from '../apollo'
import { types } from '../types'

import { createOneUserLog } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createUserLog (client: ClientType, data: types.UserLogCreateInput): Promise<types.UserLog | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneUserLog,
      variables: {
        data,
      } as types.MutationCreateOneUserLogArgs,
    })
    return result.data.createOneUserLog as types.UserLog
  } catch (e) {
  }
}
