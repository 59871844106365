
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { types, apollo } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'

export default Vue.extend({
  data () {
    return {
      list: [] as types.Advertisement[],
    }
  },

  async mounted () {
    this.list = await apollo.readAllAdvertisementsForAllApps(await apc.getClient()) ?? []
  },

  methods: {
    getLabel (ad: types.Advertisement) {
      let label = ad.title
      if (ad.isAppChannel) {
        label += ` -- App[${ad.channelId}]`
      }
      return label
    },

    async onCreate () {
      await this.$dbIon.doModal(this, 'BvaAdDetail')
    },

    async onShowDetail (ad: types.Advertisement) {
      const updated = await this.$dbIon.doModal(this, 'BvaAdDetail', { ad })
      if (updated) {
        Object.assign(ad, updated)
      }
    },

    async onDelete (ad: types.Advertisement) {
      if (await this.$dbIon.yesNoAlert(`'${ad.title}'를 삭제할까요?`, i18n.tc('messenger.delete'), i18n.tc('components.notification-tabNotificationsHeader05'))) {
        await apollo.deleteAdvertisement(await apc.getClient(), ad.id)
        this.list = this.list.filter((i) => i.id !== ad.id)
      }
    },
  },
})
