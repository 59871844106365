import { Animation, createAnimation } from '@ionic/core'

const MIN_PRESENTING_SCALE = 0.93 // from SwipeToCloseDefaults

/* eslint-disable @typescript-eslint/no-non-null-assertion */

const getScale = (aniStep: number) => {
  const delta = 1.0 - MIN_PRESENTING_SCALE
  const deltaStep = delta * aniStep
  return 1.0 - deltaStep
}

/**
 * iOS Modal Enter Animation for the Card presentation style
 */
export const iosBottomSheetEnterAnimation = (
  baseEl: HTMLElement,
  presentingEl: HTMLElement,
  full = false,
): Animation => {
  const headerEl = baseEl.querySelector('.bottom-sheet-header') as HTMLElement
  const contentEl = baseEl.querySelector('.bottom-sheet-content') as HTMLElement
  const safeAreaBtm = window.getComputedStyle(document.documentElement).getPropertyValue('--sab')
  const margin = parseInt(safeAreaBtm.split('px')[0])
  const headerH = headerEl?.offsetHeight || 0
  const contentH = margin + headerH + contentEl?.offsetHeight || 0
  const screenH = baseEl.offsetHeight


  // const aniStep = full ? 1.0 : contentH / screenH
  const aniStep = contentH / screenH
  // const contentTop = full ? 0 : screenH - contentH
  const contentTop = screenH - contentH

  const nameFull = full ? '-full' : ''

  const backdropAnimation = createAnimation('ios-enter-backdrop' + nameFull)
    .addElement(baseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', 0.01, `calc(var(--backdrop-opacity) * ${aniStep})`)
    .beforeStyles({
      'pointer-events': 'none',
    })
    .afterClearStyles(['pointer-events'])

  const wrapperAnimation = createAnimation('ios-enter-wrapper' + nameFull)
    .addElement(baseEl.querySelectorAll('.modal-wrapper, .modal-shadow')!)
    .beforeStyles({ 'opacity': 1 })

  wrapperAnimation
    .fromTo('transform', 'translateY(100vh)', `translateY(${contentTop}px)`)

  const baseAnimation = createAnimation('ios-enter-base' + nameFull)
    .addElement(baseEl)
    .easing('cubic-bezier(0.32,0.72,0,1)')
    .duration(500)
    .addAnimation(wrapperAnimation)

  if (presentingEl) {
    const isMobile = window.innerWidth < 768
    const hasCardModal = presentingEl.tagName === 'ION-MODAL' && (presentingEl as HTMLIonModalElement).presentingElement !== undefined

    const presentingAnimation = createAnimation('ios-enter-presenting' + nameFull)
      .beforeStyles({
        'transform': 'translateY(0)',
        'transform-origin': 'top center',
        'overflow': 'hidden',
      })

    const bodyEl = document.body

    if (isMobile) {
      /**
       * Fallback for browsers that does not support `max()` (ex: Firefox)
       * No need to worry about statusbar padding since engines like Gecko
       * are not used as the engine for standlone Cordova/Capacitor apps
       */
      const transformOffset = !CSS.supports('width', 'max(0px, 1px)') ? `${30 * aniStep}px` : `max(${30 * aniStep}px, calc(var(--ion-safe-area-top)*${aniStep}))`
      const modalTransform = hasCardModal ? `${-10 * aniStep}px` : transformOffset
      // const toPresentingScale = SwipeToCloseDefaults.MIN_PRESENTING_SCALE
      const toPresentingScale = getScale(aniStep)
      const finalTransform = `translateY(${modalTransform}) scale(${toPresentingScale})`

      presentingAnimation
        .afterStyles({
          'transform': finalTransform,
        })
        .beforeAddWrite(() => bodyEl.style.setProperty('background-color', 'black'))
        .addElement(presentingEl)
        .keyframes([
          { offset: 0, filter: 'contrast(1)', transform: 'translateY(0px) scale(1)', borderRadius: '0px' },
          { offset: 1, filter: `contrast(${0.85 * aniStep})`, transform: finalTransform, borderRadius: `${10 * aniStep}px ${10 * aniStep}px 0 0` },
        ])

      baseAnimation.addAnimation(presentingAnimation)
    } else {
      baseAnimation.addAnimation(backdropAnimation)

      if (!hasCardModal) {
        wrapperAnimation.fromTo('opacity', '0', `${aniStep}`)
      } else {
        // const toPresentingScale = hasCardModal ? SwipeToCloseDefaults.MIN_PRESENTING_SCALE : 1
        const toPresentingScale = hasCardModal ? getScale(aniStep) : 1
        const finalTransform = `translateY(${-10 * aniStep}px) scale(${toPresentingScale})`

        presentingAnimation
          .afterStyles({
            'transform': finalTransform,
          })
          .addElement(presentingEl.querySelector('.modal-wrapper')!)
          .keyframes([
            { offset: 0, filter: 'contrast(1)', transform: 'translateY(0) scale(1)' },
            { offset: 1, filter: `contrast(${0.85 * aniStep})`, transform: finalTransform },
          ])

        const shadowAnimation = createAnimation('ios-enter-shadow' + nameFull)
          .afterStyles({
            'transform': finalTransform,
          })
          .addElement(presentingEl.querySelector('.modal-shadow')!)
          .keyframes([
            { offset: 0, opacity: '1', transform: 'translateY(0) scale(1)' },
            { offset: 1, opacity: `${1 - aniStep}`, transform: finalTransform },
          ])

        baseAnimation.addAnimation([presentingAnimation, shadowAnimation])
      }
    }
  } else {
    baseAnimation.addAnimation(backdropAnimation)
  }

  return baseAnimation
}