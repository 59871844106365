import { ClientType } from '../apollo'
import { types, ApolloQueryResult } from '../types'

import { m_createProfileOfUser, m_createProfileWithoutUser, m_createManyProfiles } from './profile-gql'

interface Mutations {
  createProfileOfUser: types.User_Profile
  createOneProfile: types.Profile
  createManyProfiles: types.BatchPayload
}

type Result = ApolloQueryResult<Mutations>

export async function createProfileOfUser (client: ClientType, userId: number, profile: types.ProfileCreateInput, user_profile: types.User_ProfileFieldsInput): Promise<types.User_Profile | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_createProfileOfUser,
      variables: {
        userId,
        profile,
        user_profile,
      },
    }) as Result
    return result.data.createProfileOfUser
  } catch (e) {
  }
}

export async function createProfileWithoutUser (client: ClientType, data: types.ProfileCreateInput): Promise<types.Profile | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_createProfileWithoutUser,
      variables: {
        data,
      },
    }) as Result
    return result.data.createOneProfile
  } catch (e) {
  }
}

export async function createProfilesWithoutUser (client: ClientType, input: types.ProfileCreateInput[]): Promise<types.BatchPayload | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_createManyProfiles,
      variables: {
        input,
      } as types.MutationCreateManyProfilesArgs,
    }) as Result
    return result.data.createManyProfiles
  } catch (e) {
  }
}
