import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'

import { onDontShowAgainUpdated } from './user-gql'

type Callback = (newData: types.DontShowAgain) => void

export function subscribeOnDontShowAgainUpdated (client: ClientType, userId: number, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onDontShowAgainUpdated,
    variables: {
      userId,
    },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      const newData = data.onDontShowAgainUpdated as types.DontShowAgain
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
