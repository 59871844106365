export function isSameDay (date1: Date, date2: Date): boolean {
  return date1.getFullYear() === date2.getFullYear() &&
         date1.getMonth() === date2.getMonth() &&
         date1.getDate() === date2.getDate()
}

export function isSameMinute (date1: Date, date2: Date): boolean {
  return isSameDay(date1, date2) &&
         date1.getHours() === date2.getHours() &&
         date1.getMinutes() === date2.getMinutes()
}

export function previousDay (date: Date): Date {
  const prev = new Date(date)
  prev.setDate(date.getDate() - 1)
  return prev
}

export enum DateUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export function beforeDate (date: Date, before: number, unit: DateUnit): Date {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  switch (unit) {
    case DateUnit.DAY:
      return new Date(year, month, day - before)
    case DateUnit.WEEK:
      return new Date(year, month, day - (before * 7))
    case DateUnit.MONTH:
      return new Date(year, month - before, day)
    case DateUnit.YEAR:
      return new Date(year - before, month, day)
  }
}
