"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVerificationServiceWaitResultPath = exports.getVerificationServiceReturnPath = exports.getVerificationServicePath = exports.VERIFICATION_SERVICE_PATH_WAIT_RESULT_SUFFIX = exports.VERIFICATION_SERVICE_PATH_RETURN_SUFFIX = exports.VERIFICATION_SERVICE_PATH_PREFIX = void 0;
exports.VERIFICATION_SERVICE_PATH_PREFIX = 'verification-service';
exports.VERIFICATION_SERVICE_PATH_RETURN_SUFFIX = '-return';
exports.VERIFICATION_SERVICE_PATH_WAIT_RESULT_SUFFIX = 'wait-result';
function getVerificationServicePath(vtype, suffix = '') {
    return `${exports.VERIFICATION_SERVICE_PATH_PREFIX}/${vtype}${suffix}`;
}
exports.getVerificationServicePath = getVerificationServicePath;
function getVerificationServiceReturnPath(vtype) {
    return getVerificationServicePath(vtype, exports.VERIFICATION_SERVICE_PATH_RETURN_SUFFIX);
}
exports.getVerificationServiceReturnPath = getVerificationServiceReturnPath;
function getVerificationServiceWaitResultPath() {
    return `${exports.VERIFICATION_SERVICE_PATH_PREFIX}/${exports.VERIFICATION_SERVICE_PATH_WAIT_RESULT_SUFFIX}`;
}
exports.getVerificationServiceWaitResultPath = getVerificationServiceWaitResultPath;
