
import Vue from 'vue'

import { types, apollo } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'

import BvLoading from '@dinosband/bv-components/basic/BvLoading.vue'

export default Vue.extend({
  components: {
    BvLoading,
  },

  data () {
    return {
      channels: [] as types.Channel[],
      loading: true,
    }
  },

  async mounted () {
    const channels = await apollo.readChannels(await apc.getClient()) ?? []
    this.channels = channels.sort((a, b) => (b.profileCount ?? 0) - (a.profileCount ?? 0))
    this.loading = false
  },

  methods: {
    async onClickChannel (channel: types.Channel) {
      const ch = await apollo.readChannelDetail(await apc.getClient(), channel.id)
    },
  },
})
