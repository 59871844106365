import { ClientType } from '../apollo'
import { ApolloQueryResult, types } from '../types'

import { q_findManyCommerceProduct } from './commerce-gql'
import { createOneCommerceProduct, updateOneCommerceProduct, findUniqueCommerceProduct } from '@dinosband/bv-main-schema/dist/client-gql'

interface Mutations {
  createOneCommerceProduct: types.CommerceProduct
  updateOneCommerceProduct: types.CommerceProduct
}

type MutationResult = ApolloQueryResult<Mutations>

export async function createCommerceProduct (client: ClientType, data: types.CommerceProductCreateInput): Promise<types.CommerceProduct> {
  const result = await client.mutate({
    mutation: createOneCommerceProduct,
    variables: {
      data,
    } as types.MutationCreateOneCommerceProductArgs,
  }) as MutationResult
  return result.data.createOneCommerceProduct
}

export async function updateCommerceProduct (client: ClientType, id: number, data: types.CommerceProductUpdateInput): Promise<types.CommerceProduct> {
  const result = await client.mutate({
    mutation: updateOneCommerceProduct,
    variables: {
      where: { id },
      data,
    } as types.MutationUpdateOneCommerceProductArgs,
  }) as MutationResult
  return result.data.updateOneCommerceProduct
}

export async function readCommerceProducts (client: ClientType, where: types.CommerceProductWhereInput, orderBy?: types.CommerceProductOrderByWithRelationInput[], cursor?: number, take?: number): Promise<types.CommerceProduct[] | undefined> {
  const result = await client.query({
    query: q_findManyCommerceProduct,
    variables: {
      where,
      orderBy,
      cursor: cursor ? { id: cursor } : undefined,
      skip: cursor ? 1 : undefined,
      take,
    } as types.QueryFindManyCommerceProductArgs,
  })
  return result.data.findManyCommerceProduct
}

export async function readCommerceProduct (client: ClientType, id: number): Promise<types.CommerceProduct | undefined> {
  const result = await client.query({
    query: findUniqueCommerceProduct,
    variables: {
      where: { id },
    } as types.QueryFindUniqueCommerceProductArgs,
  })
  return result.data.findUniqueCommerceProduct
}
