
import Vue from 'vue'

interface CustomScrollEvent {
  target: { scrollTop: number }
  detail: { scrollTop: number }
}

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: '',
    },
    useRefresher: {
      type: Boolean,
      default: false,
    },
    labelBack: {
      type: String,
      default: 'Back',
    },
    backMeansApply: {
      type: Boolean,
      default: true,
    },
    scrollEvents: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      scrollTop: 0,
      canGoBack: false,
    }
  },

  async created () {
    // dismiss loading controller if it is still running
    await this.$dbIon.dismissLoading()

    this.canGoBack = await this.$dbiRouter.canGoBack(this)
  },

  methods: {
    onBack () {
      this.$emit('back')
      this.$dbiRouter.pop(this, this.backMeansApply)
    },

    onScrollStart () {
    },

    onScroll (e: CustomScrollEvent) {
      let scrollTop = 0
      // if (this.opts.independantScroll) {
      //   scrollTop = e.target.scrollTop
      // } else {
      //   scrollTop = e.detail.scrollTop
      // }
      scrollTop = e.detail.scrollTop
      if (scrollTop == 0) {
        this.$emit('scrollAtTop', true)
      } else if (this.scrollTop == 0) {
        this.$emit('scrollAtTop', false)
      }
      this.scrollTop = scrollTop
    },

    onScrollEnd () {
    },
  },
})
