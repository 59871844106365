import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyAdvertisement, findManyAdvertisementCount, findUniqueAdvertisement } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readAdvertisement (client: ClientType, id: number): Promise<types.Advertisement | undefined> {
  try {
    const result = await client.query({
      query: findUniqueAdvertisement,
      variables: {
        where: { id },
      } as types.QueryFindUniqueAdvertisementArgs,
    })
    return result.data.findUniqueAdvertisement
  } catch (e) {
  }
}

export async function readUnreadAdsCount (client: ClientType, lastId: number): Promise<number | undefined> {
  try {
    const now = new Date()
    const result = await client.query({
      query: findManyAdvertisementCount,
      variables: {
        where: {
          NOT: [{
            channelId: {
              equals: 0,
            },
            title: {
              contains: '[HomeBanner]',
            },
          }],
          id: { gt: lastId },
          validFrom: {
            lte: now,
          },
          validTo: {
            gte: now,
          },
        },
      } as types.QueryFindManyAdvertisementCountArgs,
    })
    return result.data.findManyAdvertisementCount
  } catch (e) {
  }
}

export async function readAdvertisements (client: ClientType, channelId: number): Promise<types.Advertisement[] | undefined> {
  try {
    const now = new Date()
    const result = await client.query({
      query: findManyAdvertisement,
      variables: {
        where: {
          channelId: {
            in: [channelId, types.AD_CHANNEL_ALL],
          },
          validFrom: {
            lte: now,
          },
          validTo: {
            gte: now,
          },
        },
      } as types.QueryFindManyAdvertisementArgs,
    })
    return result.data.findManyAdvertisement
  } catch (e) {
  }
}

export async function readAllAdvertisementsForAllApps (client: ClientType): Promise<types.Advertisement[] | undefined> {
  try {
    const result = await client.query({
      query: findManyAdvertisement,
      variables: {
        where: {
          OR: [
            {
              isAppChannel: { equals: null },
            },
            {
              isAppChannel: { equals: false },
            },
            {
              isAppChannel: { equals: true },
            },
          ],
        },
      } as types.QueryFindManyAdvertisementArgs,
    })
    return result.data.findManyAdvertisement
  } catch (e) {
  }
}

export async function readAllAdvertisements (client: ClientType): Promise<types.Advertisement[] | undefined> {
  try {
    const result = await client.query({
      query: findManyAdvertisement,
      variables: {
        where: {},
      } as types.QueryFindManyAdvertisementArgs,
    })
    return result.data.findManyAdvertisement
  } catch (e) {
  }
}

export async function readAllValidAdvertisements (client: ClientType): Promise<types.Advertisement[] | undefined> {
  try {
    const now = new Date()
    const result = await client.query({
      query: findManyAdvertisement,
      variables: {
        where: {
        // except Banner
          NOT: [{
            channelId: {
              equals: 0,
            },
            title: {
              contains: '[HomeBanner]',
            },
          },
          ],
          validFrom: {
            lte: now,
          },
          validTo: {
            gte: now,
          },
        },
        orderBy: [
          { id: 'desc' },
        ],
      } as types.QueryFindManyAdvertisementArgs,
    })
    return result.data.findManyAdvertisement
  } catch (e) {
  }
}

export async function readAllHomeBannerAdvertisements (client: ClientType): Promise<types.Advertisement[] | undefined> {
  try {
    const now = new Date()
    const result = await client.query({
      query: findManyAdvertisement,
      variables: {
        where: {
          channelId: {
            equals: 0,
          },
          title: {
            contains: '[HomeBanner]',
          },
          validFrom: {
            lte: now,
          },
          validTo: {
            gte: now,
          },
        },
        orderBy: [{ validFrom: 'desc' }],
      } as types.QueryFindManyAdvertisementArgs,
    })
    return result.data.findManyAdvertisement
  } catch (e) {
  }
}
