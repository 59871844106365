import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueChatRoomWithChannel } from './chatroom-gql'

export async function readChannelOfChatRoom (client: ClientType, chatRoomId: number): Promise<types.ChatRoom | undefined> {
  const opt = {
    query: findUniqueChatRoomWithChannel,
    variables: {
      where: {
        id: chatRoomId,
      },
    } as types.QueryFindUniqueChatRoomArgs,
  }
  try {
    const result = await client.query(opt)
    if (result.data.findUniqueChatRoom) {
      return result.data.findUniqueChatRoom
    }
  } catch (e) {
  }
}
