import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyJobOutput } from '@dinosband/bv-main-schema/dist/client-gql'

export async function searchJobOutput (client: ClientType, filter: types.JobOutputSearchFilter, cursor?: number, take = 100): Promise<types.JobOutput[] | undefined> {
  const where: types.JobOutputWhereInput = {}

  if (filter.channelId) {
    where.channelId = { equals: filter.channelId }
  }

  if (filter.chatRoomIds) {
    where.chatRoomId = { in: filter.chatRoomIds }
  }

  if (filter.groups) {
    where.group = { in: filter.groups }
  }

  if (filter.jobOfferResponseId) {
    where.jobOfferResponseId = { equals: filter.jobOfferResponseId }
  }

  if (filter.messageIds) {
    where.messageId = { in: filter.messageIds }
  }

  if (filter.published) {
    where.published = { equals: true }
  }

  if (filter.closed) {
    where.jobOfferResponse = {
      state: { equals: 'Closed' },
    }
  }

  if (filter.keywords?.length) {
    where.AND = filter.keywords.map((kw) => ({
      OR: [
        {
          title: { contains: kw },
        },
        {
          keywords: { contains: kw },
        },
        {
          mesgKeywords: { contains: kw },
        },
      ],
    }))
  }

  if (cursor) {
    where.id = { lt: cursor }
    // variables.cursor = { id: cursor - 1 }
  }

  const variables = {
    where,
    take,
    orderBy: [
      { id: 'desc' },
    ],
  } as types.QueryFindManyJobOutputArgs

  try {
    const result = await client.query<{findManyJobOutput: types.JobOutput[]}>({
      query: findManyJobOutput,
      variables,
    })
    return result.data.findManyJobOutput
  } catch (e) {
  }
}
