import VueRouter, { Route, NavigationGuardNext } from 'vue-router'
import { getToken, setToken, removeToken } from './token'

let original_path = '/'
let vueRouter: VueRouter

export function setRouter (router: VueRouter) {
  vueRouter = router
  const token = getToken()
  if (token) {
    setToken(token)
  }
  router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/sign-in']
    const authRequired = !publicPages.includes(to.path)

    if (authRequired && !getToken()) {
      // localStorage.setItem('original_path', to.path)
      original_path = to.path
      return next('/sign-in')
    }

    next()
  })
}

export function setSignedIn (token: string) {
  setToken(token)
  if (original_path) {
    original_path = '/'
    vueRouter.push(original_path)
  }
}

export function signOut () {
  removeToken()
  vueRouter.push('/sign-in')
}
