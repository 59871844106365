
import { waitFor } from '@dinosband/dbi-utils'
import Vue from 'vue'

export default Vue.extend({
  async mounted () {
    await waitFor(() => this.$el?.clientWidth > 0)
    const btnWidth = this.$el.clientWidth
    const spans = this.$el.getElementsByClassName('span-text')
    if (spans?.length) {
      const span = spans.item(0)
      if (span) {
        await waitFor(() => span.clientWidth > 0)
        const margin = 8
        let fontSize = 20
        const minFontSize = 6
        while (span.clientWidth + margin > btnWidth) {
          fontSize--
          if (fontSize < minFontSize) {
            break
          }
          span.style.fontSize = `${fontSize}px`
        }
      }
    }
  },
})
