import { ClientType } from '../apollo'
import { types, MutationResult } from '../types'

import { createOneProfileInfo, updateOneProfileInfo } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createProfileInfo (client: ClientType, data: types.ProfileInfoCreateInput): Promise<types.ProfileInfo | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneProfileInfo,
      variables: {
        data,
      } as types.MutationCreateOneProfileInfoArgs,
    }) as MutationResult
    return result.data.createOneProfileInfo
  } catch (e) {
  }
}

export async function updateProfileInfo (client: ClientType, id: number, data: types.ProfileInfoUpdateInput): Promise<types.ProfileInfo | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneProfileInfo,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneProfileInfoArgs,
    }) as MutationResult
    return result.data.updateOneProfileInfo
  } catch (e) {
  }
}