import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { onSysNoticeAdded } from './sys-notice-gql'

type Callback = (newData: types.SysNotice) => void

export function subscribeOnSysNoticeAdded (client: ClientType, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onSysNoticeAdded,
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      debug.log('=> onSysNoticeAdded')
      const newData = data.onSysNoticeAdded as types.SysNotice
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
