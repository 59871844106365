// import { client, cache } from '../apollo'
import { ClientType } from '../apollo'
import { types, SmartSubscribe } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { onProfile_ChannelAdded } from './profile_channel-gql'

type Callback = (newData: types.Profile_Channel) => void
  
export function subscribeOnProfile_ChannelAdded (client: ClientType, userId: number, profileIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onProfile_ChannelAdded,
    variables: {
      profileIds,
    },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      const newData = data.onProfile_ChannelAdded as types.Profile_Channel
      debug.log('=> onProfile_ChannelAdded: profileId, channelId = ', newData.profileId, newData.channelId)
      cb(newData)
      /*
      const profileId = newData.profileId
      const opt = option_readUserGraph(userId)
      const cached = JSON.parse(JSON.stringify(client.readQuery(opt)))
      const user = cached.findUniqueUser as types.User
      const user_profile = user.profiles.find((u_p) => u_p.profileId === profileId)
      if (user_profile) {
        const profile = user_profile.profile
        profile.channels.push(newData)
        client.writeQuery({
          query: opt.query,
          data: cached,
        })
      }
      */
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
