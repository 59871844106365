import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
// import * as frag from '../fragments-gql'

export const q_findManyJobReview = gql`
  query q_findManyJobReview($where: JobReviewWhereInput!, $skip: Int, $take: Int, $cursor: JobReviewWhereUniqueInput, $orderBy: [JobReviewOrderByWithRelationInput!]) {
    findManyJobReview(where: $where, skip: $skip, take: $take, cursor: $cursor, orderBy: $orderBy) {
      ...JobReviewFields
      reviewer {
        id
        name
        thumbnailId
      }
      reviewee {
        id
        name
        thumbnailId
      }
      offerChatRoom {
        id
        props
      }
    }
  }
  ${client.JobReviewFields}
  ${client.ProfileFields}
`

export const onJobReviewAdded = gql`
  subscription onJobReviewAdded($profileIds: [Int!]!, $userId: Int) {
    onJobReviewAdded(profileIds: $profileIds, userId: $userId) {
      ...JobReviewFields
    }
  }
  ${client.JobReviewFields}
`
