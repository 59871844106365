import { Animation, createAnimation } from '@ionic/core'

/* eslint-disable @typescript-eslint/no-non-null-assertion */

/**
 * Md Modal Leave Animation
 */
export const mdBottomSheetLeaveAnimation = (
  baseEl: HTMLElement,
  presentingEl?: HTMLElement,
  duration = 500,
  full = false,
): Animation => {
  const baseAnimation = createAnimation()
  const backdropAnimation = createAnimation()
  const wrapperAnimation = createAnimation()
  const wrapperEl = baseEl.querySelector('.modal-wrapper')!

  let aniStep = 1.0
  if (!full) {
    const style = window.getComputedStyle(wrapperEl)
    const matrix = new DOMMatrixReadOnly(style.transform)
    const curTop = matrix.m42
    const screenH = baseEl.offsetHeight
    aniStep = (screenH - curTop) / screenH
  }

  backdropAnimation
    .addElement(baseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', `calc(var(--backdrop-opacity) * ${aniStep})`, 0.0)

  wrapperAnimation
    .addElement(wrapperEl)
    // .keyframes([
    //   { offset: 0, opacity: 0.99 * aniStep, transform: `translateY(${40 - 40 * aniStep}px)` },
    //   { offset: 1, opacity: 0, transform: 'translateY(40px)' },
    // ])

  if (full) {
    wrapperAnimation.fromTo('transform', 'translateY(0vh)', 'translateY(100vh)')
  } else {
    wrapperAnimation.to('transform', 'translateY(100vh)')
  }

  return baseAnimation
    .addElement(baseEl)
    .easing('cubic-bezier(0.47,0,0.745,0.715)')
    .duration(200)
    .addAnimation([backdropAnimation, wrapperAnimation])
}