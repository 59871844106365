
import { Vue, Component, Watch } from 'vue-property-decorator'
import { RouteConfig } from 'vue-router'
import { RouteNode } from '../core/route-node'
import { RouteTransitionDirection } from '../core/router'
import { RouterEvent } from './router-event'

@Component
export default class DbiRouterView extends Vue {
  node: RouteNode | null | undefined = null
  transition = ''
  useIonNav = true
  offRouterEvent: (() => void) | undefined = undefined
  router = this.$dbiRouter

  get component () {
    return this.node?.component ?? 'div'
  }

  get compParams () {
    return this.node?.componentParams
  }

  get compRouteParams () {
    return this.node?.routeParams
  }

  @Watch('$route', { immediate: true })
  async routeUpdated (to: RouteConfig, from: RouteConfig): Promise<void> {
    // const router = this.$dbiRouter
    await this.router.update()
    await this.updateView()
  }

  created (): void {
    this.node = this.router.currentRouteNodes
    this.offRouterEvent = RouterEvent.$on('update-route', async (direction: RouteTransitionDirection) => {
      await this.updateView()
    })
  }

  beforeDestroy () {
    if (this.offRouterEvent) {
      this.offRouterEvent()
    }
  }

  async updateView (): Promise<void> {
    this.node = this.router.currentRouteNodes
    if (!this.node.children.size) {
      // tab이 없는 경우에는 여기에서 updateView를 불러주고
      // tab이 있는 경우에는 TabsView에서 updateView를 불러준다.
      await this.router.updateView(this, this.node)
    }
  }
}
