import { ClientType } from '../apollo'
import { SmartSubscribe } from '../types'
import { debug } from '@dinosband/dbi-utils'
import { JobOfferResponse } from '@dinosband/bv-main-schema/dist/types'
import {
  onJobOfferResponseUpdated,
  onJobOfferResponseOfOfferUpdated,
  onJobOfferResponseOfChatRoomUpdated,
} from './job-offer-gql'

type Callback = (newData: JobOfferResponse) => void

export function subscribeOnJobOfferResponseUpdated (client: ClientType, profileIds: number[], userId: number | undefined, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onJobOfferResponseUpdated,
    variables: { profileIds, userId },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      const newData = data.onJobOfferResponseUpdated as JobOfferResponse
      debug.log(`=> onJobOfferResponseUpdated: id=${newData.id}, jobOfferId=${newData.jobOfferId}`)
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}


export function subscribeOnJobOfferResponseOfOfferUpdated (client: ClientType, offerIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onJobOfferResponseOfOfferUpdated,
    variables: { offerIds },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      debug.log('=> onJobOfferResponseOfOfferUpdated')
      const newData = data.onJobOfferResponseOfOfferUpdated as JobOfferResponse
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}


export function subscribeOnJobOfferResponseOfChatRoomUpdated (client: ClientType, chatRoomIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onJobOfferResponseOfChatRoomUpdated,
    variables: { chatRoomIds },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      debug.log('=> onJobOfferResponseOfChatRoomUpdated')
      const newData = data.onJobOfferResponseOfChatRoomUpdated as JobOfferResponse
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
