import gql from 'graphql-tag'

export const RequestVerificationCodeInput = gql`
  input RequestVerificationCodeInput {
    profileId: Int!
    type: Int!
  }
`

export const VerifyCodeInput = gql`
  input VerifyCodeInput {
    profileId: Int!
    type: Int!
    code: String!
  }
`

export const m_requestVerificationCode = gql`
  mutation m_requestVerificationCode($input: RequestVerificationCodeInput!) {
    requestVerificationCode(input: $input) {
      ok
      code
    }
  }
`

export const m_verifyCode = gql`
  mutation m_verifyCode($input: VerifyCodeInput!) {
    verifyCode(input: $input) {
      ok
      error
    }
  }
`
