import { render, staticRenderFns } from "./DbVirtualScroll.vue?vue&type=template&id=39eef7e5&scoped=true&"
import script from "./DbVirtualScroll.vue?vue&type=script&lang=ts&"
export * from "./DbVirtualScroll.vue?vue&type=script&lang=ts&"
import style0 from "./DbVirtualScroll.vue?vue&type=style&index=0&id=39eef7e5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39eef7e5",
  null
  
)

export default component.exports