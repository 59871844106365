import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { updateOneProfile_Channel } from '@dinosband/bv-main-schema/dist/client-gql'

export async function updatePropsOfProfile_Channel (client: ClientType, profile_ChannelId: number, props: types.Profile_ChannelProps): Promise<void> {
  try {
    await client.mutate({
      mutation: updateOneProfile_Channel,
      variables: {
        where: {
          id: profile_ChannelId,
        },
        data: {
          props: {
            set: JSON.stringify(props),
          },
        },
      } as types.MutationUpdateOneProfile_ChannelArgs,
    }) as MutationResult
  } catch (e) {
  }
}

export async function updateUserInfoOfProfile_Channel (client: ClientType, profile_ChannelId: number, userInfo: Record<string, unknown>): Promise<void> {
  try {
    await client.mutate({
      mutation: updateOneProfile_Channel,
      variables: {
        where: {
          id: profile_ChannelId,
        },
        data: {
          userInfo,
        },
      } as types.MutationUpdateOneProfile_ChannelArgs,
    }) as MutationResult
  } catch (e) {
  }
}

export async function updateCurrentExpertIdOfProfile_Channel (client: ClientType, profile_ChannelId: number, currentExpertId: number): Promise<types.Profile_Channel | undefined> {
  try {
    const res = await client.mutate({
      mutation: updateOneProfile_Channel,
      variables: {
        where: {
          id: profile_ChannelId,
        },
        data: {
          currentExpertId: { set: currentExpertId },
        },
      } as types.MutationUpdateOneProfile_ChannelArgs,
    }) as MutationResult
    return res.data.updateOneProfile_Channel
  } catch (e) {
  }
}

export async function updateNoDefaultExpertOfProfile_Channel (client: ClientType, profile_ChannelId: number, noDefault: boolean): Promise<types.Profile_Channel | undefined> {
  try {
    const res = await client.mutate({
      mutation: updateOneProfile_Channel,
      variables: {
        where: {
          id: profile_ChannelId,
        },
        data: {
          noDefaultExpert: { set: noDefault },
        },
      } as types.MutationUpdateOneProfile_ChannelArgs,
    }) as MutationResult
    return res.data.updateOneProfile_Channel
  } catch (e) {
  }
}

export async function updateProfile_Channel (client: ClientType, profile_ChannelId: number, data: types.Profile_ChannelUpdateInput): Promise<types.Profile_Channel | undefined> {
  try {
    const res = await client.mutate({
      mutation: updateOneProfile_Channel,
      variables: {
        where: {
          id: profile_ChannelId,
        },
        data,
      } as types.MutationUpdateOneProfile_ChannelArgs,
    }) as MutationResult
    return res.data.updateOneProfile_Channel
  } catch (e) {
  }
}
