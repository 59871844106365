import { ApolloQueryResult, FetchResult, Observable, ObservableQuery, ObservableSubscription } from '@apollo/client/core'
import { Query, Mutation, Maybe } from '@dinosband/bv-main-schema/dist/types'

export * as types from '@dinosband/bv-main-schema/dist/types'
export type QueryResult = ApolloQueryResult<Query>
export type MutationResult = ApolloQueryResult<Mutation>
export type PromiseMaybe<T> = Promise<Maybe<T>>
export type QueryCallback = (data: unknown) => void
export type { ApolloQueryResult } from '@apollo/client/core'
export interface PaginationArgs {
  cursor?: number
  take?: number
}


export class SmartObservable {
  protected subscription: ObservableSubscription

  constructor (subscription: ObservableSubscription) {
    this.subscription = subscription
  }

  unsubscribe () {
    this.subscription.unsubscribe()
  }
}

export class SmartQuery extends SmartObservable {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  protected query: ObservableQuery<any, any>

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  constructor (query: ObservableQuery<any, any>, subscription: ObservableSubscription) {
    super(subscription)
    this.query = query
  }
}

export class SmartSubscribe extends SmartObservable {
  protected subscribe: Observable<FetchResult>

  constructor (subscribe: Observable<FetchResult>, subscription: ObservableSubscription) {
    super(subscription)
    this.subscribe = subscribe
  }
}