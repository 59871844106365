import { User_ChatRoomView } from '@dinosband/bv-main-schema/dist/types'
import { ClientType } from '../apollo'
import { upsertUser_ChatRoomView as mutation } from './user_chatroom_view-gql'

export async function upsertUser_ChatRoomView (client: ClientType, chatRoomId: number): Promise<User_ChatRoomView | undefined> {
  try {
    const result = await client.mutate({
      mutation,
      variables: {
        chatRoomId,
      },
    })
    return result.data.upsertUser_ChatRoomView
  } catch (e) {
  }
}
