// import { client, cache } from '../apollo'
import { ClientType } from '../apollo'
import { SmartSubscribe } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { onProfile_ChannelDeleted } from './profile_channel-gql'
// import { option_readUserGraph } from '../user'

type Callback = (deleted: { id: number }) => void

export function subscribeOnProfile_ChannelDeleted (client: ClientType, userId: number, profileIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onProfile_ChannelDeleted,
    variables: {
      profileIds,
    },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      const deleted = data.onProfile_ChannelDeleted
      debug.log('=> onProfile_ChannelDeleted: id = ', deleted.id)
      cb(deleted)
      /*

      const opt = option_readUserGraph(userId)
      const cached = JSON.parse(JSON.stringify(client.readQuery(opt)))
      const user = cached.findUniqueUser as types.User

      for (const u_p of user.profiles) {
        const inx = u_p.profile.channels.findIndex((p_c) => p_c.id == deleted.id)
        if (inx >= 0) {
          u_p.profile.channels.splice(inx, 1)
          client.writeQuery({
            query: opt.query,
            data: cached,
          })
          break
        }
      }
      */
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}

