import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { Components } from '@ionic/core'

let _loadingComponent: Components.IonLoading | undefined

export async function loading (message = i18n.tc('common.wait')): Promise<Components.IonLoading | undefined> {
  _loadingComponent = await Vue.prototype.$ionic.loadingController.create({
    message: message,
  })
  _loadingComponent?.present()
  return _loadingComponent
}

export async function dismissLoading (): Promise<void> {
  if (_loadingComponent) {
    await _loadingComponent.dismiss()
    _loadingComponent = undefined
  }
}
