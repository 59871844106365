import { ClientType } from '../apollo'
import { types, MutationResult } from '../types'

import { createOneJobReview } from '@dinosband/bv-main-schema/dist/client-gql'

export interface CreateJobReviewArgs {
  jrsp: types.JobOfferResponse
  review: types.JobReviewData
  reviewer: types.Profile
}

export async function createJobReview (client: ClientType, data: types.JobReviewCreateInput): Promise<types.JobReview | undefined> {
  // const { jrsp, review, reviewer } = args

  // const role = reviewer.id === jrsp.providerId ?
  //   types.JobReviewRole.PROVIDER :
  //   types.JobReviewRole.CLIENT
  // const revieweeId = reviewer.id === jrsp.providerId ?
  //   jrsp.jobOffer.clientId :
  //   jrsp.providerId

  // const data: types.JobReviewCreateInput = {
  //   ...review,
  //   role,
  //   reviewer: { connect: { id: reviewer.id } },
  //   reviewee: { connect: { id: revieweeId } },
  // }

  // if (reviewer.id === jrsp.providerId) {
  //   data.jobAsProvider = { connect: { id: jrsp.id } }
  // } else {
  //   data.jobAsClient = { connect: { id: jrsp.id } }
  // }


  try {
    const result = await client.mutate({
      mutation: createOneJobReview,
      variables: {
        data,
      } as types.MutationCreateOneJobReviewArgs,
    }) as MutationResult
    return result.data.createOneJobReview
  } catch (e) {
  }
}