import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueChannelDictionary } from '@dinosband/bv-main-schema/dist/client-gql'
import { upsertOneChannelDictionary } from './dictionary-gql'

export async function upsertChannelDictionary (client: ClientType, channelId: number, lang: string, dict: Record<string, string>): Promise<types.ChannelDictionary | undefined> {
  try {
    const result = await client.mutate({
      mutation: upsertOneChannelDictionary,
      variables: {
        where: {
          channelId_lang: {
            channelId, lang,
          },
        },
        create: {
          channelId,
          lang,
          dict,
        },
        update: {
          dict,
        },
      } as types.MutationUpsertOneChannelDictionaryArgs,
    })
    return result.data.upsertOneChannelDictionary
  } catch (e) {
  }
}

export async function readChannelDictionary (client: ClientType, channelId: number, lang: string): Promise<types.ChannelDictionary | undefined> {
  try {
    const result = await client.query({
      query: findUniqueChannelDictionary,
      variables: {
        where: {
          channelId_lang: {
            channelId,
            lang,
          },
        },
      } as types.QueryFindUniqueChannelDictionaryArgs,
    })
    return result.data.findUniqueChannelDictionary
  } catch (e) {
  }
}