import Vue from 'vue'


Vue.config.productionTip = false
Vue.config.silent = true

import i18n, { initializeI18n } from '@dinosband/bv-i18n'

import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed)

import routes from './routes'
import { DbiRouterPlugin } from '@dinosband/dbi-router'
Vue.use(DbiRouterPlugin, { routes })

const router = Vue.prototype.$vueRouter
const dbiRouter = Vue.prototype.$dbiRouter

import { VueIonicPlugin } from '@dinosband/vue-ionic'
Vue.use(VueIonicPlugin)

import { addIcons } from 'ionicons'

import * as icons from 'ionicons/icons'

addIcons({
  'home-outline': icons.homeOutline,
  'chatbubbles-outline': icons.chatbubbleOutline,
  'people-outline': icons.peopleOutline,
  'bar-chart-outline': icons.barChartOutline,
  'alert-circle-outline': icons.alertCircleOutline,
  'megaphone-outline': icons.megaphoneOutline,
  'gift-outline': icons.giftOutline,
  'person-circle-outline': icons.personCircleOutline,
  'school-outline': icons.schoolOutline,
  'close': icons.close,
})

import { DbIonicVuePlugin } from '@dinosband/db-ionic-vue'
Vue.use(DbIonicVuePlugin, {
  // globalAssets: '@dinosband/bv-assets/images',
  assetsContext: require.context(
    './assets',
    false,
    /[^\s]+\.(jpg|png|gif|bmp|svg)$/,
  ),
  staticComponentsContext: require.context(
    './components',
    true,
    /[A-Z]\w+\.(vue|js)$/,
  ),
  icons: [],
})

import { store } from './store'
import Attachment from '@dinosband/bv-attachment'
Vue.use(Attachment, {
  dbiRouter,
  store,
})

import VMdDateRangePicker from 'v-md-date-range-picker'
Vue.use(VMdDateRangePicker)

const vueOptions = {
  router,
  i18n,
}

import { setRouter } from './auth'

setRouter(router)
dbiRouter.start(true)

import { vueDelegate } from '@dinosband/vue-ionic'
vueDelegate.setOptions(vueOptions)

import { initializeApollo, initializeMessengerApollo } from './apollo'
import { initPictureCache } from '@dinosband/bv-attachment'

// initialization codes
async function initialize (): Promise<void> {
  const mainServer = process.env.VUE_APP_MAIN_SERVER || 'main.api.bizplat.app'
  const secureMain = mainServer.includes('bizplat.app') ? 's' : ''
  const httpURI = `http${secureMain}://${mainServer}/graphql`
  const wsURI = `ws${secureMain}://${mainServer}/graphql`

  await initializeApollo(httpURI, wsURI)

  const messengerServer = process.env.VUE_APP_MESSENGER_SERVER || 'messenger.api.bizplat.app'
  const secure = messengerServer.includes('bizplat.app') ? 's' : ''
  const mhttpURI = `http${secure}://${messengerServer}/graphql`
  const mwsURI = `ws${secure}://${messengerServer}/graphql`

  await initializeMessengerApollo(mhttpURI, mwsURI)

  await initPictureCache()
  await initializeI18n()
}

// create Vue after initialization is done
initialize().then(async () => {
  new Vue({
    ...vueOptions,
    render (h) {
      return h(Vue.component('BvaApp'))
    },
  }).$mount('#app')
})
