export class ListNode<K, T> {
  key: K
  value: T
  prev: ListNode<K, T> | null = null
  next: ListNode<K, T> | null = null

  constructor (key: K, value: T) {
    this.key = key
    this.value = value
  }
}

export class OrderedList<K, T> {
  protected map = new Map<K, ListNode<K, T>>()
  protected head: ListNode<K, T> | null = null
  protected tail: ListNode<K, T> | null = null

  get count () {
    return this.map.size
  }

  get first () {
    return this.head
  }

  get last () {
    return this.tail
  }

  clear () {
    this.map.clear()
    this.head = null
    this.tail = null
  }

  find (key: K): ListNode<K, T> | undefined {
    return this.map.get(key)
  }

  findMinKey (): K | undefined {
    let node = this.head
    if (node) {
      let min = node.key
      while (node) {
        if (node.key < min) {
          min = node.key
        }
        node = node.next
      }
      return min
    }
    return undefined
  }

  findMaxKey (): K | undefined {
    let node = this.head
    if (node) {
      let max = node.key
      while (node) {
        if (max < node.key) {
          max = node.key
        }
        node = node.next
      }
      return max
    }
    return undefined
  }

  push (node: ListNode<K, T>) {
    this.map.set(node.key, node)
    if (this.tail) {
      this.tail.next = node
      node.prev = this.tail
    }
    node.next = null
    this.tail = node
    if (!this.head) {
      this.head = node
    }
  }

  shift (): ListNode<K, T> | null {
    const head = this.head
    if (head) {
      this.map.delete(head.key)
      this.delete(head)
    }
    return head
  }

  delete (node: ListNode<K, T>): void {
    this.map.delete(node.key)
    if (node.prev) {
      node.prev.next = node.next
    } else {
      this.head = node.next
    }

    if (node.next) {
      node.next.prev = node.prev
    } else {
      this.tail = node.prev
    }
  }

  moveToRear (node: ListNode<K, T>): void {
    this.delete(node)
    this.push(node)
  }
}
