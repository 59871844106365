import { Capacitor } from '@capacitor/core'


// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let cordova: { plugins: { SecureKeyStore: any }}
const SecureKeyStore = Capacitor.isNativePlatform() ? cordova.plugins.SecureKeyStore : undefined

export async function get (key: string, type: string) {
  const key_ = `Beezvalley_${key}`
  if (Capacitor.isNativePlatform()) {
    return new Promise((resolve, reject) => {
      SecureKeyStore.get((value: string) => {
        resolve(value)
      }, (e: string) => {
        // console.error('secure-get', key, e)
        // reject(e)
        resolve(undefined)
      },
      key_)
    })
  } else {
    return localStorage.getItem(key_)
  }
}

export async function set (key: string, value: unknown) {
  if (value == null) {
    remove(key)
    return
  }
  let value_: string
  if (typeof value == 'string') {
    value_ = value
  } else if (typeof value == 'object') {
    value_ = JSON.stringify(value)
  } else {
    value_ = String(value)
  }
  const key_ = `Beezvalley_${key}`
  if (Capacitor.isNativePlatform()) {
    return new Promise((resolve, reject) => {
      SecureKeyStore.set((value: string) => {
        resolve(value)
      }, (e: string) => {
        console.error('secure-set', key, e)
        console.error('secure-set', key, value, e)
        reject(e)
      },
      key_, value_)
    })
  } else {
    localStorage.setItem(key_, value_)
  }
}

export async function remove (key: string) {
  const key_ = `Beezvalley_${key}`
  if (Capacitor.isNativePlatform()) {
    return new Promise((resolve, reject) => {
      SecureKeyStore.remove((value: string) => {
        resolve(value)
      }, (e: string) => {
        // console.error('secure-get', key, e)
        resolve(undefined)
      },
      key_)
    })
  } else {
    localStorage.removeItem(key_)
  }
}