import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { onMessageSummaryUpdated } from './message-info-gql'

export type MesgSummaryCallback = (mesg: types.MessageSummary) => void

export function subscribeOnMessageSummaryUpdated (client: ClientType, chatRoomId: number, cb: MesgSummaryCallback, messageId = 0, userId = 0): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onMessageSummaryUpdated,
    variables: { chatRoomId, messageId, userId },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      debug.log('=> onMessageSummaryUpdated')
      const newMesg = data.onMessageSummaryUpdated as types.MessageSummary
      cb(newMesg)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
