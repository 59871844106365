
import Vue from 'vue'

import { types } from '@dinosband/bv-main-client/src/types'
import { signOut } from '@/auth'

export default Vue.extend({
  data () {
    return {
      users: new Array<types.User>(),
      channels: new Array<types.Channel>(),
    }
  },

  methods: {
    onSignOut () {
      signOut()
    },

    onReport () {
      this.$dbiRouter.push(this, 'BvaAbusingReports')
    },
  },
})
