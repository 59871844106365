import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyUserPointLog } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readUserPointLogs (client: ClientType, channelId: number, userId: number, cursor: number, take: number): Promise<types.UserPointLog[] | undefined> {
  try {
    const result = await client.query({
      query: findManyUserPointLog,
      variables: {
        where: {
          channelId: { equals: channelId },
          userId: { equals: userId },
          id: cursor ? { lt: cursor } : undefined,
        },
        orderBy: [
          {
            id: types.SortOrder.Desc,
          },
        ],
        take,
      } as types.QueryFindManyUserPointLogArgs,
    })
    return result.data.findManyUser
  } catch (e) {
  }
}