import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'

export const q_readPayments = gql`
  query q_readPayments($where: PaymentWhereInput!, $orderBy: [PaymentOrderByWithRelationInput!], $cursor: PaymentWhereUniqueInput, $skip: Int, $take: Int) {
    findManyPayment(where: $where, orderBy: $orderBy, cursor: $cursor, skip: $skip, take: $take) {
      ...PaymentFields
      jobResponse {
        ...JobOfferResponseFields
      }
      commerceOrder {
        ...CommerceOrderFields
        product {
          ...CommerceProductFields
        }
      }
    }
  }
  ${client.PaymentFields}
  ${client.JobOfferResponseFields}
  ${client.CommerceOrderFields}
  ${client.CommerceProductFields}
`
