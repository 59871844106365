import { ClientType } from '../apollo'
import { types } from '../types'

import { _findManyChannelExpert } from './expert-gql'

export async function readChannelExperts (client: ClientType, channelId: number): Promise<types.ChannelExpert[] | undefined> {
  try {
    const result = await client.query({
      query: _findManyChannelExpert,
      variables: {
        where: {
          id: { equals: channelId },
        },
      },
    })
    return result.data.findManyChannelExpert
  } catch (e) {
  }
}

