import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueChannel } from '@dinosband/bv-main-schema/dist/client-gql'
import { findUniqueChannelGraph, findChannelByName } from './channel-gql'

// export function option_readChannel (id: number) {
//   return {
//     query: findUniqueChannel,
//     variables: {
//       where: {
//         id,
//       },
//     } as types.QueryFindUniqueChannelArgs,
//   }
// }

export async function readChannel (client: ClientType, id: number): Promise<types.Channel | undefined> {
  try {
    const result = await client.query({
      query: findUniqueChannel,
      variables: {
        where: {
          id,
        },
      } as types.QueryFindUniqueChannelArgs,
    })
    return result.data.findUniqueChannel
  } catch (e) {
  }
}

export async function readChannelDetail (client: ClientType, id: number): Promise<types.Channel | undefined> {
  try {
    const result = await client.query({
      query: findUniqueChannelGraph,
      variables: {
        where: {
          id,
        },
      } as types.QueryFindUniqueChannelArgs,
    })
    return result.data.findUniqueChannel
  } catch (e) {
  }
}

export async function readChannelByName (client: ClientType, name: string) {
  try {
    const result = await client.query<{findUniqueChannel: types.Channel}>({
      query: findChannelByName,
      variables: {
        where: {
          name,
        },
      },
    })
    return result.data.findUniqueChannel
  } catch (e) {
  }
}
