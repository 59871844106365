import { ClientType } from '../apollo'
import { MutationResult, ApolloQueryResult, types } from '../types'

import { m_updateOneUser, m_updateMyPhoneNumber } from './user-gql'

interface Mutations {
  updateMyPhoneNumber: types.UpdateMyPhoneNumberOutput
}

type Result = ApolloQueryResult<Mutations>

export async function updateUser (client: ClientType, id: number, data: types.UserUpdateInput): Promise<types.User | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_updateOneUser,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneUserArgs,
    }) as MutationResult
    return result.data.updateOneUser
  } catch (e) {
  }
}

export async function updateMyPhoneNumber (client: ClientType, tel: string, code: string): Promise<types.UpdateMyPhoneNumberOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_updateMyPhoneNumber,
      variables: {
        input: { tel, code },
      },
    }) as Result
    return result.data.updateMyPhoneNumber
  } catch (e) {
  }
}
