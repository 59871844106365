import {
  actionSheetController,
  alertController,
  loadingController,
  menuController,
  toastController,
  modalController,
  popoverController,
} from '@ionic/core'

import { NavController } from './nav-controller'
import { VueDelegate } from '../utils'

export interface IonicControllers {
  actionSheetController: typeof actionSheetController
  alertController: typeof alertController
  loadingController: typeof loadingController
  menuController: typeof menuController
  toastController: typeof toastController
  modalController: typeof modalController
  popoverController: typeof popoverController
  navController: NavController
}

interface IonicControllerInterface {
  /* eslint-disable-next-line */
  create(opts: any): Promise<HTMLElement>
}

function createController (baseController: IonicControllerInterface, delegate: VueDelegate): IonicControllerInterface {
  return Object.assign({}, baseController, {
    create (opts: Record<string, unknown>) {
      return baseController.create(Object.assign({}, opts, { delegate }))
    },
  })
}

export function createControllers (delegate: VueDelegate): IonicControllers {
  return {
    actionSheetController,
    alertController,
    loadingController,
    menuController,
    toastController,
    modalController: createController(modalController, delegate) as typeof modalController,
    popoverController: createController(popoverController, delegate) as typeof popoverController,
    navController: new NavController(),
  }
}
