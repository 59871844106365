
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { apollo, types } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'
import { convertToHtml } from '../tiptap/tiptap'

export default Vue.extend({
  data () {
    return {
      html: '',
      notice: undefined as types.SysNotice | undefined,
    }
  },

  computed: {
    subject (): string {
      return this.notice?.subject || ''
    },
  },

  async created () {
    if (this.routeParams?.id) {
      const noticeId = parseInt(this.routeParams?.id)
      this.notice = await apollo.readSysNotice(await apc.getClient(), noticeId)
      if (this.notice) {
        await this.updateHtml()
      }
    }
  },

  methods: {
    async updateHtml () {
      const json = this.notice?.content || ''
      const html = this.notice?.html || ''
      this.html = await convertToHtml({ json, html })
    },

    async onEdit () {
      const updated = await this.$dbIon.doModal(this, 'BvaNoticeCreate', {
        notice: this.notice,
      }) as types.SysNotice
      if (updated) {
        this.notice = updated
        await this.updateHtml()
      }
    },
  },
})
