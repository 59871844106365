import { BvApolloClient } from '@dinosband/bv-main-client'
import { initApolloClient, setApolloClientForPicture } from '@dinosband/bv-attachment'

export const apc = new BvApolloClient()

export async function initializeApollo (httpURI: string, wsURI: string) {
  apc.onError((error) => {
    // console.error('ApolloError: ', error)
  })
  await apc.createApolloClient(httpURI, wsURI)
  setApolloClientForPicture(apc)
}

export const mapc = new BvApolloClient()

export async function initializeMessengerApollo (httpURI: string, wsURI: string) {
  const server = { httpURI, wsURI }
  const token = apc.getAuthToken()
  mapc.setAuthToken(token)
  await mapc.createApolloClient(server.httpURI, server.wsURI)

  initApolloClient({ s3: apc, broadcast: mapc })
}