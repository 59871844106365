
import Vue from 'vue'

import { types, apollo } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'

export default Vue.extend({
  data () {
    return {
      users: [] as types.User[],
    }
  },

  async mounted () {
    const users = await apollo.readAllUsers(await apc.getClient()) ?? []
    this.users = users.sort((a, b) => a.id - b.id)
  },

  methods: {
    async onClickUser (user: types.User) {
    },
  },
})
