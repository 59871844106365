/**
 * @param str The string to trim.
 * @param char A character to trim.
 * @return Returns the trimmed string.
 */
export function _trim (str: string | undefined, char: string): string |undefined {
  return _trimStart(_trimEnd(str, char), char)
}

/**
 * @param str The string to trim.
 * @param char A character to trim.
 * @return Returns the trimmed string.
 */
export function _trimStart (str: string | undefined, char: string): string | undefined {
  if (!str) {
    return str
  }
  const length = str.length
  let start = 0
  while (start < length && str[start] === char) {
    ++start
  }
  return 0 < start ? str.substring(start) : str
}

/**
 * @param str The string to trim.
 * @param char A character to trim.
 * @return Returns the trimmed string.
 */
export function _trimEnd (str: string | undefined, char: string): string | undefined {
  if (!str) {
    return str
  }
  let end = str.length
  while (end > 0 && str[end - 1] === char) {
    --end
  }
  return end < str.length ? str.substring(0, end) : str
}