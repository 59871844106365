import { WebPlugin } from '@capacitor/core'
import { DBIFilesPlugin } from './definitions'

export class DBIFilesWeb extends WebPlugin implements DBIFilesPlugin {

  async view (options: { path: string, type: string }): Promise<{ value: string }> {
    return { value: 'success' }
  }
  async share (options: { path: string, type: string }): Promise<{ value: string }> {
    return { value: 'success' }
  }
  async download (options: { url: string, filename: string }): Promise<{ value: string }> {
    return { value: 'success' }
  }
}