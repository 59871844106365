import axios, { AxiosRequestConfig } from 'axios'
import { Capacitor } from '@capacitor/core'
import { DBIFiles } from '@dinosband/dbi-capacitor-plugin-files'
import { cacheBlob } from './file'


export function downloadAction (url_or_blob: string | Blob, filename: string, type: string) {
  let url: string
  if (typeof url_or_blob === 'string') {
    url = url_or_blob
  } else { // Blob
    url = URL.createObjectURL(url_or_blob)
  }
  if (Capacitor.isNativePlatform()) {
    const params = { url, filename }
    if (Capacitor.getPlatform() == 'android') {
      DBIFiles.download(params)
    }
  } else {
    downloadURL(url, filename, type)
  }
}

export function cacheURL (url: string, filename: string, type: string, progress?: (progress: number) => void): Promise<{path: string}> {
  const config: AxiosRequestConfig = {
    responseType: 'blob',
    headers: { 'Content-Type': type },
    onDownloadProgress: (progressEvent: { loaded: number }) => {
      if (progress) { progress(progressEvent.loaded) }
    },
  }
  return axios.get(url, config)
    .then((response) => cacheBlob(response.data, filename, type))
    .then((result) => {
      const file = new URL(result).pathname
      return { path: decodeURI(file) }
    })
}

// function downloadURLOrg (url: string, filename?: string, type?: string) {
//   const link = document.createElement('a')
//   link.href = url
//   link.target = '_blank'
//   if (type) {
//     link.type = type
//   }
//   if (filename) {
//     link.download = filename
//   }
//   link.click()
// }

// downloadURL: filename이 적용될 수 있도록 xhr을 이용하여 data를 읽어와서 저장하도록 함
function downloadURL (url: string, filename?: string, type?: string) {
  const xhr = new XMLHttpRequest()

  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = () => {
    const file = new Blob([xhr.response], { type: type ?? 'application/octet-stream' })
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(file)
    a.target = '_blank'
    if (filename) {
      a.download = filename // Set to whatever file name you want
    }
    // Now just click the link you created
    // Note that you may have to append the a element to the body somewhere
    // for this to work in Firefox
    a.click()
  }
  xhr.send()
}
