import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'

export const findManyUser_ChatRoomViewFields = gql`
  query findManyUser_ChatRoomViewFields(
    $where: User_ChatRoomViewWhereInput
    $orderBy: [User_ChatRoomViewOrderByWithRelationInput!]
    $cursor: User_ChatRoomViewWhereUniqueInput
    $skip: Int
    $take: Int
  ) {
    findManyUser_ChatRoomView(where: $where, orderBy: $orderBy, cursor: $cursor, skip: $skip, take: $take) {
      ...User_ChatRoomViewFields
    }
  }
  ${client.User_ChatRoomViewFields}
`

export const upsertUser_ChatRoomView = gql`
  mutation upsertUser_ChatRoomView($chatRoomId: Int!) {
    upsertUser_ChatRoomView(chatRoomId: $chatRoomId) {
      ...User_ChatRoomViewFields
    }
  }
  ${client.User_ChatRoomViewFields}
`

export const updateOneUser_ChatRoomView_ = gql`
  mutation updateOneUser_ChatRoomView_($id: Int!, $data: User_ChatRoomViewUpdateInput!) {
    updateOneUser_ChatRoomView(where: { id: $id }, data: $data) {
      ...User_ChatRoomViewFields
    }
  }
  ${client.User_ChatRoomViewFields}
`

export const onUser_ChatRoomViewUpdated = gql`
  subscription onUser_ChatRoomViewUpdated($chatRoomId: Int, $userId: Int) {
    onUser_ChatRoomViewUpdated(chatRoomId: $chatRoomId, userId: $userId) {
      ...User_ChatRoomViewFields
    }
  }
  ${client.User_ChatRoomViewFields}
`

export const onUser_ChatRoomViewAdded = gql`
  subscription onUser_ChatRoomViewAdded($chatRoomId: Int, $userId: Int) {
    onUser_ChatRoomViewAdded(chatRoomId: $chatRoomId, userId: $userId) {
      ...User_ChatRoomViewFields
    }
  }
  ${client.User_ChatRoomViewFields}
`
