
import { Vue, Component, Prop } from 'vue-property-decorator'

export interface NamedItem {
  name: string
}

@Component
export default class DbChip extends Vue {
  @Prop({ default: '' }) label!: string
  @Prop() item!: NamedItem
  @Prop({ default: false }) small!: boolean
}
