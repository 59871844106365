import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
// import * as frag from '../fragments-gql'

export const findManyChatRoomMenusWithChatRooms = gql`
  query findManyChatRoomMenusWithChatRooms($where: ChannelChatRoomMenuWhereInput) {
    findManyChannelChatRoomMenu(where: $where) {
      ...ChannelChatRoomMenuFields
      chatRoom {
        ...ChatRoomFields
      }
    }
  }
  ${client.ChannelChatRoomMenuFields}
  ${client.ChatRoomFields}
`

export const createOneChannelChatRoomMenu = gql`
  mutation createOneChannelChatRoomMenu($where: ChannelChatRoomMenuWhereUniqueInput!) {
    createOneChannelChatRoomMenu(where: $where) {
      ...ChannelChatRoomMenuFields
      chatRoom {
        ...ChatRoomFields
      }
    }
  }
  ${client.ChannelChatRoomMenuFields}
  ${client.ChatRoomFields}
`

export const deleteOneChannelChatRoomMenu = gql`
  mutation deleteOneChannelChatRoomMenu($where: ChannelChatRoomMenuWhereUniqueInput!) {
    deleteOneChannelChatRoomMenu(where: $where) {
      id
      channelId
    }
  }
`

export const onChannelChatRoomMenuAdded = gql`
  subscription onChannelChatRoomMenuAdded($channelIds: [Int!]!) {
    onChannelChatRoomMenuAdded(channelIds: $channelIds) {
      ...ChannelChatRoomMenuFields
      chatRoom {
        ...ChatRoomFields
      }
    }
  }
  ${client.ChannelChatRoomMenuFields}
  ${client.ChatRoomFields}
`

export const onChannelChatRoomMenuUpdated = gql`
  subscription onChannelChatRoomMenuUpdated($channelIds: [Int!]!) {
    onChannelChatRoomMenuUpdated(channelIds: $channelIds) {
      ...ChannelChatRoomMenuFields
    }
  }
  ${client.ChannelChatRoomMenuFields}
`

export const onChannelChatRoomMenuDeleted = gql`
  subscription onChannelChatRoomMenuDeleted($channelIds: [Int!]!) {
    onChannelChatRoomMenuDeleted(channelIds: $channelIds) {
      id
      channelId
    }
  }
`
