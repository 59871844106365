"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSettableNotiType = exports.canAddChatRoomNotification = exports.canSetChatRoomNotification = exports.canChatRoomBeListedOnMenu = exports.checkChatRoomPermission = void 0;
const chat_room_1 = require("../chat-room");
const types = __importStar(require("../types"));
const notification_1 = require("./notification");
function checkChatRoomPermission(args) {
    const { chatRoomId, status, restricted, profileId, channelExperts, expertInfos, participants, isAdmin, passwordApprovedChatRoomIds, accessType = 'enter', } = args;
    if (status !== types.ChatRoomStatus.OPENED) {
        return 'initialize';
    }
    if (participants && participants.length > 0) {
        if (participants.find((p) => p.profileId === profileId)) {
            return 'ok';
        }
        else {
            return 'participate';
        }
    }
    if (isAdmin) {
        return 'ok';
    }
    if (restricted) {
        const criteria = JSON.parse(restricted);
        const checkPermission = () => {
            if (criteria.expertId) {
                const expert = channelExperts.find((exp) => exp.id === criteria.expertId);
                if (!expert.disabled) {
                    if (expertInfos === null || expertInfos === void 0 ? void 0 : expertInfos.find((info) => info.approvedAt && info.channelExpertId === criteria.expertId)) {
                        return 'ok';
                    }
                    else {
                        return 'expert';
                    }
                }
            }
            else if (criteria.password) {
                if (passwordApprovedChatRoomIds) {
                    const ids = passwordApprovedChatRoomIds.split(',').map((id) => parseInt(id));
                    return ids.includes(chatRoomId) ? 'ok' : 'password';
                }
                else {
                    return 'password';
                }
            }
            return 'ok';
        };
        const result = checkPermission();
        if (result !== 'ok') {
            return result;
        }
        // 전문가 제한 목록이 있는 경우 처리
        const expertIds = expertInfos ? expertInfos
            .map((i) => i.channelExpertId)
            : [];
        const approvedExpertIds = expertInfos ? expertInfos
            .filter((i) => i.approvedAt && !i.deniedAt)
            .map((i) => i.channelExpertId)
            : [];
        const matchExpertIds = (allowedExpertIds) => {
            if (!allowedExpertIds) {
                return true;
            }
            if (allowedExpertIds.length === 0) {
                return false;
            }
            else {
                if (expertIds.length === 0) {
                    // non-expert user
                    return allowedExpertIds.includes(0);
                }
                else {
                    for (const id of approvedExpertIds) {
                        if (allowedExpertIds.includes(id)) {
                            return true;
                        }
                    }
                }
                return false;
            }
        };
        switch (accessType) {
            case 'enter':
                return 'ok';
            case 'write':
                return matchExpertIds(criteria === null || criteria === void 0 ? void 0 : criteria.writerExpertIds) ? 'ok' : 'expert';
            case 'apply':
                return matchExpertIds(criteria === null || criteria === void 0 ? void 0 : criteria.providerExpertIds) ? 'ok' : 'expert';
        }
    }
    return 'ok';
}
exports.checkChatRoomPermission = checkChatRoomPermission;
function canChatRoomBeListedOnMenu(type) {
    return !(0, chat_room_1.isPrivateChatRoom)(type);
}
exports.canChatRoomBeListedOnMenu = canChatRoomBeListedOnMenu;
function canSetChatRoomNotification(type) {
    return [
        types.ChatRoomType.GENERAL,
        types.ChatRoomType.JOB_OFFER,
    ].includes(type);
}
exports.canSetChatRoomNotification = canSetChatRoomNotification;
function canAddChatRoomNotification(type) {
    if (canSetChatRoomNotification(type)) {
        if ((0, notification_1.getDefaultNotification)(type, types.MesgNotificationType.New)) {
            return true;
        }
        if ((0, notification_1.getDefaultNotification)(type, types.MesgNotificationType.Keyword)) {
            return true;
        }
    }
    return false;
}
exports.canAddChatRoomNotification = canAddChatRoomNotification;
function isSettableNotiType(chatRoomType, notiType) {
    return canSetChatRoomNotification(chatRoomType) && [
        types.MesgNotificationType.New,
        types.MesgNotificationType.Keyword,
    ].includes(notiType);
}
exports.isSettableNotiType = isSettableNotiType;
