import { ClientType } from '../apollo'
import { types } from '../types'

import {
  findManySettlement,
  findUniqueSettlement,
} from '@dinosband/bv-main-schema/dist/client-gql'

export async function readSettlements (client: ClientType, where: types.SettlementWhereInput): Promise<types.Settlement[] | undefined> {
  try {
    const result = await client.query({
      query: findManySettlement,
      variables: {
        where,
      } as types.QueryFindManySettlementArgs,
    })
    return result.data.findManySettlement
  } catch (e) {
  }
}

export async function readSettlement (client: ClientType, id: number): Promise<types.Settlement | undefined> {
  try {
    const result = await client.query({
      query: findUniqueSettlement,
      variables: {
        where: { id },
      } as types.QueryFindUniqueSettlementArgs,
    })
    return result.data.findUniqueSettlement
  } catch (e) {
  }
}
