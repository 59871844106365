import Vue from 'vue'
import { Components, NavComponentWithProps, ViewController } from '@ionic/core'

export class NavController {
  _getNav (vueComp: Vue): Components.IonNav | undefined {
    const nav = vueComp.$el.closest('ion-nav') as Components.IonNav | undefined
    return nav
    // return vueComp.$el.closest('ion-nav') as Components.IonNav | undefined
  }

  async push (vueComp: Vue, component: string, params: Record<string, unknown>): Promise<void> {
    const nav = this._getNav(vueComp)
    if (nav) {
      await nav.push(component, params)
      const tabs = vueComp.$el.closest('ion-tabs')
      if (tabs) {
        tabs.dispatchEvent(new CustomEvent('onNavPush'))
      }
    } else {
      console.error('Nav.push: failed to find ion-nav')
    }
  }

  async pop (vueComp: Vue): Promise<void> {
    await this._getNav(vueComp)?.pop()
  }

  async popToRoot (vueComp: Vue): Promise<void> {
    await this._getNav(vueComp)?.popToRoot()
  }

  async setPages (vueComp: Vue, views: NavComponentWithProps[]): Promise<boolean> {
    const nav = this._getNav(vueComp)
    if (nav) {
      return await nav.setPages(views)
    } else {
      return false
    }
  }

  async getActive (vueComp: Vue): Promise<ViewController | undefined> {
    const nav = this._getNav(vueComp)
    if (nav) {
      return await nav.getActive()
    } else {
      return undefined
    }
  }


  async canGoBack (vueComp: Vue): Promise<boolean> {
    const nav = this._getNav(vueComp)
    if (nav) {
      const active = await nav.getActive()
      if (active?.nav) {
        return await nav.canGoBack()
      } else {
        return false
      }
      // return active != undefined
      // // nav.canGoBack() works correctly
      // const can = await nav.canGoBack()
      // return can
      // Since nav.canGoBack() returns false for the first view, we use nav.getActive() instead.
      // const active = await nav.getActive()
      // return active != undefined
    } else {
      return false
    }
  }
}
