"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertSysNotiToString = void 0;
const types = __importStar(require("./types"));
const i18n_1 = require("./i18n");
function convertSysNotiToString(noti, lang, dict) {
    const info = JSON.parse(noti.info);
    switch (noti.type) {
        case types.SysNotificationType.CH_EXPERT_REQUESTED:
            return (0, i18n_1.i18n)(lang, 'noti.ch-expert-requested', {
                channelName: translate(dict, info.channelName),
                expertName: translate(dict, info.expertName),
                requester: info.requesterName,
            });
        case types.SysNotificationType.CH_EXPERT_APPROVED:
            return (0, i18n_1.i18n)(lang, 'noti.ch-expert-approved', {
                channelName: translate(dict, info.channelName),
                expertName: translate(dict, info.expertName),
            });
        case types.SysNotificationType.CH_EXPERT_DENIED:
            return (0, i18n_1.i18n)(lang, 'noti.ch-expert-denied', {
                channelName: translate(dict, info.channelName),
                expertName: translate(dict, info.expertName),
            });
        case types.SysNotificationType.CH_ADMIN_ASSIGNED:
            return (0, i18n_1.i18n)(lang, 'noti.ch-admin-assigned', {
                channelName: translate(dict, info.channelName),
            });
        case types.SysNotificationType.CH_ADMIN_REMOVED:
            return (0, i18n_1.i18n)(lang, 'noti.ch-admin-removed', {
                channelName: translate(dict, info.channelName),
            });
        case types.SysNotificationType.CH_BECAME_OWNER:
            return (0, i18n_1.i18n)(lang, 'noti.ch-became-owner', {
                channelName: translate(dict, info.channelName),
            });
        case types.SysNotificationType.CH_EXPELLED:
            return (0, i18n_1.i18n)(lang, 'noti.ch-expelled', {
                channelName: translate(dict, info.channelName),
            });
        case types.SysNotificationType.CH_SUSPENDED:
            return (0, i18n_1.i18n)(lang, 'noti.ch-suspended', {
                channelName: translate(dict, info.channelName),
            });
        case types.SysNotificationType.CH_MESG_SCREENED:
            return (0, i18n_1.i18n)(lang, 'noti.ch-expert-requested', {});
        case types.SysNotificationType.JOB_OFFER_DELIEVERED:
            return (0, i18n_1.i18n)(lang, 'noti.job-offer.delievered', {
                count: info.count,
            });
        case types.SysNotificationType.JOB_OFFER_HIRED:
            return (0, i18n_1.i18n)(lang, 'noti.job-offer.hired', {
                client: info.clientName,
            });
        case types.SysNotificationType.JOB_OFFER_FIRED:
            return (0, i18n_1.i18n)(lang, 'noti.job-offer.fired', {
                client: info.clientName,
            });
        case types.SysNotificationType.JOB_OFFER_CANCEL_CANCELED:
            return (0, i18n_1.i18n)(lang, 'noti.job-offer.cancel-canceled', {
                issuer: info.issuerName,
            });
        case types.SysNotificationType.JOB_OFFER_AGREE_CANCELED:
            return (0, i18n_1.i18n)(lang, 'noti.job-offer.agree-canceled', {
                issuer: info.issuerName,
            });
        case types.SysNotificationType.JOB_OFFER_CLOSED:
            return (0, i18n_1.i18n)(lang, 'noti.job-offer.closed', {
                client: info.clientName,
            });
        case types.SysNotificationType.JOB_OFFER_REOPENED:
            return (0, i18n_1.i18n)(lang, 'noti.job-offer.reopened', {
                client: info.clientName,
            });
        case types.SysNotificationType.JOB_OFFER_FINISHED:
            return (0, i18n_1.i18n)(lang, 'noti.job-offer.finished', {
                client: info.clientName,
            });
        case types.SysNotificationType.JOB_OFFER_REVIEWED:
            return (0, i18n_1.i18n)(lang, 'noti.job-offer.reviewed', {
                reviewer: info.reviewerName,
            });
        case types.SysNotificationType.SYS_ACCOUNT_SUSPENDED:
            return (0, i18n_1.i18n)(lang, 'noti.sys.account-suspended', {
                reason: info.reason,
            });
        case types.SysNotificationType.SYS_MESG_DELETED:
            return (0, i18n_1.i18n)(lang, 'noti.ch-mesg-screened', {});
        case types.SysNotificationType.SYS_REPORT_PROCESSED:
            if (info.channelName) {
                return (0, i18n_1.i18n)(lang, 'noti.sys.report.channel', {
                    channelName: translate(dict, info.channelName),
                });
            }
            if (info.profileName) {
                return (0, i18n_1.i18n)(lang, 'noti.sys.report.profile', {
                    profile: info.profileName,
                });
            }
            return (0, i18n_1.i18n)(lang, 'noti.sys.report.channel', {
                at: info.createdAt,
            });
        case types.SysNotificationType.SYS_GOT_USER_PENALTY: {
            const penalty = info;
            switch (penalty.type) {
                case 'expel':
                    return (0, i18n_1.i18n)(lang, 'noti.sys.penalty.expel', {});
                case 'suspend':
                    return (0, i18n_1.i18n)(lang, 'noti.sys.penalty.expel', {
                        hours: penalty.hours.toString(),
                    });
                case 'warning': {
                    let reason = (0, i18n_1.i18n)(lang, 'noti.sys.penalty.warning.unknown');
                    if (penalty.props) {
                        const warning = JSON.parse(penalty.props);
                        switch (warning.reason) {
                            case 'message':
                                reason = (0, i18n_1.i18n)(lang, 'noti.sys.penalty.warning.message');
                                break;
                            case 'channel':
                                reason = (0, i18n_1.i18n)(lang, 'noti.sys.penalty.warning.channel');
                                break;
                        }
                    }
                    return (0, i18n_1.i18n)(lang, 'noti.sys.penalty.warning', {
                        reason,
                    });
                }
                case 'release':
                    return (0, i18n_1.i18n)(lang, 'noti.sys.penalty.release', {});
                default:
                    return (0, i18n_1.i18n)(lang, 'noti.sys.penalty.unknown', {});
            }
        }
        default:
            return '';
    }
}
exports.convertSysNotiToString = convertSysNotiToString;
function translate(dict, str) {
    var _a;
    return (_a = dict === null || dict === void 0 ? void 0 : dict.dict[str]) !== null && _a !== void 0 ? _a : str;
}
