
import Vue from 'vue'
// import { isPlatform } from '@ionic/core'
import { eventBus } from '../utils/event-bus'

class Options {
  title = ''
  components: Record<string, unknown>[] = []
  footerText = ''
  useRefresher = false
  canGoBack = false
  showMenuButton = false
  independantScroll = false
}

interface CustomScrollEvent {
  target: { scrollTop: number }
  detail: { scrollTop: number }
}

export default Vue.extend({
  props: {
    params: {
      type: Object,
      default () { return {} },
    },
    title: {
      type: String,
      default: '',
    },
    components: {
      type: Array,
      default: undefined,
    },
    footerText: {
      type: String,
      default: undefined,
    },
    useRefresher: {
      type: Boolean,
      default: false,
    },
    backMeansApply: {
      type: Boolean,
      default: true,
    },
    labelBack: {
      type: String,
      default: 'Back',
    },
    showMenuButton: {
      type: Boolean,
      // default: !isPlatform('desktop'),
      default: true,
    },
    independantScroll: {
      type: Boolean,
      default: false,
    },
    catchScroll: {
      type: Boolean,
      default: false,
    },
    scrollY: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      siteRight: this.$dbIon.siteConfig?.right,
      scrollTop: 0,
      canGoBack: false,
      opts: new Options(),
    }
  },

  async created () {
    // dismiss loading controller if it is still running
    await this.$dbIon.dismissLoading()

    this.opts = Object.assign({}, this.$props, this.params) as Options
    this.canGoBack = await this.$dbiRouter.canGoBack(this)
    /*
    this.opts = {
      title: this.title,
      components: this.components,
      footerText: this.footerText,
      useRefresher: this.useRefresher,
      canGoBack: this.canGoBack,
      showMenuButton: this.showMenuButton,
    }
    if (this.params) {
      Object.assign(this.opts, this.params)
    }
    */
  },

  mounted () {
    if (this.opts.independantScroll) {
      (this.$refs.outerContainer as HTMLElement).style.height = '100%'
      ;(this.$refs.mainContainer as HTMLElement).style.overflow = 'auto'
      ;(this.$refs.rightContainer as HTMLElement).style.overflow = 'auto'
    } else {
      (this.$refs.outerContainer as HTMLElement).style.overflow = 'auto'
    }
  },

  methods: {
    onBack () {
      this.$emit('back')
      this.$dbiRouter.pop(this, this.backMeansApply)
    },

    onScrollStart () {
    },

    onScroll (e: CustomScrollEvent) {
      let scrollTop = 0
      if (this.opts.independantScroll) {
        scrollTop = e.target.scrollTop
      } else {
        scrollTop = e.detail.scrollTop
      }
      if (scrollTop == 0) {
        eventBus.$emit('scrollAtTop', true)
      } else if (this.scrollTop == 0) {
        eventBus.$emit('scrollAtTop', false)
      }
      this.scrollTop = scrollTop
    },

    onScrollEnd () {
    },
  },
})
