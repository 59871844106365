
import Vue from 'vue'
// import { Components } from '@ionic/core'
import { store as routerStore } from '@dinosband/dbi-router'

interface MenuNavItem {
  label: string
  path: string
  icon?: string
  key?: string
}

export default Vue.extend({
  props: {
    item: {
      type: Object as () => MenuNavItem | string,
      required: true,
    },
    currentPath: {
      type: String,
      required: true,
    },
    isDarkMode: {
      type: Boolean,
      required: true,
    },
    keepChildView: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      label: '',
      path: '',
      icon: '',
      key: '',
    }
  },

  computed: {
    style (): Record<string, string> {
      return {}
      // if (this.currentPath.startsWith(this.path)) {
      //   return {
      //     '--background': 'var(--ion-color-light)',
      //   }
      // } else {
      //   return {}
      // }
    },

    iconName (): string {
      if (this.icon) {
        let icon = this.icon
        if (this.currentPath.startsWith(this.path)) {
          icon = icon.replace('str', 'fill')
        }
        const prefix = this.isDarkMode ? 'dark-' : ''
        return prefix + icon
      }
      return ''
    },

    badge (): string | number | undefined {
      return routerStore.getBadge(this.key)
    },
  },

  created () {
    if (typeof this.item === 'string') {
      this.path = this.item
    } else {
      this.label = this.item.label
      this.path = this.item.path
      this.icon = this.item.icon
      this.key = this.item.key
      return
    }
    if (this.path !== '') {
      const node = this.$dbiRouter.getRouteTreeFromPath(this.path).getTopMostNode().getBottomNode()
      const route = node.route
      // const route = this.$dbiRouter.getRouteFromPath(this.path)
      if (route) {
        this.label = route.label || ''
        this.icon = route.icon || ''
      }
      this.key = node.key
    }
  },

  methods: {
    onClick (): void {
      if (this.keepChildView) {
        this.$dbiRouter.go(this.path)
      } else {
        this.$dbiRouter.resetAndGo(this.path)
      }
      // const side = document.getElementById('side') as unknown as Components.IonMenu
      // side.close()
    },
  },
})
