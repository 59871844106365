import type { ApolloClient, NormalizedCacheObject } from '@apollo/client/core'

export type ClientType = ApolloClient<NormalizedCacheObject>
export type ReconnectedCb = () => void
export type ErrorCb = (error: string) => void

export const EV_RECONNECTED = 'reconnected'
export const EV_RECONNECTING = 'reconnecting'
export const EV_ERROR = 'error'

export interface SubscriptionClientType {
  OnReconnecting: (cb: () => void) => void
  OnReconnected: (cb: () => void) => void
}
