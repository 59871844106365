
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { Components } from '@ionic/core'

export default Vue.extend({
  props: {
    enableTop: {
      type: Boolean,
      default: false,
    },

    enableBottom: {
      type: Boolean,
      default: true,
    },

    moreTop: {
      type: Boolean,
      default: false,
    },

    moreBottom: {
      type: Boolean,
      default: true,
    },

    loadMoreTop: {
      type: Function as () => Promise<void>,
      default: undefined,
    },

    loadMoreBottom: {
      type: Function as () => Promise<void>,
      default: undefined,
    },

    threshold: {
      type: Number,
      default: 10,
    },

    spinner: {
      type: String,
      default: 'lines',
    },

    text: {
      type: String,
      default: i18n.tc('strings.src.componentsDbInfiniteScroll'),
    },

    scrollEvents: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    thrStr (): string {
      return `${this.threshold}px`
    },
  },

  async mounted () {
    await this.$nextTick()
    const container = this.$refs.container
    if (container) {
      container.scrollTop = 0
    }
  },

  methods: {
    async onLoadMoreTop (ev: CustomEvent) {
      const target = ev.target as Components.IonInfiniteScrollContent
      if (this.loadMoreTop) {
        await this.loadMoreTop()
        target.complete()
      }
    },

    async onLoadMoreBottom (ev: CustomEvent) {
      const target = ev.target as Components.IonInfiniteScrollContent
      if (this.loadMoreBottom) {
        await this.loadMoreBottom()
        target.complete()
      }
    },

    onScroll (ev: CustomEvent<{scrollTop: number}>) {
      this.$emit('scroll', ev.detail.scrollTop)
    },
  },
})
