class AssetManager {
  _assets = new Map<string, string>()
  _globalAssets = ''

  setGlobalAssets (path: string): void {
    this._globalAssets = path
  }

  registerAssets (reqContext: __WebpackModuleApi.RequireContext): void {
    reqContext.keys().forEach((fileName: string) => {
      const name = fileName.slice(fileName.lastIndexOf('/') + 1)
      const context = reqContext(fileName)
      this._assets.set(name, context)
      // this._assets.set(name, reqContext(fileName))
    })
  }

  addAsset (name: string, data: string) {
    this._assets.set(name, data)
  }

  addAssets (assets: Record<string, string>) {
    Object.keys(assets).forEach((name)=> {
      this.addAsset(name, assets[name])
    })
  }

  getUrl (name: string): string | undefined {
    if (name.startsWith('@/')) {
      return `~${this._globalAssets}/${name.slice(2)}`
    }
    return this._assets.get(name)
  }
}

export const assetManager = new AssetManager()
