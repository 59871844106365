import { AttachmentInput, AttachmentType } from '../types'
import * as upload from '../core/upload'
import * as error from '../errors'

// requestAttachmentFile: request File Upload Url
// for profile_image, channel_image, general_image, banner_image
export async function requestAttachmentFile (file: File, type: AttachmentType, thumbnail?: string | ArrayBuffer) {
  const domain = fileDomain(type)
  const url = await upload.requestFileUploadURL(file, domain)
  const attachment: AttachmentInput = {
    file: file,
    title: file.name,
    thumbnail: thumbnail as string,
    size: file.size,
    type: file.type ?? '',
    url,
  }
  return attachment
}

// uploadAttachmentFile
// for profile_image, channel_image, general_image, banner_image
export async function uploadAttachmentFile (attachment: { url?: string, file?: File }, type: AttachmentType) {
  const url = attachment.url
  const file = attachment.file
  if (!url || !file) {
    console.error('uploadAttachment', 'no file')
    throw error.Error_No_File
  }
  const domain = fileDomain(type)
  await upload.uploadFile(file, domain, url, (progress: number) => {
  }).catch((e) => {
    // updateUploadingState(url, AttachmentState.ERROR, { file, chatRoomId })
    throw e
  })
}

function fileDomain (type: AttachmentType) {
  if (type == AttachmentType.PROFILE_IMAGE) {
    return 'profiles'
  } else if (type == AttachmentType.CHANNEL_IMAGE) {
    return 'channels'
  } else if (type == AttachmentType.GENERAL_IMAGE) {
    return 'general'
  } else if (type == AttachmentType.BANNER_IMAGE) {
    return 'banner'
  }


  return 'files'
}