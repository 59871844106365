
import { makeImageThumbnail } from './image'
import { makeVideoSnapshot, isVideoType } from './video'

export async function makeThumbnail (file: Blob | string, maxWidth = 192, maxHeight = 192, cropAspectRatio?: number, quality?: number): Promise<string> {
  if (file instanceof Blob) {
    const type = file.type
    if (type === 'image/svg+xml') {
      return await readAsDataURL(file)
    }
    if (isVideoType(type)) {
      const snapshot = await makeVideoSnapshot(file)
      let blob: Blob
      if (typeof snapshot === 'string') {
        blob = await (await fetch(snapshot)).blob()
      } else {
        blob = snapshot
      }
      return makeImageThumbnail(blob, maxWidth, maxHeight, cropAspectRatio, quality) 
    } else {
      return makeImageThumbnail(file, maxWidth, maxHeight, cropAspectRatio, quality)
    }
  } else {
    return makeImageThumbnail(file, maxWidth, maxHeight, cropAspectRatio, quality)
  }
}

async function readAsDataURL (file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const fr = new FileReader
    fr.onload = () => {
      if (fr.result) {
        resolve(fr.result as string)
      } else {
        reject()
      }
    }
    fr.readAsDataURL(file)
  })
}
