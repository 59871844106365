import { ClientType } from '../apollo'
import { types, ApolloQueryResult } from '../types'

import {
  m_requestSignInCode,
  m_signIn,
  m_signUp,
  m_updateDeviceToken,
  m_deleteUser,
  q_checkUserExist,
} from './user-gql'

interface Mutations {
  requestSignInCode: types.RequestSignInCodeOutput
  signIn: types.SignInOutput
  signUp: types.SignUpOutput
  updateDeviceToken: types.DeviceTokenOutput
  deleteUser: types.DeleteUserOutput
}

interface Queries {
  checkUserExist: types.CheckUserExistOutput
}

type Result = ApolloQueryResult<Mutations>

export async function requestSignInCode (client: ClientType, input: types.RequestSignInCodeInput): Promise<types.RequestSignInCodeOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_requestSignInCode,
      variables: {
        input,
      },
    }) as Result
    return result.data.requestSignInCode
  } catch (e) {
  }
}

export async function signIn (client: ClientType, input: types.SignInInput): Promise<types.SignInOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_signIn,
      variables: {
        input,
      },
    }) as Result
    return result.data.signIn
  } catch (e) {
  }
}

export async function signUp (client: ClientType, input: types.SignUpInput): Promise<types.SignUpOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_signUp,
      variables: {
        input,
      },
    }) as Result
    return result.data.signUp
  } catch (e) {
  }
}

export async function updateDeviceToken (client: ClientType, input: types.DeviceTokenInput): Promise<types.DeviceTokenOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_updateDeviceToken,
      variables: {
        input,
      },
    }) as Result
    return result.data.updateDeviceToken
  } catch (e) {
  }
}

export async function deleteUser (client: ClientType, input: types.DeleteUserInput): Promise<types.DeleteUserOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_deleteUser,
      variables: {
        input,
      },
    }) as Result
    return result.data.deleteUser
  } catch (e) {
  }
}

export async function checkUserExist (client: ClientType, tel: string): Promise<types.CheckUserExistOutput | undefined> {
  try {
    const result = await client.query({
      query: q_checkUserExist,
      variables: { tel },
    }) as ApolloQueryResult<Queries>
    return result.data.checkUserExist
  } catch (e) {
  }
}