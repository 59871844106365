import { ClientType } from '../apollo'
import { ApolloQueryResult } from '../types'

import { m_resendEmail } from './user-gql'

interface Mutations {
  resendEmail: boolean
}

type Result = ApolloQueryResult<Mutations>

export async function resendEmail (client: ClientType, userId: number): Promise<boolean | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_resendEmail,
      variables: { userId },
    }) as Result
    return result.data.resendEmail
  } catch (e) {
  }
}
