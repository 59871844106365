import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { onUserUpdated } from './user-gql'
// import { option_readUserGraph } from './read-user-graph'

type Callback = (newData: types.User) => void

export function subscribeOnUserUpdated (client: ClientType, userId: number, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onUserUpdated,
    variables: {
      userId,
    },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      debug.log('=> onUserUpdated')
      const newData = data.onUserUpdated as types.User
      cb(newData)
      /*

      const opt = option_readUserGraph(userId)
      const cached = JSON.parse(JSON.stringify(client.readQuery(opt)))
      Object.assign(cached, newData)
      client.writeQuery({
        query: opt.query,
        data: cached,
      })
      */
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
