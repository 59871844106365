
import { Vue, Component, Prop } from 'vue-property-decorator'
import { FabController, FabButton } from './fab-controller'

@Component
export default class BvFabList extends Vue {
  @Prop() controller!: FabController

  getLabel (fab: FabButton): string {
    return this.$mainStore.translate(fab.label)
  }

  getStyle (fab: FabButton) {
    return {
      '--background': fab.backgroundColor,
    }
  }

  onClick (fab: FabButton) {
    fab.onClick()
  }
}
