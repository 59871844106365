import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { _updateOneExpertInfo } from './expert-gql'

export async function updateExpertInfo (client: ClientType, id: number, data: types.ExpertInfoUpdateInput): Promise<types.ExpertInfo | undefined> {
  try {
    const result = await client.mutate({
      mutation: _updateOneExpertInfo,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneExpertInfoArgs,
    }) as MutationResult
    return result.data.updateOneExpertInfo
  } catch (e) {
  }
}
