"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LessonItemGroupScalarFieldEnum = exports.LessonFlashCard_UnitScalarFieldEnum = exports.LessonFlashCard_KeywordScalarFieldEnum = exports.LessonFlashCardType = exports.LessonFlashCardScalarFieldEnum = exports.LessonExamType = exports.LessonExamScalarFieldEnum = exports.LessonExamQuestion_UnitScalarFieldEnum = exports.LessonExamQuestionScalarFieldEnum = exports.LessonCreatorScalarFieldEnum = exports.LessonCreaterSource = exports.JsonNullValueInput = exports.JsonNullValueFilter = exports.JobTransactionScalarFieldEnum = exports.JobReviewScalarFieldEnum = exports.JobOutputScalarFieldEnum = exports.JobOfferScalarFieldEnum = exports.JobOfferResponseScalarFieldEnum = exports.HashTagScalarFieldEnum = exports.FollowScalarFieldEnum = exports.FaQScalarFieldEnum = exports.ExternalRequestScalarFieldEnum = exports.ExpertInfoScalarFieldEnum = exports.DontShowAgainScalarFieldEnum = exports.DocumentScalarFieldEnum = exports.DeviceToken_TopicsScalarFieldEnum = exports.DeviceTokenInputMode = exports.DeletedUserScalarFieldEnum = exports.CustomerRelationshipScalarFieldEnum = exports.CustomerCommunicationLogScalarFieldEnum = exports.CommerceProductScalarFieldEnum = exports.CommerceProductReviewScalarFieldEnum = exports.CommerceOrderScalarFieldEnum = exports.ClientVersionsScalarFieldEnum = exports.ChatRoomScalarFieldEnum = exports.ChannelUserUsageScalarFieldEnum = exports.ChannelScalarFieldEnum = exports.ChannelExpertScalarFieldEnum = exports.ChannelDictionaryScalarFieldEnum = exports.ChannelDeviceTokenScalarFieldEnum = exports.ChannelChatRoomMenuScalarFieldEnum = exports.CategoryScalarFieldEnum = exports.CategoryKeywordScalarFieldEnum = exports.BannedWordsScalarFieldEnum = exports.AttachmentScalarFieldEnum = exports.ApplicationsScalarFieldEnum = exports.AdvertiserScalarFieldEnum = exports.AdvertisementScalarFieldEnum = exports.AccountInfoScalarFieldEnum = exports.AbusingReportScalarFieldEnum = void 0;
exports.UserPointLogScalarFieldEnum = exports.UserPenaltyScalarFieldEnum = exports.UserLogScalarFieldEnum = exports.UserKeywordScalarFieldEnum = exports.UserIdentityScalarFieldEnum = exports.UserBlockingScalarFieldEnum = exports.UserAdActionScalarFieldEnum = exports.UrlMetadataScalarFieldEnum = exports.TransactionIsolationLevel = exports.ThumbnailScalarFieldEnum = exports.SysPictureScalarFieldEnum = exports.SysNotificationScalarFieldEnum = exports.SysNoticeScalarFieldEnum = exports.SysInfoScalarFieldEnum = exports.SortOrder = exports.SignInTelVerificationScalarFieldEnum = exports.SignInScalarFieldEnum = exports.SettlementScalarFieldEnum = exports.ServerErrorScalarFieldEnum = exports.SecretScalarFieldEnum = exports.SearchWordScalarFieldEnum = exports.ReportActionScalarFieldEnum = exports.ReactionScalarFieldEnum = exports.QueryMode = exports.Profile_ChatRoomScalarFieldEnum = exports.Profile_ChannelScalarFieldEnum = exports.Profile_ChannelActivityScalarFieldEnum = exports.ProfileScalarFieldEnum = exports.ProfileReviewScalarFieldEnum = exports.ProfileInfoScalarFieldEnum = exports.ProductScalarFieldEnum = exports.PluginScalarFieldEnum = exports.PaymentStatusHistoryScalarFieldEnum = exports.PaymentScalarFieldEnum = exports.PaymentApiFailureLogScalarFieldEnum = exports.NullsOrder = exports.NullableJsonNullValueInput = exports.MessageTranslationScalarFieldEnum = exports.MessageInfoScalarFieldEnum = exports.MesgNotificationScalarFieldEnum = exports.LessonYouTubeUserScalarFieldEnum = exports.LessonWatchHistoryScalarFieldEnum = exports.LessonVideo_UnitScalarFieldEnum = exports.LessonVideo_KeywordScalarFieldEnum = exports.LessonVideoType = exports.LessonVideoSource = exports.LessonVideoScalarFieldEnum = exports.LessonUnitScalarFieldEnum = exports.LessonSubjectScalarFieldEnum = exports.LessonKeywordScalarFieldEnum = void 0;
exports.VersionChangeScalarFieldEnum = exports.User_ProfileScalarFieldEnum = exports.User_ChatRoomViewScalarFieldEnum = exports.UserUsageScalarFieldEnum = exports.UserScalarFieldEnum = exports.UserPointWithdrawRequestScalarFieldEnum = void 0;
exports.AbusingReportScalarFieldEnum = {
    ChannelId: 'channelId',
    ChatRoomId: 'chatRoomId',
    ClosedAt: 'closedAt',
    CreatedAt: 'createdAt',
    Description: 'description',
    Id: 'id',
    LastActionAt: 'lastActionAt',
    MessageId: 'messageId',
    ProfileId: 'profileId',
    ReactionId: 'reactionId',
    ReporterId: 'reporterId',
    UserId: 'userId'
};
exports.AccountInfoScalarFieldEnum = {
    BankAccountHolder: 'bankAccountHolder',
    BankAccountNo: 'bankAccountNo',
    BankCode: 'bankCode',
    BankName: 'bankName',
    Id: 'id',
    ProfileId: 'profileId',
    UserId: 'userId'
};
exports.AdvertisementScalarFieldEnum = {
    AdvertiserId: 'advertiserId',
    ByChannel: 'byChannel',
    ChannelId: 'channelId',
    Contents: 'contents',
    CreatedAt: 'createdAt',
    Id: 'id',
    IsAppChannel: 'isAppChannel',
    Title: 'title',
    ValidFrom: 'validFrom',
    ValidTo: 'validTo'
};
exports.AdvertiserScalarFieldEnum = {
    BizId: 'bizId',
    Email: 'email',
    Id: 'id',
    Name: 'name',
    RegId: 'regId',
    Tel: 'tel'
};
exports.ApplicationsScalarFieldEnum = {
    AppId: 'appId',
    AppSecret: 'appSecret',
    Blocked: 'blocked',
    CreatedAt: 'createdAt',
    Id: 'id',
    LastAccessedAt: 'lastAccessedAt',
    Name: 'name',
    Polices: 'polices'
};
exports.AttachmentScalarFieldEnum = {
    Embed: 'embed',
    ExpertInfoId: 'expertInfoId',
    Id: 'id',
    Info: 'info',
    Size: 'size',
    Thumbnail: 'thumbnail',
    ThumbnailId: 'thumbnailId',
    Title: 'title',
    Type: 'type',
    Url: 'url'
};
exports.BannedWordsScalarFieldEnum = {
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    Id: 'id',
    UpdatedAt: 'updatedAt',
    Words: 'words'
};
exports.CategoryKeywordScalarFieldEnum = {
    Id: 'id',
    Keyword: 'keyword'
};
exports.CategoryScalarFieldEnum = {
    ChannelId: 'channelId',
    Id: 'id',
    Name: 'name',
    Type: 'type'
};
exports.ChannelChatRoomMenuScalarFieldEnum = {
    ChannelId: 'channelId',
    ChatRoomId: 'chatRoomId',
    Id: 'id',
    Seq: 'seq',
    Text: 'text',
    Type: 'type'
};
exports.ChannelDeviceTokenScalarFieldEnum = {
    ChannelId: 'channelId',
    DeviceType: 'deviceType',
    Id: 'id',
    Lang: 'lang',
    Token: 'token',
    UserId: 'userId',
    Version: 'version'
};
exports.ChannelDictionaryScalarFieldEnum = {
    ChannelId: 'channelId',
    Dict: 'dict',
    Id: 'id',
    Lang: 'lang'
};
exports.ChannelExpertScalarFieldEnum = {
    ChannelId: 'channelId',
    CollectedInfo: 'collectedInfo',
    Description: 'description',
    Disabled: 'disabled',
    ExpertPolicy: 'expertPolicy',
    Id: 'id',
    IsOpen: 'isOpen',
    Name: 'name'
};
exports.ChannelScalarFieldEnum = {
    AccessCode: 'accessCode',
    ActionButtons: 'actionButtons',
    BlockSearch: 'blockSearch',
    CertificationNotice: 'certificationNotice',
    ChannelRoomId: 'channelRoomId',
    CommerceProductMeta: 'commerceProductMeta',
    Company: 'company',
    CreatedAt: 'createdAt',
    Description: 'description',
    ExpertName: 'expertName',
    Id: 'id',
    Keywords: 'keywords',
    Layout: 'layout',
    MatchKeywords: 'matchKeywords',
    Name: 'name',
    NeedAccessCode: 'needAccessCode',
    NeedApproval: 'needApproval',
    OwnUserId: 'ownUserId',
    OwnerId: 'ownerId',
    Picture: 'picture',
    PrivacyPolicy: 'privacyPolicy',
    ProductMeta: 'productMeta',
    ProfileCount: 'profileCount',
    Props: 'props',
    TermsOfService: 'termsOfService',
    ThumbnailId: 'thumbnailId',
    Timestamp: 'timestamp',
    UiComponents: 'uiComponents',
    WelcomeMesg: 'welcomeMesg'
};
exports.ChannelUserUsageScalarFieldEnum = {
    ChannelId: 'channelId',
    Data: 'data',
    Id: 'id',
    ReadReactionId: 'readReactionId',
    UserId: 'userId'
};
exports.ChatRoomScalarFieldEnum = {
    AccessCode: 'accessCode',
    ChannelId: 'channelId',
    CommentsRootChatRoomId: 'commentsRootChatRoomId',
    CommentsRootMessageId: 'commentsRootMessageId',
    Description: 'description',
    Id: 'id',
    Keywords: 'keywords',
    Name: 'name',
    Process: 'process',
    Props: 'props',
    Restricted: 'restricted',
    RootMessageId: 'rootMessageId',
    Status: 'status',
    Type: 'type'
};
exports.ClientVersionsScalarFieldEnum = {
    Android: 'android',
    Id: 'id',
    Ios: 'ios'
};
exports.CommerceOrderScalarFieldEnum = {
    CancelledAt: 'cancelledAt',
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    Currency: 'currency',
    DownloadedAt: 'downloadedAt',
    Id: 'id',
    PaidExt: 'paidExt',
    PriceExt: 'priceExt',
    ProductId: 'productId',
    ProfileId: 'profileId',
    ReceivedAt: 'receivedAt',
    RefundExt: 'refundExt',
    RefundedAt: 'refundedAt',
    RefundedExt: 'refundedExt',
    SellerId: 'sellerId',
    SellerUserId: 'sellerUserId',
    SentAt: 'sentAt',
    SettlementId: 'settlementId',
    TempSettlementId: 'tempSettlementId',
    ToBePaidExt: 'toBePaidExt',
    ToBeRefunded: 'toBeRefunded',
    UserId: 'userId'
};
exports.CommerceProductReviewScalarFieldEnum = {
    CreatedAt: 'createdAt',
    Description: 'description',
    Id: 'id',
    Point: 'point',
    ProductId: 'productId',
    ReviewerId: 'reviewerId',
    ReviewerUserId: 'reviewerUserId',
    SellerUserId: 'sellerUserId'
};
exports.CommerceProductScalarFieldEnum = {
    Attachments: 'attachments',
    Cat: 'cat',
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    Currency: 'currency',
    Desc: 'desc',
    DigitalFiles: 'digitalFiles',
    ExtraTerms: 'extraTerms',
    Id: 'id',
    Name: 'name',
    PriceExt: 'priceExt',
    Review: 'review',
    ReviewCount: 'reviewCount',
    SellerId: 'sellerId',
    SellerUserId: 'sellerUserId',
    SoldCount: 'soldCount',
    Specs: 'specs',
    Sub: 'sub',
    Thumbnails: 'thumbnails',
    Type: 'type'
};
exports.CustomerCommunicationLogScalarFieldEnum = {
    CreatedAt: 'createdAt',
    Id: 'id',
    Memo: 'memo',
    Rejected: 'rejected',
    RelationshipId: 'relationshipId',
    StageChanged: 'stageChanged',
    Title: 'title',
    Type: 'type',
    When: 'when',
    Where: 'where',
    Who: 'who'
};
exports.CustomerRelationshipScalarFieldEnum = {
    Bookmarked: 'bookmarked',
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    CustomerPcId: 'customerPcId',
    Grade: 'grade',
    HadDeal: 'hadDeal',
    Id: 'id',
    Rejected: 'rejected',
    Stage: 'stage',
    Tags: 'tags',
    UserId: 'userId',
    UserPcId: 'userPcId',
    WasConnected: 'wasConnected'
};
exports.DeletedUserScalarFieldEnum = {
    DeletedAt: 'deletedAt',
    Id: 'id',
    Penalty: 'penalty',
    Reason: 'reason',
    Tel: 'tel'
};
exports.DeviceTokenInputMode = {
    Add: 'Add',
    Delete: 'Delete'
};
exports.DeviceToken_TopicsScalarFieldEnum = {
    CreatedAt: 'createdAt',
    DeletedAt: 'deletedAt',
    Id: 'id',
    Token: 'token',
    Topics: 'topics',
    UpdatedAt: 'updatedAt'
};
exports.DocumentScalarFieldEnum = {
    ChannelId: 'channelId',
    Content: 'content',
    Id: 'id',
    Lang: 'lang',
    Type: 'type',
    Version: 'version'
};
exports.DontShowAgainScalarFieldEnum = {
    Id: 'id',
    Name: 'name',
    Type: 'type',
    UserId: 'userId'
};
exports.ExpertInfoScalarFieldEnum = {
    AgreePolicyAt: 'agreePolicyAt',
    AgreePrivacyAt: 'agreePrivacyAt',
    ApprovedAt: 'approvedAt',
    ChannelExpertId: 'channelExpertId',
    ChannelId: 'channelId',
    DeniedAt: 'deniedAt',
    DenyReason: 'denyReason',
    Description: 'description',
    Id: 'id',
    ProfileChannelId: 'profile_ChannelId',
    ReRequestedAt: 'reRequestedAt',
    RequestedAt: 'requestedAt',
    UserId: 'userId'
};
exports.ExternalRequestScalarFieldEnum = {
    CreatedAt: 'createdAt',
    Error: 'error',
    Id: 'id',
    RepliedAt: 'repliedAt',
    Reply: 'reply',
    Request: 'request',
    Success: 'success',
    Type: 'type'
};
exports.FaQScalarFieldEnum = {
    Answer: 'answer',
    Category: 'category',
    Id: 'id',
    Question: 'question',
    Seq: 'seq'
};
exports.FollowScalarFieldEnum = {
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    FollowerId: 'followerId',
    Id: 'id',
    UserId: 'userId'
};
exports.HashTagScalarFieldEnum = {
    ChannelId: 'channelId',
    Count: 'count',
    Id: 'id',
    Name: 'name'
};
exports.JobOfferResponseScalarFieldEnum = {
    CanceledAt: 'canceledAt',
    CanceledBy: 'canceledBy',
    ChannelId: 'channelId',
    ChatRoomId: 'chatRoomId',
    ClientId: 'clientId',
    ClientUserId: 'clientUserId',
    CreatedAt: 'createdAt',
    Currency: 'currency',
    Duration: 'duration',
    FinishedAt: 'finishedAt',
    HiredAt: 'hiredAt',
    Id: 'id',
    IsPublicByClient: 'isPublicByClient',
    IsPublicByProvider: 'isPublicByProvider',
    JobOfferId: 'jobOfferId',
    OfferChatRoomId: 'offerChatRoomId',
    PaidExt: 'paidExt',
    PartsApplied: 'partsApplied',
    PartsHired: 'partsHired',
    Price: 'price',
    PriceExt: 'priceExt',
    Props: 'props',
    ProviderId: 'providerId',
    ProviderUserId: 'providerUserId',
    Refund: 'refund',
    RefundAgreedAt: 'refundAgreedAt',
    RefundExt: 'refundExt',
    RefundedExt: 'refundedExt',
    SettlementId: 'settlementId',
    StartAt: 'startAt',
    State: 'state',
    TempSettlementId: 'tempSettlementId',
    ToBeDoneByClient: 'toBeDoneByClient',
    ToBeDoneByProvider: 'toBeDoneByProvider',
    ToBePaid: 'toBePaid',
    ToBePaidExt: 'toBePaidExt',
    ToBeRefunded: 'toBeRefunded'
};
exports.JobOfferScalarFieldEnum = {
    ChannelId: 'channelId',
    ChatRoomId: 'chatRoomId',
    ClientId: 'clientId',
    ClientUserId: 'clientUserId',
    CountsHired: 'countsHired',
    CreatedAt: 'createdAt',
    DesignatedProviderId: 'designatedProviderId',
    DesignatedProviderUserId: 'designatedProviderUserId',
    GroupChatRoomId: 'groupChatRoomId',
    Id: 'id',
    IsPublic: 'isPublic',
    IsPublicByClient: 'isPublicByClient',
    MesgId: 'mesgId',
    PartsAndCounts: 'partsAndCounts',
    Props: 'props',
    State: 'state'
};
exports.JobOutputScalarFieldEnum = {
    ChannelId: 'channelId',
    ChatRoomId: 'chatRoomId',
    ClientId: 'clientId',
    ClientUserId: 'clientUserId',
    CreatedAt: 'createdAt',
    Group: 'group',
    Id: 'id',
    JobOfferId: 'jobOfferId',
    JobOfferResponseId: 'jobOfferResponseId',
    Keywords: 'keywords',
    MesgKeywords: 'mesgKeywords',
    MessageId: 'messageId',
    Props: 'props',
    ProviderId: 'providerId',
    ProviderUserId: 'providerUserId',
    Published: 'published',
    PublishedByClient: 'publishedByClient',
    PublishedByProvider: 'publishedByProvider',
    Title: 'title',
    Type: 'type',
    WhoCanPublish: 'whoCanPublish'
};
exports.JobReviewScalarFieldEnum = {
    ChannelId: 'channelId',
    ChatRoomId: 'chatRoomId',
    CreatedAt: 'createdAt',
    Detail: 'detail',
    Id: 'id',
    JobAsClientId: 'jobAsClientId',
    JobAsProviderId: 'jobAsProviderId',
    OfferChatRoomId: 'offerChatRoomId',
    Point1: 'point1',
    Point2: 'point2',
    Point3: 'point3',
    PointOverall: 'pointOverall',
    RevieweeId: 'revieweeId',
    RevieweeUserId: 'revieweeUserId',
    ReviewerId: 'reviewerId',
    ReviewerUserId: 'reviewerUserId',
    Role: 'role'
};
exports.JobTransactionScalarFieldEnum = {
    Body: 'body',
    CreatedAt: 'createdAt',
    Id: 'id',
    JobOfferResponseId: 'jobOfferResponseId',
    MesgId: 'mesgId',
    Type: 'type'
};
exports.JsonNullValueFilter = {
    AnyNull: 'AnyNull',
    DbNull: 'DbNull',
    JsonNull: 'JsonNull'
};
exports.JsonNullValueInput = {
    JsonNull: 'JsonNull'
};
exports.LessonCreaterSource = {
    Beezvalley: 'beezvalley',
    Youtube: 'youtube'
};
exports.LessonCreatorScalarFieldEnum = {
    CreatedAt: 'createdAt',
    Id: 'id',
    Source: 'source',
    UpdatedAt: 'updatedAt',
    UserId: 'userId',
    YoutubeUserId: 'youtubeUserId'
};
exports.LessonExamQuestionScalarFieldEnum = {
    Answer: 'answer',
    Content: 'content',
    CreatedAt: 'createdAt',
    DeletedAt: 'deletedAt',
    ExamId: 'examId',
    Explanation: 'explanation',
    Id: 'id',
    MesgId: 'mesgId',
    Number: 'number',
    Point: 'point',
    UpdatedAt: 'updatedAt',
    Uuid: 'uuid'
};
exports.LessonExamQuestion_UnitScalarFieldEnum = {
    Id: 'id',
    Index: 'index',
    QuestionId: 'questionId',
    UnitId: 'unitId'
};
exports.LessonExamScalarFieldEnum = {
    CreatedAt: 'createdAt',
    DeletedAt: 'deletedAt',
    Grade: 'grade',
    Id: 'id',
    Month: 'month',
    SubjectId: 'subjectId',
    Title: 'title',
    Type: 'type',
    UpdatedAt: 'updatedAt',
    Year: 'year'
};
exports.LessonExamType = {
    Suneung: 'suneung',
    Trial: 'trial'
};
exports.LessonFlashCardScalarFieldEnum = {
    Answers: 'answers',
    Content: 'content',
    CreatedAt: 'createdAt',
    DeletedAt: 'deletedAt',
    Explanation: 'explanation',
    Id: 'id',
    MesgId: 'mesgId',
    Type: 'type',
    UpdatedAt: 'updatedAt',
    Uuid: 'uuid',
    Wrongs: 'wrongs'
};
exports.LessonFlashCardType = {
    Blank: 'blank',
    Ox: 'ox'
};
exports.LessonFlashCard_KeywordScalarFieldEnum = {
    CardId: 'cardId',
    Id: 'id',
    Index: 'index',
    KeywordId: 'keywordId'
};
exports.LessonFlashCard_UnitScalarFieldEnum = {
    CardId: 'cardId',
    Id: 'id',
    Index: 'index',
    UnitId: 'unitId'
};
exports.LessonItemGroupScalarFieldEnum = {
    Id: 'id',
    Title: 'title'
};
exports.LessonKeywordScalarFieldEnum = {
    CreatedAt: 'createdAt',
    DeletedAt: 'deletedAt',
    Description: 'description',
    Id: 'id',
    Keyword: 'keyword',
    UpdatedAt: 'updatedAt',
    Url: 'url'
};
exports.LessonSubjectScalarFieldEnum = {
    Id: 'id',
    Title: 'title'
};
exports.LessonUnitScalarFieldEnum = {
    CreatedAt: 'createdAt',
    DeletedAt: 'deletedAt',
    Id: 'id',
    Index: 'index',
    Level: 'level',
    SubjectId: 'subjectId',
    SuperUnitId: 'superUnitId',
    Title: 'title',
    UpdatedAt: 'updatedAt'
};
exports.LessonVideoScalarFieldEnum = {
    CreatedAt: 'createdAt',
    CreatorId: 'creatorId',
    DeletedAt: 'deletedAt',
    Description: 'description',
    Duration: 'duration',
    GroupId: 'groupId',
    Id: 'id',
    IndexInGroup: 'indexInGroup',
    LessonId: 'lessonId',
    MesgId: 'mesgId',
    PreviewClip: 'previewClip',
    Source: 'source',
    ThumbnailUrl: 'thumbnailUrl',
    Title: 'title',
    Type: 'type',
    UpdatedAt: 'updatedAt',
    Uuid: 'uuid',
    VideoId: 'videoId'
};
exports.LessonVideoSource = {
    Beezvallay: 'beezvallay',
    Youtube: 'youtube'
};
exports.LessonVideoType = {
    Example: 'example',
    Lesson: 'lesson',
    Preview: 'preview'
};
exports.LessonVideo_KeywordScalarFieldEnum = {
    Id: 'id',
    Index: 'index',
    KeywordId: 'keywordId',
    VideoId: 'videoId'
};
exports.LessonVideo_UnitScalarFieldEnum = {
    Id: 'id',
    Index: 'index',
    ItemId: 'itemId',
    UnitId: 'unitId'
};
exports.LessonWatchHistoryScalarFieldEnum = {
    CreatedAt: 'createdAt',
    DeletedAt: 'deletedAt',
    Id: 'id',
    LastWatchPosition: 'lastWatchPosition',
    LessonMesgId: 'lessonMesgId',
    LessonUuid: 'lessonUuid',
    TotalWatchTime: 'totalWatchTime',
    UpdatedAt: 'updatedAt',
    UserId: 'userId',
    WatchCount: 'watchCount'
};
exports.LessonYouTubeUserScalarFieldEnum = {
    CreatedAt: 'createdAt',
    Id: 'id',
    Name: 'name',
    ThumbnailUrl: 'thumbnailUrl',
    UpdatedAt: 'updatedAt'
};
exports.MesgNotificationScalarFieldEnum = {
    ChatRoomId: 'chatRoomId',
    CreatedAt: 'createdAt',
    Id: 'id',
    MessageId: 'messageId',
    UserId: 'userId'
};
exports.MessageInfoScalarFieldEnum = {
    BookmarkCount: 'bookmarkCount',
    ChannelId: 'channelId',
    ChatRoomId: 'chatRoomId',
    CommentsChatRoomId: 'commentsChatRoomId',
    CommentsCount: 'commentsCount',
    Id: 'id',
    LikesCount: 'likesCount',
    NotiUserCount: 'notiUserCount',
    UserId: 'userId'
};
exports.MessageTranslationScalarFieldEnum = {
    Id: 'id',
    LastAccessedAt: 'lastAccessedAt',
    MesgId: 'mesgId',
    MesgT: 'mesgT',
    Target: 'target',
    Version: 'version'
};
exports.NullableJsonNullValueInput = {
    DbNull: 'DbNull',
    JsonNull: 'JsonNull'
};
exports.NullsOrder = {
    First: 'first',
    Last: 'last'
};
exports.PaymentApiFailureLogScalarFieldEnum = {
    CreatedAt: 'createdAt',
    Params: 'params',
    PaymentId: 'paymentId',
    Response: 'response',
    UpdatedAt: 'updatedAt'
};
exports.PaymentScalarFieldEnum = {
    Amount: 'amount',
    AmountForeign: 'amountForeign',
    ApprovedAt: 'approvedAt',
    ChannelId: 'channelId',
    CommerceOrderId: 'commerceOrderId',
    CreatedAt: 'createdAt',
    Currency: 'currency',
    Detail: 'detail',
    EventCode: 'eventCode',
    FormData: 'formData',
    GoodName: 'goodName',
    Id: 'id',
    JobResponseId: 'jobResponseId',
    Moid: 'moid',
    PayMethod: 'payMethod',
    PgVendor: 'pgVendor',
    Refunded: 'refunded',
    RefundedForeign: 'refundedForeign',
    ResultCode: 'resultCode',
    ResultMsg: 'resultMsg',
    Status: 'status',
    Tid: 'tid',
    UpdatedAt: 'updatedAt',
    UserId: 'userId'
};
exports.PaymentStatusHistoryScalarFieldEnum = {
    Amount: 'amount',
    CreatedAt: 'createdAt',
    Id: 'id',
    PaymentId: 'paymentId',
    Status: 'status',
    UpdatedAt: 'updatedAt'
};
exports.PluginScalarFieldEnum = {
    ApprovedAt: 'approvedAt',
    ChannelId: 'channelId',
    Id: 'id',
    RejectReason: 'rejectReason',
    RejectedAt: 'rejectedAt',
    Seq: 'seq',
    SubmitAt: 'submitAt',
    Title: 'title',
    Type: 'type',
    Uuid: 'uuid',
    Version: 'version'
};
exports.ProductScalarFieldEnum = {
    Cat: 'cat',
    ChannelId: 'channelId',
    Currency: 'currency',
    Desc: 'desc',
    Duration: 'duration',
    ExtraTerms: 'extraTerms',
    Id: 'id',
    Name: 'name',
    Price: 'price',
    PriceExt: 'priceExt',
    Sub: 'sub',
    Type: 'type'
};
exports.ProfileInfoScalarFieldEnum = {
    Data: 'data',
    Id: 'id',
    ProfileId: 'profileId',
    Type: 'type'
};
exports.ProfileReviewScalarFieldEnum = {
    CreatedAt: 'createdAt',
    Description: 'description',
    Id: 'id',
    PredefinedCode: 'predefinedCode',
    RevieweeId: 'revieweeId',
    ReviewerId: 'reviewerId',
    Role: 'role',
    Type: 'type'
};
exports.ProfileScalarFieldEnum = {
    Address: 'address',
    BlockChatting: 'blockChatting',
    Ceo: 'ceo',
    CreatedAt: 'createdAt',
    Deleted: 'deleted',
    Description: 'description',
    Email: 'email',
    Email2: 'email2',
    EmailCode: 'emailCode',
    EmailCodeValidUntil: 'emailCodeValidUntil',
    EmailVerified: 'emailVerified',
    Homepage: 'homepage',
    Id: 'id',
    Name: 'name',
    Picture: 'picture',
    Tel: 'tel',
    Tel2: 'tel2',
    TelCode: 'telCode',
    TelCodeValidUntil: 'telCodeValidUntil',
    TelVerified: 'telVerified',
    ThumbnailId: 'thumbnailId',
    Type: 'type',
    UserId: 'userId'
};
exports.Profile_ChannelActivityScalarFieldEnum = {
    AttendCount: 'attendCount',
    ChannelId: 'channelId',
    CustomPoint: 'customPoint',
    FollowerCount: 'followerCount',
    Id: 'id',
    LatestPointAt: 'latestPointAt',
    Level: 'level',
    Point: 'point',
    PointSpent: 'pointSpent',
    PointTerm: 'pointTerm',
    PostCount: 'postCount',
    ProfileChannelId: 'profileChannelId',
    RecentAttendCount: 'recentAttendCount',
    RecentFollowerCount: 'recentFollowerCount',
    RecentPostCount: 'recentPostCount',
    RecentReferCount: 'recentReferCount',
    ReferCount: 'referCount',
    ResetAt: 'resetAt',
    UserId: 'userId'
};
exports.Profile_ChannelScalarFieldEnum = {
    AgreePrivacyAt: 'agreePrivacyAt',
    AgreeTermsAt: 'agreeTermsAt',
    ApprovalRequestedAt: 'approvalRequestedAt',
    ApprovedAt: 'approvedAt',
    BlockChatting: 'blockChatting',
    CertRequestedAt: 'certRequestedAt',
    CertVerifiedAt: 'certVerifiedAt',
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    CurrentExpertId: 'currentExpertId',
    CustodianId: 'custodianId',
    DenyReason: 'denyReason',
    ExpelledAt: 'expelledAt',
    FavoriteChatRooms: 'favoriteChatRooms',
    Id: 'id',
    IsAdmin: 'isAdmin',
    IsPublic: 'isPublic',
    MesgFilter: 'mesgFilter',
    MesgFilterTokens: 'mesgFilterTokens',
    NoDefaultExpert: 'noDefaultExpert',
    PasswordApprovedChatRoomIds: 'passwordApprovedChatRoomIds',
    ProfileId: 'profileId',
    Props: 'props',
    ReferrerId: 'referrerId',
    ReviewAverage: 'reviewAverage',
    ReviewCount: 'reviewCount',
    SuspendUntil: 'suspendUntil',
    UserId: 'userId',
    UserInfo: 'userInfo'
};
exports.Profile_ChatRoomScalarFieldEnum = {
    ChatRoomId: 'chatRoomId',
    Id: 'id',
    ProfileId: 'profileId',
    Role: 'role',
    UserId: 'userId'
};
exports.QueryMode = {
    Default: 'default',
    Insensitive: 'insensitive'
};
exports.ReactionScalarFieldEnum = {
    Censored: 'censored',
    ChannelId: 'channelId',
    ChatRoomId: 'chatRoomId',
    CreatedAt: 'createdAt',
    Id: 'id',
    LikesCount: 'likesCount',
    MesgId: 'mesgId',
    ParentReactionId: 'parentReactionId',
    ProfileName: 'profileName',
    Props: 'props',
    ReportedAt: 'reportedAt',
    TargetProfileName: 'targetProfileName',
    TargetUserId: 'targetUserId',
    Type: 'type',
    UpdatedAt: 'updatedAt',
    UserId: 'userId'
};
exports.ReportActionScalarFieldEnum = {
    AbusingReportId: 'abusingReportId',
    CreatedAt: 'createdAt',
    Description: 'description',
    Id: 'id',
    PenaltyId: 'penaltyId'
};
exports.SearchWordScalarFieldEnum = {
    CreatedAt: 'createdAt',
    Id: 'id',
    UserId: 'userId',
    Words: 'words'
};
exports.SecretScalarFieldEnum = {
    Id: 'id',
    Key: 'key',
    Value: 'value'
};
exports.ServerErrorScalarFieldEnum = {
    CreatedAt: 'createdAt',
    Id: 'id',
    Message: 'message',
    Type: 'type'
};
exports.SettlementScalarFieldEnum = {
    Amount: 'amount',
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    Id: 'id',
    ProfileId: 'profileId',
    UserId: 'userId'
};
exports.SignInScalarFieldEnum = {
    DeviceId: 'deviceId',
    DeviceType: 'deviceType',
    Id: 'id',
    Token: 'token',
    UserId: 'userId',
    ValidUntil: 'validUntil'
};
exports.SignInTelVerificationScalarFieldEnum = {
    Code: 'code',
    Count: 'count',
    Id: 'id',
    Tel: 'tel',
    ValidUntil: 'validUntil'
};
exports.SortOrder = {
    Asc: 'asc',
    Desc: 'desc'
};
exports.SysInfoScalarFieldEnum = {
    Categories: 'categories',
    Ext: 'ext',
    Id: 'id',
    PluginUrlTemplate: 'pluginUrlTemplate',
    VersionAndroid: 'versionAndroid',
    VersionIos: 'versionIos'
};
exports.SysNoticeScalarFieldEnum = {
    ChannelId: 'channelId',
    Content: 'content',
    CreatedAt: 'createdAt',
    Html: 'html',
    Id: 'id',
    Subject: 'subject'
};
exports.SysNotificationScalarFieldEnum = {
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    Id: 'id',
    Info: 'info',
    ReceiverId: 'receiverId',
    ReceiverUserId: 'receiverUserId',
    Type: 'type'
};
exports.SysPictureScalarFieldEnum = {
    Id: 'id',
    ThumbnailId: 'thumbnailId',
    Type: 'type',
    Url: 'url'
};
exports.ThumbnailScalarFieldEnum = {
    Data: 'data',
    Id: 'id'
};
exports.TransactionIsolationLevel = {
    ReadCommitted: 'ReadCommitted',
    ReadUncommitted: 'ReadUncommitted',
    RepeatableRead: 'RepeatableRead',
    Serializable: 'Serializable'
};
exports.UrlMetadataScalarFieldEnum = {
    CreatedAt: 'createdAt',
    Data: 'data',
    Id: 'id',
    Url: 'url'
};
exports.UserAdActionScalarFieldEnum = {
    Action: 'action',
    AdId: 'adId',
    CreatedAt: 'createdAt',
    Id: 'id',
    RefererId: 'refererId',
    UserId: 'userId'
};
exports.UserBlockingScalarFieldEnum = {
    BlockeeId: 'blockeeId',
    BlockeeProfileId: 'blockeeProfileId',
    BlockerId: 'blockerId',
    CreatedAt: 'createdAt',
    Id: 'id'
};
exports.UserIdentityScalarFieldEnum = {
    Id: 'id',
    IdNo: 'idNo',
    Name: 'name',
    Tel: 'tel'
};
exports.UserKeywordScalarFieldEnum = {
    Id: 'id',
    Keywords: 'keywords',
    Locations: 'locations',
    UserId: 'userId'
};
exports.UserLogScalarFieldEnum = {
    Body: 'body',
    CreatedAt: 'createdAt',
    Id: 'id',
    Type: 'type',
    UserId: 'userId',
    Uuid: 'uuid'
};
exports.UserPenaltyScalarFieldEnum = {
    AcknowledgedAt: 'acknowledgedAt',
    CreatedAt: 'createdAt',
    Hours: 'hours',
    Id: 'id',
    Props: 'props',
    Reason: 'reason',
    Type: 'type',
    UserId: 'userId'
};
exports.UserPointLogScalarFieldEnum = {
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    Id: 'id',
    Point: 'point',
    Reason: 'reason',
    UserId: 'userId'
};
exports.UserPointWithdrawRequestScalarFieldEnum = {
    CanceledAt: 'canceledAt',
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    Id: 'id',
    Note: 'note',
    Point: 'point',
    UserId: 'userId',
    WithdrewAt: 'withdrewAt'
};
exports.UserScalarFieldEnum = {
    AgreeToMarketingAt: 'agreeToMarketingAt',
    AuxInfo: 'auxInfo',
    ChannelId: 'channelId',
    CreatedAt: 'createdAt',
    Deleted: 'deleted',
    DeletedAt: 'deletedAt',
    Email: 'email',
    EmailCode: 'emailCode',
    EmailCodeValidUntil: 'emailCodeValidUntil',
    EmailVerified: 'emailVerified',
    ExtProvider: 'extProvider',
    ExtToken: 'extToken',
    ExtUserId: 'extUserId',
    Id: 'id',
    IsAdmin: 'isAdmin',
    LastAccessedAt: 'lastAccessedAt',
    LastEventId: 'lastEventId',
    LastMesgNotiId: 'lastMesgNotiId',
    LastNoticeId: 'lastNoticeId',
    LastSysNotiId: 'lastSysNotiId',
    NewMessageDisplaySetting: 'newMessageDisplaySetting',
    NotiSetting: 'notiSetting',
    ReferChannelId: 'referChannelId',
    ReferrerId: 'referrerId',
    Tel: 'tel',
    Timestamp: 'timestamp'
};
exports.UserUsageScalarFieldEnum = {
    Data: 'data',
    Id: 'id'
};
exports.User_ChatRoomViewScalarFieldEnum = {
    ChatRoomId: 'chatRoomId',
    Filter: 'filter',
    Id: 'id',
    LastAccessedAt: 'lastAccessedAt',
    LastMessageId: 'lastMessageId',
    LastMessageSeq: 'lastMessageSeq',
    UserId: 'userId'
};
exports.User_ProfileScalarFieldEnum = {
    Access: 'access',
    Id: 'id',
    ProfileId: 'profileId',
    UserId: 'userId'
};
exports.VersionChangeScalarFieldEnum = {
    Break: 'break',
    Bugs: 'bugs',
    BugsEn: 'bugsEn',
    Changes: 'changes',
    ChangesEn: 'changesEn',
    Id: 'id',
    Version: 'version'
};
