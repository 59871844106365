import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { createOneUserPointWithdrawRequest } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createUserPointWithdrawRequest (client: ClientType, data: types.UserPointWithdrawRequestCreateInput): Promise<types.UserPointWithdrawRequest | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneUserPointWithdrawRequest,
      variables: {
        data,
      } as types.MutationCreateOneUserPointWithdrawRequestArgs,
    }) as MutationResult
    return result.data.createOneUserPointWithdrawRequest
  } catch (e) {
  }
}
