import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'

export const q_findManyAbusingReport = gql`
  query q_findManyAbusingReport($where: AbusingReportWhereInput!, $take: Int, $orderBy: [AbusingReportOrderByWithRelationInput!]) {
    findManyAbusingReport(where: $where, take: $take, orderBy: $orderBy) {
      ...AbusingReportFields
      actions {
        ...ReportActionFields
      }
      profile {
        ...ProfileFields
      }
    }
  }
  ${client.AbusingReportFields}
  ${client.ReportActionFields}
  ${client.ProfileFields}
`

export const q_findUniqueAbusingReport = gql`
  query q_findUniqueAbusingReport($where: AbusingReportWhereUniqueInput!) {
    findUniqueAbusingReport(where: $where) {
      ...AbusingReportFields
      channel {
        ...ChannelFields
        owner {
          id
          name
        }
      }
      chatRoom {
        ...ChatRoomFields
        channel {
          id
          name
        }
      }
      profile {
        ...ProfileFields
        users {
          id
          userId
        }
      }
      actions {
        ...ReportActionFields
      }
    }
  }
  ${client.AbusingReportFields}
  ${client.ChannelFields}
  ${client.ChatRoomFields}
  ${client.ProfileFields}
  ${client.ReportActionFields}
`
