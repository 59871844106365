import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
import * as frag from '../fragments-gql'

export const q_findManyJobOfferGraph = gql`
  query q_findManyJobOfferGraph($where: JobOfferWhereInput!, $orderBy: [JobOfferOrderByWithRelationInput!]) {
    findManyJobOffer(where: $where, orderBy: $orderBy) {
      ...JobOfferFields
      client {
        ...ProfileFields
      }
      responses {
        ...JobOfferResponseFields
        provider {
          ...ProfileFields
        }
      }
    }
  }
  ${client.JobOfferResponseFields}
  ${client.JobOfferFields}
  ${client.ProfileFields}
`

export const q_findManyJobOfferResponse = gql`
  query q_findManyJobOfferResponse($where: JobOfferResponseWhereInput!, $skip: Int, $take: Int, $cursor: JobOfferResponseWhereUniqueInput, $orderBy: [JobOfferResponseOrderByWithRelationInput!]) {
    findManyJobOfferResponse(where: $where, skip: $skip, take: $take, cursor: $cursor, orderBy: $orderBy) {
      ...JobOfferResponseFields
      jobOffer {
        ...JobOfferFields
      }
      reviewByClient {
        ...JobReviewFields
      }
      reviewByProvider {
        ...JobReviewFields
      }
    }
  }
  ${client.JobOfferResponseFields}
  ${client.JobOfferFields}
  ${client.JobReviewFields}
`

export const q_findManyJobOffer = gql`
  query q_findManyJobOffer($where: JobOfferWhereInput!, $skip: Int, $take: Int, $cursor: JobOfferWhereUniqueInput, $orderBy: [JobOfferOrderByWithRelationInput!]) {
    findManyJobOffer(where: $where, skip: $skip, take: $take, cursor: $cursor, orderBy: $orderBy) {
      ...JobOfferGraph
    }
  }
  ${frag.JobOfferGraph}
`

export const q_findUniqueJobOffer = gql`
  query q_findUniqueJobOffer($where: JobOfferWhereUniqueInput!) {
    findUniqueJobOffer(where: $where) {
      ...JobOfferGraph
    }
  }
  ${frag.JobOfferGraph}
`

export const q_findUniqueJobOfferResponse = gql`
  query q_findUniqueJobOfferResponse($where: JobOfferResponseWhereUniqueInput!) {
    findUniqueJobOfferResponse(where: $where) {
      ...JobOfferResponseFields
      jobOffer {
        ...JobOfferFields
      }
      reviewByClient {
        ...JobReviewFields
      }
      reviewByProvider {
        ...JobReviewFields
      }
    }
  }
  ${client.JobOfferResponseFields}
  ${client.JobOfferFields}
  ${client.JobReviewFields}
`

export const q_findManyJobOfferResponseWithPayments = gql`
  query q_findManyJobOfferResponseWithPayments($where: JobOfferResponseWhereInput!, $skip: Int, $take: Int, $cursor: JobOfferResponseWhereUniqueInput, $orderBy: [JobOfferResponseOrderByWithRelationInput!]) {
    findManyJobOfferResponse(where: $where, skip: $skip, take: $take, cursor: $cursor, orderBy: $orderBy) {
      ...JobOfferResponseFields
      jobOffer {
        ...JobOfferFields
      }
      payments {
        ...PaymentFields
        statusHistory {
          ...PaymentStatusHistoryFields
        }
      }
    }
  }
  ${client.JobOfferResponseFields}
  ${client.JobOfferFields}
  ${client.PaymentFields}
  ${client.PaymentStatusHistoryFields}
`

export const m_updateOneJobOfferResponse = gql`
  mutation m_updateOneJobOfferResponse($where: JobOfferResponseWhereUniqueInput!, $data: JobOfferResponseUpdateInput!) {
    updateOneJobOfferResponse(where: $where, data: $data) {
      ...JobOfferResponseFields
      jobOffer {
        ...JobOfferFields
      }
    }
  }
  ${client.JobOfferResponseFields}
  ${client.JobOfferFields}
`

export const onJobOfferUpdated = gql`
  subscription onJobOfferUpdated($profileIds: [Int!]!, $userId: Int) {
    onJobOfferUpdated(profileIds: $profileIds, userId: $userId) {
      ...JobOfferFields
    }
  }
  ${client.JobOfferFields}
`

export const onJobOfferOfResponseUpdated = gql`
  subscription onJobOfferOfResponseUpdated($offerIds: [Int!]!) {
    onJobOfferOfResponseUpdated(offerIds: $offerIds) {
      ...JobOfferFields
    }
  }
  ${client.JobOfferFields}
`

export const onJobOfferResponseUpdated = gql`
  subscription onJobOfferResponseUpdated($profileIds: [Int!]!, $userId: Int) {
    onJobOfferResponseUpdated(profileIds: $profileIds, userId: $userId) {
      ...JobOfferResponseFields
      jobOffer {
        ...JobOfferFields
      }
    }
  }
  ${client.JobOfferResponseFields}
  ${client.JobOfferFields}
`

export const onJobOfferResponseOfOfferUpdated = gql`
  subscription onJobOfferResponseOfOfferUpdated($offerIds: [Int!]!) {
    onJobOfferResponseOfOfferUpdated(offerIds: $offerIds) {
      ...JobOfferResponseFields
      jobOffer {
        ...JobOfferFields
      }
    }
  }
  ${client.JobOfferResponseFields}
  ${client.JobOfferFields}
`

export const onJobOfferResponseOfChatRoomUpdated = gql`
  subscription onJobOfferResponseOfChatRoomUpdated($chatRoomIds: [Int!]!) {
    onJobOfferResponseOfChatRoomUpdated(chatRoomIds: $chatRoomIds) {
      ...JobOfferResponseFields
      jobOffer {
        ...JobOfferFields
      }
    }
  }
  ${client.JobOfferResponseFields}
  ${client.JobOfferFields}
`
