import Vue, { VueConstructor } from 'vue'
import { FrameworkDelegate } from '@ionic/core'

import {
  LIFECYCLE_DID_ENTER,
  LIFECYCLE_DID_LEAVE,
  LIFECYCLE_WILL_ENTER,
  LIFECYCLE_WILL_LEAVE,
  LIFECYCLE_WILL_UNLOAD,
} from '@ionic/core'

interface HTMLVueElement extends HTMLElement {
  __vue__: Vue
}

//----------------------------------------------------------------------------------------------
// VueDelegate
//----------------------------------------------------------------------------------------------
export class VueDelegate implements FrameworkDelegate {
  options: Record<string, unknown> = {}

  setOptions (options: Record<string, unknown>): void {
    this.options = options
  }

  // Attach the passed Vue component to DOM
  attachViewToDom (parentElement: HTMLElement, component: string, props: Record<string, unknown> = {}, classes: string[] = []): Promise<HTMLElement> {
    const options = this.options
    return new Promise(function (resolve, reject) {
      const attachToComponent = (Component: VueConstructor<Vue>) => {
        const componentInstance = new Component(Object.assign({}, options, { propsData: props }))
        // eslint-disable-next-line
        ;(componentInstance as any).$$props = props
        componentInstance.$mount()

        const el = componentInstance.$el as HTMLVueElement
        el.__vue__ = componentInstance

        // Add any classes to the Vue component's root element
        for (const cls of classes) {
          el.classList.add(cls)
        }

        bindLifecycleEvents(componentInstance, el)

        // Append the Vue component to DOM
        parentElement.appendChild(el)
        resolve(el)
      }

      const Component = Vue.component(component)
      if (Component.name) { // if Componet has name, it means it is a VueConstructor
        attachToComponent(Component)
      } else {
        // lazy load the component
        const asyncComponent = Component as unknown as () => Promise<{default: VueConstructor<Vue>}>
        asyncComponent().then((Component) => {
          // register loaded component globally to prevent read it again
          Vue.component(component, Component.default)
          attachToComponent(Component.default)
        })
      }
      /*
      const componentInstance = new Component(Object.assign({}, options, { propsData: props }))
      // eslint-disable-next-line
      ;(componentInstance as any).$$props = props
      componentInstance.$mount()

      const el = componentInstance.$el as HTMLVueElement
      el.__vue__ = componentInstance

      // Add any classes to the Vue component's root element
      for (const cls of classes) {
        el.classList.add(cls)
      }

      bindLifecycleEvents(componentInstance, el)

      // Append the Vue component to DOM
      parentElement.appendChild(el)
      resolve(el)
      */
    })
  }

  // Remove the earlier created Vue component from DOM
  removeViewFromDom (parentElement: HTMLElement, childElement: HTMLVueElement): Promise<void> {
    return new Promise(function (resolve, reject) {
      // Destroy the Vue component instance
      const vueComp = childElement.__vue__
      if (vueComp) {
        if (vueComp.$parent) {
          // When the component has a slot, the component has a parent component.
          // So, we need to destroy the parent in this case.
          vueComp.$parent.$destroy()
        } else {
          vueComp.$destroy()
        }
      }

      // eslint-disable-next-line
      delete (vueComp as any).$$props
      // eslint-disable-next-line
      ;(childElement as any).__vue__ = undefined

      // remove element
      childElement.remove()

      resolve()
    })
  }
}

const LIFECYCLES = [
  LIFECYCLE_WILL_ENTER,
  LIFECYCLE_DID_ENTER,
  LIFECYCLE_WILL_LEAVE,
  LIFECYCLE_DID_LEAVE,
  LIFECYCLE_WILL_UNLOAD,
]

/* eslint-disable-next-line */
export function bindLifecycleEvents (instance: any, element: HTMLElement): void {
  LIFECYCLES.forEach((eventName) => {
    /* eslint-disable-next-line */
    element.addEventListener(eventName, (ev: any) => {
      if (eventName === LIFECYCLE_DID_LEAVE) {
      }
      if (eventName === LIFECYCLE_WILL_UNLOAD) {
      }
      if (typeof instance[eventName] === 'function') {
        instance[eventName](ev.detail)
      }
    })
  })
}

export const vueDelegate = new VueDelegate()