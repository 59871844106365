
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { types, apollo } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'
import { setSignedIn } from '../auth'

export default Vue.extend({
  data () {
    return {
      tel: '',
      code: '',
    }
  },

  methods: {
    async onRequestCode () {
      if (this.tel === '') {
        this.$dbIon.alert(i18n.tc('strings.components.aSignIn05'))
      }
      const res = await apollo.requestSignInCode(await apc.getClient(), {
        tel: this.tel,
      })
      if (res?.ok) {
        if (res.code) {
          this.code = res.code
        } else {
          this.$dbIon.alert(i18n.tc('strings.components.aSignIn06'))
        }
      } else {
        this.$dbIon.alert(`오류가 발생했습니다.: code = ${res?.code}`)
      }
    },
    async onVerifyCode () {
      if (this.code === '') {
        this.$dbIon.alert(i18n.tc('strings.components.aSignIn08'))
      }
      const res = await apollo.signIn(await apc.getClient(), {
        tel: this.tel,
        code: this.code,
        admin: true,
      })
      if (!res) {
        this.$dbIon.alert(i18n.tc('strings.components.aSignIn10'))
      } else if (res.error) {
        if (res.error === types.SignInError.NO_PERMISSION) {
          this.$dbIon.alert(i18n.tc('strings.components.aSignIn09'))
        } else {
          this.$dbIon.alert(i18n.tc('strings.components.aSignIn10'))
        }
        // if (res.error === types.SignInError.NOT_REGISTERED) {
        //   const out = await apollo.signUp(await apc.getClient(), {
        //     token: res.token,
        //     isAdmin: true,
        //     profile: {
        //       name: 'Admin',
        //       tel: this.tel,
        //     },
        //   })
        //   if (out.token) {
        //     setSignedIn(out.token)
        //   }
        // } else {
        //   console.error('Failed to signIn: ', res.error)
        // }
      } else {
        setSignedIn(res.token)
      }
    },
  },
})
