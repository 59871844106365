"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommerceProductType = exports.TranslationMode = exports.CRM_STAGE = exports.CRM_STAGE_REJECTED = exports.AD_CHANNEL_ALL = exports.AD_CHANNEL_HOME = exports.MesgFilterType = exports.JoinChannelError = exports.JobReviewRole = exports.VerifyCodeError = exports.CodeVerificationType = exports.ProfileReviewCode = exports.ProfileReviewRole = exports.ProfileReviewType = exports.DefaultNewMessageDisplaySetting = exports.NewMessageDisplaySetting = exports.DefaultNotificationSetting = exports.NotificationSetting = exports.MesgNotificationType = exports.SysNotificationType = exports.CategoryType = exports.ChannelChatRoomMenuType = exports.ChatRoomType = exports.ChatRoomStatus = exports.UpdateUserFavoriteOutput = exports.ProfileInfoType = exports.DeviceType = exports.ProfileAccess = exports.UpdateMyPhoneNumberError = exports.SignInError = exports.MAX_MATCH_KEYWORD_SET_COUNT = void 0;
__exportStar(require("./_generated/schema"), exports);
__exportStar(require("./hot-line"), exports);
__exportStar(require("./verification-service"), exports);
exports.MAX_MATCH_KEYWORD_SET_COUNT = 10; // 채널별 MatchKeyword의 최대 갯수
// export interface RequestSignInCodeInput {
//   tel: string
// }
// export interface RequestSignInCodeOutput {
//   ok: boolean
//   code?: string
// }
// export interface SignInInput {
//   tel: string
//   code: string
// }
var SignInError;
(function (SignInError) {
    SignInError[SignInError["NONE"] = 0] = "NONE";
    SignInError[SignInError["CODE_MISMATCH"] = 1] = "CODE_MISMATCH";
    SignInError[SignInError["NOT_REGISTERED"] = 2] = "NOT_REGISTERED";
    SignInError[SignInError["TIMEOUT"] = 3] = "TIMEOUT";
    SignInError[SignInError["INTERNAL_ERROR"] = 4] = "INTERNAL_ERROR";
    SignInError[SignInError["ALREADY_REGISTERED"] = 5] = "ALREADY_REGISTERED";
    SignInError[SignInError["NO_PERMISSION"] = 6] = "NO_PERMISSION";
})(SignInError = exports.SignInError || (exports.SignInError = {}));
var UpdateMyPhoneNumberError;
(function (UpdateMyPhoneNumberError) {
    UpdateMyPhoneNumberError[UpdateMyPhoneNumberError["CODE_MISMATCH"] = 1] = "CODE_MISMATCH";
    UpdateMyPhoneNumberError[UpdateMyPhoneNumberError["ALREADY_REGISTERED"] = 2] = "ALREADY_REGISTERED";
    UpdateMyPhoneNumberError[UpdateMyPhoneNumberError["TIMEOUT"] = 3] = "TIMEOUT";
    UpdateMyPhoneNumberError[UpdateMyPhoneNumberError["INTERNAL_ERROR"] = 4] = "INTERNAL_ERROR";
    UpdateMyPhoneNumberError[UpdateMyPhoneNumberError["TOO_MANY_FAILED"] = 5] = "TOO_MANY_FAILED";
})(UpdateMyPhoneNumberError = exports.UpdateMyPhoneNumberError || (exports.UpdateMyPhoneNumberError = {}));
// export interface SignInOutput {
//   userId: number
//   token: string
//   error?: SignInError
// }
// export interface SignUpInput {
//   token: string
//   isAdmin?: boolean
//   profile: ProfileCreateInput
// }
// export interface SignUpOutput {
//   userId: number
//   token?: string
// }
var ProfileAccess;
(function (ProfileAccess) {
    ProfileAccess[ProfileAccess["NONE"] = 0] = "NONE";
    ProfileAccess[ProfileAccess["ALL"] = 65535] = "ALL";
})(ProfileAccess = exports.ProfileAccess || (exports.ProfileAccess = {}));
// export interface User_ProfileFieldsInput {
//   access: ProfileAccess
// }
var DeviceType;
(function (DeviceType) {
    DeviceType[DeviceType["UNKNOWN"] = 0] = "UNKNOWN";
    DeviceType[DeviceType["ANDROID"] = 1] = "ANDROID";
    DeviceType[DeviceType["IOS"] = 2] = "IOS";
    DeviceType[DeviceType["WEB"] = 3] = "WEB";
})(DeviceType = exports.DeviceType || (exports.DeviceType = {}));
// export interface DeviceTokenInput {
//   userId: number
//   token: string
//   deviceType: DeviceType
// }
// export interface DeviceTokenOutput {
//   ok: boolean
// }
var ProfileInfoType;
(function (ProfileInfoType) {
    ProfileInfoType[ProfileInfoType["INSTAGRAM"] = 0] = "INSTAGRAM";
    ProfileInfoType[ProfileInfoType["FACEBOOK"] = 1] = "FACEBOOK";
    ProfileInfoType[ProfileInfoType["YOUTUBE"] = 2] = "YOUTUBE";
    ProfileInfoType[ProfileInfoType["RESUME"] = 3] = "RESUME";
})(ProfileInfoType = exports.ProfileInfoType || (exports.ProfileInfoType = {}));
var UpdateUserFavoriteOutput;
(function (UpdateUserFavoriteOutput) {
    UpdateUserFavoriteOutput[UpdateUserFavoriteOutput["UNSET"] = 0] = "UNSET";
    UpdateUserFavoriteOutput[UpdateUserFavoriteOutput["SET"] = 1] = "SET";
    UpdateUserFavoriteOutput[UpdateUserFavoriteOutput["ERROR"] = 2] = "ERROR";
})(UpdateUserFavoriteOutput = exports.UpdateUserFavoriteOutput || (exports.UpdateUserFavoriteOutput = {}));
var ChatRoomStatus;
(function (ChatRoomStatus) {
    ChatRoomStatus[ChatRoomStatus["OPENED"] = 0] = "OPENED";
    ChatRoomStatus[ChatRoomStatus["CLOSED"] = 1] = "CLOSED";
    ChatRoomStatus[ChatRoomStatus["DELETED"] = 2] = "DELETED";
})(ChatRoomStatus = exports.ChatRoomStatus || (exports.ChatRoomStatus = {}));
var ChatRoomType;
(function (ChatRoomType) {
    ChatRoomType[ChatRoomType["UNKNOWN"] = -1] = "UNKNOWN";
    ChatRoomType[ChatRoomType["GENERAL"] = 0] = "GENERAL";
    ChatRoomType[ChatRoomType["ONE_TO_ONE"] = 1] = "ONE_TO_ONE";
    ChatRoomType[ChatRoomType["NOTICE"] = 2] = "NOTICE";
    ChatRoomType[ChatRoomType["HELPDESK"] = 3] = "HELPDESK";
    ChatRoomType[ChatRoomType["JOB_OFFER"] = 4] = "JOB_OFFER";
    ChatRoomType[ChatRoomType["JOB_GROUP"] = 5] = "JOB_GROUP";
    ChatRoomType[ChatRoomType["INFO"] = 6] = "INFO";
    ChatRoomType[ChatRoomType["SNS"] = 7] = "SNS";
    ChatRoomType[ChatRoomType["COMMENTS"] = 8] = "COMMENTS";
})(ChatRoomType = exports.ChatRoomType || (exports.ChatRoomType = {}));
var ChannelChatRoomMenuType;
(function (ChannelChatRoomMenuType) {
    ChannelChatRoomMenuType[ChannelChatRoomMenuType["TEXT"] = 0] = "TEXT";
    ChannelChatRoomMenuType[ChannelChatRoomMenuType["CHAT_ROOM"] = 1] = "CHAT_ROOM";
})(ChannelChatRoomMenuType = exports.ChannelChatRoomMenuType || (exports.ChannelChatRoomMenuType = {}));
var CategoryType;
(function (CategoryType) {
    CategoryType[CategoryType["COMMON"] = 0] = "COMMON";
    CategoryType[CategoryType["EXPERT_ONLY"] = 1] = "EXPERT_ONLY";
})(CategoryType = exports.CategoryType || (exports.CategoryType = {}));
var SysNotificationType;
(function (SysNotificationType) {
    SysNotificationType[SysNotificationType["CH_EXPERT_REQUESTED"] = 1] = "CH_EXPERT_REQUESTED";
    SysNotificationType[SysNotificationType["CH_EXPERT_APPROVED"] = 2] = "CH_EXPERT_APPROVED";
    SysNotificationType[SysNotificationType["CH_EXPERT_DENIED"] = 3] = "CH_EXPERT_DENIED";
    SysNotificationType[SysNotificationType["CH_ADMIN_ASSIGNED"] = 4] = "CH_ADMIN_ASSIGNED";
    SysNotificationType[SysNotificationType["CH_BECAME_OWNER"] = 5] = "CH_BECAME_OWNER";
    SysNotificationType[SysNotificationType["CH_EXPELLED"] = 6] = "CH_EXPELLED";
    SysNotificationType[SysNotificationType["CH_SUSPENDED"] = 7] = "CH_SUSPENDED";
    SysNotificationType[SysNotificationType["CH_MESG_SCREENED"] = 8] = "CH_MESG_SCREENED";
    SysNotificationType[SysNotificationType["JOB_OFFER_DELIEVERED"] = 9] = "JOB_OFFER_DELIEVERED";
    SysNotificationType[SysNotificationType["JOB_OFFER_HIRED"] = 10] = "JOB_OFFER_HIRED";
    SysNotificationType[SysNotificationType["JOB_OFFER_FIRED"] = 11] = "JOB_OFFER_FIRED";
    SysNotificationType[SysNotificationType["JOB_OFFER_CLOSED"] = 12] = "JOB_OFFER_CLOSED";
    SysNotificationType[SysNotificationType["JOB_OFFER_REOPENED"] = 13] = "JOB_OFFER_REOPENED";
    SysNotificationType[SysNotificationType["JOB_OFFER_FINISHED"] = 14] = "JOB_OFFER_FINISHED";
    SysNotificationType[SysNotificationType["JOB_OFFER_REVIEWED"] = 15] = "JOB_OFFER_REVIEWED";
    SysNotificationType[SysNotificationType["SYS_ACCOUNT_SUSPENDED"] = 16] = "SYS_ACCOUNT_SUSPENDED";
    SysNotificationType[SysNotificationType["SYS_MESG_DELETED"] = 17] = "SYS_MESG_DELETED";
    SysNotificationType[SysNotificationType["SYS_REPORT_PROCESSED"] = 18] = "SYS_REPORT_PROCESSED";
    SysNotificationType[SysNotificationType["CH_ADMIN_REMOVED"] = 19] = "CH_ADMIN_REMOVED";
    SysNotificationType[SysNotificationType["SYS_GOT_USER_PENALTY"] = 20] = "SYS_GOT_USER_PENALTY";
    SysNotificationType[SysNotificationType["JOB_OFFER_CANCEL_CANCELED"] = 21] = "JOB_OFFER_CANCEL_CANCELED";
    SysNotificationType[SysNotificationType["JOB_OFFER_AGREE_CANCELED"] = 22] = "JOB_OFFER_AGREE_CANCELED";
})(SysNotificationType = exports.SysNotificationType || (exports.SysNotificationType = {}));
var MesgNotificationType;
(function (MesgNotificationType) {
    MesgNotificationType[MesgNotificationType["_FIRST"] = 0] = "_FIRST";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    MesgNotificationType[MesgNotificationType["New"] = 0] = "New";
    MesgNotificationType[MesgNotificationType["Keyword"] = 1] = "Keyword";
    MesgNotificationType[MesgNotificationType["Reply"] = 2] = "Reply";
    MesgNotificationType[MesgNotificationType["Mention"] = 3] = "Mention";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    MesgNotificationType[MesgNotificationType["_LAST"] = 3] = "_LAST";
})(MesgNotificationType = exports.MesgNotificationType || (exports.MesgNotificationType = {}));
// 알림 설정 bit
var NotificationSetting;
(function (NotificationSetting) {
    NotificationSetting[NotificationSetting["CHATTING"] = 0] = "CHATTING";
    NotificationSetting[NotificationSetting["RECRUITING"] = 1] = "RECRUITING";
    NotificationSetting[NotificationSetting["CHANNEL_CATEGORY"] = 2] = "CHANNEL_CATEGORY";
    NotificationSetting[NotificationSetting["CHANNEL_ACTIVITY"] = 3] = "CHANNEL_ACTIVITY";
    NotificationSetting[NotificationSetting["RECRUITING_ACTIVITY"] = 4] = "RECRUITING_ACTIVITY";
    NotificationSetting[NotificationSetting["MARKETING"] = 5] = "MARKETING";
    NotificationSetting[NotificationSetting["SYSTEM_NOTICES"] = 6] = "SYSTEM_NOTICES";
    NotificationSetting[NotificationSetting["MAX"] = 7] = "MAX";
})(NotificationSetting = exports.NotificationSetting || (exports.NotificationSetting = {}));
exports.DefaultNotificationSetting = 95; //binary: 1011111
// 채널 신규 메시지 표시 설정 bit
var NewMessageDisplaySetting;
(function (NewMessageDisplaySetting) {
    NewMessageDisplaySetting[NewMessageDisplaySetting["ALL_NEW_MESSAGES"] = 0] = "ALL_NEW_MESSAGES";
    NewMessageDisplaySetting[NewMessageDisplaySetting["MESSAGE_COUNTER_BY_CATEGORY"] = 1] = "MESSAGE_COUNTER_BY_CATEGORY";
    NewMessageDisplaySetting[NewMessageDisplaySetting["NAX"] = 2] = "NAX";
})(NewMessageDisplaySetting = exports.NewMessageDisplaySetting || (exports.NewMessageDisplaySetting = {}));
exports.DefaultNewMessageDisplaySetting = 3; //binary: 11
var ProfileReviewType;
(function (ProfileReviewType) {
    ProfileReviewType[ProfileReviewType["MANNER"] = 0] = "MANNER";
    ProfileReviewType[ProfileReviewType["WORK"] = 1] = "WORK";
})(ProfileReviewType = exports.ProfileReviewType || (exports.ProfileReviewType = {}));
var ProfileReviewRole;
(function (ProfileReviewRole) {
    ProfileReviewRole[ProfileReviewRole["NA"] = 0] = "NA";
    ProfileReviewRole[ProfileReviewRole["EMPLOYER"] = 1] = "EMPLOYER";
    ProfileReviewRole[ProfileReviewRole["EMPLOYEE"] = 2] = "EMPLOYEE";
})(ProfileReviewRole = exports.ProfileReviewRole || (exports.ProfileReviewRole = {}));
var ProfileReviewCode;
(function (ProfileReviewCode) {
    ProfileReviewCode[ProfileReviewCode["POSITIVE1"] = 257] = "POSITIVE1";
    ProfileReviewCode[ProfileReviewCode["POSITIVE2"] = 258] = "POSITIVE2";
    ProfileReviewCode[ProfileReviewCode["POSITIVE3"] = 260] = "POSITIVE3";
    ProfileReviewCode[ProfileReviewCode["NEGATIVE1"] = 513] = "NEGATIVE1";
    ProfileReviewCode[ProfileReviewCode["NEGATIVE2"] = 514] = "NEGATIVE2";
    ProfileReviewCode[ProfileReviewCode["NEGATIVE3"] = 516] = "NEGATIVE3";
    ProfileReviewCode[ProfileReviewCode["AVERAGE"] = 769] = "AVERAGE";
})(ProfileReviewCode = exports.ProfileReviewCode || (exports.ProfileReviewCode = {}));
var CodeVerificationType;
(function (CodeVerificationType) {
    CodeVerificationType[CodeVerificationType["TEL"] = 0] = "TEL";
    CodeVerificationType[CodeVerificationType["EMAIL"] = 1] = "EMAIL";
})(CodeVerificationType = exports.CodeVerificationType || (exports.CodeVerificationType = {}));
// export interface RequestVerificationCodeInput {
//   userId: number
//   profileId: number
//   type: CodeVerificationType
// }
// export interface RequestVerificationCodeOutput {
//   ok: boolean
//   code?: string
// }
// export interface VerifyCodeInput {
//   userId: number
//   profileId: number
//   type: CodeVerificationType
//   code: string
// }
var VerifyCodeError;
(function (VerifyCodeError) {
    VerifyCodeError[VerifyCodeError["CODE_MISMATCH"] = 1] = "CODE_MISMATCH";
    VerifyCodeError[VerifyCodeError["NOT_REQUESTED"] = 2] = "NOT_REQUESTED";
    VerifyCodeError[VerifyCodeError["TIMEOUT"] = 3] = "TIMEOUT";
    VerifyCodeError[VerifyCodeError["INTERNAL_ERROR"] = 4] = "INTERNAL_ERROR";
})(VerifyCodeError = exports.VerifyCodeError || (exports.VerifyCodeError = {}));
var JobReviewRole;
(function (JobReviewRole) {
    JobReviewRole[JobReviewRole["CLIENT"] = 0] = "CLIENT";
    JobReviewRole[JobReviewRole["PROVIDER"] = 1] = "PROVIDER";
})(JobReviewRole = exports.JobReviewRole || (exports.JobReviewRole = {}));
var JoinChannelError;
(function (JoinChannelError) {
    JoinChannelError[JoinChannelError["NONE"] = 0] = "NONE";
    JoinChannelError[JoinChannelError["CODE_MISMATCH"] = 1] = "CODE_MISMATCH";
    JoinChannelError[JoinChannelError["INVALID_CHANNEL"] = 2] = "INVALID_CHANNEL";
    JoinChannelError[JoinChannelError["UNKNOWN"] = 3] = "UNKNOWN";
    JoinChannelError[JoinChannelError["UNMET_CRITERIA"] = 4] = "UNMET_CRITERIA";
})(JoinChannelError = exports.JoinChannelError || (exports.JoinChannelError = {}));
var MesgFilterType;
(function (MesgFilterType) {
    MesgFilterType["NONE"] = "none";
    MesgFilterType["FILTER"] = "filter";
    MesgFilterType["ALL"] = "all";
})(MesgFilterType = exports.MesgFilterType || (exports.MesgFilterType = {}));
exports.AD_CHANNEL_HOME = 0;
exports.AD_CHANNEL_ALL = -1;
exports.CRM_STAGE_REJECTED = 999;
var CRM_STAGE;
(function (CRM_STAGE) {
    CRM_STAGE[CRM_STAGE["PROSPECT"] = 0] = "PROSPECT";
    CRM_STAGE[CRM_STAGE["LEAD"] = 1] = "LEAD";
    CRM_STAGE[CRM_STAGE["CONTACT"] = 2] = "CONTACT";
    CRM_STAGE[CRM_STAGE["PROPOSE"] = 3] = "PROPOSE";
    CRM_STAGE[CRM_STAGE["NEGOTIATE"] = 4] = "NEGOTIATE";
    CRM_STAGE[CRM_STAGE["WON"] = 5] = "WON";
    CRM_STAGE[CRM_STAGE["ON_GOING"] = 6] = "ON_GOING";
    CRM_STAGE[CRM_STAGE["REJECTED"] = 999] = "REJECTED";
})(CRM_STAGE = exports.CRM_STAGE || (exports.CRM_STAGE = {}));
var TranslationMode;
(function (TranslationMode) {
    TranslationMode[TranslationMode["OFF"] = 0] = "OFF";
    TranslationMode[TranslationMode["MANUAL"] = 1] = "MANUAL";
    TranslationMode[TranslationMode["AUTO"] = 2] = "AUTO";
    TranslationMode[TranslationMode["AUTOPLUS"] = 3] = "AUTOPLUS";
})(TranslationMode = exports.TranslationMode || (exports.TranslationMode = {}));
var CommerceProductType;
(function (CommerceProductType) {
    CommerceProductType["DIGITAL"] = "digital";
})(CommerceProductType = exports.CommerceProductType || (exports.CommerceProductType = {}));
