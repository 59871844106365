import Vue from 'vue'
import { dbiAnalytics } from '@dinosband/dbi-analytics'
import { isPlatform } from '@ionic/core'

// let _modalController

// export function setModalController (controller) {
//   _modalController = controller
// }

// function cloneObject (obj: Record<string, unknown>) {
//   return JSON.parse(JSON.stringify(obj))
// }

export async function doModal (
  vue: Vue | undefined,
  component: string,
  data?: unknown,
  mode?: string,
  swipeToClose = true,
  backdropDismiss = true,
  cssClass = 'dbi-modal',
): Promise<unknown|null> {
  const modalController = Vue.prototype.$ionic.modalController
  dbiAnalytics.logEvent('bv_view_page', { component })
  // let tempData = data
  // if (data) {
  //   tempData = cloneObject(data)
  // }
  let presentingElement
  if (vue) {
    presentingElement = (vue.$el?.closest('ion-modal') || vue.$el?.closest('ion-page')) as HTMLElement
  } else {
    presentingElement = document.querySelector('ion-modal') as HTMLElement
  }

  if (!presentingElement) {
    const element = document.querySelectorAll('ion-page.can-go-back:not(.ion-page-hidden)')
    presentingElement = element[element.length - 1] as HTMLElement ?? undefined
  }

  if (!presentingElement) {
    presentingElement = document.querySelector('ion-tab:not(.tab-hidden) > ion-page') as HTMLElement
  }

  if (cssClass === 'dbi-modal' && !isPlatform('ios')) {
    cssClass = 'modal-fullscreen'
  }

  const node = Vue.prototype.$dbiRouter.newModal(component, data)

  const opts = {
    mode,
    component: 'IonNavVue',
    componentProps: {
      root: component,
      // rootParams: { data: tempData, params: tempData, isModal: true },
      rootParams: {
        data, params: data,
        isModal: true,
        routeParams: node.routeParams,
        routeNode: node,
      },
    },
    swipeToClose,
    presentingElement,
    cssClass,
    backdropDismiss,
  }
  // if (mode) {
  //   opts.mode = mode
  // }
  const modal = await modalController.create(opts)

  await modal.present()
  const res = await modal.onDidDismiss()
  Vue.prototype.$dbiRouter.closeModal()
  if (res.role == 'ok') {
    return res.data
  } else {
    return null
  }
}

export async function doFullScreenModal (
  vue: Vue | undefined,
  component: string,
  data?: unknown,
  maxFull?: boolean,
): Promise<unknown|null> {
  return await doModal(vue, component, data, 'md', false, true, maxFull ? 'modal-maxscreen' : 'modal-fullscreen')
}