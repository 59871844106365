
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class BvImageLoader extends Vue {
  @Prop() multiple?: boolean

  click (ev: Event) {
    (this.$refs.image_album as HTMLElement).click()
  }

  onSelectImage (e: { target: HTMLInputElement}) {
    this.$emit('select', e)
  }
}
