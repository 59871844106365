import { ClientType } from '../apollo'
import { ApolloQueryResult, types } from '../types'

import { m_blockProfile, m_unblockProfile } from './blocking-gql'

interface Mutations {
  blockProfile: types.UserBlocking
  unblockProfile: types.UnblockProfileOutput
}

type MutationResult = ApolloQueryResult<Mutations>

export async function blockProfile (client: ClientType, profileId: number, userId: number): Promise<types.UserBlocking | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_blockProfile,
      variables: { profileId, userId },
    }) as MutationResult
    return result.data.blockProfile
  } catch (e) {
  }
}

export async function unblockProfile (client: ClientType, profileId: number, userId: number): Promise<types.UnblockProfileOutput | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_unblockProfile,
      variables: { profileId, userId },
    }) as MutationResult
    return result.data.unblockProfile
  } catch (e) {
  }
}
