import gql from 'graphql-tag'

import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueJobOffer } from '@dinosband/bv-main-schema/dist/client-gql'
import { q_findManyJobOffer, q_findUniqueJobOffer, q_findManyJobOfferGraph } from './job-offer-gql'

export async function readJobOffer (client: ClientType, id: number) {
  try {
    const result = await client.query<{findUniqueJobOffer: types.JobOffer}>({
      query: findUniqueJobOffer,
      variables: {
        where: { id },
      } as types.QueryFindUniqueJobOfferArgs,
    })
    return result.data.findUniqueJobOffer
  } catch (e) {
  }
}

export async function readJobOfferGraph (client: ClientType, id: number) {
  try {
    const result = await client.query<{findUniqueJobOffer: types.JobOffer}>({
      query: q_findUniqueJobOffer,
      variables: {
        where: { id },
      } as types.QueryFindUniqueJobOfferArgs,
    })
    return result.data.findUniqueJobOffer
  } catch (e) {
  }
}

export async function readJobOffers (client: ClientType, variables: types.QueryFindManyJobOfferArgs) {
  try {
    const result = await client.query<{findManyJobOffer: types.JobOffer[]}>({
      query: q_findManyJobOffer,
      variables,
    })
    return result.data.findManyJobOffer
  } catch (e) {
  }
}

export async function readJobOfferGraphs (client: ClientType, variables: types.QueryFindManyJobOfferArgs) {
  try {
    const result = await client.query<{findManyJobOffer: types.JobOffer[]}>({
      query: q_findManyJobOfferGraph,
      variables,
    })
    return result.data.findManyJobOffer
  } catch (e) {
  }
}

export async function readMyJobOffers (client: ClientType, profileIds: number[]) {
  try {
    const result = await client.query<{findManyJobOffer: types.JobOffer[]}>({
      query: q_findManyJobOffer,
      variables: {
        where: {
          clientId: { in: profileIds },
        },
        orderBy: [
          { id: 'desc' },
        ],
      },
    })
    return result.data.findManyJobOffer
  } catch (e) {
  }
}

export async function readJobOfferState (client: ClientType, ids: number[]) {
  if (!ids.length) {
    return []
  }
  try {
    const result = await client.query<{findManyJobOffer: types.JobOffer[]}>({
      query: gql`query findManyJobOffer($where: JobOfferWhereInput) {
      findManyJobOffer(where: $where) {
        id
        state
      }
    }`,
      variables: {
        where: { id: { in: ids } },
      },
    })
    return result.data.findManyJobOffer as {id: number, state: types.JobOfferState}[]
  } catch (e) {
  }
}
