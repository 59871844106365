import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { updateOneMessageInfo } from '@dinosband/bv-main-schema/dist/client-gql'
import { m_upsertOneMessageInfo } from './message-info-gql'

export async function likeMessage (client: ClientType, userId: number, mesgId: number, chatRoomId: number): Promise<types.MessageInfo | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_upsertOneMessageInfo,
      variables: {
        where: { id: mesgId },
        update: {
          likedUsers: {
            connect: [{ id: userId }],
          },
        },
        create: {
          id: mesgId,
          chatRoom: { connect: { id: chatRoomId } },
          likedUsers: {
            connect: [{ id: userId }],
          },
        },
      } as types.MutationUpsertOneMessageInfoArgs,
    }) as MutationResult
    const ret = result.data.upsertOneMessageInfo || undefined
    return ret
  } catch (e) {
  }
}

export async function cancelLikeMessage (client: ClientType, userId: number, mesgId: number): Promise<types.MessageInfo | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneMessageInfo,
      variables: {
        where: { id: mesgId },
        data: {
          likedUsers: {
            disconnect: [{ id: userId }],
          },
        },
      } as types.MutationUpdateOneMessageInfoArgs,
    }) as MutationResult
    return result.data.updateOneMessageInfo
  } catch (e) {
  }
}

export async function bookmarkMessage (client: ClientType, userId: number, mesgId: number, chatRoomId: number): Promise<types.MessageInfo | null | undefined> {
  try {
    const result = await client.mutate({
      mutation: m_upsertOneMessageInfo,
      variables: {
        where: { id: mesgId },
        update: {
          bookmarkedUsers: {
            connect: [{ id: userId }],
          },
        },
        create: {
          id: mesgId,
          chatRoom: { connect: { id: chatRoomId } },
          bookmarkedUsers: {
            connect: [{ id: userId }],
          },
        },
      } as types.MutationUpsertOneMessageInfoArgs,
    }) as MutationResult
    return result.data.upsertOneMessageInfo
  } catch (e) {
  }
}

export async function cancelBookmarkMessage (client: ClientType, userId: number, mesgId: number): Promise<types.MessageInfo | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneMessageInfo,
      variables: {
        where: { id: mesgId },
        data: {
          bookmarkedUsers: {
            disconnect: [{ id: userId }],
          },
        },
      } as types.MutationUpdateOneMessageInfoArgs,
    }) as MutationResult
    return result.data.updateOneMessageInfo
  } catch (e) {
  }
}
