import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyUser } from '@dinosband/bv-main-schema/dist/client-gql'
import { findUserGraph, q_readAllUsers, findUserAndProfiles, q_readUserTimestamp, q_findUsersByMesgFilter } from './user-gql'

export async function readAllUsers (client: ClientType): Promise<types.User[] | undefined> {
  try {
    const result = await client.query({
      query: q_readAllUsers,
      variables: {
        where: {},
        orderBy: [
          {
            id: types.SortOrder.Asc,
          },
        ],
      } as types.QueryFindManyUserArgs,
    })
    return result.data.findManyUser
  } catch (e) {
  }
}

export function option_readUserGraph (id: number) {
  return {
    query: findUserGraph,
    variables: {
      where: {
        id,
      },
    } as types.QueryFindManyProfileArgs,
  }
}

export async function readUserGraph (client: ClientType, id: number): Promise<types.User | undefined> {
  const opt = option_readUserGraph(id)
  try {
    const result = await client.query(opt)
    return result.data.findUniqueUser
  } catch (e) {
  }
}

export async function readUserTimestamp (client: ClientType, id: number): Promise<types.User | undefined> {
  try {
    const result = await client.query({
      query: q_readUserTimestamp,
      variables: {
        where: { id },
      } as types.QueryFindUniqueUserArgs,
    })
    return result.data.findUniqueUser
  } catch (e) {
  }
}

export async function readUserAndProfiles (client: ClientType, id: number): Promise<types.User | undefined> {
  try {
    const result = await client.query({
      query: findUserAndProfiles,
      variables: {
        where: { id },
      } as types.QueryFindUniqueUserArgs,
    })
    return result.data.findUniqueUser
  } catch (e) {
  }
}

export async function readUsersOfProfile (client: ClientType, profileId: number): Promise<types.User[] | undefined> {
  try {
    const result = await client.query({
      query: findManyUser,
      variables: {
        where: {
          profiles: {
            some: {
              profileId: {
                equals: profileId,
              },
            },
          },
        },
      } as types.QueryFindManyUserArgs,
    })
    return result.data.findManyUser
  } catch (e) {
  }
}

export async function checkEmailExist (client: ClientType, email: string): Promise<boolean | undefined> {
  try {
    const result = await client.query({
      query: findManyUser,
      variables: {
        where: { email: { equals: email } },
      } as types.QueryFindManyUserArgs,
    })
    return !!result.data.findManyUser?.length
  } catch (e) {
  }
}

export async function findUsersByMesgFilter (client: ClientType, input: types.FindUsersByMesgFilterInput): Promise<types.FindUsersByMesgFilterOutput | undefined> {
  try {
    const result = await client.query({
      query: q_findUsersByMesgFilter,
      variables: {
        input,
      },
    })
    return result.data.findUsersByMesgFilter
  } catch (e) {
  }
}