import i18n from '@dinosband/bv-i18n'
import { types } from '../types'
import type { Store } from '../store'
import { getJobMatchingProcess } from './templates'
import { getTranslatedActionName, getTranslatedStateName } from './process-dictionary'
import { getProcessOfChatRoom } from './utils'

const STR_LOADING = i18n.tc('process.reading')

export class ProcessManager {
  protected _process: types.ChatRoomProcess | undefined | null = undefined
  protected _mesgState: types.JobOfferState = 'Open'
  protected _state: types.ProcessState | undefined
  protected _store!: Store
  protected _chatRoom: types.ChatRoom | undefined

  initialize (store: Store, chatRoomOrId: number | types.ChatRoom) {
    this._store = store
    if (typeof chatRoomOrId === 'number') {
      const chatRoom = store.publicChatRooms.find((cr) => cr.id === chatRoomOrId)
      if (chatRoom) {
        this._chatRoom = chatRoom
        this.setProcess(chatRoom)
      } else {
        console.error('processManger.initialize: can not find chatRoom', chatRoomOrId)
        this._process = null
      }
    } else {
      this._chatRoom = chatRoomOrId
      this.setProcess(this._chatRoom)
    }
  }

  protected setProcess (chatRoom: types.ChatRoom) {
    if (chatRoom.process) {
      this._process = getProcessOfChatRoom(chatRoom)
    } else {
      this._process = getJobMatchingProcess()
    }
  }

  get process () {
    return this._process
  }

  get state () {
    return this._state
  }

  get isLoading () {
    return this._process === undefined
  }

  get isProcessEmpty () {
    return this.process && !this.process.states.length
  }

  getState (jrspState: types.JobOfferResponseState) {
    const process = this.process
    if (process) {
      if (jrspState === 'Canceled') {
        return {
          id: 'Canceled',
          name: i18n.tc('process.canceled'),
        } as types.ProcessState
      } else {
        let state = process.states.find((st) => st.id === jrspState)
        if (!state) {
          // find next state if not found same state
          const states: types.JobOfferResponseState[] = [
            'Start', 'Open', 'Hired', 'WorkFinished', 'Closed',
          ]
          const inx = states.findIndex((st) => st === jrspState)
          for (let i = inx + 1; i < states.length; i++) {
            state = process.states.find((st) => st.id === states[i])
            if (state) {
              return state
            }
          }
        }
        return state
      }
    } else {
      return undefined
    }
  }

  getStartState () {
    return this.process?.states.find((st) => st.id === 'Start')
  }

  getAction (state: types.ProcessState | undefined, isClient: boolean): types.ProcessAction | undefined {
    const actions = this.getActions(state, isClient)
    if (actions.length) {
      return actions[0]
    }
    return undefined
  }

  getActions (state: types.ProcessState | undefined, isClient: boolean): types.ProcessAction[] {
    if (state) {
      const stateId = state.id
      if (isClient) {
        return this.process?.clientActions?.filter((act) => act.from === stateId) || []
      } else {
        return this.process?.providerActions?.filter((act) => act.from === stateId) || []
      }
    }
    return []
  }

  getApplyAction (): types.ProcessAction | undefined {
    let act = this.process?.providerActions?.find((act) => act.id === 'Apply')
    if (!act) {
      act = this.process?.clientActions?.find((act) => act.id === 'Apply')
    }
    return act
  }

  getActionName (action: types.ProcessAction, jrsp?: types.JobOfferResponse) {
    const getAction = (): types.ProcessAction => {
      if (!jrsp) {
        return action
      }
      if (action.id === 'Review' && this._chatRoom) {
        if (jrsp.reviewByClient && jrsp.reviewByProvider) {
          let toBeDone = ''
          if (this._store.profileIds.includes(jrsp.providerId)) {
            toBeDone = jrsp.toBeDoneByProvider || ''
          } else {
            toBeDone = jrsp.toBeDoneByClient || ''
          }
          if (toBeDone) {
            const toDo = toBeDone.split(',')[0]
            if (toDo) {
              return {
                id: 'Todo',
                name: toDo,
                from: action.from,
                to: action.to,
              }
            }
          }
        }
        return action
      } else {
        return action
      }
    }
    return getTranslatedActionName(this.process, getAction())
  }

  getStateName (state: types.ProcessState | undefined, jobState: types.JobOfferState | undefined) {
    if (this.isLoading) { return STR_LOADING }
    if (this.isProcessEmpty) { return i18n.tc('message.information') }

    if (state) {
      if ((state.id === 'Open' || state.id === 'Start') && jobState === 'Hired') {
        return i18n.tc('message.closed')
      }
      return getTranslatedStateName(this.process, state)
    } else {
      return i18n.tc('process.unknown')
    }
  }

  getStateColor (state: types.ProcessState | undefined, jobState: types.JobOfferState | undefined) {
    if (this.isLoading) { return 'dark' }
    if (this.isProcessEmpty) { return 'primary' }

    switch (state?.id) {
      case 'Start':
        return jobState === 'Open' ? 'primary' : 'dark'
      case 'Open':
        return jobState === 'Open' ? 'success' : 'dark'
      case 'Hired':
        return 'success'
      case 'WorkFinished':
        return 'success'
      case 'Closed':
        return 'dark'
      case 'Canceled':
        return 'danger'
      case undefined:
        return 'danger'
      default:
        return 'success'
    }
  }

  getMesgStateName (jobState: types.JobOfferState) {
    if (this.isProcessEmpty) { return i18n.tc('message.information') }
    if (jobState === 'Open') {
      const state = this.process?.states.find((st) => st.id === 'Start')
      if (state) {
        return getTranslatedStateName(this.process, state)
      }
      return i18n.tc('message.ongoing')
    } else {
      return i18n.tc('message.closed')
    }
  }

  getMesgStateColor (jobState: types.JobOfferState) {
    if (this.isProcessEmpty) { return 'success' }
    return jobState === 'Open' ? 'primary' : 'dark'
  }

  isBeforeHired (jrspState: types.JobOfferResponseState | undefined) {
    if (this.getStartState() && jrspState == 'Start') {
      return true
    } else if (!this.getStartState() && jrspState == 'Open') {
      return true
    }
    return false
  }

  isWorkFinished (jrspState: types.JobOfferResponseState | undefined) {
    if (this.isLoading) { return false }
    if (this.isProcessEmpty) { return false }
    if (!jrspState) { return false }
    return jrspState === 'WorkFinished'
  }
}
