import i18n from '@dinosband/bv-i18n'
import { TextFieldTypes, AlertInput } from '@ionic/core'

export type SimpleInputArgs = {
  header?: string
  message: string
  type?: TextFieldTypes | 'checkbox' | 'radio' | 'textarea'
  min?: number
  max?: number
  placeholder?: string
  value?: string
  inputs?: AlertInput[]
  checkInputs?: (values: Record<string, string>) => Promise<boolean> | boolean
}

export async function getSimpleInput (vue: Vue, args: SimpleInputArgs, repeat = true): Promise<string | Record<string, string> | undefined> {
  const inputs: AlertInput[] = args.inputs ?? [{
    name: 'input',
    value: args.value,
    type: args.type,
    placeholder: args.placeholder,
  }]

  // eslint-disable-next-line
  while (true) {
    const alert = await vue.$ionic.alertController.create({
      header: args.header,
      message: args.message,
      inputs,
      buttons: [
        {
          text: i18n.tc('common.cancel'),
          role: 'cancel',
        },
        {
          text: i18n.tc('common.ok'),
          role: 'ok',
        },
      ],
    })
    await alert.present()
    const res = await alert.onDidDismiss()
    if (res?.role === 'ok') {
      const values = res.data?.values as Record<string, string>
      if (values) {
        let valid = false
        if (args.checkInputs) {
          valid = await args.checkInputs(values)
        } else {
          valid = Object.values(values).every((v) => !!v)
          if (!valid) {
            await vue.$dbIon.alert(i18n.tc('keyword.retryInput'))
          }
          if (args.type === 'number') {
            const value = parseInt(values.input)
            if (Number.isNaN(value)) {
              await vue.$dbIon.alert('올바른 숫자 형식이 아닙니다.')
              valid = false
            } else if (args.min && value < args.min) {
              await vue.$dbIon.alert(`${args.min} 이상으로 입력해 주세요.`)
              valid = false
            } else if (args.max && args.max < value) {
              await vue.$dbIon.alert(`${args.max} 이하로 입력해 주세요.`)
              valid = false
            }
          }
        }
        if (!repeat || valid) {
          if (args.inputs) {
            return values
          } else {
            return values.input
          }
        }
        continue
      }
    }
    return undefined
    // if (args.inputs) {
    //   const values = res.data?.values
    //   if (args.checkInputs) {
    //     const valid = await args.checkInputs(values)
    //   }
    //   if (repeat && args.checkInputs && args.checkInputs(res.data?.values)) {
    //     await vue.$dbIon.alert('입력이 되지 않았습니다. 다시 입력 바랍니다.')
    //   } else {
    //     return res.data?.values
    //   }
    // } else {
    //   const input = res.data?.values?.input
    //   if (repeat) {
    //     if (input) {
    //       return input
    //     } else {
    //       await vue.$dbIon.alert('입력이 되지 않았습니다. 다시 입력 바랍니다.')
    //     }
    //   } else {
    //     return input
    //   }
    // }
    // } else {
    //   return undefined
    // }
  }
}

export async function getSimpleKeyword (vue: Vue, keywords: string[], keyword?: string): Promise<string | undefined> {
  return await vue.$dbIon.getSimpleInput(vue, {
    header: i18n.tc('components.channelsmenu36'),
    message: i18n.tc('keyword.inputKeyword'),
    value: keyword,
    checkInputs: async (values) => {
      const { input } = values as { input: string }
      if (input) {
        if (keywords.includes(input)) {
          await vue.$dbIon.alert(i18n.tc('keyword.overlapedKeyword'))
          return false
        }
        return true
      }
      return false
    },
  }) as string
}
