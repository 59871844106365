export type DbFab = {
  id: string
  icon: string
  badge?: string
  backgroundColor?: string
  iconColor?: string
  onClick: () => void
  priority?: number
  visible?: boolean
  checkVisible?: () => boolean
}

export class FabController {
  protected _buttons: DbFab[] = []

  addButton (fab: DbFab) {
    if (!this._buttons.find((i) => i.id === fab.id)) {
      this._buttons.push(fab)
    }
  }

  removeButton (id: string) {
    const inx = this._buttons.findIndex((i) => i.id === id)
    if (inx >= 0) {
      this._buttons.splice(inx, 1)
    }
  }

  get buttons (): DbFab[] {
    const visibleButtons = this._buttons.filter((i) => i.checkVisible?.() ?? i.visible)
    visibleButtons.sort((a, b) => (a.priority ?? 999) - (b.priority ?? 999))
    return visibleButtons
  }
}
