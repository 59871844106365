import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { apollo } from '@dinosband/bv-main-client'
import { apc } from '@/apollo'
import { isImageType, isVideoType, makeThumbnail, resizeFile } from '@dinosband/bv-attachment'
import { AttachmentInput, AttachmentType, requestAttachmentFile, uploadAttachmentFile } from '@dinosband/bv-attachment'
import _ from 'lodash'

type AttachmentInfo = {
  url: string
  attachment: AttachmentInput
  thumbnailId?: number
  thumbnail?: string
}

export function saveAttachment (
  vue: Vue,
  e: { target: HTMLInputElement },
  attType: AttachmentType,
  cb: (info: AttachmentInfo) => void,
): void {
  let w = 1024, h = 1024 // picture width & height
  let tw = 192, th = 192 // thumbnail width & height

  switch (attType) {
    case AttachmentType.PROFILE_IMAGE:
      w = 384, h = 384
      tw = 96, th = 96
      break
    case AttachmentType.CHANNEL_IMAGE:
      w = 1024, h = 1024
      tw = 384, th = 192
      break
    case AttachmentType.GENERAL_IMAGE:
      w = 1024, h = 1024
      tw = 192, th = 192
      break
  }
  _.forEach(e.target.files, async (file, i) => {
    if (file) {
      // eslint-disable-next-line
      const fileThumbnail = (file as any).thumbnail
      if (fileThumbnail || isImageType(file.type)) {
        // await vue.$dbIon.loading()
        const source = fileThumbnail ? fileThumbnail : file
        const resizedFile = await resizeFile(source, file.name, w, h, w / h)
        const thumbnailData = await makeThumbnail(source, tw, th, tw / th)
        const thumbnail = await apollo.createThumbnail(await apc.getClient(), thumbnailData)
        if (thumbnail) {
          const attachment = await requestAttachmentFile(resizedFile, attType, '')
          await uploadAttachmentFile(attachment, attType)
            .catch((error: Error | string) => vue.$dbIon.alert(error.toString()))
          // await vue.$dbIon.dismissLoading()
          if (attachment.url) {
            cb({
              thumbnailId: thumbnail.id,
              thumbnail: thumbnail.data,
              url: attachment.url,
              attachment,
            })
          }
        }
      } else if (isVideoType(file.type)) {
        await vue.$dbIon.alert(i18n.tc('utils.messengerattachment'))
        return
      } else {
        await vue.$dbIon.loading()
        const attachment = await requestAttachmentFile(file, AttachmentType.GENERAL_FILE)
        await uploadAttachmentFile(attachment, AttachmentType.GENERAL_FILE)
          .catch((error: Error | string) => vue.$dbIon.alert(error.toString()))
        await vue.$dbIon.dismissLoading()
        if (attachment.url) {
          cb({
            url: attachment.url,
            attachment,
          })
        }
      }
    }
  })
  e.target.value = ''
  e.target.files = null
}

export function savePicture (
  vue: Vue,
  e: { target: HTMLInputElement },
  cb: (info: AttachmentInfo) => void,
): void {
  _.forEach(e.target.files, async (file, i) => {
    if (file) {
      if (isImageType(file.type)) {
        const attType = AttachmentType.GENERAL_IMAGE
        await vue.$dbIon.loading()
        const attachment = await requestAttachmentFile(file, attType, '')
        await uploadAttachmentFile(attachment, attType)
          .catch((error: Error | string) => vue.$dbIon.alert(error.toString()))
        await vue.$dbIon.dismissLoading()
        if (attachment.url) {
          cb({
            thumbnailId: 0,
            url: attachment.url,
            attachment,
          })
        }
      }
    }
  })
  e.target.value = ''
  e.target.files = null
}
