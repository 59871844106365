import { ClientType } from '../apollo'
import { SmartSubscribe } from '../types'
import { debug } from '@dinosband/dbi-utils'
import { JobReview } from '@dinosband/bv-main-schema/dist/types'
import { onJobReviewAdded } from './job-review-gql'

type Callback = (newData: JobReview) => void

export function subscribeOnJobReviewAdded (client: ClientType, profileIds: number[], userId: number, cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onJobReviewAdded,
    variables: { profileIds, userId },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      debug.log('=> onJobReviewAdded')
      const newData = data.onJobReviewAdded as JobReview
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
