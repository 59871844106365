import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
import * as frag from '../fragments-gql'

export const findUserGraph = gql`
  query findUserGraph($where: UserWhereUniqueInput!) {
    findUniqueUser(where: $where) {
      ...UserGraph
    }
  }
  ${frag.UserGraph}
`

export const q_readUserTimestamp = gql`
  query q_readUserTimestamp($where: UserWhereUniqueInput!) {
    findUniqueUser(where: $where) {
      id
      timestamp
    }
  }
`

export const findUserAndProfiles = gql`
  query findUserAndProfiles($where: UserWhereUniqueInput!) {
    findUniqueUser(where: $where) {
      ...UserFields
      myProfiles {
        ...ProfileFields
      }
      blockedUsers {
        ...UserBlockingFields
      }
      blockingUsers {
        ...UserBlockingFields
      }
    }
  }
  ${client.UserFields}
  ${client.ProfileFields}
  ${client.UserBlockingFields}
`

export const q_readAllUsers = gql`
  query findManyUser($where: UserWhereInput!) {
    findManyUser(where: $where) {
      ...UserGraph
    }
  }
  ${frag.UserGraph}
`

export const q_checkUserExist = gql`
  query q_checkUserExist($tel: String!) {
    checkUserExist(tel: $tel) {
      tel
      exist
      email
    }
  }
`

export const q_findUsersByMesgFilter = gql`
  query findUsersByMesgFilter($input: FindUsersByMesgFilterInput!) {
    findUsersByMesgFilter(input: $input) {
      count
      userIds
    }
  }
`

export const m_requestSignInCode = gql`
  mutation m_requestSignInCode($input: RequestSignInCodeInput!) {
    requestSignInCode(input: $input) {
      ok
      code
    }
  }
`

export const m_signIn = gql`
  mutation m_signIn($input: SignInInput!) {
    signIn(input: $input) {
      userId
      token
      error
    }
  }
`

export const m_signUp = gql`
  mutation m_signUp($input: SignUpInput!) {
    signUp(input: $input) {
      userId
      token
    }
  }
`

export const m_deleteUser = gql`
  mutation m_deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      ok
    }
  }
`

export const m_updateMyPhoneNumber = gql`
  mutation m_updateMyPhoneNumber($input: UpdateMyPhoneNumberInput!) {
    updateMyPhoneNumber(input: $input) {
      ok
      error
    }
  }
`

export const m_updateDeviceToken = gql`
  mutation m_updateDeviceToken($input: DeviceTokenInput!) {
    updateDeviceToken(input: $input) {
      ok
    }
  }
`

export const m_updateOneUser = gql`
  mutation m_updateOneUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
    updateOneUser(where: $where, data: $data) {
      ...UserFields
    }
  }
  ${client.UserFields}
`

export const m_resendEmail = gql`
  mutation m_resendEmail($userId: Int!) {
    resendEmail(userId: $userId)
  }
`

export const onUserUpdated = gql`
  subscription onUserUpdated($userId: Int!) {
    onUserUpdated(userId: $userId) {
      ...UserFields
    }
  }
  ${client.UserFields}
`

export const onUserKeywordUpdated = gql`
  subscription onUserKeywordUpdated($userId: Int!) {
    onUserKeywordUpdated(userId: $userId) {
      ...UserKeyword
    }
  }
  ${client.UserKeyword}
`

export const onDontShowAgainUpdated = gql`
  subscription onDontShowAgainUpdated($userId: Int!) {
    onDontShowAgainUpdated(userId: $userId) {
      ...DontShowAgainFields
    }
  }
  ${client.DontShowAgainFields}
`

export const onProfileReviewUpdated = gql`
  subscription onProfileReviewUpdated($profileIds: [Int]!) {
    onProfileReviewUpdated(profileIds: $profileIds) {
      ...ProfileReview
    }
  }
  ${client.ProfileReview}
`
