import type { AxiosResponse, AxiosStatic } from 'axios'
import { getApiToken } from '@dinosband/bv-main-schema/dist/auth'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type ResponseType = Promise<AxiosResponse<any>>

export class HttpServer {
  protected _url = ''
  protected _axios: AxiosStatic | undefined

  get axios () {
    if (!this._axios) {
      throw 'HttpServer: not initialized'
    }
    return this._axios
  }

  setUrl (url: string): void {
    this._url = url

    // add '/' to url if it does not end with '/'
    if (this._url.slice(-1) != '/') {
      this._url += '/'
    }
  }

  async initialize () {
    if (!this._axios) {
      this._axios = (await import('axios')).default
    }
  }

  protected _getAuthHeader (token: string): Record<string, string> {
    if (token) {
      const apiToken = getApiToken(token)
      return { Authorization: `Bearer ${apiToken}` }
    } else {
      return {}
    }
  }

  async get (path: string, token: string): ResponseType {
    await this.initialize()
    const url = this._url + path
    const headers = this._getAuthHeader(token)
    const res = await this.axios.get(url, { headers })
    return res?.data
  }

  async post (path: string, param: unknown, token: string): ResponseType {
    await this.initialize()
    const url = this._url + path
    const headers = this._getAuthHeader(token)
    const res = await this.axios.post(url, param, { headers })
    return res.data
  }

  async put (path: string, param: unknown, token: string): ResponseType {
    await this.initialize()
    const url = this._url + path
    const headers = this._getAuthHeader(token)
    const res = await this.axios.put(url, param, { headers })
    return res.data
  }

  async delete (path: string, token: string): ResponseType {
    await this.initialize()
    const url = this._url + path
    const headers = this._getAuthHeader(token)
    const res = await this.axios.delete(url, { headers })
    return res.data
  }
}

export const httpServer = new HttpServer()
