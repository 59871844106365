import Vue from 'vue'
import '@dinosband/vue-ionic'
import { Components } from '@ionic/core'

import { Route } from '../core/interface'
import { Router, NativeComponent, NewPageCallback, RouteTransitionDirection } from '../core/router'
import { RouteNode } from '../core/route-node'
import { RouterEvent } from './router-event'
// import { isPlatform } from '@ionic/core'
// import { dbiAnalytics } from '@dinosband/dbi-analytics'
import { updateNavStack } from './nav-stack'

export type NodeReadyCallBack = (node: RouteNode) => Promise<boolean>

export class RouterForVue extends Router {
  useIonicRouter: boolean // URL history를 사용하지 않고 모든 것을 IonNav controller를 사용
  useNavController: boolean // URL history를 사용. stack navigation 동작은 IonNav controller를 사용 (path -> navStack 관리가 필요함)
  updatingView = false

  constructor (routes: Route[], waitForNodeReady: NodeReadyCallBack, onNewPage: NewPageCallback) {
    super(routes, waitForNodeReady, onNewPage)

    // this.useIonicRouter = true
    this.useIonicRouter = false
    // this.useIonicRouter = isPlatform('ios') || isPlatform('hybrid')

    this.useNavController = true
  }

  get event () {
    return RouterEvent
  }

  _getRouteNode (comp: NativeComponent): RouteNode | undefined {
    let vue = comp as unknown as Vue | undefined | null
    while (vue) {
      if (vue.routeNode) {
        return vue.routeNode
      }
      // const key = 'routeParams'
      // const keyDash = 'route-params'
      // if (Object.prototype.hasOwnProperty.call(vue.$attrs, key)) {
      //   return (vue.$attrs[key] as unknown) as DbParams
      // }
      // if (Object.prototype.hasOwnProperty.call(vue.$attrs, keyDash)) {
      //   return (vue.$attrs[keyDash] as unknown) as DbParams
      // }
      // if (Object.prototype.hasOwnProperty.call(vue.$props, key)) {
      //   return (vue.$props[key] as unknown) as DbParams
      // }
      // if (Object.prototype.hasOwnProperty.call(vue.$props, keyDash)) {
      //   return (vue.$props[keyDash] as unknown) as DbParams
      // }
      vue = vue.$parent
    }
    return undefined
  }

  async _pushNode (comp: NativeComponent, node: RouteNode) {
    const vue = comp as unknown as Vue
    const nav = vue.$el?.closest('ion-nav') as Components.IonNav
    await nav.push(node.component, {
      routeNode: node,
      routeParams: node.routeParams,
      params: node.componentParams,
    })
  }

  async _popNode (comp: NativeComponent) {
    const vue = comp as unknown as Vue
    await vue.$ionic.navController.pop(vue)
  }

  async _popToRoot (comp: NativeComponent) {
    const vue = comp as unknown as Vue
    await vue.$ionic.navController.popToRoot(vue)
  }

  async _closeAllModals () {
    let hasModal = true
    while (hasModal) {
      const modal = document.querySelector('ion-modal') as Components.IonModal
      if (modal) {
        await modal.dismiss(false, 'cancel')
      } else {
        hasModal = false
      }
    }
  }

  _setDetailPane (key: string, node: RouteNode) {
    RouterEvent.$emit('detail-pane-with-key', {
      key,
      node,
    })
  }

  _closeDetailPane (key: string, animated: boolean) {
    RouterEvent.$emit('detail-pane-with-key', {
      key,
      animated,
    })
  }

  _updateRoute (direction: RouteTransitionDirection) {
    RouterEvent.$emit('update-route', direction)
  }

  handleBackButton () {
    alert('BackButton')
  }

  async updateView (vue: Vue, node: RouteNode) {
    if (!this.updatingView) {
      this.updatingView = true
      if (!this.useIonicRouter && this.useNavController) {
        await updateNavStack(vue, node)
      }
      RouterEvent.$emit('params-changed', node)
      this.updatingView = false
    }
  }
}
