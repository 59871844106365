
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { ResizeObserver } from '@juggle/resize-observer'
import { isPlatform } from '@ionic/core'
import { createGesture, Gesture, GestureDetail, Animation, getTimeGivenProgression } from '@ionic/core'
import { iosBottomSheetLeaveAnimation, mdBottomSheetLeaveAnimation } from '../utils/bottom-sheet/animations'
import { waitFor } from '@dinosband/dbi-utils'

const clamp = (min: number, n: number, max: number) => Math.max(min, Math.min(n, max))
const computeDuration = (remaining: number, velocity: number) => clamp(400, remaining / Math.abs(velocity * 1.1), 500)

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      resizeObserver: null as ResizeObserver | null,

      modalEl: undefined as HTMLElement | undefined,
      wrapperEl: undefined as HTMLElement | undefined,
      headerEl: undefined as HTMLElement | undefined,
      contentEl: undefined as HTMLElement | undefined,

      top: -1,
      screenH: 0,
      contentH: 0,

      animation: undefined as Animation | undefined,
      gesture: undefined as Gesture | undefined,

      initialized: false,
    }
  },

  async mounted () {
    // await this.$nextTick()

    await waitFor(() => {
      this.modalEl = this.$el?.closest('ion-modal')
      this.wrapperEl = this.$el?.closest('.modal-wrapper')
      this.headerEl = this.$refs.header as HTMLElement
      this.contentEl = this.$refs.content as HTMLElement
      return !!(this.modalEl && this.wrapperEl && this.headerEl && this.contentEl)
    })

    if (this.modalEl && this.wrapperEl && this.headerEl && this.contentEl) {
      this.resizeObserver = new ResizeObserver((entries, observer) => {
        entries.forEach((entry, index) => {
          let { inlineSize: width, blockSize: height } = entry.contentBoxSize[0]
          width = Math.floor(width)
          height = Math.floor(height)
          if (entry.target.id === 'content') {
            this.onResize(width, height)
          } else {
            this.onScreenResize(width, height)
          }
        })
      })
      if (this.$el) {
        this.resizeObserver.observe(this.$el)
      }
      if (this.contentEl) {
        this.resizeObserver.observe(this.contentEl)
      }
    } else {
      console.error('failed to find elements')
    }
    // this.detect('views', () => {
    //   this.modalEl = this.$el?.closest('ion-modal')
    //   this.wrapperEl = this.$el?.closest('.modal-wrapper')
    //   this.headerEl = this.$refs.header as HTMLElement
    //   this.contentEl = this.$refs.content as HTMLElement
    //   return !!(this.modalEl && this.wrapperEl && this.headerEl && this.contentEl)
    // }, () => {
    //   this.resizeObserver = new ResizeObserver((entries, observer) => {
    //     entries.forEach((entry, index) => {
    //       let { inlineSize: width, blockSize: height } = entry.contentBoxSize[0]
    //       width = Math.floor(width)
    //       height = Math.floor(height)
    //       if (entry.target.id === 'content') {
    //         this.onResize(width, height)
    //       } else {
    //         this.onScreenResize(width, height)
    //       }
    //     })
    //   })
    //   if (this.$el) {
    //     this.resizeObserver.observe(this.$el)
    //   }
    //   if (this.contentEl) {
    //     this.resizeObserver.observe(this.contentEl)
    //   }
    // })
  },

  beforeDestroy () {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
    if (this.animation) {
      this.animation.destroy()
      this.animation = null
    }
    if (this.gesture) {
      this.gesture.destroy()
      this.gesture = null
    }

    this.modalEl = undefined
    this.wrapperEl = undefined
    this.headerEl = undefined
    this.contentEl = undefined
  },

  methods: {
    detect (name: string, detector: () => boolean, onDetected: () => void, count = 100) {
      this.$nextTick(() => {
        const detected = detector()
        if (detected) {
          onDetected()
        } else {
          count--
          if (count == 0) {
            alert(`Failed to detect ${name}`)
          } else {
            this.detect(name, detector, onDetected, count)
          }
        }
      })
    },

    onScreenResize (width: number, height: number) {
      this.screenH = this.modalEl?.clientHeight || 0

      if (!this.initialized) {
        this.initialize()
        this.initialized = true
      }
    },

    async onResize (width: number, height: number) {
      const contentH = this.contentEl?.offsetHeight || 0
      const headerH = this.headerEl?.offsetHeight || 0
      const safeAreaBtm = window.getComputedStyle(document.documentElement).getPropertyValue('--sab')
      this.contentH = contentH + headerH + parseInt(safeAreaBtm.split('px')[0]) // margin bottom  40px

      if (height > 0) {
        if (this.top < 0) {
          this.top = this.screenH - this.contentH
        } else if (this.animation && this.top > 0) {
          const newTop = this.screenH - this.contentH
          let step = this.top / this.screenH
          const newStep = newTop / this.screenH

          const duration = 100
          const tick = 20

          let count = Math.floor(duration / tick)
          const dStep = (newStep - step) / count

          this.animation.progressStart(true, step)
          this.animation.progressStep(step)
          const interval = setInterval(() => {
            if (count == 1) {
              step = newStep // to set end position exactly
            } else {
              step += dStep
            }
            this.animation.progressStep(step)
            if (--count <= 0) {
              clearInterval(interval)
              this.top = newTop
            }
          }, tick)
        }
      }
    },

    initialize () {
      // this.initializeTop()
      this.initializeBackdrop()
      this.initializeSwipe()
    },

    initializeBackdrop () {
      // enable backdrop dismiss
      const backdrop = this.modalEl?.querySelector('ion-backdrop') as HTMLElement
      if (backdrop) {
        backdrop.style.pointerEvents = 'auto'
      }
    },

    initializeSwipe () {
      const presentingEl = this.$parent?.$$props?.presentingElement
      if (isPlatform('ios')) {
        this.animation = iosBottomSheetLeaveAnimation(this.modalEl, presentingEl, 500, true)
      } else {
        this.animation = mdBottomSheetLeaveAnimation(this.modalEl, presentingEl, 500, true)
      }

      if (this.headerEl) {
        this.gesture = createGesture({
          el: this.headerEl,
          direction: 'y',
          onStart: this.onSwipeStart,
          onMove: this.onSwipeMove,
          onEnd: this.onSwipeEnd,
        })
        this.gesture.enable(true)
      }
    },

    getStep (detail: GestureDetail) {
      const position = this.top + detail.deltaY
      return position / this.screenH
    },

    onSwipeStart (detail: GestureDetail) {
      const step = this.getStep(detail)
      if (step <= 0) {
        return
      }

      this.animation?.progressStart(true, step)
      this.animation?.progressStep(step)
    },

    onSwipeMove (detail: GestureDetail) {
      const step = this.getStep(detail)
      if (step <= 0) {
        return
      }

      this.animation?.progressStep(step)
    },

    onSwipeEnd (detail: GestureDetail) {
      // swipe down: close the bottom sheet
      if (detail.velocityY > 0) {
        this.onCancel()
        return
      }

      const step = this.getStep(detail)
      if (step <= 0) {
        return
      }

      this.gesture?.enable(false)
      this.animation?.easing('cubic-bezier(1, 0, 0.68, 0.28)')

      const newStepValue = getTimeGivenProgression([0, 0], [1, 0], [0.68, 0.28], [1, 1], step)[0] + 0.001

      const duration = computeDuration(step * this.screenH, detail.velocityY)

      this.animation
        ?.progressEnd(0, newStepValue, duration)
        .onFinish(() => {
          this.gesture?.enable(true)
          this.top = 0
        })
    },

    async onCancel () {
      await this.$ionic.modalController.dismiss(undefined, 'cancel')
    },
  },
})
