import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { createOneAbusingReport, findManyAbusingReport } from '@dinosband/bv-main-schema/dist/client-gql'

export async function reportAbusingProfile (client: ClientType, userId: number, profileId: number, channelId: number | undefined, description: string): Promise<types.AbusingReport | undefined> {
  const variables = {
    data: {
      description,
      reporter: { connect: { id: userId } },
      profile: { connect: { id: profileId } },
    },
  } as types.MutationCreateOneAbusingReportArgs
  if (channelId) {
    variables.data.channel = { connect: { id: channelId } }
  }

  try {
    const result = await client.mutate({
      mutation: createOneAbusingReport,
      variables,
    }) as MutationResult
    return result.data.createOneAbusingReport
  } catch (e) {
  }
}

export async function reportAbusingChannel (client: ClientType, userId: number, channelId: number, description: string): Promise<types.AbusingReport | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneAbusingReport,
      variables: {
        data: {
          description,
          reporter: { connect: { id: userId } },
          channel: { connect: { id: channelId } },
        },
      } as types.MutationCreateOneAbusingReportArgs,
    }) as MutationResult
    return result.data.createOneAbusingReport
  } catch (e) {
  }
}

export async function reportAbusingMessage (client: ClientType, userId: number, chatRoomId: number, profileId: number, messageId: number, description: string): Promise<types.AbusingReport | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneAbusingReport,
      variables: {
        data: {
          description,
          reporter: { connect: { id: userId } },
          chatRoom: { connect: { id: chatRoomId } },
          profile: { connect: { id: profileId } },
          messageId,
        },
      } as types.MutationCreateOneAbusingReportArgs,
    }) as MutationResult
    return result.data.createOneAbusingReport
  } catch (e) {
  }
}

export async function readAbusingReportsOfUser (client: ClientType, userId: number): Promise<types.AbusingReport[] | undefined> {
  try {
    const result = await client.query({
      query: findManyAbusingReport,
      variables: {
        where: { reporterId: { equals: userId } },
      } as types.QueryFindManyAbusingReportArgs,
    })
    return result.data.findManyAbusingReport
  } catch (e) {
  }
}
