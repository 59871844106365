import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueJobReview, findManyJobReview } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readJobReviewsOfProfile (client: ClientType, profileId: number): Promise<types.JobReview[] | undefined> {
  try {
    const result = await client.query<{findManyJobReview: types.JobReview[]}>({
      query: findManyJobReview,
      variables: {
        where: {
          revieweeId: { equals: profileId },
        },
        orderBy: [
          { createdAt: 'desc' },
        ],
        take: 100,
      } as types.QueryFindManyJobReviewArgs,
    })
    return result.data.findManyJobReview
  } catch (e) {
  }
}

export async function readJobReviewOfJobOfferResponse (client: ClientType, jrspId: number, isProvider: boolean): Promise<types.JobReview | undefined> {
  try {
    const result = await client.query<{findUniqueJobReview: types.JobReview}>({
      query: findUniqueJobReview,
      variables: {
        where: isProvider ? {
          jobAsProviderId: jrspId,
        } : {
          jobAsClientId: jrspId,
        },
      } as types.QueryFindUniqueJobReviewArgs,
    })
    return result.data.findUniqueJobReview
  } catch (e) {
  }
}

export async function readReviewsOfJobOfferResponse (client: ClientType, jrspId: number): Promise<types.JobReview[] | undefined> {
  try {
    const result = await client.query<{findManyJobReview: types.JobReview[]}>({
      query: findManyJobReview,
      variables: {
        where: {
          OR: [
            {
              jobAsProviderId: { equals: jrspId },
            },
            {
              jobAsClientId: { equals: jrspId },
            },
          ],
        },
      } as types.QueryFindManyJobReviewArgs,
    })
    return result.data.findManyJobReview
  } catch (e) {
  }
}

export async function readMyJobReviews (client: ClientType, userId: number, channelId?: number): Promise<types.JobReview[] | undefined> {
  try {
    const result = await client.query<{findManyJobReview: types.JobReview[]}>({
      query: findManyJobReview,
      variables: {
        where: {
          OR: [
            { revieweeUserId: { equals: userId } },
            { reviewerUserId: { equals: userId } },
          ],
          channelId: channelId ? { equals: channelId } : undefined,
        },
        orderBy: [
          { createdAt: 'desc' },
        ],
        take: 100,
      } as types.QueryFindManyJobReviewArgs,
    })
    return result.data.findManyJobReview
  } catch (e) {
  }
}
