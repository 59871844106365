import Vue from 'vue'

export async function tooltip (vue: Vue, ev: Event, text: string): Promise<void> {
  const popover = await vue.$ionic.popoverController.create({
    component: 'DbTooltip',
    componentProps: { text },
    event: ev,
  })
  await popover.present()
}
