function testLocalStorage () {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export class Logger {
  readonly name: string
  readonly count: number
  protected pos = -1
  protected seq = 0
  protected initialized = false
  protected hasLocalStorage = true

  constructor (name: string, count: number) {
    this.name = name
    this.count = count
    this.hasLocalStorage = testLocalStorage()
  }

  protected makeKey (id: number) {
    return `${this.name}-${id}`
  }

  protected initialize () {
    if (this.initialized) {
      return
    }
    this.initialized = true

    if (!this.hasLocalStorage) {
      return
    }

    for (let i = 0; i < this.count; i++) {
      const key = this.makeKey(i)
      const log = localStorage.getItem(key)
      if (log) {
        const seq = parseInt(log.split(':')[0], 10)
        if (this.seq < seq) {
          this.seq = seq
          this.pos = i
        }
      }
    }
  }

  add (mesg: string) {
    if (!this.hasLocalStorage) {
      return
    }

    this.initialize()
    this.seq++
    this.pos = (this.pos + 1) % this.count
    const key = this.makeKey(this.pos)
    localStorage.setItem(key, `${this.seq}:${mesg}`)
  }

  get logs (): string[] {
    if (!this.hasLocalStorage) {
      return []
    }

    this.initialize()
    const logs = []
    for (let i = 0; i < this.count; i++) {
      const pos = (this.pos + 1 + i + this.count) % this.count
      const key = this.makeKey(pos)
      const item = localStorage.getItem(key)
      if (item) {
        logs.push(item)
        // const inx = item.indexOf(':')
        // if (inx >= 0) {
        //   logs.push(item.slice(inx + 1))
        // }
      }
    }
    return logs
  }
}