import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { createOneSysNotice } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createSysNotice (client: ClientType, data: types.SysNoticeCreateInput): Promise<types.SysNotice | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneSysNotice,
      variables: {
        data,
      } as types.MutationCreateOneSysNoticeArgs,
    }) as MutationResult
    return result.data.createOneSysNotice
  } catch (e) {
  }
}
