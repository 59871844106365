
export function convertTagsToText (text: string, newlinetag = '<br/>') {
  return text.replace(/&/gi, '&amp;').replace(/</gi, '&lt;').replace(/>/gi, '&gt;').replace(/\\"/gi, '&quot;').replace(/'/gi, '&#039;').replace(/\n/gi, newlinetag)
}

export function convertNewlineToTag (text: string) {
  return text.split('\n').join('<br />')
}

export function convertMessageAppropriately (message: string) {
  return convertTagsToText(message)
}

export function convertMessageAppropriatelyWithoutNewline (message: string) {
  return convertTagsToText(message, ' ')
}

if (typeof String.prototype.replaceAll === 'undefined') {
  String.prototype.replaceAll = function (this: string, match: string | RegExp, replace: string) {
    return this.replace(new RegExp(match, 'g'), () => replace)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any
}