import gql from 'graphql-tag'

import * as client from '@dinosband/bv-main-schema/dist/client-gql'

export const ProfileBase = gql`
  fragment ProfileBase on Profile {
    ...ProfileFields
    infos {
      ...ProfileInfoFields
    }
  }
  ${client.ProfileFields}
  ${client.ProfileInfoFields}
`

export const ChannelGraph = gql`
  fragment ChannelGraph on Channel {
    ...ChannelFields
    owner {
      ...ProfileFields
    }
    chatRoomMenus {
      ...ChannelChatRoomMenuFields
      chatRoom {
        ...ChatRoomFields
      }
    }
    experts {
      ...ChannelExpertFields
    }
    plugins {
      ...PluginFields
    }
  }
  ${client.ChannelFields}
  ${client.ProfileFields}
  ${client.ChannelChatRoomMenuFields}
  ${client.ChatRoomFields}
  ${client.ChannelExpertFields}
  ${client.PluginFields}
`

export const ExpertInfoGraph = gql`
  fragment ExpertInfoGraph on ExpertInfo {
    ...ExpertInfoFields
    channelExpert {
      ...ChannelExpertFields
    }
  }
  ${client.ExpertInfoFields}
  ${client.ChannelExpertFields}
`

export const Profile_ChannelGraph = gql`
  fragment Profile_ChannelGraph on Profile_Channel {
    ...Profile_ChannelFields
    expertInfos {
      ...ExpertInfoGraph
    }
    channel {
      ...ChannelGraph
    }
  }
  ${client.Profile_ChannelFields}
  ${ExpertInfoGraph}
  ${ChannelGraph}
`

export const JobOfferGraph = gql`
  fragment JobOfferGraph on JobOffer {
    ...JobOfferFields
    responses {
      ...JobOfferResponseFields
    }
  }
  ${client.JobOfferFields}
  ${client.JobOfferResponseFields}
`

export const JobOfferResponseGraph = gql`
  fragment JobOfferResponseGraph on JobOfferResponse {
    ...JobOfferResponseFields
    jobOffer {
      ...JobOfferFields
    }
  }
  ${client.JobOfferFields}
  ${client.JobOfferResponseFields}
`

export const ProfileGraph = gql`
  fragment ProfileGraph on Profile {
    ...ProfileBase
    channels {
      ...Profile_ChannelGraph
    }
  }
  ${ProfileBase}
  ${Profile_ChannelGraph}
`

export const User_ProfileGraph = gql`
  fragment User_ProfileGraph on User_Profile {
    ...User_ProfileFields
    profile {
      ...ProfileGraph
    }
  }
  ${client.User_ProfileFields}
  ${ProfileGraph}
`

export const UserBlockingGraph = gql`
  fragment UserBlockingGraph on UserBlocking {
    ...UserBlockingFields
    blockee {
      id
      profiles {
        id
        profileId
        # profile {
        #   id
        # }
      }
    }
    # blockeeProfile {
    #   id
    #   name
    #   deleted
    #   thumbnailId
    # }
    blocker {
      id
      profiles {
        id
        profileId
        # profile {
        #   id
        # }
      }
    }
  }
  ${client.UserBlockingFields}
`

export const ChatRoomGraph = gql`
  fragment ChatRoomGraph on ChatRoom {
    ...ChatRoomFields
    participants {
      ...Profile_ChatRoomFields
      profile {
        id
        name
        thumbnailId
        # ...ProfileBase
      }
    }
  }
  ${client.ChatRoomFields}
  ${client.Profile_ChatRoomFields}
`

export const User_ChatRoomViewGraph = gql`
  fragment User_ChatRoomViewGraph on User_ChatRoomView {
    ...User_ChatRoomViewFields
    chatRoom {
      ...ChatRoomGraph
    }
  }
  ${client.User_ChatRoomViewFields}
  ${ChatRoomGraph}
`

export const UserGraph = gql`
  fragment UserGraph on User {
    ...UserFields
    myProfiles {
      ...ProfileFields
    }
    profileChannels {
      ...Profile_ChannelFields
    }
    expertInfos {
      ...ExpertInfoFields
    }
    blockedUsers {
      ...UserBlockingFields
    }
    blockingUsers {
      ...UserBlockingFields
    }
  }
  ${client.UserFields}
  ${client.ProfileFields}
  ${client.Profile_ChannelFields}
  ${client.ExpertInfoFields}
  ${client.UserBlockingFields}
`

export const Profile_ChatRoomGraph = gql`
  fragment Profile_ChatRoomGraph on Profile_ChatRoom {
    ...Profile_ChatRoomFields
    chatRoom {
      ...ChatRoomFields
    }
  }
  ${client.Profile_ChatRoomFields}
  ${client.ChatRoomFields}
`

export const ChatRoomWithParticipants = gql`
  fragment ChatRoomWithParticipants on ChatRoom {
    ...ChatRoomFields
    participants {
      ...Profile_ChatRoomFields
      profile {
        ...ProfileBase
        users {
          id
          userId
        }
      }
    }
    jobOfferResponse {
      ...JobOfferResponseFields
      jobOffer {
        ...JobOfferFields
      }
    }
  }
  ${client.ChatRoomFields}
  ${client.Profile_ChatRoomFields}
  ${ProfileBase}
  ${client.JobOfferResponseFields}
  ${client.JobOfferFields}
`
