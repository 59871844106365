import Vue from 'vue'
import { isScalar } from './scalar'

export function copyScalarFields (dst: unknown, src: unknown) {
  const srcObj = src as Record<string, unknown>
  const dstObj = dst as Record<string, unknown>

  /*
    DO NOT DELETE: as src may have less fields
  */
  // delete fields not in src
  // for (const [key, value] of Object.entries(dstObj)) {
  //   if (isScalar(value)) {
  //     if (!Object.prototype.hasOwnProperty.call(srcObj, key)) {
  //       dstObj[key] = undefined
  //     }
  //   }
  // }

  // set new values
  for (const [key, value] of Object.entries(srcObj)) {
    if (isScalar(value)) {
      Vue.set(dstObj, key, value)
      // dstObj[key] = value
    }
  }
}

export interface DataObject {
  __typename: string
  id: number
  __CACHE_ID__?: number
  // eslint-disable-next-line
  [key: string]: any
}

// eslint-disable-next-line
function isDataObject (object: any): object is DataObject {
  if ((typeof object === 'object') && (object !== null)) {
    return '__typename' in object
  }
  return false
}

function toDataObject (obj: unknown): DataObject {
  if (isDataObject(obj)) {
    return obj as DataObject
  }
  throw `${obj} is not DataObject`
}

function isSameDataObject (a: DataObject, b: DataObject): boolean {
  return a.__typename === b.__typename && a.id === b.id
}

interface LinkStore {
  from: string
  field: string
  to: string | string[]
}

interface CacheStore {
  root: string
  objects: Record<string, unknown>[]
  links: LinkStore[]
}

type OnUpdatedCallback = () => void

//--------------------------------------------------------------------------------
// class DataObjectCache
//--------------------------------------------------------------------------------
export class DataObjectCache {
  protected cache = new Map<string, DataObject>()
  protected parents = new Map<string, DataObject[]>()
  protected _root: DataObject | undefined = undefined
  protected onUpdatedCb: OnUpdatedCallback | undefined = undefined
  protected cacheId = 1

  get size (): number {
    return this.cache.size
  }

  get root () {
    return this._root
  }

  protected makeKey (obj: DataObject): string {
    return `${obj.__typename}:${obj.id}`
  }

  onUpdated (cb: OnUpdatedCallback) {
    this.onUpdatedCb = cb
  }

  protected setUpdated () {
    if (this.onUpdatedCb) {
      this.onUpdatedCb()
    }
  }

  reset () {
    this._root = undefined
    this.cache = new Map<string, DataObject>()
    this.parents = new Map<string, DataObject[]>()
  }

  get (o: unknown): DataObject | undefined {
    const obj = toDataObject(o)
    const key = this.makeKey(obj)
    return this.cache.get(key)
  }

  build (o: unknown): DataObject {
    this.reset()
    this._root = Vue.observable(this.put(o))
    return this._root
  }

  put (o: unknown): DataObject {
    const obj = toDataObject(o)
    const exist = this.get(obj)
    const me = exist || obj
    if (exist) {
      copyScalarFields(me, obj)
    } else {
      const key = this.makeKey(obj)
      this.cache.set(key, obj)
      obj.__CACHE_ID__ = this.cacheId++
    }

    // put children
    Object.entries(obj).forEach(([key, val]) => {
      if (Array.isArray(val)) {
        const children = val.map((child) => {
          if (isDataObject(child)) {
            return this.put(child)
          } else {
            return child
          }
        })
        Vue.set(me, key, children)
        // me[key] = children
        children.forEach((child) => {
          if (isDataObject(child)) {
            this.link(me, child)
          }
        })
      } else if (isDataObject(val)) {
        const child = this.put(val)
        Vue.set(me, key, child)
        // me[key] = child
        this.link(me, child)
      } else if (typeof val === 'object') { // Json object
        Vue.set(me, key, val)
      }
    })

    return me
  }

  update (o: unknown): unknown | undefined {
    const ret = this.put(o)
    this.setUpdated()
    return ret
    // const obj = toDataObject(o)
    // const exist = this.get(obj)
    // if (exist) {
    //   copyScalarFields(exist, obj)
    // }
    // this.setUpdated()
    // return exist
  }

  protected link (parent: DataObject, child: DataObject) {
    const key = this.makeKey(child)
    const parents = this.parents.get(key)
    if (parents) {
      if (!parents.find((p) => isSameDataObject(parent, p))) {
        parents.push(parent)
      }
    } else {
      this.parents.set(key, [parent])
    }
  }

  addLink (p: unknown, c: unknown, linkOnly = false) {
    const parent = toDataObject(p)
    const child = linkOnly ? toDataObject(c) : this.put(toDataObject(c))
    this.link(parent, child)
    this.setUpdated()
    return child
  }

  delLink (p: unknown, c: unknown) {
    const parent = toDataObject(p)
    const child = toDataObject(c)
    const key = this.makeKey(child)

    // disconnect child from parent
    this.disconnect(parent, child)

    // remove reference
    const parents = this.parents.get(key)
    if (parents) {
      const inx = parents?.findIndex((p) => isSameDataObject(p, parent))
      if (inx >= 0) {
        parents.splice(inx, 1)
      }
      if (parents.length == 0) {
        this.delete(c)
      }
    }
    this.setUpdated()
  }

  delete (o: unknown): void {
    const obj = toDataObject(o)
    const key = this.makeKey(obj)
    const parents = this.parents.get(key)

    // delLink children
    this.delLinkChildren(obj)

    // disconnect obj from parents
    if (parents) {
      parents.forEach((parent) => {
        this.disconnect(parent, obj)
      })
    }
    // delete parents map for obj
    this.parents.delete(key)

    // remove obj from cache
    this.cache.delete(key)
    this.setUpdated()
  }

  protected disconnect (parent: DataObject, child: DataObject) {
    Object.entries(parent).forEach(([key, val]) => {
      if (Array.isArray(val)) {
        const inx = val.findIndex((v) => isSameDataObject(v, child))
        if (inx >= 0) {
          val.splice(inx, 1)
        }
      } else if (isDataObject(val) && isSameDataObject(val, child)) {
        delete parent[key]
      }
    })
  }

  protected delLinkChildren (obj: DataObject) {
    Object.entries(obj).forEach(([key, val]) => {
      if (Array.isArray(val)) {
        val.forEach((child) => {
          if (isDataObject(child)) {
            this.delLink(obj, child)
          }
        })
      } else if (isDataObject(val)) {
        this.delLink(obj, val)
      }
    })
  }

  toJSON (): string {
    if (!this.root) {
      return ''
    }

    // const tick = Date.now()
    const objects: Record<string, unknown>[] = []
    const links: LinkStore[] = []
    this.cache.forEach((obj, from) => {
      const clone: Record<string, unknown> = {}
      Object.entries(obj).forEach(([field, val]) => {
        if (Array.isArray(val)) {
          links.push({
            from,
            field,
            to: val.map((v) => this.makeKey(v)),
          })
        } else if (isDataObject(val)) {
          links.push({
            from,
            field,
            to: this.makeKey(val),
          })
        } else if (isScalar(val)) {
          clone[field] = val
        } else if (typeof val === 'object') { // Json object
          clone[field] = val
        }
      })
      delete clone.__CACHE_ID__
      objects.push(clone)
    })

    const store: CacheStore = {
      root: this.makeKey(this.root),
      objects,
      links,
    }
    const json = JSON.stringify(store)
    return json
  }

  fromJSON (json: string) {
    this.reset()
    // const tick = Date.now()
    const store = JSON.parse(json) as CacheStore
    if (!store.root) {
      return undefined
    }
    store.objects.forEach((o) => {
      const obj = toDataObject(o)
      obj.__CACHE_ID__ = this.cacheId++
      this.cache.set(this.makeKey(obj), obj)
    })
    store.links.forEach((link) => {
      const obj = this.cache.get(link.from)
      if (obj) {
        if (Array.isArray(link.to)) {
          const children: DataObject[] = []
          link.to.forEach((to) => {
            const child = this.cache.get(to)
            if (child) {
              children.push(child)
              this.link(obj, child)
            } else {
              console.error('ObjectCache.fromJSON: failed to find child: ', to)
            }
          })
          Vue.set(obj, link.field, children)
          // obj[link.field] = children
        } else {
          const child = this.cache.get(link.to)
          if (child) {
            Vue.set(obj, link.field, child)
            this.link(obj, child)
          }
          // obj[link.field] = this.cache.get(link.to)
        }
      } else {
        console.error('ObjectCache.fromJSON: failed to find object: ', link.from)
      }
    })
    this._root = Vue.observable(this.cache.get(store.root))
    if (!this.root) {
      console.error('ObjectCache.fromJSON: failed to find a root: ', store.root)
    }
    return this.root
  }
}

export const objCache = new DataObjectCache()
