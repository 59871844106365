import Vue from 'vue'
import VueRouter from 'vue-router'
import DbiRouterViewBase from './DbiRouterViewBase.vue'
import DbiRouterView from './DbiRouterView.vue'
import DbiTabsView from './DbiTabsView.vue'
import DbDetailPaneNav from './DbDetailPaneNav.vue'
import DbDetailEmpty from './DbDetailEmpty.vue'

Vue.use(VueRouter)

export function createVueRouter (): VueRouter {
  const routes = [
    {
      path: '*',
      component: DbiRouterViewBase,
      // component: DbiRouterView,
    },
  ]
  return new VueRouter({
    mode: 'history',
    routes,
  })
}

export function registerRouterViews (): void {
  Vue.component('DbiRouterViewBase', DbiRouterViewBase)
  Vue.component('DbiRouterView', DbiRouterView)
  Vue.component('DbiTabsView', DbiTabsView)
  Vue.component('DbDetailPaneNav', DbDetailPaneNav)
  Vue.component('DbDetailEmpty', DbDetailEmpty)
}
