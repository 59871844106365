import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyMessageInfo } from '@dinosband/bv-main-schema/dist/client-gql'
import { findManyMessageSummary } from './message-info-gql'

export async function readMessageSummaries (client: ClientType, mesgIds: number[]) {
  try {
    const result = await client.query<{findManyMessageSummary: types.MessageSummary[]}>({
      query: findManyMessageSummary,
      variables: {
        mesgIds,
      } as types.QueryFindManyMessageSummaryArgs,
    })
    return result.data?.findManyMessageSummary ?? []
  } catch (e) {
  }
}

export async function readMessageInfos (client: ClientType, mesgIds: number[]) {
  try {
    const result = await client.query<{findManyMessageInfo: types.MessageInfo[]}>({
      query: findManyMessageInfo,
      variables: {
        where: { id: { in: mesgIds } },
      } as types.QueryFindManyMessageInfoArgs,
    })
    return result.data.findManyMessageInfo
  } catch (e) {
  }
}

export async function readLatestMessages (client: ClientType, channelIds: number[]) {
  try {
    const result = await client.query<{findManyMessageInfo: types.MessageInfo[]}>({
      query: findManyMessageInfo,
      variables: {
        where: {
          jobOffer: {
            chatRoom: {
              channelId: { in: channelIds },
            },
          },
        },
        orderBy: [
          { id: 'desc' },
        ],
        take: 30,
      } as types.QueryFindManyMessageInfoArgs,
    })
    return result.data.findManyMessageInfo
  } catch (e) {
  }
}

export async function readSuggestedMessages (client: ClientType, channelIds: number[]) {
  try {
    const result = await client.query<{findManyMessageInfo: types.MessageInfo[]}>({
      query: findManyMessageInfo,
      variables: {
        where: {
          jobOffer: {
            chatRoom: {
              channelId: { notIn: channelIds },
            },
          },
        },
        orderBy: [
          { id: 'desc' },
        ],
        take: 30,
      } as types.QueryFindManyMessageInfoArgs,
    })
    return result.data.findManyMessageInfo
  } catch (e) {
  }
}

export async function readBookmarkMessages (client: ClientType, userId: number, cursor?: number, take?: number, channelId?: number) {
  try {
    const result = await client.query<{findManyMessageInfo: types.MessageInfo[]}>({
      query: findManyMessageInfo,
      variables: {
        where: {
          bookmarkedUsers: {
            some: { id: { equals: userId } },
          },
          channelId: channelId ? { equals: channelId } : undefined,
        },
        cursor: cursor ? { id: cursor } : undefined,
        skip: cursor ? 1 : 0,
        take,
        orderBy: [
          { id: 'desc' },
        ],
      } as types.QueryFindManyMessageInfoArgs,
    })
    return result.data.findManyMessageInfo
  } catch (e) {
  }
}

export async function readLikedMessages (client: ClientType, userId: number) {
  try {
    const result = await client.query<{findManyMessageInfo: types.MessageInfo[]}>({
      query: findManyMessageInfo,
      variables: {
        where: {
          likedUsers: {
            some: { id: { equals: userId } },
          },
        },
        orderBy: [
          { id: 'desc' },
        ],
      } as types.QueryFindManyMessageInfoArgs,
    })
    return result.data.findManyMessageInfo
  } catch (e) {
  }
}
