const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: 'home',
    component: 'BvaHome',
    label: 'Home',
    icon: 'home-outline',
  },
  {
    path: 'sign-in',
    component: 'BvaSignIn',
    label: 'Sign In',
    icon: '',
  },
  {
    path: 'channels',
    component: 'BvaChannels',
    label: 'Channels',
    icon: 'chatbubbles-outline',
  },
  {
    path: 'users',
    component: 'BvaUsers',
    label: 'Users',
    icon: 'people-outline',
  },
  {
    path: 'analytics',
    component: 'BvaAnalytics',
    label: 'Analytics',
    icon: 'bar-chart-outline',
  },
  {
    path: 'tod-lessons',
    component: 'BvaTodLessons',
    label: 'Tod Lessons',
    icon: 'school-outline',
  },
  {
    path: 'reports',
    component: 'BvaAbusingReports',
    label: 'Reports',
    icon: 'alert-circle-outline',
  },
  {
    path: 'notice',
    component: 'BvaNotice',
    label: 'Notice',
    icon: 'megaphone-outline',
  },
  {
    path: 'advertise',
    component: 'BvaAdList',
    label: 'Advertise',
    icon: 'gift-outline',
  },
  {
    path: 'avatars',
    component: 'BvaDefaultAvatars',
    label: 'Avatars',
    icon: 'person-circle-outline',
  },
  {
    path: '*',
    component: 'SamplePageNotFound',
  },
]

export default routes