import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'
// import * as frag from '../fragments-gql'

export const findManyMessageTranslations = gql`
  query getMessageTranslations($mesgId: Int!, $version: String) {
    getMessageTranslations(mesgId: $mesgId, version: $version) {
      ...MessageTranslation
    }
  }
  ${client.MessageTranslation}
`
export const findMessageTranslation = gql`
  query getMessageTranslation($mesgId: Int!, $target: String!, $version: String) {
    getMessageTranslation(mesgId: $mesgId, target: $target version: $version) {
      ...MessageTranslation
    }
  }
  ${client.MessageTranslation}
`
