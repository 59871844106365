import { Capacitor } from '@capacitor/core'
import { Window, Entry, FileEntry } from '../@types/cordova-plugin-file'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let MediaPicker: any

export async function presentMediaPicker (options?: { selectMode?: number, thumbnailResolution?: number }) {
  const args = {
    'selectMode': 101, //101=picker image and video , 100=image , 102=video
    'maxSelectCount': 1, //default 40 (Optional)
    'maxSelectSize': 50 * 1024 * 1024, // (Optional)
    'thumbnailQuality': 80,
    'thumbnailResolution': 512,
  }
  if (options?.selectMode) {
    args.selectMode = options.selectMode
  }
  if (options?.thumbnailResolution) {
    args.thumbnailResolution = options.thumbnailResolution
  }
  return new Promise<{file: File}[]>((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MediaPicker.getMedias(args, async (medias: any[]) => {
      if (!medias.length) {
        console.error('MediaPicker: ' + 'selection empty')
        resolve([])
        return
      }
      const result: { file: File}[] = []
      for (const i of medias) {
        let url: string = i.uri
        if (Capacitor.getPlatform() == 'android') {
          url = 'file://' + i.path
        }
        const thumbnail = await getThumbnail(i)
        const file = await getFile(url)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const f_: any = file; f_.thumbnail = thumbnail
        result.push({ file })
      }
      resolve(result)
    },
    reject)
  })
}

type ProgressCallback = (event: { loaded: number }) => void
export async function readFileEntry (entry: FileEntry, progress?: ProgressCallback) {
  return new Promise((resolve, reject) => {
    entry.file((file) => {
      const reader = new FileReader()
      reader.onprogress = (event: { loaded: number }) => {
        if (progress) {
          progress(event)
        }
      }
      reader.onloadend = async () => {
        const data = reader.result as unknown as ArrayBuffer
        // const f_ = file as any
        // f_.data = reader.result
        resolve(data)
      }
      reader.onerror = reject
      reader.readAsArrayBuffer(file)
    })
  })
}


export async function getThumbnail (media: unknown): Promise<string> {
  return new Promise((resolve, reject) => {
    MediaPicker.extractThumbnail(media, (data: { thumbnailBase64: string }) => {
      resolve('data:image/jpeg;base64,' + data.thumbnailBase64)
    }, reject)
  })
}

export async function getFile (url: string) {
  const w = window as unknown as Window
  return new Promise<File>((resolve, reject) => {
    w.resolveLocalFileSystemURL(url, (entry: Entry) => {
      const fileEntry = entry as FileEntry
      fileEntryToFile(fileEntry).then((file: File) => {
        resolve(file)
      }).catch((reason: unknown) => {
        reject
      })
      // const file = fileEntryToFile(fileEntry)
      // resolve(file)
    },
    reject)
  })
}

async function fileEntryToFile (entry: FileEntry): Promise<File> {
  return new Promise((resolve, reject) => {
    entry.file(async (file: File) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const f_ = file as any
      // if (Capacitor.getPlatform() == 'android') {
      //   f_.fileURL = entry.toURL()
      // } else if (Capacitor.getPlatform() == 'ios') {
      f_.entry = entry
      // }
      resolve(file)
    },
    reject)
  })
}

