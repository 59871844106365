import * as storage from './preferences-store'
import * as secure from './secure-store'

type _PROPERTY_TYPES = 'number' | 'string' | 'boolean'| 'object'

export function settings_property (type: _PROPERTY_TYPES) {
  return _property('storage', storage, type)
}

export function secure_property (type: _PROPERTY_TYPES) {
  return _property('secure', secure, type)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _property (storeType: string, store: any, type: _PROPERTY_TYPES) {
  return (target: unknown, key: string) => {
    const _key = Symbol()
    const _initialized = Symbol()
    Object.defineProperty(target, key, {
      set: function (value) {
        const initialized = this[_initialized]
        if (!initialized) {
          // initialize with default value
          this[_initialized] = true
          const promise = store.get(key, type).then((storageValue: unknown) => {
            this[_key] = storageValue ?? value // if not stored, use default value
          }).catch((e: unknown) => {
            this[_key] = value // if not stored, use default value
          })
          this.initializing.push(promise)
          return
        }
        // save even if is same object
        this[_key] = value
        store.set(key, value).then((result: unknown) => {
        }).catch((e: unknown) => {
          // console.error(`${storeType}/settings-set:`, key, value, e)
        })
      },
      get: function () {
        return this[_key]
      },
    })
  }
}