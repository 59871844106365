import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'

export const upsertOneUserUsage = gql`
  mutation upsertOneUserUsage($where: UserUsageWhereUniqueInput!, $create: UserUsageCreateInput!, $update: UserUsageUpdateInput!) {
    upsertOneUserUsage(where: $where, create: $create, update: $update) {
      ...UserUsageFields
    }
  }
  ${client.UserUsageFields}
`