
import Vue from 'vue'
import { MenuItem } from '../utils'

export default Vue.extend({
  props: {
    root: {
      type: Object,
      default: undefined,
    },
    maxLevel: {
      type: Number,
      default: 2,
    },
  },

  data () {
    return {
      level: 1,
    }
  },

  computed: {
    topNodes (): MenuItem[] {
      const root = this.root as MenuItem
      if (root) {
        return this.root.children
      } else {
        return []
      }
    },
  },

  methods: {
    menuLabel (node: MenuItem): string {
      const suffix = this.level < this.maxLevel && node.hasChildren() ? '...' : ''
      return node.name + suffix
    },

    async onClick (ev: CustomEvent, node: MenuItem): Promise<void> {
      if (this.level < this.maxLevel && node.hasChildren()) {
        const popover = await this.$ionic.popoverController.create({
          component: 'DbDropDownPopover',
          componentProps: {
            node,
            onSelected: this.onSubmenuSelected,
            level: this.level + 1,
            maxLevel: this.maxLevel,
          },
          event: ev,
          translucent: true,
        })
        await popover.present()
      } else {
        this.$emit('selected', node)
      }
    },

    onSubmenuSelected (node: MenuItem): void {
      this.$emit('selected', node)
    },
  },
})
