import { ClientType } from '../apollo'
import { types } from '../types'

import { updateOneChatRoom } from '@dinosband/bv-main-schema/dist/client-gql'

export async function updateChatRoom (client: ClientType, id: number, data: types.ChatRoomUpdateInput): Promise<types.ChatRoom | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneChatRoom,
      variables: {
        where: { id },
        data,
      },
    })
    return result.data.updateOneChatRoom
  } catch (e) {
  }
}
