import { ListNode, OrderedList } from './ordered-list'

export class LRUList<K extends number | string, T> {
  protected list = new OrderedList<K, T>()
  protected _minKey: K | undefined
  protected _maxKey: K | undefined

  get count () {
    return this.list.count
  }

  get minKey () {
    return this._minKey
  }

  get maxKey () {
    return this._maxKey
  }

  protected updateKeyRangeAdd (key: K) {
    if (this._minKey === undefined || key < this._minKey) {
      this._minKey = key
    }
    if (this._maxKey === undefined || this._maxKey < key) {
      this._maxKey = key
    }
  }

  protected updateKeyRangeDelete (key: K) {
    if (this.count == 0) {
      this._minKey = undefined
      this._maxKey = undefined
    } else {
      if (key === this._minKey) {
        this._minKey = this.list.findMinKey()
      }
      if (key === this._maxKey) {
        this._maxKey = this.list.findMaxKey()
      }
    }
  }

  reset (): void {
    this._minKey = undefined
    this._maxKey = undefined
    this.list.clear()
  }

  get (key: K): T | null {
    const node = this.list.find(key)
    if (node) {
      this.list.moveToRear(node)
      return node.value
    }
    return null
  }

  touch (key: K): void {
    const node = this.list.find(key)
    if (node) {
      this.list.moveToRear(node)
    }
  }

  set (key: K, value: T): void {
    this.updateKeyRangeAdd(key)
    const node = this.list.find(key)
    if (node) {
      node.value = value
      this.list.moveToRear(node)
    } else {
      const newNode = new ListNode<K, T>(key, value)
      this.list.push(newNode)
    }
  }

  delete (key: K): void {
    const node = this.list.find(key)
    if (node) {
      this.list.delete(node)
      this.updateKeyRangeDelete(key)
    }
  }

  deleteOldest (): K | null {
    const node = this.list.shift()
    if (node) {
      this.updateKeyRangeDelete(node.key)
      return node.key
    }
    return null
  }

  getOldest (): T | null {
    const node = this.list.first
    if (node) {
      return node.value
    } else {
      return null
    }
  }

  forEach (cb: (key: K, value: T) => void) {
    let node = this.list.first
    while (node) {
      cb(node.key, node.value)
      node = node.next
    }
  }

  dump () {
    let node = this.list.first
    while (node) {
      const preKey = node.prev ? node.prev.key : -1
      const nextKey = node.next ? node.next.key : -1
      node = node.next
    }
  }
}