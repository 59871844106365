import type { ApolloCache, NormalizedCacheObject } from '@apollo/client/core'
import { PubSub, waitFor } from '@dinosband/dbi-utils'
import { BvApolloClientImplBase } from './apollo-impl-base'
import { ErrorCb, EV_ERROR, EV_RECONNECTED, EV_RECONNECTING } from './types'
import type { ClientType } from './types'

export class BvApolloClient {

  initialized = false

  protected pubsub = new PubSub()
  protected authToken: string | undefined
  protected _impl: BvApolloClientImplBase | undefined
  protected get impl () {
    if (this._impl) {
      return this._impl
    }
    throw 'BvApolloClient: not initialized'
  }

  async createApolloClient (httpURI: string, wsURI: string, cache?: ApolloCache<NormalizedCacheObject>) {
    const { BvApolloClientImpl } = await import('./apollo-impl')
    this._impl = new BvApolloClientImpl(this.pubsub)
    this._impl.setAuthToken(this.authToken)
    await this._impl.createApolloClient(httpURI, wsURI, cache)
    this.initialized = true
  }

  async getClient (): Promise<ClientType> {
    await waitFor(() => this.initialized, 1_000 * 60)
    return this.impl.client
  }

  // get wsClient () {
  //   return this._wsClient
  // }

  get cache () {
    return this.impl.cache
  }

  get isLive () {
    return Boolean(this._impl?.isLive)
  }

  setAuthToken (token: string | undefined) {
    this.authToken = token
    if (this._impl) {
      this._impl.setAuthToken(token)
    }
  }

  getAuthToken () {
    return this.authToken
  }

  onReconnected (cb: () => void) {
    return this.pubsub.subscribe(EV_RECONNECTED, cb)
  }

  onReconnecting (cb: () => void) {
    return this.pubsub.subscribe(EV_RECONNECTING, cb)
  }

  onError (cb: ErrorCb) {
    return this.pubsub.subscribe(EV_ERROR, cb)
  }

  cleanup () {
    this.initialized = false
    this._impl?.cleanup()
  }

  async reconnect () {
    this.initialized = false
    await this._impl?.reconnect()
    this.initialized = !!this._impl
  }

  async reconnectSubscription () {
    await this._impl?.reconnectSubscription()
  }
}
