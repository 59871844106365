
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { types, apollo } from '@dinosband/bv-main-client'
import { apc, mapc } from '@/apollo'
import { Message } from '@dinosband/bv-messenger-schema'
import { MessageDeleteReason } from '@dinosband/bv-messenger-schema'
import { queryMessageDetail, deleteMessage } from '@dinosband/bv-messenger-client'
import { format } from 'date-fns'

interface Params {
  id: number
}

export default Vue.extend({
  props: {
    params: {
      type: Object as () => Params,
      default: undefined,
    },
  },

  data () {
    return {
      report: undefined as types.AbusingReport | undefined,
      message: undefined as Message | undefined,
      reaction: undefined as types.Reaction | undefined,
      response: '',
      close: false,
      reporter: undefined as types.Profile | undefined,
    }
  },

  computed: {
    type (): string {
      if (this.report?.channel) {
        return i18n.tc('report.platform')
      }
      if (this.report?.profile) {
        return i18n.tc('report.profile')
      }
      if (this.report?.messageId) {
        return i18n.tc('report.message')
      }
      return '??'
    },

    channel (): types.Channel | undefined {
      if (this.report?.channel) {
        return this.report.channel
      }
      if (this.report?.chatRoom?.channel) {
        return this.report.chatRoom.channel
      }
      return undefined
    },

    channelName (): string {
      return this.channel?.name ?? i18n.tc('strings.components.aAbusingReportAction14')
    },

    chatRoomName (): string {
      return this.report?.chatRoom?.name || i18n.tc('strings.components.aAbusingReportAction14')
    },

    profile (): types.Profile | undefined {
      return this.report?.profile || undefined
    },

    profileName (): string {
      return this.report?.profile?.name || i18n.tc('strings.components.aAbusingReportAction14')
    },

    userId (): number {
      return this.profile?.userId ?? 0
    },

    reporterUserId (): number {
      return this.report?.reporterId ?? 0
    },

    reporterName (): string {
      return this.reporter?.name ?? ''
    },

    messageContent (): string {
      return this.message?.props.body || i18n.tc('components.notification-tabNotificationsMesg05')
    },

    messageThumbnail (): string {
      return this.message?.props.attachments?.[0]?.thumbnail ?? ''
    },

    comment (): string | undefined {
      const props = this.reaction?.props as types.ReactionProps | undefined
      return props?.comment
    },

    actions (): types.ReportAction[] {
      return this.report?.actions || []
    },

    description (): string {
      return this.report?.description ?? '내용 없음'
    },

    createdAt (): string {
      return this.formatDate(this.report?.createdAt) ?? 'Error'
    },

    lastActionAt (): string {
      return this.formatDate(this.report?.lastActionAt) ?? '없음'
    },
  },

  async created () {
    let id = this.params?.id || this.routeParams?.id
    if (typeof id === 'string') {
      id = parseInt(id, 10)
    }
    if (id) {
      this.report = await apollo.readAbusingReport(await apc.getClient(), id)
      if (this.report?.messageId) {
        this.message = await queryMessageDetail(await mapc.getClient(), this.report?.chatRoomId ?? 0, this.report?.messageId)
      }
      if (this.report?.reactionId) {
        this.reaction = await apollo.readReaction(await apc.getClient(), this.report.reactionId)
      }
      const reporterUserId = this.reporterUserId
      if (reporterUserId && this.channel) {
        const pcs = await apollo.readProfile_ChannelsOfUser(await apc.getClient(), reporterUserId, this.channel.id)
        if (pcs?.length) {
          this.reporter = pcs[0].profile
        }
      }
    }
  },

  methods: {
    formatDate (dateStr: string | undefined) {
      if (dateStr) {
        return format(new Date(dateStr), 'yyyy-MM-dd')
      }
      return undefined
    },

    actionText (action: types.ReportAction) {
      return `${action.description} -- [${action.createdAt}]`
    },

    onClickMessage () {
      if (this.message) {
        this.$dbIon.doModal(this, 'BvaUpdateMessage', { message: this.message })
      }
    },

    async warning () {
      const userId = this.userId
      if (userId) {
        const warning: types.UserPenaltyPropsWarning = {
          reason: 'message',
          chatRoomId: this.report?.chatRoomId || undefined,
          messageId: this.report?.messageId || undefined,
        }
        const res = await apollo.warnUser(await apc.getClient(), userId, JSON.stringify(warning))
        if (res) {
          this.$dbIon.toast(i18n.tc('strings.components.aAbusingReportAction18', 1, { userId }))
        }
      }
    },

    async suspend (days: number) {
      const userId = this.userId
      if (userId) {
        const res = await apollo.suspendUser(await apc.getClient(), userId, days * 24)
        if (res) {
          this.$dbIon.toast(i18n.tc('strings.components.aAbusingReportAction19', 1, { userId, days: days }))
        }
      }
    },

    async expel () {
      const userId = this.userId
      if (userId) {
        const res = await apollo.expelUser(await apc.getClient(), userId)
        if (res) {
          this.$dbIon.toast(i18n.tc('strings.components.aAbusingReportAction20', 1, { userId }))
        }
      }
    },

    async release () {
      const userId = this.userId
      if (userId) {
        const res = await apollo.releaseUser(await apc.getClient(), userId)
        if (res) {
          this.$dbIon.toast(i18n.tc('strings.components.aAbusingReportAction21', 1, { userId }))
        }
      }
    },

    async blockMessage () {
      const messageId = this.report?.messageId
      const chatroomId = this.report?.chatRoomId
      if (messageId && chatroomId) {
        const result = await deleteMessage(await mapc.getClient(), messageId, chatroomId, MessageDeleteReason.POLICY_VIOLATION)
        if (result?.data?.deleteMessage?.id != undefined) {
          this.$dbIon.alert(i18n.tc('strings.components.aAbusingReportAction22'))
        }
      }
    },

    async onSubmit () {
      if (this.report && this.response) {
        if (await this.$dbIon.yesNoAlert(i18n.tc('strings.components.aAbusingReportAction23'),
          i18n.tc('strings.components.aAbusingReportAction29'), i18n.tc('strings.components.aAbusingReportAction30'))) {
          await apollo.createReportAction(await apc.getClient(), this.report.id, this.response, this.close)
          this.$dbIon.closePage(this, true)
        }
      }
    },

    async onAction (ev: Event) {
      await this.$dbIon.showActionMenu(this, ev, {
        title: i18n.tc('strings.components.aAbusingReportAction13'),
        items: [
          {
            text: this.profile ? i18n.tc('notification.warning') : '',
            handler: async () => {
              await this.warning()
            },
          },
          {
            text: this.profile ? i18n.tc('strings.components.aAbusingReportAction24') : '',
            handler: async () => {
              await this.suspend(3)
            },
          },
          {
            text: this.profile ? i18n.tc('strings.components.aAbusingReportAction25') : '',
            handler: async () => {
              await this.suspend(10)
            },
          },
          {
            text: this.profile ? i18n.tc('strings.components.aAbusingReportAction26') : '',
            handler: async () => {
              await this.expel()
            },
          },
          {
            text: this.profile ? i18n.tc('strings.components.aAbusingReportAction27') : '',
            handler: async () => {
              await this.release()
            },
          },
          {
            text: this.message ? i18n.tc('strings.components.aAbusingReportAction28') : '',
            handler: async () => {
              await this.blockMessage()
            },
          },
        ],
      }, 'ios')
    },

    onClickShowMessage () {
      const channelId = this.channel?.id ?? 0
      const chatRoomId = this.report?.chatRoomId ?? 0
      const messageId = this.report?.messageId ?? 0
      this.showMessage(channelId, chatRoomId, messageId)
    },

    showMessage (channelId: number, chatRoomId: number, messageId: number) {
      const host = process.env.NODE_ENV === 'development' ? 'http://121.67.5.252:8082' : 'https://webapp.bizplat.app'
      const url = `${host}/tabs/_main__id=${channelId}/+BvMessenger__chatRoomId=${chatRoomId}__startMessageId=${messageId}`
      window.open(url)
    },
  },
})
