
import i18n from '@dinosband/bv-i18n'
import Vue from 'vue'
import { analyze, setOutput } from './analyze'
import { memConsole } from './console'

type Moment = {
  _d: Date
}

export default Vue.extend({
  data () {
    const rangeFrom = new Date()
    rangeFrom.setHours(0, 0, 0)
    const rangeTo = new Date()
    rangeTo.setHours(23, 59, 59)

    return {
      showProgress: false,
      progress: 0,
      memConsole,
      rangeFrom,
      rangeTo,
    }
  },

  computed: {
    logs (): string[] {
      return this.memConsole.logs
    },
  },

  created () {
    setOutput(memConsole)
  },

  methods: {
    onChangeRange (range: Moment[]) {
      this.rangeFrom = range[0]._d
      this.rangeFrom.setHours(0, 0, 0)
      this.rangeTo = range[1]._d
      this.rangeTo.setHours(23, 59, 59)
    },

    async onAnalyze () {
      const range = {
        from: this.rangeFrom,
        to: this.rangeTo,
      }
      memConsole.clear()
      this.progress = 0
      this.showProgress = true
      // this.$dbIon.loading()
      await analyze(range, (p) => {
        this.progress = p
      })
      // this.$dbIon.dismissLoading()
      this.showProgress = false
    },
  },
})
