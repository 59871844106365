import { ClientType } from '../apollo'
import { types } from '../types'

import {
  findManyProduct,
  findUniqueProduct,
  updateOneProduct,
  createOneProduct,
  deleteOneProduct,
} from '@dinosband/bv-main-schema/dist/client-gql'

export async function createProduct (client: ClientType, data: types.ProductCreateInput): Promise<types.Product | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneProduct,
      variables: {
        data,
      } as types.MutationCreateOneProductArgs,
    })
    return result.data.createOneProduct
  } catch (e) {
  }
}

export async function readProducts (client: ClientType, where: types.ProductWhereInput): Promise<types.Product[] | undefined> {
  try {
    const result = await client.query({
      query: findManyProduct,
      variables: {
        where,
      } as types.QueryFindManyProductArgs,
    })
    return result.data.findManyProduct
  } catch (e) {
  }
}

export async function readProduct (client: ClientType, id: number): Promise<types.Product | undefined> {
  try {
    const result = await client.query({
      query: findUniqueProduct,
      variables: {
        where: { id },
      } as types.QueryFindUniqueProductArgs,
    })
    return result.data.findUniqueProduct
  } catch (e) {
  }
}

export async function updateProduct (client: ClientType, id: number, data: types.ProductUpdateInput): Promise<types.Product | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneProduct,
      variables: {
        where: { id },
        data,
      } as types.MutationUpdateOneProductArgs,
    })
    return result.data.updateOneProduct
  } catch (e) {
  }
}

export async function deleteProduct (client: ClientType, id: number): Promise<types.Product | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteOneProduct,
      variables: {
        where: { id },
      } as types.MutationDeleteOneProductArgs,
    })
    return result.data.deleteOneProduct
  } catch (e) {
  }
}