import { FirebaseKeys } from './types'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'

export class Analytics {
  isInitialized = false
  isEnabled = true

  async initialilzeGoogleAnalytics (firebaseKeys: FirebaseKeys) {
    await FirebaseAnalytics.initializeFirebase({
      apiKey: firebaseKeys.apiKey,
      authDomain: firebaseKeys.authDomain,
      projectId: firebaseKeys.projectId,
      storageBucket: firebaseKeys.storageBucket,
      messagingSenderId: firebaseKeys.messagingSenderId,
      appId: firebaseKeys.appId,
      measurementId: firebaseKeys.measurementId,
    })
    this.isInitialized = true
  }

  async setUserId (id: number) {
    if (!this.isInitialized) { return }
    await FirebaseAnalytics.setUserId({ userId: id.toString() })
  }

  async setUserProperty (name: string, value: string) {
    if (!this.isInitialized) { return }
    await FirebaseAnalytics.setUserProperty({ name, value })
  }

  async logEvent (name: string, params: Record<string, unknown>) {
    if (!this.isInitialized) { return }
    if (this.isEnabled) {
      await FirebaseAnalytics.logEvent({ name, params })
    }
  }

  async setCollectionEnabled (enabled: boolean) {
    if (!this.isInitialized) { return }
    await FirebaseAnalytics.setCollectionEnabled({ enabled })
  }

  async setSessionTimeoutDuration (duration: number) {
    if (!this.isInitialized) { return }
    await FirebaseAnalytics.setSessionTimeoutDuration({ duration })
  }

  async enable () {
    if (!this.isInitialized) { return }
    this.isEnabled = true
    FirebaseAnalytics.enable()
  }

  async disable () {
    if (!this.isInitialized) { return }
    this.isEnabled = false
    FirebaseAnalytics.disable()
  }
}
