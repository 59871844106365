import { ClientType } from '../apollo'
import { types } from '../types'

import { updateOneUser_ChatRoomView_ } from './user_chatroom_view-gql'

export async function updateUser_ChatRoomView (client: ClientType, id: number, data: types.User_ChatRoomViewUpdateInput) {
  try {
    const result = await client.mutate({
      mutation: updateOneUser_ChatRoomView_,
      variables: {
        id,
        data,
      },
    })
    return result.data?.updateOneUser_ChatRoomView
  } catch (e) {
  }
}
