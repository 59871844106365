import { isPlatform } from '@ionic/core'
import { Device, DevicePlugin } from '@capacitor/device'

export const i18nDefaultLocale = process.env.VUE_APP_I18N_LOCALE || 'ko'
export const i18nFallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'

export async function detectLanguage () {
  let locale = ''
  if (isPlatform('hybrid')) {
    const device: DevicePlugin = Device
    const res = await device.getLanguageCode()
    locale = res.value
  } else {
    locale = _getBrowserLocale() ?? i18nDefaultLocale
  }
  const language = getLanguageFromLocale(locale)
  return language
}

export function _getBrowserLocale (options = {}) {
  const defaultOptions = { countryCodeOnly: false }

  const opt = { ...defaultOptions, ...options }

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim()

  return trimmedLocale
}

// function _getTargetLanguage (): string {
//   //const locale = _getBrowserLocale({ countryCodeOnly: true })
//   let locale = _getBrowserLocale()
//   locale = locale?.length && locale.includes('zh') ? locale : locale?.split('-')[0]
//   const language = locale?.length && locale != 'ko' ? locale : i18n.locale
//   return language
// }

function getLanguageFromLocale (locale: string): string {
  if (locale.includes('zh')) {
    return locale
  }
  return locale.split('-')[0]
}