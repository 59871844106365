import { ClientType } from '../apollo'
import { types } from '../types'

import { findUniqueReaction } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readReaction (client: ClientType, reactionId: number): Promise<types.Reaction | undefined> {
  try {
    const result = await client.query<{findUniqueReaction: types.Reaction}>({
      query: findUniqueReaction,
      variables: {
        where: {
          id: reactionId,
        },
      } as types.QueryFindUniqueReportActionArgs,
    })
    return result.data.findUniqueReaction
  } catch (e) {
  }
}