import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { debug } from '@dinosband/dbi-utils'

import { onChannelUpdated } from './channel-gql'

type Callback = (newData: types.Channel) => void

export function subscribeOnChannelUpdated (client: ClientType, channelIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onChannelUpdated,
    variables: {
      channelIds,
    },
  })
  const subscription = subscribe.subscribe({
    next: ({ data }) => {
      const newData = data.onChannelUpdated as types.Channel
      debug.log('=> onChannelUpdated')
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
