// import { getThumbnails } from 'video-metadata-thumbnails'
import VideoSnapshot from 'video-snapshot'

export async function makeVideoSnapshot (file: Blob): Promise<string | Blob> {
  // const results = await getThumbnails(file, { start: 0, end: 0 })
  // return results[0].blob as unknown as Blob
  const video = new VideoSnapshot(file)
  return await video.takeSnapshot()
}

export function isVideoType (type: string) {
  const videoTypes = ['video/mp4', 'video/m4v', 'video/mov', 'video/quicktime', 'video/*']
  return videoTypes.includes(type)
}