import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { updateOneAbusingReport } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createReportAction (client: ClientType, reportId: number, description: string, close: boolean): Promise<types.AbusingReport | undefined> {
  try {
    const now = new Date()
    const result = await client.mutate({
      mutation: updateOneAbusingReport,
      variables: {
        where: { id: reportId },
        data: {
          actions: {
            create: [{
              description,
            }],
          },
          lastActionAt: { set: now },
          closedAt: { set: close ? now : null },
        },
      } as types.MutationUpdateOneAbusingReportArgs,
    }) as MutationResult
    return result.data.updateOneAbusingReport
  } catch (e) {
  }
}
