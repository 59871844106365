/* eslint-disable @typescript-eslint/no-explicit-any */

import { ChatRoomRestrictedCriteria, ChatRoomType, JobOfferState, MesgFilter, HotLine, ProductInfo } from '@dinosband/bv-main-schema/dist/types'
import { MessagesWhereAdvancedLocationsInput } from './_generated/schema'

export * from './_generated/schema'

export enum AttachmentState {
  INITIAL = 'INITIAL',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  DELETED = 'DELETED',
  ERROR = 'ERROR',
}

export interface Attachment {
  id: number
  title?: string
  type?: string
  size?: number
  info?: string
  thumbnail?: string
  embed?: string
  url?: string
}

export enum MessageType {
  TEXT = 'TEXT',
  JOB_OFFER = 'JOB_OFFER',
  REPLY = 'REPLY',
  JOB_OFFER_TRANSACTION = 'TRANSACTION',
  COPIED_JOB_OFFER = 'COPIED_JOB_OFFER',
  POST = 'POST',
}

export enum MessageCapability {
  JOB_OFFER = 'JOB_OFFER',
  OPEN_CHATROOM = 'OPEN_CHATROOM',
}

export enum MessageDeleteReason {
  USER_DELETE = 1,
  POLICY_VIOLATION = 2,
  ADMIN_DELETE = 3,
  MESSAGE_MOVED = 4,
}

export interface Message {
  id: number
  seq: number
  createdAt: string
  updatedAt?: string
  deletedAt?: string
  deleteReason?: MessageDeleteReason
  chatRoomId: number
  profileId: number
  props: MessageProps
  verifiedAt?: number
}

export type UpdateMessage = Partial<Omit<Message, 'props' | 'id'>> & {
  id: number
  props: Partial<Message['props']>
}

export type DeleteMessage = Partial<Pick<Message, 'id' | 'deletedAt'>> ;

export type MessageDateRange = {
  from: Date
  to: Date
}

export const MESSAGE_VERSION = 1

export type MessageTransactionType =
  | 'apply'
  | 'action'
  | 'ask-action'
  | 'ask-pay'
  | 'ask-refund'
  | 'suggest-refund'
  | 'edit-refund'
  | 'cancel'
  | 'share-output'
  | 'cancel-share-output'
  | 'enter-media-room'
  | 'error'

export interface MessageTransaction {
  type: MessageTransactionType
  args: Record<string, unknown>
}

export enum MessageAccess {
  private = 'private',
  anonymous = 'anonymous',
  // public = undefined,
}

/*
********************************
!!!주의 Object 타입을 넣어서 보내면 db에 저장시 문제가 발생 할 수 있습니다.
********************************
*/

export interface MessageProps {
  version: number // MESSAGE_VERSION
  type: MessageType
  title: string
  body: string
  chatRoomType: ChatRoomType
  subType?: string // defined in each channel
  accessType?: string // private | anonymous | undefined(public)
  chatRoomName?: string // for push notification
  channelId?: number
  channelName?: string // for push notification
  userId: number
  profileName?: string // for push notification
  profileThumbnailId?: number // for push notification
  byAdmin?: boolean
  capabilities?: string[] // MessageCapability
  designatedProfileId?: number // JobOffer 대상이 정해진 경우 profileId
  designatedUserId?: number // JobOffer 대상이 정해진 경우 userId
  jobOfferId?: number // JOB_OFFER
  jobOfferState?: JobOfferState
  fromExternal?: boolean // JOB_OFFER
  locations?: string[] // JOB_OFFER
  place?: string
  times?: MessageDateRange[] // JOB_OFFER
  attachments?: Attachment[]
  attachmentInfo?: string
  urlAttachments?: string[] // 외부 URL 형태의 attachments
  mentionedProfileIds?: number[]
  originalMessageId?: number // REPLY
  originalMessageUser?: { userId: number, profileId: number} // REPLY
  mentionUserId?: number[] // for push notification
  recvUserIds?: number[] // for push notification
  permission?: ChatRoomRestrictedCriteria // for push notification
  analyzedKeyword?: string[]
  hashTags?: string[]
  notiUserCount: number
  sourceURL?: string
  noPreview?: boolean
  content?: string
  lessonId?: string
  lessonItemId?: string
  lessonTitle?: string
  failedToSend?: boolean // only for client
  rootMessageChatRoomId?: number // only for client
  rootMessageId?: number // only for client
  targetProfileId?: number // only for client
  custom?: any // custom data Only for empthy story
  customData?: string // custom data string
  customKeywords?: string[] // keywords for custom data
  censored?: boolean
  hotLine?: HotLine
  product?: ProductInfo
  language?: string
  detectLanguage?: boolean
  translated?: string
  hideTranslation?: boolean
  // chat-room move
  // prevChatRoomId?: number
  // prevChatRoomMessageSeq?: number
  commentsRootChatRoomId?: number
  commentsRootMessageId?: number
  // moved by admin
  movedFromChatRoomId?: number
  movedFromMessageId?: number
  movedFromMessageSeq?: number
  // @deprecated
  originalMessageProfileId?: number // REPLY
  originalMessageUserId?: number[] // for push notification
  // notification
  notification?: {
    title: string
    body: string
  }
}

export interface MesgSearchLocationArgs extends MessagesWhereAdvancedLocationsInput {
  keyword: string
  locations: string[]
}

export interface MesgSearchArgs {
  searchKeywords?: string[]
  searchLocations?: MesgSearchLocationArgs[]
  excludeKeywords?: string[]
  excludeFinished?: boolean
  createdAfter?: string
}

export interface QueryFilter extends MesgFilter {
  excludeFinished: boolean
  excludeDeleted?: boolean
  excludeKeywords?: string[]
  createdAfter?: string
  onlyOwnMessages?: boolean
  includeByAdmin?: boolean
}

export type MesgSearchFilter = QueryFilter & MesgSearchArgs

export interface ChatRoomQueryOption {
  filterOwnMessages?: boolean
  excludeDeleted?: boolean
  onlyOwnMessages?: boolean
  includeByAdmin?: boolean
}

export interface UnreadCount {
  chatRoomId: number
  unreadCount: number
  lastMessageId: number
  lastMessageSeq: number
}

export interface UnreadNotiCount {
  unreadCount: number
  lastSysNotiId: number
  lastReadSysNotiId: number
  lastMesgNotiId: number
  lastReadMesgNotiId: number
}

export const UnreadNotiCount_Empty: UnreadNotiCount = {
  unreadCount: 0,
  lastSysNotiId: 0,
  lastReadSysNotiId: 0,
  lastMesgNotiId: 0,
  lastReadMesgNotiId: 0,
}

export enum NotificationType {
  System = 0,
  Message = 1,
  General = 2,
}

export interface BvNotificationPayload {
  type: number
  subtype: number
  timestamp: number // seconds
  title?: string
  titleAlt?: string // if subtitle is not empty
  subtitle?: string
  body: string
}

export interface BvMesgNotificationPayload extends BvNotificationPayload {
  channelId?: number
  channelName?: string
  chatRoomId?: number
  chatRoomName?: string
  chatRoomType: ChatRoomType
  attachmentInfo?: string
  profileId?: number
  profileName?: string
  profileThumbnail?: string // experimental
  profileThumbnailId?: number // experimental
  messageId?: number
  lessonTitle?: string
}

export interface BvSysNotificationPayload extends BvNotificationPayload {
  id: number
}

export interface BvGeneralNotificationPayload extends BvNotificationPayload {
  generalType: string
}

export interface ChatRoomLastMessageInfo {
  id: number // if possible
  seq: number
  createdAt: string
  updatedAt?: string
  deletedAt?: string
  deleteReason?: MessageDeleteReason
  chatRoomId: number
  profileId: number
}

export interface ChatRoomMessageInfo {
  id: number
  lastMessageId: number
  lastMessageSeq: number
}
