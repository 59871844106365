import { ClientType } from '../apollo'
import { ApolloQueryResult, types } from '../types'

import { updateOneSysInfo } from '@dinosband/bv-main-schema/dist/client-gql'

interface Mutations {
  updateOneSysInfo: types.SysInfo
}

type Result = ApolloQueryResult<Mutations>

export async function updateSysInfo (client: ClientType, data: types.SysInfoUpdateInput): Promise<types.SysInfo | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneSysInfo,
      variables: {
        data,
      },
    }) as Result
    return result.data.updateOneSysInfo
  } catch (e) {
  }
}

