import { ClientType } from '../apollo'
import { types } from '../types'

import { deleteOneAdvertisement } from '@dinosband/bv-main-schema/dist/client-gql'

export async function deleteAdvertisement (client: ClientType, id: number): Promise<types.Advertisement | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteOneAdvertisement,
      variables: {
        where: { id },
      },
    })
    return result.data.deleteOneAdvertisement
  } catch (e) {
  }
}
