import { MessageDeleteReason } from '@dinosband/bv-messenger-schema'

export const _MESSAGE_DATA_PROPERTIES = `
  id
  seq
  chatRoomId
  profileId
  createdAt
  updatedAt
  deletedAt
  deleteReason
  props
`

export const _MESSAGE_INFO_PROPERTIES = `
  id
  seq
  chatRoomId
  createdAt
  updatedAt
  deletedAt
  deleteReason
`
export const ExcludeDeleteReasons = [
  MessageDeleteReason.ADMIN_DELETE,
  MessageDeleteReason.MESSAGE_MOVED,
]

export function shouldExcludeMessage (deleteReason: MessageDeleteReason | undefined) {
  if (!deleteReason) {
    return false
  }
  return ExcludeDeleteReasons.includes(deleteReason)
}