import { ClientType } from '../apollo'
import { types } from '../types'

import { deleteOneSysNotice } from '@dinosband/bv-main-schema/dist/client-gql'

export async function deleteSysNotice (client: ClientType, id: number): Promise<types.SysNotice | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteOneSysNotice,
      variables: {
        where: { id },
      },
    })
    return result.data.deleteOneSysNotice
  } catch (e) {
  }
}
