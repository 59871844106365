
// import isMobile from './'

import type { DbParams } from '@dinosband/dbi-router'

let routeParams: DbParams | undefined

export function getRouteParams<T = DbParams> (component: Vue): T {
  const params = routeParams
  routeParams = undefined
  return (params ?? component.$dbiRouter.getRouteParams(component)) as T
}

const _COMPONENTS_WITH_AUTO_PARAMS = ['BvMessenger', 'BvMessageBoard', 'MessageSearchPage', 'MessageSearchOptions']

export function pushRouter<T extends DbParams> (component: Vue, name: string, params?: T, showDetail = false) {
  routeParams = params
  if (!_COMPONENTS_WITH_AUTO_PARAMS.includes(name)) {
    params = undefined
  }
  if (showDetail) {
    component.$dbiRouter.pushOrDetail(component, name, params, true)
  } else {
    // component.$dbiRouter.push(component, name, params, true)
    component.$dbiRouter.pushOrDetail(component, name, params, true)
  }
  // let path = `+${name}`
  // if (_COMPONENTS_WITH_AUTO_PARAMS.includes(name)) {
  //   for (const key of Object.keys(params)) {
  //     const value = params[key]
  //     if (typeof value != 'object' &&
  //         typeof value != 'function') {
  //       path += `__${key}=${value}`
  //     }
  //   }
  // }

  // component.$dbiRouter.push(component, path, true)
}
