import _Vue from 'vue'
import VueRouter from 'vue-router'

import { Route, RouteNode, DbParams, NewPageCallback } from '../core'
import { RouterForVue, NodeReadyCallBack } from './router-for-vue'
import { createVueRouter, registerRouterViews } from './utils'

export type { Route, DbParams, DetailInfo } from '../core'
export { Router, RouteNode } from '../core'
export { store } from './store'
export { RouterEvent } from './router-event'

export interface DbiRouterOptions {
  routes: Route[]
  waitForNodeReady: NodeReadyCallBack
  onNewPage: NewPageCallback
}

function install (Vue: typeof _Vue, options: DbiRouterOptions): void {
  installMixin(Vue)

  const vueRouter = createVueRouter()
  registerRouterViews()

  const router = new RouterForVue(options.routes, options.waitForNodeReady, options.onNewPage)
  router.setNativeRouter(vueRouter)

  Vue.prototype.$dbiRouter = router
  Vue.prototype.$vueRouter = vueRouter
}

function installMixin (Vue: typeof _Vue) {
  Vue.mixin({
    props: {
      routeNode: {
        type: Object as () => RouteNode,
        default: undefined,
      },
      routeParams: {
        type: Object as () => DbParams,
        default: undefined,
      },
    },

    methods: {
      ionViewDidEnter () {
        const node = this.routeNode
        if (node) {
          node.setMounted()
        }
        // if (node?.under?.over) {
        //   this.$dbiRouter.pop(this)
        // }
      },

      ionViewWillUnload () {
        const node = this.routeNode
        if (node?.under?.over) {
          if (node.isModal || node.isDetail) {
            node.popNode()
          } else {
            // node가 pop이 된 경우.
            // modal이나 detail이 아닌 경우에는 path에 반영하기 위해 dbiRouter.pop()을 호출한다.
            this.$dbiRouter.pop(this, false, true)
          }
        }
      },
    },
  })
}

export const DbiRouterPlugin = {
  install,
  version: '__VERSION__',
}

declare module 'vue/types/vue' {
  interface Vue {
    $dbiRouter: RouterForVue
    $vueRouter: VueRouter
    routeNode?: RouteNode
    routeParams?: DbParams
  }
}
