import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { onExpertInfoUpdated } from './expert-gql'
import { debug } from '@dinosband/dbi-utils'

type Callback = (newData: types.ExpertInfo) => void

export function subscribeOnExpertInfoUpdated (client: ClientType, userId: number, profile_ChannelIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onExpertInfoUpdated,
    variables: { userId, profile_ChannelIds },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      debug.log('=> onExpertInfoUpdated')
      const newData = data.onExpertInfoUpdated as types.ExpertInfo
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
