import { ClientType } from '../apollo'
import { MutationResult, types } from '../types'

import { deleteOneProfile_Channel } from '@dinosband/bv-main-schema/dist/client-gql'

export async function deleteProfile_Channel (client: ClientType, id: number): Promise<types.Profile_Channel | undefined | null> {
  try {
    const result = await client.mutate({
      mutation: deleteOneProfile_Channel,
      variables: {
        where: { id },
      } as types.MutationDeleteOneProfile_ChannelArgs,
    }) as MutationResult
    return result.data.deleteOneProfile_Channel
  } catch (e) {
  }
}
