
import i18n from '@dinosband/bv-i18n'
import Vue, { provide } from 'vue'
import { isPlatform } from '@ionic/core'
import { profiler, waitFor } from '@dinosband/dbi-utils'
import { eventBus } from '../utils/event-bus'
import { PluginListenerHandle } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import { ResizeObserver } from '@juggle/resize-observer'
import { FabController } from '../utils/fab-controller'

interface CustomScrollEvent {
  target: { scrollTop: number }
  detail: { scrollTop: number }
}

export default Vue.extend({
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    headerShadow: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    footer: {
      type: String,
      default: undefined,
    },
    components: {
      type: Array,
      default: undefined,
    },
    useRefresher: {
      type: Boolean,
      default: false,
    },
    backMeansApply: {
      type: Boolean,
      default: true,
    },
    labelBack: {
      type: String,
      default: '',
    },
    showMenuButton: {
      type: Boolean,
      default: false,
    },
    menuId: {
      type: String,
      default: undefined,
    },
    independantScroll: {
      type: Boolean,
      default: false,
    },
    catchScroll: {
      type: String,
      default: 'true',
    },
    scrollY: {
      type: String,
      default: 'true',
    },
    margin: {
      type: Boolean,
      default: false,
    },
    titleLeft: {
      type: Boolean,
      default: false,
    },
    transparentHeader: {
      type: Boolean,
      default: false,
    },
    transparentFooter: {
      type: Boolean,
      default: false,
    },
    toolbarColor: {
      type: String,
      default: '',
    },
    toolbarMode: {
      type: String,
      default: '',
    },
    headerBackground: {
      type: String,
      default: '',
    },
    backButtonColor: {
      type: String,
      default: '',
    },
    // for modal
    ok: {
      type: String,
      default: '',
    },
    disableOk: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: String,
      default: i18n.tc('common.close'),
    },
    data: {
      type: Object,
      default: undefined,
    },
    blockBackbutton: {
      type: Boolean,
      default: undefined,
    },
    cbOk: {
      type: Function,
      default: undefined,
    },
    cbCancel: {
      type: Function,
      default: undefined,
    },
    cbBack: {
      type: Function,
      default: undefined,
    },
  },

  data () {
    return {
      siteRight: this.$dbIon.siteConfig?.right,
      scrollTop: 0,
      canGoBack: false,
      isModalWindow: false,
      isDetailBottom: false,
      showHeader: false,
      isClosing: false,
      backBtnText: '',
      isKeyboardVisible: false,
      isOnCancel: false,
      isOnOk: false,
      keyboardDidHide: undefined as undefined | PluginListenerHandle,
      keyboardDidShow: undefined as undefined | PluginListenerHandle,
      params: undefined as undefined | unknown,
      resizeObserver: null as ResizeObserver | null,
      fabController: new FabController(),
    }
  },

  computed: {
    backButtonMode (): string {
      return isPlatform('ios') ? 'ios' : 'md'
    },

    containerMarginClass (): string {
      return this.margin ? 'container-margin' : ''
    },

    isModal (): boolean {
      return this.isModalWindow && !this.canGoBack
    },

    isPushModal (): boolean {
      return this.isModalWindow && this.canGoBack
    },

    styleToolbar (): Record<string, string> {
      if (this.transparentHeader) {
        return {
          '--background': 'rgba(255, 255, 255, 0)',
          color: this.backButtonColor,
          // 'backdrop-filter': 'blur(6px)',
          // '--background': 'rgba(255, 255, 255, 0.2)',
        }
      } else if (this.headerBackground) {
        return {
          '--background': this.headerBackground,
          color: this.backButtonColor,
        }
      } else {
        return {}
      }
    },

    styleBackButton (): Record<string, string> {
      if (this.backButtonColor) {
        return {
          color: this.backButtonColor,
        }
      } else {
        return {}
      }
    },

    styleTitle (): Record<string, string> {
      if (this.titleLeft && isPlatform('ios')) {
        return {
          'text-align': 'left',
          'padding-left': '3rem',
        }
      } else {
        return {}
      }
    },

    styleIonContent (): Record<string, string> {
      return {
        position: this.fullscreen ? 'absolute' : 'relative',
      }
    },

    styleFooter (): Record<string, string> {
      if (this.transparentFooter) {
        return {
          'background': 'rgba(0, 0, 0, 0)',
          'border-top': 'solid 2px rgba(255, 255, 255, 0.1)',
          // 'backdrop-filter': 'blur(6px)',
        }
      } else {
        return {}
      }
    },
  },

  async created () {
    profiler.of('db-page-ref').incRef()


    this.$dbiRouter.setTitle(this, this.title)
    this.$dbiRouter.setNativeComponent(this)
    this.canGoBack = this.$dbiRouter.canGoBack(this)

    provide('fab-controller', this.fabController)
  },

  // async onNavDidChange () {
  //   this.canGoBack = await this.$dbiRouter.canGoBack(this)
  // },

  async mounted () {
    // dismiss loading controller if it is still running
    await this.$dbIon.dismissLoading()

    await waitFor(() => !!this.$el)

    // let isModal = false
    // const modal = this.$el.closest('ion-modal')
    // if (modal) {
    //   isModal = true
    // } else if (this.$parent?.$$props?.isModal) {
    //   isModal = true
    // }
    const isModal = this.$dbiRouter.isModal(this)

    // for debugging
    if (isModal) {
      if (isPlatform('hybrid')) {
        this.keyboardDidHide = await Keyboard.addListener('keyboardDidHide', async () => {
          this.isKeyboardVisible = false
          if (this.isOnCancel) {
            await this.dismissPage(false)
          } else if (this.isOnOk) {
            await this.dismissPage(true)
          }
        })

        this.keyboardDidShow = await Keyboard.addListener('keyboardDidShow', async () => {
          this.isOnCancel = false
          this.isOnOk = false
          this.isKeyboardVisible = true
        })
      }
    }
    this.isModalWindow = isModal
    this.isDetailBottom = this.$dbiRouter.isDetailBottom(this)

    this.canGoBack = this.$dbiRouter.canGoBack(this)

    // await delay(500)
    // this.canGoBack = await this.$dbiRouter.canGoBack(this)

    // full screen modal
    if (this.fullscreen) {
      const wrapper = document.getElementsByClassName('modal-wrapper')[0] as HTMLElement
      if (wrapper) {
        wrapper.style.width = '100%'
        wrapper.style.height = '100%'
      }
    }

    await this.$nextTick()
    this.backBtnText = this.getBackBtnText()

    // waitFor(() => {
    //   const header = this.$refs.header as HTMLElement
    //   return header?.clientHeight > 0
    // }).then(() => {
    //   const header = this.$refs.header as HTMLElement
    //   this.$emit('headerReady', header?.clientHeight)
    // })
    waitFor(() => {
      const header = this.$refs.header as HTMLElement
      const footer = this.$refs.footer as HTMLElement
      return Boolean(header && footer)
    }, 10_000).then(() => {
      const header = this.$refs.header as HTMLElement
      const footer = this.$refs.footer as HTMLElement
      const content = this.$refs.content as HTMLElement
      this.resizeObserver = new ResizeObserver((entries, observer) => {
        entries.forEach((entry, index) => {
          let { /*inlineSize: width,*/ blockSize: height } = entry.contentBoxSize[0]
          height = Math.floor(height)
          if (entry.target.id === 'header') {
            if (height > 0) {
              this.$emit('headerReady', height)
            }
            this.$emit('headerResized', height)
          } else if (entry.target.id === 'content') {
            this.$emit('contentResized', height)
          } else if (entry.target.id === 'footer') {
            this.$emit('footerResized', height)
          }
        })
      })
      this.resizeObserver.observe(header)
      this.resizeObserver.observe(content)
      this.resizeObserver.observe(footer)
    })
  },

  beforeDestroy () {
    if (isPlatform('hybrid')) {
      this.keyboardDidHide?.remove()
      this.keyboardDidShow?.remove()
    }
    profiler.of('db-page-ref').decRef()
  },

  methods: {
    getBackBtnText (): string {
      if (this.title && this.title.length > 20) {
        return ' '
      } else {
        if (this.labelBack) {
          return this.labelBack
        } else {
          return ''
          // return this.$dbiRouter.getBackTitle(this)
        }
      }
    },

    doBack (isOK: boolean) {
      if (this.cbBack) {
        const cb = this.cbBack as () => boolean
        if (!cb()) {
          return
        }
      }
      const node = this.$dbiRouter.getRouteNode(this)
      if (node?.onClose) {
        node.onClose(isOK)
      }
      // if (this.$parent?.$$props?.onClosedCb) {
      //   const cb = this.$parent.$$props.onClosedCb as (isOK: boolean) => void
      //   cb(isOK)
      //   return
      // }
      this.$emit('back')
    },

    async onClickBack () {
      if (this.isClosing) {
        return
      }
      this.isClosing = true
      this.doBack(this.backMeansApply)
      setTimeout(() => {
        this.$dbiRouter.pop(this, this.backMeansApply)
        // if (!this.isPushModal) {
        //   this.$dbiRouter.pop(this, this.backMeansApply)
        // }
      }, 10)
    },

    goBack (isOK: boolean) {
      if (this.isClosing) {
        return
      }
      this.isClosing = true
      this.doBack(isOK)
      this.$dbiRouter.pop(this, isOK)
    },

    async onCancel () {
      if (this.blockBackbutton) {
        return
      }

      if (this.cbCancel) {
        const cb = this.cbCancel as () => boolean
        if (!cb()) {
          return
        }
      }

      if (isPlatform('hybrid') && this.isKeyboardVisible) {
        this.isOnCancel = true
        Keyboard.hide()
      } else {
        await this.dismissPage(false)
      }
    },

    async onOK (params: unknown | undefined) {
      if (this.cbOk) {
        const cb = this.cbOk as () => boolean
        if (!cb()) {
          return
        }
      }

      this.params = params ?? this.data

      if (isPlatform('hybrid') && this.isKeyboardVisible) {
        this.isOnOk = true
        Keyboard.hide()
      } else {
        await this.dismissPage(true)
      }
    },

    onScrollStart () {
    },

    onScroll (e: CustomScrollEvent) {
      let scrollTop = 0
      if (this.independantScroll) {
        scrollTop = e.target.scrollTop
      } else {
        scrollTop = e.detail.scrollTop
      }
      if (!this.hideHeader) {
        const header = this.$refs.header as HTMLElement
        if (scrollTop < 3) {
          eventBus.$emit('scrollAtTop', true)
          if (header) {
            if (this.headerShadow) {
              header.classList.remove('header-shadow')
            } else {
              const headers = this.$el.getElementsByClassName('header-shadow-here')
              if (headers.length) {
                headers[0].classList.remove('header-shadow')
              }
            }
          }
          // if (header && !this.headerShadow) {
          //   // header.classList.add('ion-no-border')
          //   header.classList.remove('header-shadow')
          // }
        } else if (this.scrollTop < 3) {
          eventBus.$emit('scrollAtTop', false)
          // header.classList.remove('ion-no-border')
          if (this.headerShadow) {
            header.classList.add('header-shadow')
          } else {
            const headers = this.$el.getElementsByClassName('header-shadow-here')
            if (headers.length) {
              headers[0].classList.add('header-shadow')
            }
          }
        }
        this.scrollTop = scrollTop
      }
      if (this.catchScroll === 'true') {
        this.$emit('scroll', scrollTop)
      }
    },

    onScrollEnd () {
    },

    async dismissPage (isOK: boolean) {
      if (this.isDetailBottom) {
        this.$dbiRouter.closeDetailPane(undefined, false)
      } else {
        if (isOK) {
          await this.$ionic.modalController.dismiss(this.params, 'ok')
        } else {
          await this.$ionic.modalController.dismiss(undefined, 'cancel')
        }
      }
      // if (this.$dbiRouter.inDetailPane(this)) {
      //   RouterEvent.$emit('detail-pane')
      // } else {
      //   if (isOK) {
      //     await this.$ionic.modalController.dismiss(this.params, 'ok')
      //   } else {
      //     await this.$ionic.modalController.dismiss(undefined, 'cancel')
      //   }
      // }
    },
  },
})
