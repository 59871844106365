import i18n from '@dinosband/bv-i18n'
import type { ChannelDictionary } from '@dinosband/bv-main-schema/dist/types'
import settings from '@dinosband/bv-settings'

class DictionaryManager {
  protected dicts: ChannelDictionary[] = []

  constructor (readonly sizeCache = 10) {}

  initialize () {
    this.loadCache()
  }

  translate (channelId: number, target: string, org?: string | null): string {
    const dict = this.getDictionary(channelId)
    if (!target.startsWith('$') && !org) {
      // target이 $로 시작되지 않으면 target 자체가 org이다.
      org = target
    }
    return dict?.dict[target] ?? org ?? target
  }

  translateSentence (channelId: number, target: string): string {
    let out = target
    const dict = this.getDictionary(channelId)
    if (dict) {
      const keys = Object.keys(dict.dict).sort((a, b) => b.length - a.length)
      keys.forEach((key) => {
        out = out.replaceAll(key, dict.dict[key])
      })
    }
    return out
  }

  getDictionary (channelId: number): ChannelDictionary | null {
    const locale = i18n.locale
    const lang = !locale || locale.startsWith('ko') ? 'ko' : 'en'
    const inx = this.dicts.findIndex((d) => d.channelId === channelId && d.lang === lang)
    if (inx >= 0) {
      const dict = this.dicts[inx]
      if (inx < this.dicts.length - 1) {
        // 최근에 사용한 (리스트의 마지막) 사전이 아니면 마지막으로 이동
        this.dicts.splice(inx, 1)
        this.dicts.push(dict)
        this.saveCache()
      }
      return dict
    }
    return null
  }

  updateDictionary (dict: ChannelDictionary) {
    const inx = this.dicts.findIndex((d) => d.channelId === dict.channelId && d.lang === dict.lang)
    if (inx >= 0) {
      this.dicts[inx] = dict
    } else {
      this.dicts.push(dict)
      while (this.dicts.length > this.sizeCache) {
        // 가장 오래된 것 부터 삭제
        this.dicts.shift()
      }
    }
    this.saveCache()
  }

  protected loadCache () {
    const parsed = JSON.parse(settings.dictionary) as ChannelDictionary[]
    if (parsed && Array.isArray(parsed)) {
      this.dicts = parsed.filter((d) => d.__typename === 'ChannelDictionary')
    } else {
      this.dicts = []
    }
    this.saveCache()
  }

  protected saveCache () {
    settings.dictionary = JSON.stringify(this.dicts)
  }
}

export const dictionaryManager = new DictionaryManager()
