
import Vue from 'vue'
export default Vue.extend({
  props: {
    nav: {
      type: Object,
      default: null,
    },
    components: {
      type: Array,
      default () {
        return []
      },
    },
  },
})
