import { ClientType } from '../apollo'
import { types } from '../types'

import {
  deleteOneSearchWord,
  deleteManySearchWord,
} from '@dinosband/bv-main-schema/dist/client-gql'

export async function deleteUserSearchWord (client: ClientType, id: number): Promise<types.SearchWord | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteOneSearchWord,
      variables: {
        where: { id },
      } as types.MutationDeleteOneSearchWordArgs,
    })
    return result.data.deleteOneSearchWord
  } catch (e) {
  }
}

export async function deleteAllUserSearchWords (client: ClientType, userId: number): Promise<types.SearchWord[] | undefined> {
  try {
    const result = await client.mutate({
      mutation: deleteManySearchWord,
      variables: {
        where: {
          userId: { equals: userId },
        },
      } as types.MutationDeleteManySearchWordArgs,
    })
    return result.data.deleteManySearchWord
  } catch (e) {
  }
}
