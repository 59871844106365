import { ClientType } from '../apollo'
import { types } from '../types'

import { updateOneDocument } from '@dinosband/bv-main-schema/dist/client-gql'

export async function updateDocument (client: ClientType, id: number, data: types.DocumentUpdateInput): Promise<types.Document | undefined> {
  try {
    const result = await client.mutate({
      mutation: updateOneDocument,
      variables: {
        where: { id },
        data,
      },
    })
    return result.data.updateOneDocument
  } catch (e) {
  }
}
