import { ClientType } from '../apollo'
import { ApolloQueryResult, types } from '../types'

import { upsertOneUserUsage } from './user-usage-gql'

interface Mutations {
  upsertOneUserUsage: types.UserUsage
}

type Result = ApolloQueryResult<Mutations>

export async function upsertUserUsage (client: ClientType, id: number, data: string): Promise<types.UserUsage | undefined> {
  try {
    const result = await client.mutate({
      mutation: upsertOneUserUsage,
      variables: {
        where: { id },
        create: { id, data } as types.UserUsageCreateInput,
        update: { data: { set: data } } as types.UserUsageUpdateInput,
      },
    }) as Result
    return result.data.upsertOneUserUsage
  } catch (e) {
  }
}
