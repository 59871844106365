import gql from 'graphql-tag'
import * as client from '@dinosband/bv-main-schema/dist/client-gql'

export const onSysNoticeAdded = gql`
  subscription onSysNoticeAdded {
    onSysNoticeAdded {
      ...SysNoticeFields
    }
  }
  ${client.SysNoticeFields}
`
