import { ClientType } from '../apollo'
import { SmartSubscribe, types } from '../types'
import { debug } from '@dinosband/dbi-utils'

import {
  onChannelChatRoomMenuAdded,
  onChannelChatRoomMenuUpdated,
  onChannelChatRoomMenuDeleted,
} from './chatroom-menu-gql'

type Callback = (newData: types.ChannelChatRoomMenu) => void

export function subscribeOnChannelChatRoomMenuAdded (client: ClientType, channelIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onChannelChatRoomMenuAdded,
    variables: { channelIds },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      const newData = data.onChannelChatRoomMenuAdded as types.ChannelChatRoomMenu
      debug.log('=> onChannelChatRoomMenuAdded: id = ', newData.id)
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}

export function subscribeOnChannelChatRoomMenuUpdated (client: ClientType, channelIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onChannelChatRoomMenuUpdated,
    variables: { channelIds },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      const newData = data.onChannelChatRoomMenuUpdated as types.ChannelChatRoomMenu
      debug.log('=> onChannelChatRoomMenuUpdated: id = ', newData.id)
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}

export function subscribeOnChannelChatRoomMenuDeleted (client: ClientType, channelIds: number[], cb: Callback): SmartSubscribe {
  const subscribe = client.subscribe({
    query: onChannelChatRoomMenuDeleted,
    variables: { channelIds },
  })
  const subscription = subscribe.subscribe({
    next: async ({ data }) => {
      const newData = data.onChannelChatRoomMenuDeleted as types.ChannelChatRoomMenu
      debug.log('=> onChannelChatRoomMenuDeleted: id = ', newData.id)
      cb(newData)
    },
  })

  return new SmartSubscribe(subscribe, subscription)
}
