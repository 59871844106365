
import Vue from 'vue'
import { ActionMenu, ActionMenuItem } from '../utils'
import { Components } from '@ionic/core'

export default Vue.extend({
  props: {
    menu: {
      type: Object as () => ActionMenu,
      default: undefined,
    },
  },

  computed: {
    items (): ActionMenuItem[] {
      return this.menu.items.filter((item) => item.text)
    },

    hasIcon (): boolean {
      return !!this.items.find((i) => i.icon)
    },
  },

  methods: {
    onClick (item: ActionMenuItem) {
      const popOver = this.$el.closest('ion-popover') as Components.IonPopover
      if (popOver) {
        popOver.dismiss(true, item.role)
      }
      if (item.handler) {
        item.handler()
      }
    },

    iconName (item: ActionMenuItem): string {
      return item.icon ?? ''
    },
  },
})
