import { ClientType } from '../apollo'
import { types } from '../types'

import { findManyChannel } from '@dinosband/bv-main-schema/dist/client-gql'
import { findSuggestedChannels, findMyChannelGraphs, findManyChannelCounts } from './channel-gql'

export async function readChannels (client: ClientType): Promise<types.Channel[] | undefined> {
  try {
    const result = await client.query({
      query: findManyChannel,
    })
    return result.data.findManyChannel
  } catch (e) {
  }
}

export async function readChannelGraphs (client: ClientType, channelIds: number[]): Promise<types.Channel[] | undefined> {
  try {
    const result = await client.query({
      query: findMyChannelGraphs,
      variables: {
        where: {
          id: { in: channelIds },
        },
      } as types.QueryFindManyChannelArgs,
    })
    return result.data.findMyChannels
  } catch (e) {
  }
}

export async function readChannelCounts (client: ClientType, channelIds: number[]): Promise<types.Channel[] | undefined> {
  try {
    const result = await client.query({
      query: findManyChannelCounts,
      variables: {
        where: {
          id: { in: channelIds },
        },
      } as types.QueryFindManyChannelArgs,
    })
    return result.data.findManyChannel
  } catch (e) {
  }
}

export async function readUpdatedChannelGraphs (client: ClientType, channelIds: number[], timestamp: number): Promise<types.Channel[] | undefined> {
  try {
    const result = await client.query({
      query: findMyChannelGraphs,
      variables: {
        where: {
          id: { in: channelIds },
          timestamp: timestamp ? { gt: timestamp } : undefined,
        },
      } as types.QueryFindManyChannelArgs,
    })
    return result.data.findMyChannels
  } catch (e) {
  }
}

export async function readSuggestedChannels (client: ClientType, joinedChannelIds: number[]) {
  try {
    const result = await client.query<{findSuggestedChannels: types.Channel[]}>({
      query: findSuggestedChannels,
      variables: {
        joinedChannelIds,
      },
    })
    return result.data.findSuggestedChannels
  } catch (e) {
  }
}
