import gql from 'graphql-tag'

export const q_getLatestVersion = gql`
  query q_getLatestVersion($version: String!, $platform: String) {
    getLatestVersion(version: $version, platform: $platform) {
      version
      changes
      bugs
      changesEn
      bugsEn
      break
    }
  }
`