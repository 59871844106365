import { ClientType } from '../apollo'
import { types } from '../types'

import { findManySearchWord } from '@dinosband/bv-main-schema/dist/client-gql'

export async function readUserSearchWords (client: ClientType, userId: number) {
  try {
    const result = await client.query<{findManySearchWord: types.SearchWord[]}>({
      query: findManySearchWord,
      variables: {
        where: { userId: { equals: userId } },
        orderBy: [{ createdAt: 'desc' }],
      } as types.QueryFindManySearchWordArgs,
    })
    return result.data.findManySearchWord
  } catch (e) {
  }
}

export async function readRecentSearchWords (client: ClientType, take: number) {
  try {
    const result = await client.query<{findManySearchWord: types.SearchWord[]}>({
      query: findManySearchWord,
      variables: {
        orderBy: [{ createdAt: 'desc' }],
        take,
      } as types.QueryFindManySearchWordArgs,
    })
    return result.data.findManySearchWord
  } catch (e) {
  }
}
