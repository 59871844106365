
import i18n from '@dinosband/bv-i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Message } from '@dinosband/bv-messenger-schema'
import { editMessage, deleteMessage } from '@dinosband/bv-messenger-client'
import { MessageDeleteReason } from '@dinosband/bv-messenger-schema'
import { mapc } from '@/apollo'

type Params = {
  message: Message
}

@Component
export default class BvaUpdateMessage extends Vue {
  @Prop() params!: Params

  message!: Message

  created () {
    this.message = this.params.message
  }

  get file_title (): string {
    return `${i18n.tc('report.message')} ${i18n.tc('messenger.edit')}`
  }

  get title (): string {
    return this.message.props?.title ?? ''
  }

  get body (): string {
    return this.message.props?.body ?? ''
  }

  get isCensored (): boolean {
    return this.message.props?.censored ?? false
  }

  get censorLabel (): string {
    return this.isCensored ? i18n.tc('common.unblock') : i18n.tc('common.block') //'차단 해제하기' : '차단하기'
  }

  async onClickCensor () {

    if (await this.$dbIon.yesNoAlert(i18n.tc('messenger.componentsmessenger15', 1, { actionName: this.censorLabel }), `${i18n.tc('common.block')} ${i18n.tc('components.channelsChannelFrontActionItem05')}`, this.censorLabel)) {
      this.message.props.censored = !this.isCensored
      const updated = await editMessage(await mapc.getClient(), this.message.id, this.message.chatRoomId, this.message.props)
      if (updated) {
        this.message = updated
      }
    }
  }

  async onClickDelete () {
    if (await this.$dbIon.yesNoAlert(i18n.tc('components.channelsmenu85'), i18n.tc('messenger.delete'), i18n.tc('messenger.delete'))) {
      const deleted = await deleteMessage(await mapc.getClient(), this.message.id, this.message.chatRoomId, MessageDeleteReason.ADMIN_DELETE)
      if (deleted) {
        this.$dbIon.alert(i18n.tc('file.deleted'))
      }
    }
  }
}
