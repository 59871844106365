import { LRUList } from './lru-list'

type PromiseFns<T> = {
  resolve: (data: T) => void
  reject: (reason?: string) => void
}

export class AsyncCache<K extends number | string, T> {
  protected cacheCount: number
  protected cache = new LRUList<K, T>()
  protected waitings = new Map<K, PromiseFns<T>[]>()
  log = false

  constructor (cacheCount: number) {
    this.cacheCount = cacheCount
  }

  async get (key: K, func: () => Promise<T | undefined>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const fns = { resolve, reject }
      const cached = this.cache.get(key)
      if (cached) {
        resolve(cached)
        return
      }

      const waiting = this.waitings.get(key)
      if (waiting) {
        // if (this.log) {
        // }
        waiting.push(fns)
      } else {
        // if (this.log) {
        // }
        this.waitings.set(key, [fns])
        func().then((result) => {
          // if (this.log) {
          // }
          if (!result) {
            this.callWaitings(key, (fns) => {
              fns.reject()
            })
            return
          }
          this.set(key, result)
          this.callWaitings(key, (fns) => {
            // if (this.log) {
            // }
            fns.resolve(result)
          })
        }).catch(() => {
          this.callWaitings(key, (fns) => {
            // if (this.log) {
            // }
            fns.reject()
          })
        })
      }
    })
  }

  protected callWaitings (key: K, cb: (fns: PromiseFns<T>) => void) {
    const waiting = this.waitings.get(key)
    if (waiting) {
      for (const fns of waiting) {
        cb(fns)
      }
    }
    this.waitings.delete(key)
  }

  set (key: K, value: T) {
    if (this.cacheCount) {
      this.cache.set(key, value)
      while (this.cacheCount < this.cache.count) {
        this.cache.deleteOldest()
      }
    }
  }
}
