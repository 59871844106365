import { debug, waitFor } from '@dinosband/dbi-utils'

import { NativeRouter, DbParams, Route } from './interface'
import { RouteNode, PathModifierCmd } from './route-node'

export interface NativeComponent {
  /* eslint-disable-next-line no-use-before-define */
  $dbiRouter: Router
}

export interface DetailInfo {
  key: string
  node?: RouteNode
  animated?: boolean
}

export enum RouteTransitionDirection {
  jump = '',
  forward = 'forward',
  backward = 'backward',
}

type BeforePushCallback = (node: RouteNode) => Promise<boolean>

export type NodeReadyCallBack = (node: RouteNode) => Promise<boolean>
export type NewPageCallback = (node: RouteNode) => void

export abstract class Router {
  readonly routes: Route[] = []
  protected _redirects: Route[] = []
  protected _routeNameMap = new Map<string, Route>()
  protected _currentRouteNodes!: RouteNode
  protected _modalTrees: RouteNode[] = []
  protected _detailTrees = new Map<string, RouteNode>()
  protected _hasDetailPane = false
  protected _keepTabRoutes = false
  protected _isTabBarVisible = false
  protected _currentPath = ''
  protected _currentView: RouteNode | undefined
  protected _nativeRouter: NativeRouter | undefined = undefined
  protected _transitionDirection: RouteTransitionDirection = RouteTransitionDirection.jump
  protected _initialPath = ''
  protected _visitedInitialPath = false
  protected _needRedirect = false

  readonly waitForNodeReady: NodeReadyCallBack
  readonly onNewPage: NewPageCallback
  protected beforePushCb: BeforePushCallback | undefined = undefined

  abstract _getRouteNode (comp: NativeComponent): RouteNode | undefined
  abstract _pushNode (comp: NativeComponent, node: RouteNode): Promise<void>
  abstract _popNode (comp: NativeComponent): Promise<void>
  abstract _popToRoot (comp: NativeComponent): Promise<void>
  abstract _closeAllModals (): Promise<void>
  abstract _setDetailPane (key: string, node: RouteNode): void
  abstract _closeDetailPane (key: string, animated: boolean): void
  abstract _updateRoute (direction: RouteTransitionDirection): void

  constructor (routes: Route[], waitForNodeReady: NodeReadyCallBack, onNewPage: NewPageCallback) {
    this.waitForNodeReady = waitForNodeReady
    this.onNewPage = onNewPage
    this.addRoutes(routes)
  }

  start (immediate = false) {
    if (immediate) {
      this._visitedInitialPath = true
      this.update()
      return
    }
    // deep-link 처리를 위해 여기에서 redirect를 우선 처리
    const path = document.location.pathname
    this.redirect(path)
    // if (path !== redirect) {
    //   this._nativeRouter?.push(redirect)
    // }
    // 앱이 초기화 되기 전에는 우선 home으로 시작
    const home = this.redirect('/')
    if (path !== home && path !== '/') {
      this._needRedirect = true
    }
    this.update(home)
  }

  setInitialPath (path: string) {
    this._initialPath = path
  }

  goToInitialPath () {
    this._visitedInitialPath = true
    this._needRedirect = false
    if (this._initialPath) {
      this._nativeRouter?.push(this._initialPath)
      this._initialPath = ''
    } else {
      this.update()
    }
  }

  get needRedirect (): boolean {
    return this._needRedirect || !!this._initialPath
  }

  get currentPath (): string {
    return this._currentPath
  }

  get currentRouteNodes (): RouteNode {
    return this._currentRouteNodes
  }

  get transitionDirection (): RouteTransitionDirection {
    return this._transitionDirection
  }

  setNativeRouter (router: NativeRouter): void {
    this._nativeRouter = router
  }

  addRoutes (routes: Route[], prePath = ''): void {
    routes.forEach((route) => {
      if (route.redirect) {
        this._redirects.push(route)
      } else {
        this.routes.push(this._newRoute(route))
      }
    })
  }

  protected _newRoute (route: Route): Route {
    // if it is reference route, return original
    if (route.isRef) {
      const ref = this.routes.find((r) => route.path === r.path)
      if (ref) {
        return ref
      } else {
        throw 'Faild to find reference route: ' + route.path
      }
    }

    // add children
    if (route.children) {
      route._childRoutes = []
      route.children.forEach((ch) => {
        route._childRoutes?.push(this._newRoute(ch))
      })
    }

    // add named children
    if (route.namedChildren) {
      route._namedChildRoutes = {}
      Object.entries(route.namedChildren).forEach(([key, children]) => {
        const routes: Route[] = []
        children.forEach((ch) => {
          routes.push(this._newRoute(ch))
        })
        if (route._namedChildRoutes) {
          route._namedChildRoutes[key] = routes
        }
      })
    }

    //----------------------------------
    // decompose path to cmd and params
    //----------------------------------
    const peList = route.path.split('/')
    let i = 0
    // set pathCmd
    route._pathCmd = peList[0]
    i = 1

    // set pathParams
    if (i < peList.length) {
      route._pathParams = []
      for (; i < peList.length; i++) {
        const pe = peList[i]
        if (pe[0] === '$') {
          route._pathParams.push(pe)
          route._isView = true
        } else if (pe[0] === '*') {
          route._isWildcard = true
        } else if (pe[0] === ':') {
          route._pathParams.push(pe.slice(1))
        } else {
          throw `Error: path parameter (${pe}) does not start with ':'`
        }
      }
    }
    return route
  }

  async update (path?: string): Promise<RouteNode | undefined> {
    if (!path && !this._visitedInitialPath) {
      this._updateRoute(this._transitionDirection)
      return undefined
    }

    const newPath = decodeURIComponent(document.location.pathname)
    this._currentPath = path ?? this.redirect(newPath)
    const newRouteNodes = this.getRouteTreeFromPath(this._currentPath)

    if (this._currentRouteNodes) {
      await this.closeAllModals()
      await this.closePagesForNewPath(newRouteNodes)
      if (this._currentRouteNodes.isSame(newRouteNodes)) {
        this._currentRouteNodes.assign(newRouteNodes)
      } else {
        this._currentRouteNodes = newRouteNodes
      }
    } else {
      this._currentRouteNodes = newRouteNodes
    }

    this._updateRoute(this._transitionDirection)
    return this._currentRouteNodes
  }

  getRouteFromPath (path: string): Route | null {
    let node = this.getRouteTreeFromPath(path).getVisibleNode()
    if (node.currentChild) {
      node = node.currentChild.getVisibleNode()
    }
    return node.route
  }

  protected redirect (path: string): string {
    // process redirect
    const redirect = this._redirects.find((route) => {
      if (typeof route.redirect === 'function') {
        return path.startsWith(route.path)
      } else {
        return route.path === path
      }
    })
    if (redirect && redirect.redirect) {
      if (typeof redirect.redirect === 'function') {
        path = redirect.redirect(path)
      } else {
        path = redirect.redirect
      }
    }
    return path
  }

  getRouteTreeFromPath (path: string): RouteNode {
    // path = this.redirect(decodeURIComponent(path))
    try {
      return RouteNode.buildNodeTreeFromPath(path, this.routes)
    } catch (e) {
      console.error('_addNewPathToHistoryNodes: ', e)
      console.error('path: ', path)
      return new RouteNode(null)
    }
  }

  getRouteNode (comp: NativeComponent): RouteNode | undefined {
    return this._getRouteNode(comp)
  }

  getRouteParams (comp: NativeComponent): DbParams | undefined {
    const node = this._getRouteNode(comp)
    return node?.routeParams
  }

  protected _callRouterFunction (comp: NativeComponent, func: (node: RouteNode) => void): void {
    const node = this.getRouteNode(comp)
    if (node) {
      func(node)
    }
  }

  beforePush (cb: BeforePushCallback) {
    this.beforePushCb = cb
  }

  protected makePath (component: string, params?: DbParams) {
    const entries = Object.entries(params ?? {})
    const path = '+' + component + entries.map(([key, value]) => {
      if (value !== undefined && typeof value != 'object' && typeof value != 'function') {
        return `__${key}=${value}`
      } else {
        return ''
      }
    }).join('')
    return path
  }

  protected pushNode (comp: NativeComponent, node: RouteNode, newNode: RouteNode) {
    node.pushNode(newNode)
    this._pushNode(comp, newNode)
  }

  protected async waitForReady () {
    const tick = Date.now()
    await waitFor(() => {
      const topNode = this.currentRouteNodes.getTopMostNode()
      return topNode.level == 1 || topNode.isMounted
    }, 3_000)
    const waiting = Date.now() - tick
    if (waiting >= 3_000) {
      debug.log('dbiRouter.waitForReady took too long:', waiting / 1000)
    }
  }

  activeNode (): RouteNode {
    if (this.modalTree) {
      return this.modalTree.getTopMostNode()
    } else {
      return this.currentRouteNodes.getTopMostNode()
    }
  }

  //------------------------------------------------------------------
  // routing methods
  //------------------------------------------------------------------

  async go (path: string, closePages = true): Promise<void> {
    // debug.log('dbiRouter.go', path)
    if (!this._visitedInitialPath) {
      this.setInitialPath(path)
      return
    }
    if (path === this._currentPath) {
    } else {
      await this.waitForReady()
      // if (closePages) {
      //   await this._closeAllModals()
      //   await this.closePagesForNewPath(path)
      // }
      this._nativeRouter?.push(path)
    }
  }

  async resetAndGo (path: string): Promise<void> {
    debug.log('dbiRouter.resetAndGo', path)
    await this.closeAllModals()
    // this.closePagesForNewPath(path)
    // if (this.useIonicRouter) {
    //   const nav = document.querySelector('ion-nav') as Components.IonNav
    //   if (nav) {
    //     await nav.popToRoot({
    //       animated: false,
    //     })
    //   }
    // }
    await this.go(path, true)
  }

  push (comp: NativeComponent, component: string, params?: DbParams, hideTabBar = false): void {
    this._callRouterFunction(comp, (node) => {
      if (node.isDetail || node.isModal) {
        const newNode = RouteNode.makeComponentNode(component, params)
        this.pushNode(comp, node, newNode)
      } else {
        // If root has under, stack over the root. Otherwise, stack over the node
        let root = node
        while (root.parent) {
          root = root.parent
        }
        if (root.under) {
          node = root
        }

        let path = this.makePath(component, params)
        if (hideTabBar) {
          path = '*' + path
        }
        const newPath = '/' + this.currentRouteNodes.toPath({
          cmd: PathModifierCmd.push,
          target: node,
          params: { pushPath: path },
        })
        this.go(newPath)
      }
    })
  }

  pop (comp: NativeComponent, isOK = false, alreadyUnloaded = false): void {
    this._callRouterFunction(comp, (node) => {
      if (node.isDetail || node.isModal) {
        if (node.under) {
          node.popNode()
          this._popNode(comp)
        }
      } else {
        while (node.parent) {
          node = node.parent
        }
        if (node.under) {
          // pop node
          // node.popNode()
          // if (!alreadyUnloaded) {
          //   this._popNode(comp)
          // }
          // update path
          const newPath = '/' + this.currentRouteNodes.toPath({
            cmd: PathModifierCmd.pop,
            target: node,
            params: {},
          })
          this.go(newPath, true)
        }
      }
    })
  }

  canGoBack (comp: NativeComponent): boolean {
    let node = this.getRouteNode(comp)
    if (node) {
      while (node.parent) {
        node = node.parent
      }
      if (node.under) {
        return true
      }
    }
    return false
  }

  popToRoot (comp: NativeComponent): void {
    this._callRouterFunction(comp, (node) => {
      while (node.parent) {
        node = node.parent
      }
      if (node.under) {
        const newPath = '/' + this.currentRouteNodes.toPath({
          cmd: PathModifierCmd.popToRoot,
          target: node,
          params: {},
        })
        this.go(newPath)
      }
    })
  }

  switchView (comp: NativeComponent, child: RouteNode): void {
    this._callRouterFunction(comp, (node) => {
      if (node.currentChild?.key === child.key) {
      } else {
        const switchNode = node.findChild(child.key)
        if (switchNode) {
          this._currentView = switchNode
          const newPath = '/' + this.currentRouteNodes.toPath({
            cmd: PathModifierCmd.switch,
            target: node,
            params: { switchNode },
          })
          this.go(newPath)
        } else {
          throw 'switchView: failed to find child'
        }
      }
    })
  }

  //-----------------------------------------------------------------
  // modal
  //-----------------------------------------------------------------

  protected get modalTree (): RouteNode | undefined {
    if (this._modalTrees.length) {
      return this._modalTrees[this._modalTrees.length - 1]
    } else {
      return undefined
    }
  }

  newModal (component: string, params: DbParams) {
    const node = RouteNode.makeComponentNode(component, params)
    node.setIsModal()
    this._modalTrees.push(node)
    return node
  }

  pushModal (comp: NativeComponent, component: string, params?: DbParams) {
    this.push(comp, component, params)
  }
  // pushModal (comp: NativeComponent, component: string, params?: DbParams, onClose?: OnCloseCallback) {
  //   if (onClose) {
  //     const node = this.getRouteNode(comp)
  //     if (node) {
  //       const newNode = RouteNode.makeComponentNode(component, params, onClose)
  //       this.pushNode(comp, node, newNode)
  //     } else {
  //       console.error('pushModal: no modal', component)
  //     }
  //   } else {
  //     this.push(comp, component, params)
  //   }
  // }

  closeModal () {
    if (this._modalTrees.length) {
      this._modalTrees.pop()
    }
  }

  isModal (comp: NativeComponent): boolean {
    const node = this.getRouteNode(comp)
    return node?.getBottomNode()?.isModal ?? false
  }

  async closeAllModals () {
    this._modalTrees = []
    await this._closeAllModals()
  }

  //-----------------------------------------------------------------------
  // detail pane
  //-----------------------------------------------------------------------

  get keepTabRoutes () {
    return this._keepTabRoutes
  }

  setKeepTabRoutes (use: boolean) {
    this._keepTabRoutes = use
  }

  get isTabBarVisible () {
    return this._isTabBarVisible
  }

  showTabBar (show: boolean) {
    this._isTabBarVisible = show
  }

  get hasDetailPane (): boolean {
    return this._hasDetailPane
  }

  setHasDetailPane (has: boolean) {
    this._hasDetailPane = has
  }

  getDetailTree (key: string): RouteNode | undefined {
    return this._detailTrees.get(key)
  }

  inDetailPane (comp: NativeComponent) {
    const node = this.getRouteNode(comp)
    return node?.getBottomNode()?.isDetail ?? false
  }

  isDetailBottom (comp: NativeComponent) {
    const node = this.getRouteNode(comp)
    return node?.isDetail ?? false
  }

  protected makeDetailTree (comp: NativeComponent | undefined, key: string, component: string, params?: DbParams) {
    const node = RouteNode.makeComponentNode(component, params)
    node.setIsDetail()
    if (comp && this.inDetailPane(comp)) {
      this.push(comp, component, params)
    } else {
      this._setDetailPane(key, node)
    }
  }

  showOnDetail (comp: NativeComponent | undefined, component: string, params?: DbParams) {
    if (!comp) {
      this.makeDetailTree(comp, '', component, params)
      return
    }
    this._callRouterFunction(comp, (node) => {
      let key = ''
      if (this.keepTabRoutes) {
        const view = node.getBottomNode()
        key = view.key
      }
      this.makeDetailTree(comp, key, component, params)
    })
  }

  pushOrDetail (comp: NativeComponent, component: string, params?: DbParams, hideTabBar = false) {
    this._callRouterFunction(comp, (node) => {
      if (this.hasDetailPane) {
        this.showOnDetail(comp, component, params)
        // let key = ''
        // if (this.keepTabRoutes) {
        //   const view = node.getBottomNode()
        //   key = view.key
        // }
        // this.makeDetailTree(key, component, params)
      } else {
        this.push(comp, component, params, hideTabBar)
        // const entries = Object.entries(params ?? {})
        // const path = '+' + component + entries.map(([key, value]) => {
        //   if (typeof value != 'object' && typeof value != 'function') {
        //     return `__${key}=${value}`
        //   } else {
        //     return ''
        //   }
        // }).join('')
        // this.push(comp, path)
      }
    })
  }

  closeDetailPane (key: string | undefined, animated: boolean) {
    if (key === undefined) {
      if (this.keepTabRoutes) {
        const node = this.currentRouteNodes.getTopMostNode()
        key = node.getBottomNode().key
      } else {
        key = ''
      }
    }
    this._closeDetailPane(key, animated)
  }

  protected async closePagesForNewPath (newTree: RouteNode) {
    const newTop = newTree.getTopMostNode()
    const newBottom = newTop.getBottomNode()
    const top = this.currentRouteNodes.getTopMostNode()
    const bottom = top.getBottomNode()
    const comp = bottom.nativeComponent
    if (!comp) {
      console.error('closePagesForNewPath: bottom =', bottom)
      return
      // throw 'closePagesForNewPath: undefined comp'
    }

    // pop을 하는 경우에는 pop()에서 이미 pop을 했으므로 여기에서는 push만 신경쓰면 된다.
    const isNormalMove = newTop.isSame(top) || newTop.under?.isSame(top) || top.under?.isSame(newTop)

    if (this.keepTabRoutes) {
      if (bottom.isSame(newBottom)) {
        this.closeDetailPane(bottom.key, false)
        if (!isNormalMove) {
          bottom.popToRoot()
          await this._popToRoot(comp)
        }
      } else {
        bottom.popToRoot()
        await this._popToRoot(comp)
      }
    } else {
      this.closeDetailPane('', false)
      if (!isNormalMove) {
        bottom.popToRoot()
        await this._popToRoot(comp)
      }
    }

    // if (this.keepTabRoutes) {
    //   const newTree = this.getRouteTreeFromPath(path)
    //   const newTop = newTree.getTopMostNode()
    //   const newBottom = newTop.getBottomNode()
    //   const top = this.currentRouteNodes.getTopMostNode()
    //   const bottom = top.getBottomNode()
    //   if (bottom.isSame(newBottom)) {
    //     this.closeDetailPane(bottom.key, false)
    //   }
    // } else {
    //   this.closeDetailPane('', false)
    // }
  }

  //----------------------------------------------------------------------
  // auto back title
  //----------------------------------------------------------------------

  setNativeComponent (comp: NativeComponent): void {
    this._callRouterFunction(comp, (node) => {
      node.setNativeComponent(comp)
    })
  }

  setTitle (comp: NativeComponent, title: string): void {
    this._callRouterFunction(comp, (node) => {
      node.title = title
    })
  }

  getBackTitle (comp: NativeComponent): string {
    const node = this.getRouteNode(comp)
    return node?.under?.title ?? ''
  }

  getActiveComponent (): NativeComponent | undefined {
    return this.currentRouteNodes.getTopMostNode().nativeComponent
  }
}

//export default new Router()
