import { Logger } from './logger'

const MAX_LOGS = 100
const MAX_STORE_LOGS = 10

type LogType = 'log' | 'error'

export type MemLogItem = {
  type: LogType
  seq: number
  mesg: string
  time: Date
}

class Debug {
  protected _memLogs: MemLogItem[] = []
  protected _useConsole = true
  protected _useMemLog = true
  protected _seq = 0
  protected _logger = new Logger('debug-log', MAX_STORE_LOGS)

  /* eslint-disable-next-line */
  log (...args: any[]): void {
    this.logWithType('log', ...args)
  }

  /* eslint-disable-next-line */
  error (...args: any[]): void {
    this.logWithType('error', ...args)
  }

  /* eslint-disable-next-line */
  protected logWithType (type: LogType, ...args: any[]): void {
    const logConsole = type === 'error' ? true : this._useConsole
    const logMem = type === 'error' ? true : this._useMemLog
    const logStorage = type === 'error'
    const doLog = logConsole || logMem || logStorage

    if (doLog) {
      const simplified = args.map((arg) => simplify(arg))

      if (logConsole) {
        const frozen = simplified.map((arg) => freeze(arg))
        if (type === 'error') {
          console.error('[ERR]', ...frozen)
        } else {
        }
      }

      if (logMem || logStorage) {
        const mesgs = simplified.map((arg) => stringify(arg))
        const mesg = mesgs.join(' ')

        if (logMem) {
          while (this._memLogs.length >= MAX_LOGS) {
            this._memLogs.shift()
          }
          const time = new Date()
          this._memLogs.push({ type, seq: ++this._seq, mesg, time })
        }

        if (logStorage) {
          this._logger.add(mesg)
        }
      }
    }
  }

  get logs (): MemLogItem[] {
    return this._memLogs
  }

  get storageLogs (): string[] {
    return this._logger.logs
  }

  enableConsoleLog (enable = true) {
    this._useConsole = enable
  }

  enableMemLog (enable = true) {
    this._useMemLog = enable
  }
}

function isObject (arg: unknown): boolean {
  return typeof arg === 'object' && arg !== null
}

function isFunction (arg: unknown): boolean {
  return typeof arg === 'function'
}

// simplify by removing circular references
function simplify (arg: unknown): unknown {
  const set = new Set<unknown>()

  const simplifyChild = (v: unknown): unknown => {
    if (Array.isArray(v)) {
      return v.map((v) => simplifyChild(v))
    }
    if (isObject(v)) {
      if (set.has(v)) {
        return '_ref_'
      }
      set.add(v)

      const entries = Object.entries(v as Record<string, unknown>)
        .map(([k, v]) => [k, simplifyChild(v)])
      return Object.fromEntries(entries)
    }
    if (!isFunction(v)) {
      return v
    }
    return undefined
  }
  return simplifyChild(arg)
}

function freeze (arg: unknown): unknown {
  if (isObject(arg) || Array.isArray(arg)) {
    return JSON.parse(JSON.stringify(arg))
  } else {
    return arg
  }
}

function stringify (arg: unknown): string {
  if (isObject(arg)) {
    return JSON.stringify(arg)
  } else {
    return `${arg}`
  }
}

export const debug = new Debug()
