import { ClientType } from '../apollo'
import { types, MutationResult } from '../types'

import { createOneDontShowAgain } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createDontShowAgain (client: ClientType, userId: number, name: string): Promise<types.DontShowAgain | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneDontShowAgain,
      variables: {
        data: {
          user: { connect: { id: userId } },
          type: 0,
          name,
        },
      } as types.MutationCreateOneDontShowAgainArgs,
    }) as MutationResult
    return result.data.createOneDontShowAgain
  } catch (e) {
  }
}
