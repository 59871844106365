"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.i18n = void 0;
const dictionary = {
    ko: {
        'noti.ch-expert-requested': '{requester}님이 {channelName}의 신규 {expertName} 인증을 요청하셨습니다.',
        'noti.ch-expert-approved': '{channelName}의 {expertName} 인증 요청이 승인되었습니다.',
        'noti.ch-expert-denied': '{channelName}의 {expertName} 인증 요청이 거절되었습니다.',
        'noti.ch-admin-assigned': '고객님이 {channelName}의 부운영자로 지정되셨습니다.',
        'noti.ch-admin-removed': '{channelName}의 부운영자 자격이 해제되었습니다.',
        'noti.ch-became-owner': '고객님이 {channelName}의 운영자가 되셨습니다.',
        'noti.ch-expelled': '{channelName}에서 강제 퇴장 되셨습니다.',
        'noti.ch-suspended': '{channelName}에서 일시 사용 정지 되셨습니다.',
        'noti.ch-mesg-screened': '고객님이 올리신 글이 부적절한 내용으로 판단되어 삭제되었습니다.',
        'noti.job-offer.delievered': '고객님이 올리신 공고가 {count}명의 고객님들께 전달되었습니다.',
        'noti.job-offer.hired': '{client}님이 고객님을 선택하셨습니다.',
        'noti.job-offer.fired': '{client}님이 취소를 요청하셨습니다.',
        'noti.job-offer.cancel-canceled': '{issuer}님이 취소 요청을 철회하셨습니다.',
        'noti.job-offer.agree-canceled': '{issuer}님이 취소 요청을 수락하셨습니다.',
        'noti.job-offer.closed': '{client}님이 올린 공고가 마감되었습니다.',
        'noti.job-offer.reopened': '{client}님이 올린 공고가 다시 활성화되었습니다.',
        'noti.job-offer.finished': '{client}님이 업무를 마쳤다고 알려오셨습니다.',
        'noti.job-offer.reviewed': '{reviewer}님이 고객님에 대한 리뷰를 올리셨습니다.',
        'noti.sys.account-suspended': '고객님의 계정의 다음의 이유로 정지되었습니다: {reason}',
        'noti.sys.report.channel': '고객님이 플랫폼 {channelName} 관련하여 신고하신 건에 대한 응답이 접수되었습니다.',
        'noti.sys.report.profile': '고객님이 프로필 {profile}님 관련하여 신고하신 건에 대한 응답이 접수되었습니다.',
        'noti.sys.report.at': '고객님이 {at}에 신고하신 건에 대한 응답이 접수되었습니다.',
        'noti.sys.penalty.expel': '고객님의 계정이 강제로 삭제되었습니다.',
        'noti.sys.penalty.suspend': '고객님의 계정이 {hours} 시간 동안 이용 정지되었습니다.',
        'noti.sys.penalty.warning': '!! 경고 !! -- {reason}',
        'noti.sys.penalty.warning.unknown': '자세한 내용은 고객 센터로 문의해 주시기 바랍니다.',
        'noti.sys.penalty.warning.message': '합당한 메시지만 게시하셔야 합니다.',
        'noti.sys.penalty.warning.channel': '합당한 방식으로 플랫폼을 운영하셔야 합니다.',
        'noti.sys.penalty.release': '고객님 계정의 일시 이용 정지가 해제되었습니다.',
        'noti.sys.penalty.unknown': '고객님 계정에 벌칙이 부과되었습니다. 자세한 내용은 고객 센터로 문의해 주시기 바랍니다.',
        'push.title': '알림',
        'push.sent-attachment': '첨부 파일을 보냈습니다.',
    },
    en: {
        'noti.ch-expert-requested': 'A request for {channelName}\'s new {expertName} certification request has been initiated by {requester}',
        'noti.ch-expert-approved': '{channelName}\'s {expertName} certification request has been approved',
        'noti.ch-expert-denied': '{channelName}\'s {expertName} certification request has been denied',
        'noti.ch-admin-assigned': 'You have been designated as a sub-administrator of {channelName}.',
        'noti.ch-admin-removed': 'The sub-administrator status for {channelName} has been revoked.',
        'noti.ch-became-owner': 'You have become the administrator of {channelName}.',
        'noti.ch-expelled': 'You have been forcibly removed from {channelName}.',
        'noti.ch-suspended': 'You have been temporarily suspended from {channelName}.',
        'noti.ch-mesg-screened': 'The content you posted has been deemed inappropriate and has been deleted.',
        'noti.job-offer.delievered': 'The posting you submitted has been delivered to {count} users.',
        'noti.job-offer.hired': '{client} has hired you.',
        'noti.job-offer.fired': '{client} has requested a cancellation.',
        'noti.job-offer.cancel-canceled': '{issuer} has withdrawn the cancellation request.',
        'noti.job-offer.agree-canceled': '{issuer} has accepted the cancellation request.',
        'noti.job-offer.closed': '{client}\'s posting has been closed.',
        'noti.job-offer.reopened': '{client}\'s posting has been reactivated.',
        'noti.job-offer.finished': '{client} has informed that he/she has completed his/her tasks.',
        'noti.job-offer.reviewed': '{reviewer} has posted a review for you.',
        'noti.sys.account-suspended': 'Your account has been suspended for the following reasons: {reason}',
        'noti.sys.report.channel': 'A response to the report you filed regarding platform {channelName} has been received.',
        'noti.sys.report.profile': 'A response to the report you filed regarding profile {profile} has been received.',
        'noti.sys.report.at': 'A response to the report you filed at {at} has been received.',
        'noti.sys.penalty.expel': 'Your account has been forcibly deleted.',
        'noti.sys.penalty.suspend': 'Your account has been suspended for {hours} hours.',
        'noti.sys.penalty.warning': '!! WARNING !! -- {reason}',
        'noti.sys.penalty.warning.unknown': 'Please contact the customer center for further details.',
        'noti.sys.penalty.warning.message': 'You should post only appropriate messages.',
        'noti.sys.penalty.warning.channel': 'You should operate the platform in an appropriate manner.',
        'noti.sys.penalty.release': 'The temporary suspension of your account has been lifted.',
        'noti.sys.penalty.unknown': 'A penalty has been imposed on your account. Please contact the customer center for more details.',
        'push.title': 'Notification',
        'push.sent-attachment': 'Message with attachments.',
    },
};
function i18n(lang, key, values) {
    var _a, _b;
    let str = (_b = (_a = dictionary[lang]) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : '';
    if (values) {
        Object.entries(values).forEach(([k, v]) => {
            str = str.replace(`{${k}}`, v);
        });
    }
    return str;
}
exports.i18n = i18n;
