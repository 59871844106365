import { ClientType } from '../apollo'
import { types } from '../types'

import { createOneUserAdAction } from '@dinosband/bv-main-schema/dist/client-gql'

export async function createUserAdAction (client: ClientType, data: types.UserAdActionCreateInput): Promise<types.UserAdAction | undefined> {
  try {
    const result = await client.mutate({
      mutation: createOneUserAdAction,
      variables: {
        data,
      } as types.MutationCreateOneUserAdActionArgs,
    })
    return result.data.createOneUserAdAction as types.UserAdAction
  } catch (e) {
  }
}
