import i18n from '@dinosband/bv-i18n'
import { getUserLanguage } from '@dinosband/bv-i18n'
import { intlFormatDistance } from 'date-fns'
// import { ko } from 'date-fns/locale'

export function formatPhoneNumber (input: string): string {
  const number = input.replace(/[^0-9]/g, '')

  const first = 3, third = 4
  let second = 4

  if (number.length < (first + second + third)) {
    second = 3
  }
  let phoneStr = number.substr(0, first)
  if (number.length <= first) {
    return phoneStr
  }
  phoneStr += '-' + number.substr(first, second)
  if (number.length <= first + second) {
    return phoneStr
  }
  phoneStr += '-' + number.substr(first + second, third)
  return phoneStr
}

export function formatPastTime (targetTime: string): string {
  return intlFormatDistance(new Date(targetTime), new Date(), { locale: i18n.locale })
  // const pastTime = new Date(2021, 1, 8, 0, 12, 5) // test
  // if (!targetTime) {
  //   return i18n.tc('strings.src.format')
  // }
  // const pastTime = new Date(targetTime)
  // const now = new Date()

  // const beetweenTime = Math.floor((now.getTime() - pastTime.getTime()) / 1000)

  // if (beetweenTime < 60) {
  //   return i18n.tc('format.now')
  // } else if (beetweenTime < 3600) {
  //   const minute = Math.floor(beetweenTime / 60)
  //   return i18n.tc('format.minute', minute, { 'count': minute })
  // } else if (beetweenTime < 86400) {
  //   const hour = Math.floor(beetweenTime / 60 / 60)
  //   return i18n.tc('format.ago', hour, { 'count': hour })
  // } else if (beetweenTime < 604800) {
  //   const day = Math.floor(beetweenTime / 60 / 60 / 24)
  //   return i18n.tc('format.ago', day, { 'count': day })
  // } else if (pastTime.getFullYear() === now.getFullYear()) {
  //   return i18n.tc('utils.timeformatMMDD', 2, {
  //     'month': (pastTime.getMonth() + 1).toString(),
  //     'date': pastTime.getDate().toString(),
  //   })
  // } else {
  //   return i18n.tc('utils.timeformatYYMMDD', 3, {
  //     'year': pastTime.getFullYear().toString(),
  //     'month': (pastTime.getMonth() + 1).toString(),
  //     'date': pastTime.getDate().toString(),
  //   })
  // }
}

export function isValidDate (dateStr: string): boolean {
  const time = new Date(dateStr)
  const year = time.getFullYear()
  return 2021 <= year && year < 3000
}

export function formatDate (targetTime: Date): string {
  const time = new Date(targetTime)
  return i18n.tc('utils.timeformatYYMMDD', 3, {
    'year': time.getFullYear().toString(),
    'month': (time.getMonth() + 1).toString(),
    'date': time.getDate().toString(),
  })
}

export function formatDateSimple (targetTime: Date): string {
  const time = new Date(targetTime)
  const year = time.getFullYear() - 2000
  const month = time.getMonth() + 1
  return i18n.tc('utils.timeformatYYMM', 2, {
    'year': year.toString(),
    'month': month.toString(),
  })
}

export enum DateFormat {
  YEAR_MONTH_DATE_WEEK, //	1988년 5월 8일 일요일 / Sunday, May 8, 1988
  YEAR_MONTH_DATE,	// 1988년 5월 8일 / May 8, 1988
  MONTH_DATE, // 5월 8일 / May 8
  YEAR_MONTH, // 2023년 8월
}

export function toLocaleDateString (date: Date, format: DateFormat): string {
  let opt: Intl.DateTimeFormatOptions | undefined = undefined
  if (format === DateFormat.YEAR_MONTH_DATE_WEEK) {
    opt = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  } else if (format === DateFormat.YEAR_MONTH_DATE) {
    opt = { year: 'numeric', month: 'long', day: 'numeric' }
  } else if (format === DateFormat.MONTH_DATE) {
    opt = { month: 'long', day: 'numeric' }
  } else if (format === DateFormat.YEAR_MONTH) {
    opt = { month: 'long', year: 'numeric' }
  }
  return date.toLocaleDateString(getUserLanguage(), opt)
}

export enum TimeFormat {
  HOUR_MINUTE, //	오전 03:30 / 03:30 AM
}

export function toLocaleTimeString (date: Date, format: TimeFormat = TimeFormat.HOUR_MINUTE): string {
  let opt: Intl.DateTimeFormatOptions | undefined = undefined
  if (format === TimeFormat.HOUR_MINUTE) {
    opt = { hour: '2-digit', minute: '2-digit' }
  }
  return date.toLocaleTimeString(getUserLanguage(), opt)
}